import apiBase from './apiBase';

export type GetCategoriesRes = {
  id: number;
  name: string;
}[];

export const getCategories = () => apiBase.get<GetCategoriesRes>('/categories');

export const addCategory = (params: { name: string }) =>
  apiBase.post('/category', params);

export const updateCategory = (params: { name: string }, categoryId: number) =>
  apiBase.put(`/category/${categoryId}`, params);

export const deleteCategory = (categoryId: number) =>
  apiBase.delete(`/category/${categoryId}`);
