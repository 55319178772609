import apiBase from './apiBase';

export type Point = {
  id: number;
  year: number;
  month: number;
  total_sale_amount: number;
  used_amount: number;
  granted_point: number;
  disappeared_amount: number;
  issued_balance: number;
};

export type GetPointRes = {
  data: Point[];
};

export const getPoint = () => apiBase.get<GetPointRes>('/point');
