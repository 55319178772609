import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as LeftArrow } from '../../../image/pagenate_left.svg';
import { ReactComponent as RightArrow } from '../../../image/pagenate_right.svg';
import { ReactComponent as Dot } from '../../../image/pagenate_dot.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
`;
const FooterArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 42px;
`;

const LeftArrowIcon = styled(LeftArrow)`
  margin-right: 39px;
`;
const RightArrowIcon = styled(RightArrow)``;

const DotIcon = styled(Dot)`
  margin-right: 39px;
`;

const NumArea = styled.div`
  display: flex;
  align-items: center;
`;

const Num = styled.div<{ isActive: boolean }>`
  padding-left: 1px;
  width: 26px;
  height: 26px;
  text-align: center;
  padding-top: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-right: 39px;

  ${({ isActive }) =>
    isActive
      ? `
    background: #212121;
    color: #FFFFFF;
    border-radius: 50%;
  `
      : `
    color: #212121;
  `}
`;

type Props = {
  list: any[];
  children: <T>(list: T[]) => React.ReactNode;
};

export const PageNation: React.FC<Props> = ({ list, children }) => {
  const [pageNate, setPageNate] = useState(1);
  useEffect(() => {
    setPageNate(1);
  }, [list]);
  const showCount = 20; // 1ページあたり表示する件数
  const showNumber = 2; // フッターに表示する数値の数(アクティブ数値を基準に左右の数)
  const last = Math.ceil(list.length / showCount);
  const footerNumbers = Array.from(new Array(last)).map((_, i) => i + 1);
  const skipFooterNumbers = footerNumbers.filter(
    (v) =>
      // 最初の数値である
      v === 1 ||
      // アクティブ数値より、プラスマイナスshowNumberでない
      (pageNate - showNumber <= v && v <= pageNate + showNumber) ||
      // 最後の数値である
      v === last,
  );

  const footerNumElm = skipFooterNumbers.map((num, i) => {
    return (
      <NumArea key={num}>
        <Num
          key={num}
          isActive={pageNate === num}
          onClick={() => setPageNate(num)}
        >
          {num}
        </Num>
        {last !== num && num + 1 !== skipFooterNumbers[i + 1] && <DotIcon />}
      </NumArea>
    );
  });

  return (
    <Wrapper>
      {children(list.slice(showCount * (pageNate - 1), showCount * pageNate))}
      <FooterArea>
        <LeftArrowIcon
          onClick={() => pageNate !== 1 && setPageNate(pageNate - 1)}
        />
        {footerNumElm}
        <RightArrowIcon
          onClick={() => pageNate !== last && setPageNate(pageNate + 1)}
        />
      </FooterArea>
    </Wrapper>
  );
};
