import React from 'react';
import styled from 'styled-components';
import dayjs, { getTime } from '../../../lib/utils/dayjs';

import { ReservationTableHeader } from './ReservationTableHeader';
import { ReservationTableLeftBar } from './ReservationTableLeftBar';
import { ReservationTableBody } from './ReservationTableBody';

const Wrapper = styled.div`
  display: flex;
`;

const ReservationTableArea = styled.div`
  overflow-x: scroll;
  border-top: 1px solid #979797;
`;

const stylistMockData = [
  {
    id: 1,
    name: 'フリー',
    canReservationCount: 1,
    schedules: [
      {
        startTime: '2019/07/24 9:00',
        endTime: '2019/07/24 10:30',
      },
    ],
  },
  {
    id: 2,
    name: '青空　一郎',
    canReservationCount: 1,
    schedules: [
      {
        startTime: '2019/07/24 9:00',
        endTime: '2019/07/24 10:00',
      },
      {
        startTime: '2019/07/24 10:00',
        endTime: '2019/07/24 11:00',
      },
    ],
  },
  {
    id: 3,
    name: '青空　二郎',
    canReservationCount: 1,
    schedules: [
      {
        startTime: '2019/07/24 13:00',
        endTime: '2019/07/24 14:00',
      },
    ],
  },
  {
    id: 4,
    name: '青空　三郎',
    canReservationCount: 1,
    schedules: [],
  },
  {
    id: 5,
    name: '青空　四郎',
    canReservationCount: 1,
    schedules: [],
  },
];

const stepMinutes = 30; // スケジュールに表示する刻み時間(分)
const salesStartTime = '9:00'; // 営業開始日 (各店舗によって異なる場合はfetchする)
const salesEndTime = '22:00'; // 営業終了日 (各店舗によって異なる場合はfetchする)

type Props = {
  day: dayjs.Dayjs;
};

export const ReservationTable: React.FC<Props> = ({ day }) => {
  const [startHour, startMinute] = salesStartTime.split(':');
  const [endHour, endMinute] = salesEndTime.split(':');
  const days = getTime(
    day,
    stepMinutes,
    +startHour,
    +startMinute,
    +endHour,
    +endMinute,
  );

  return (
    <Wrapper>
      <ReservationTableLeftBar stylists={stylistMockData} />
      <ReservationTableArea>
        <ReservationTableHeader
          days={days}
          stepMinutes={stepMinutes}
          schedules={stylistMockData.flatMap((it) => it.schedules)}
        />
        <ReservationTableBody days={days} stylists={stylistMockData} />
      </ReservationTableArea>
    </Wrapper>
  );
};
