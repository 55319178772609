import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import useSWR from 'swr';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table, Column, Row } from '../parts/lv1/Table';
import { Navigation } from '../parts/lv1/Navigation';
import { Paging } from '../parts/lv1/Paging';
import { Coupon, getStylistCoupons } from '../../lib/api/coupon';
import { FontSize } from '../../lib/styles/variables';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TitleMargin = styled.div`
  width: 20%;
  margin: 0 0 12px 0;
`;

const TableMargin = styled.div`
  margin: 24px 0 60px 0;
`;

const Button = styled.button`
  font-size: 12px;
  color: #4a90e2;
  background: #fff;
`;

const LeftColumn = styled.div`
  width: 100%;
  text-align: right;
`;

const SelectBoxArea = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
  position: relative;

  span {
    width: 9px;
    font-size: 9px;
    pointer-events: none;
  }
`;

const SelectBox = styled.select`
  width: 39px;
  height: 18px;
  border: none;
  border-radius: 0;
  background: #fff;
  letter-spacing: 1.63304px;
  color: #757575;
`;

export const ContentShopCoupons = () => {
  const salonId = 1;
  const stylistId = 1000001;
  const { data: getStylistCouponsRes } = useSWR('getStylistCoupons', () =>
    getStylistCoupons({
      salonId,
      stylistId,
    }),
  );

  const history = useHistory();

  const links = [
    { name: '店舗クーポン', to: `/content/coupon/shop`, isselected: true },
    {
      name: '美容師クーポン',
      to: `/content/coupon/stylist`,
      isselected: false,
    },
  ];

  const columns: Column<Coupon>[] = [
    {
      header: 'メニュー',
      accessor: (row: Row<Coupon>) =>
        row.original.menus.map((menu) => menu.name).join(','),
      width: 150,
    },
    {
      header: 'クーポン名',
      accessor: 'name',
      width: 680,
    },
    {
      header: '価格',
      accessor: (row: Row<Coupon>) => (
        <LeftColumn>{`${row.original.price.toLocaleString()}円`}</LeftColumn>
      ),
      width: 144,
    },
    {
      header: '',
      accessor: (row: Row<Coupon>) => (
        <Button
          onClick={() =>
            history.push(`/content/coupon/shop/${row.original.id}/edit`)
          }
        >
          編集
        </Button>
      ),
      width: 60,
    },
    {
      header: '',
      accessor: (row: Row<Coupon>) => (
        <SelectBoxArea>
          <SelectBox name="status" value={row.original.is_public.toString()}>
            <option value="true">掲載</option>
            <option value="false">非掲載</option>
          </SelectBox>
          <span>▼</span>
        </SelectBoxArea>
      ),
      width: 60,
    },
  ];

  const coupons = useMemo(() => getStylistCouponsRes?.data.data ?? [], [
    getStylistCouponsRes,
  ]);

  return (
    <Wrapper>
      <TopContent>
        <TitleMargin>
          <MainContentTitle text="店舗クーポン" />
        </TitleMargin>
        <Navigation links={links} />
      </TopContent>

      <SquareButton
        text="新規作成"
        width={136}
        height={40}
        fontSize={15}
        borderRadius={4}
        color="BLUE"
        handleClick={() => history.push(`/content/coupon/shop/add`)}
      />
      <TableMargin>
        <Table columns={columns} data={coupons} />
      </TableMargin>
    </Wrapper>
  );
};
