/* eslint no-plusplus: 0 */
import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { SquareButton } from '../parts/lv3/SquareButton';
import { DatePicker } from '../parts/lv3/DatePicker';
import { FontSize } from '../../lib/styles/variables';
import { Triangle } from '../parts/lv3/Triangle';
import { Table } from '../parts/lv1/Table';
import { PerShopSearchForm } from '../parts/lv1/PerShopSearchForm';
import { Margin } from '../parts/lv3/Margin';

const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 64px;
  margin-right: 16px;
  margin-left: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const DatePickerArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`;

const Hyphen = styled.span`
  font-size: ${FontSize.X_LARGE};
  font-weight: bold;
  line-height: 100%;
`;

export interface PerShopSearch {
  // TODO:: idに変更
  shopNames: string[];
}

const columns = [
  { header: '店舗', accessor: 'accessor1', width: 107 },
  { header: '出金者ID', accessor: 'accessor2', width: 78 },
  { header: '従業員名', accessor: 'accessor3', width: 120 },
  { header: '金額', accessor: 'accessor4', width: 106 },
  { header: '科目', accessor: 'accessor5', width: 106 },
  { header: '備考', accessor: 'accessor6', width: 587 },
];

const data = Array.from({ length: 10 }, (_, i) => ({
  accessor1: ['渋谷', '新宿'][i % 2],
  accessor2: '123456',
  accessor3: '田中　太郎',
  accessor4: '100,000円',
  accessor5: '',
  accessor6: '',
}));

export const PerShop = () => {
  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs()
      .add(1, 'month')
      .format('YYYY-MM-DD'),
  );

  const [search, setSearch] = useState<PerShopSearch>({ shopNames: [] });
  const searchData = () => {
    if (search.shopNames.length === 0) {
      return data;
    }
    return data.filter((it) => search.shopNames.includes(it.accessor1));
  };

  return (
    <Wrapper>
      <TopContentWrapper>
        <MainContentTitle text="店舗毎売上と入出金" />
        <SquareButton
          fontSize={16}
          width={242}
          height={31}
          text="CSVで出金情報を書き出す"
          color="BLUE_BORDER"
          handleClick={() => console.log(startDate, endDate)}
        />
      </TopContentWrapper>

      <DatePickerArea>
        <DatePicker
          date={startDate}
          handleChange={(date) => setStartDate(date)}
          style={{ fontSize: '20px', width: '158px', height: '30px' }}
        />
        <Hyphen> - </Hyphen>
        <DatePicker
          date={endDate}
          handleChange={(date) => setEndDate(date)}
          style={{ fontSize: '20px', width: '158px', height: '30px' }}
        />
        <Triangle size={14} />
      </DatePickerArea>
      <PerShopSearchForm setSearch={setSearch} />
      <Margin margin="40px 0px 0px 0px">
        <Table data={searchData()} columns={columns} />
      </Margin>
    </Wrapper>
  );
};
