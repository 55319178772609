import apiBase from './apiBase';
import { PageData } from './utils';

export type Material = {
  id: string;
  name: string;
  code: string;
  category_name: string;
  dealer_name: string;
  is_salon_enipment: number;
  order_price: number;
  standard_amount: number;
  store_sale_price: number;
};

export type ResponseGetMaterials = {
  data: Material[];
} & PageData;

export const getMaterials = async (salonId: string) => {
  const { data } = await apiBase.get<ResponseGetMaterials>(
    `/salon/${salonId}/material/materials`,
  );

  return data;
};
