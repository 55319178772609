import React from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

type StyledInputProps = {
  fontSize?: string;
  width?: string;
  height: string;
  weight?: string;
};

const StyledInput = styled.input<{ style: StyledInputProps }>`
  border: none;
  color: #212121;
  background: #fff;
  font-size: ${({ style }) => style.fontSize ?? '16px'};
  font-weight: ${({ style }) => style.weight ?? 'bold'};
  line-height: ${({ style }) => style.height ?? 'auto'};
  letter-spacing: 1.36087px;
  width: ${({ style }) => style.width ?? 'auto'};
  position: relative;

  ::-webkit-clear-button {
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0%;
  }
`;

type Props = {
  id?: string;
  date: string;
  handleChange: (date: string) => void;
  type?: 'date' | 'month';
  style: StyledInputProps;
  min?: string;
  max?: string;
};

export const DatePicker: React.FC<Props> = ({
  id,
  date,
  handleChange,
  type,
  style,
  min,
  max,
}) => {
  return (
    <>
      <StyledInput
        id={id}
        type={type || 'date'}
        value={date}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(event.target.value)
        }
        style={style}
        min={min}
        max={max}
      />
    </>
  );
};
