import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin-right: 2rem;
`;

const InputCustom = styled.input`
  border: none;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  line-height: 36px;
  width: 100%;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const Label = styled.div`
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  color: #757575;
`;

const Unit = styled.div<{ isDisabled?: boolean }>`
  position: absolute;
  top: 2rem;
  right: 1rem;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.63304px;
  color: ${({ isDisabled }) => (isDisabled ? '#D8D8D8' : '#212121')};
`;

export const StylistDetailInputText: FC<{
  value: string | number | undefined;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string;
  unit?: string;
  disabled?: boolean;
}> = ({ value = '', label, onChange, type, placeholder, unit, disabled }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <div>
        <InputCustom
          value={value}
          type={type || 'text'}
          placeholder={placeholder}
          onChange={onChange}
          required
          disabled={disabled}
        />
        {unit && <Unit isDisabled={disabled}>{unit}</Unit>}
      </div>
    </Wrapper>
  );
};
