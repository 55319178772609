import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router';
import { Navigation } from '../parts/lv1/Navigation';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Color } from '../../lib/styles/variables';
import { deleteReward, getRewards, Reward } from '../../lib/api/stylist';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperContent = styled.div`
  width: 100%;
`;

const WrapperInnerNavi = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 2rem;
  border-collapse: collapse;
  tr {
    th {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      background: #f1f1f1;
    }
    td {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-size: 14px;
    }
  }
`;

const ButtonCancel = styled.button`
  color: ${Color.RED};
  background: none;
`;

const WrapperPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Color.BLUE};
  background: none;
`;

const Index = styled.button<{ isCurrent: boolean }>`
  width: 30px;
  margin: 0 1rem;
  height: 30px;
  border-radius: 30px;
  background: ${({ isCurrent }) => (isCurrent ? '#000' : 'none')};
  color: ${({ isCurrent }) => (isCurrent ? '#fff' : '#000')};
`;

const WrapperIndexes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StylistReward = () => {
  const { id, stylistId } = useParams<{ id: string; stylistId: string }>();
  const history = useHistory();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [rewards, setRewards] = useState<Reward[]>([]);

  useEffect(() => {
    getRewards(stylistId)
      .then(({ data }) => {
        setRewards(data.data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [stylistId]);

  const indexes = useMemo(() => {
    const numberOfIndexes = Math.ceil(rewards.length / 20) || 1;
    return Array.from(Array(numberOfIndexes).keys());
  }, [rewards]);

  const links = useMemo(
    () => [
      {
        name: '基本情報',
        to: `/store/detail/${id}/stylist/detail/${stylistId}`,
        isselected: false,
      },
      {
        name: '顧客リスト',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/customer`,
        isselected: false,
      },
      {
        name: '売上実績',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/sales`,
        isselected: false,
      },
      {
        name: '評価',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation`,
        isselected: false,
      },
      {
        name: '手当',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/allowance`,
        isselected: false,
      },
      {
        name: '報酬保証',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/reward`,
        isselected: true,
      },
      {
        name: '賞与',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/bonus`,
        isselected: false,
      },
      {
        name: '歩合',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/commission`,
        isselected: false,
      },
    ],
    [id, stylistId],
  );

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickAdd = useCallback(() => {
    history.push(`/store/detail/${id}/stylist/detail/${stylistId}/reward/add`);
  }, [history, id, stylistId]);

  const handleClickIndex = useCallback(
    (index: number) => () => {
      setCurrentIndex(index);
    },
    [setCurrentIndex],
  );

  const handleClickCancel = useCallback(
    (rewardId: number) => async () => {
      try {
        await deleteReward(String(rewardId));
      } catch (e) {
        alert('エラーが発生しました');
      }
    },
    [],
  );

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="美容師詳細" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} />
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperInnerNavi>
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="追加"
            borderRadius={4}
            color="BLUE"
            handleClick={handleClickAdd}
          />
        </WrapperInnerNavi>

        <WrapperContent>
          <StyledTable>
            <thead>
              <tr>
                <th>日付</th>
                <th>内容</th>
                <th>補償額</th>
                <th>保証期間</th>
                <th>契約状況</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {rewards.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.updated_at.split(' ')[0]}</td>
                    <td>{item.subject}</td>
                    <td>{`${item.amount.toLocaleString()}円`}</td>
                    <td>
                      {`${item.from_date.replaceAll(
                        '-',
                        '/',
                      )} 〜 ${item.to_date.replaceAll('-', '/')}`}
                    </td>
                    {/* TODO: API修正後対応 */}
                    <td>TODO</td>
                    <td>
                      <ButtonCancel
                        type="button"
                        onClick={handleClickCancel(item.id)}
                      >
                        取り消し
                      </ButtonCancel>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
          <WrapperPagination>
            <img
              src={`${process.env.PUBLIC_URL}/assets/arrow_circle_left.svg`}
              alt="arrow_circle_left"
            />

            <WrapperIndexes>
              {indexes.map((item) => {
                return (
                  <Index
                    key={item}
                    isCurrent={item === currentIndex}
                    onClick={handleClickIndex(item)}
                  >
                    {item + 1}
                  </Index>
                );
              })}
            </WrapperIndexes>

            <img
              src={`${process.env.PUBLIC_URL}/assets/arrow_circle_right.svg`}
              alt="arrow_circle_right"
            />
          </WrapperPagination>
        </WrapperContent>
      </WrapperShadow>
    </Wrapper>
  );
};
