type GetQueryParams = {
  [key: string]: any;
};

const getQueryParams = (queryParams: GetQueryParams): string => {
  const keys = Object.keys(queryParams);
  const queries: any[] = [];

  keys.forEach((key) => {
    if (queryParams[key] && queryParams[key] !== '') {
      queries.push(`${key}=${queryParams[key]}&`);
    }
  });

  return queries.join('');
};

export default getQueryParams;
