import React from 'react';
import styled from 'styled-components';

type Props = {
  style: {
    height: number;
    width: number;
    color: string;
  };
  direction: 'UP' | 'DOWN' | 'RIGHT' | 'LEFT';
  handleClick?: () => void;
};

type WrapperProps = {
  style: Props['style'];
  direction: Props['direction'];
};

const Wrapper = styled.div<WrapperProps>`
  color: ${({ style }) => (style.color ? style.color : 'auto')};

  &[direction='UP'] {
    transform: rotate(90deg);
  }

  &[direction='DOWN'] {
    transform: rotate(-90deg);
  }

  &[direction='RIGHT'] {
    transform: rotate(180deg);
  }

  img {
    width: ${({ style }) => (style.width ? `${style.width}px` : 'auto')};
    height: ${({ style }) => (style.height ? `${style.height}px` : 'auto')};
  }
`;

export const ArrowIcon: React.FC<Props> = ({
  style,
  direction,
  handleClick,
}) => {
  return (
    <Wrapper onClick={handleClick} style={style} direction={direction}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/arrow-left.svg`}
        alt="arrow_icon"
      />
    </Wrapper>
  );
};
