import axios from 'axios';
import apiBase from './apiBase';
import configuration from '../config';

export type AccountType = {
  id: number;
  login_id: string;
  user_name: string;
  role: string;
  is_deleted: number;
  created_at: string;
  updated_at: string;
};

export type AuthParams = {
  login_id: string;
  password: string;
};

export const login = (params: AuthParams) =>
  axios({
    method: 'POST',
    url: `${configuration.apiURL}/login`,
    data: params,
  });

export const register = (params: AuthParams) =>
  axios({
    method: 'POST',
    url: `${configuration.apiURL}/register`,
    data: params,
  });

export const getMe = () => apiBase.get<AccountType>('/me');

export type UpdateProfileParams = {
  user_name: string;
};

export const updateProfile = (params: UpdateProfileParams) =>
  apiBase.put<AccountType>('/me', params);
