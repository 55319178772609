import React from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import { FontSize } from '../../../lib/styles/variables';

type Props = {
  message: any;
  large?: boolean;
};

const Wrapper = styled.div<{ large?: boolean }>`
  color: #da3922;
  padding: 4px 0;
  font-size: ${({ large }) => (large ? FontSize.X_LARGE : 'inherit')};
`;

export const ValidationErrorMessage: React.FC<Props> = ({ message, large }) => {
  return <Wrapper large={large}>{message}</Wrapper>;
};

type PropsMessageList = {
  errors: FieldErrors<FieldValues>;
  errorMessageList?: string[];
};
export const ValidateErrorMessageList: React.FC<PropsMessageList> = ({
  errors,
  errorMessageList,
}) => {
  const messageDom = Object.keys(errors).map((key) => {
    const error = errors[key];
    return <p key={error.message}>{error.message}</p>;
  });
  if (errorMessageList) {
    return (
      <ValidationErrorMessage
        message={[
          ...messageDom,
          ...errorMessageList.map((errorMessage) => (
            <p key={errorMessage}>{errorMessage}</p>
          )),
        ]}
      />
    );
  }
  return <ValidationErrorMessage message={messageDom} />;
};
