import React, { FC } from 'react';
import styled from 'styled-components';
import { WorkingCondition } from '../../../lib/api/stylist';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      th {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        background: #f1f1f1;
      }
      td {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-size: 14px;
      }
    }
  }
`;

type Props = {
  working_conditions: WorkingCondition[];
};

export const StylistDetailTableWorkingCondition: FC<Props> = ({
  working_conditions,
}) => {
  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th>店舗</th>
            <th>グレード</th>
            <th>分配率</th>
          </tr>
        </thead>
        <tbody>
          {working_conditions.map((item) => {
            return (
              <tr key={item.salon_name}>
                <td>{item.salon_name}</td>
                <td>{item.grade}</td>
                <td>{`${item.distribution_rate}%`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};
