import { Reservation } from '../../components/pages/Reservation';
import apiBase from './apiBase';
import { User } from './user';

type ReservationCategory = {
  id: number;
  salon_id: number;
  name: string;
  reduction_rate: number;
};

export type ReservationMenu = {
  id: number;
  salon_id: number;
  name: string;
  description: string;
  price: number;
  minute: number;
  reward_point: number;
  categories: ReservationCategory[];
};

export type ReservationCoupon = {
  id: number;
  stylist_id: number;
  salon_id: number;
  image_url: string;
  name: string;
  price: number;
  minute: number;
  description: string;
  reward_point: number;
  categories: ReservationCategory[];
};

export type ReservationPayment = {
  use_point: number;
  price: number;
  payment_amount: number;
  reward_point: number;
};

export type BeInvitedByUser = User;

export type ReservationUser = User & { be_invited_by_user: BeInvitedByUser };

type PersonInCharge = {
  id: number;
  memo: string;
};

type Qa = {
  id: number;
  salon_id: number;
  seq: number;
  question: string;
  type: string;
  answer: string;
};

export type Reservation = {
  id: number;
  user_id: number;
  reservation_number: string;
  salon_id: number;
  menu_id: number;
  coupon_id: number;
  stylist_id: number;
  is_named: number;
  visit_salon_time: string;
  finish_time: string;
  price: number;
  use_point: number;
  payment_amount: number;
  reward_point: number;
  request: string;
  memo: string;
  status: number;
  review_point: number | null;
  review_message: string | null;
  status_label: string;
  menu: ReservationMenu;
  coupon: ReservationCoupon;
  user: ReservationUser;
  stylist: {
    id: number;
    user_name: string;
  };
  payment: ReservationPayment;
  qa: Qa[];
  person_in_charge: PersonInCharge;
};

export type GetReservationsOfUserRes = {
  user_name_kanji: string;
  user_name_kana: string;
  data: Reservation[];
};

type DeleteReservationRes = {
  id: string;
  object: string;
  canceled: string;
};

export const getReservationDetail = (reservationId: string) =>
  apiBase.get<Reservation>(`/reservation/${reservationId}`);

export const getReservationsOfUser = (salonId: number, customerId: string) =>
  apiBase.get<GetReservationsOfUserRes>(
    `/salon/${salonId}/user/${customerId}/reservations`,
  );

export const addReservation = (
  salonId: string | number,
  params: Partial<Reservation>,
) => apiBase.post<Reservation>(`/salon/${salonId}/reservation`, params);

export const updateReservation = (
  reservationId: string,
  params: Partial<Reservation>,
) => apiBase.put<Reservation>(`/reservation/${reservationId}`, params);

export const updatePersonInCharge = (
  reservationId: string,
  params: { id: number; memo: string },
) =>
  apiBase.put<DeleteReservationRes>(
    `/reservation/${reservationId}/personInCharge`,
    params,
  );

export const deleteReservation = (reservationId: string) =>
  apiBase.delete<DeleteReservationRes>(`/reservation/${reservationId}`);
