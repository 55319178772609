import React, { useContext, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { getSalons, Salon } from '../../lib/api/salon';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const TableWrapper = styled.div`
  width: 1000px;
  margin: auto;
  margin-top: 100px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr {
    th {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      background: #f1f1f1;
      :first-child {
        width: 80%;
      }
    }
    td {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-size: 16px;
      width: 200px;
      :first-child {
        width: 80%;
      }
    }
  }
  button {
    color: #4a90e2;
    background: none;
  }
`;

export const SelectSalon = () => {
  const history = useHistory();
  const { selectSalon } = useContext(AccountContext);
  const [salons, setSalons] = useState<Salon[]>([]);

  useEffect(() => {
    getSalons()
      .then(({ data }) => {
        setSalons(data.data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, []);

  const handleClickLogin = useCallback(
    (id: number) => () => {
      selectSalon(id);
      history.replace('/');
    },
    [history, selectSalon],
  );

  return (
    <Wrapper>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>店舗</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {salons.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>
                    <button type="button" onClick={handleClickLogin(item.id)}>
                      ログイン
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
};
