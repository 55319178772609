import apiBase from './apiBase';
import getQueryParams from '../utils/getQueryParams';
import { Product } from './product';
import { DescriptionType } from '../../components/pages/CreateTopics';

type GetBlogsParams = {
  brand_name: string;
  author: string;
  salon_name: string;
  form_date: string;
  to_date: string;
};

export type PostBlogsParams = {
  brand_name: string;
  author: string;
  salon_name: string;
  form_date: string;
  to_date: string;
};

export type Blog = {
  id: number;
  stylist_id: number;
  product_id: number;
  product?: Product;
  image_url: string;
  title: string;
  is_public: number;
  created_at: string;
  author: string;
  salon_name: string;
  brand_name: string;
  descriptions?: DescriptionType[];
};

type GetBlogsRes = {
  data: Blog[];
};

type PostBlogsRes = {
  data: Blog[];
};

export const getBlogs = (params: Partial<GetBlogsParams>) =>
  apiBase.get<GetBlogsRes>(`/blogs?${getQueryParams(params)}`);

export const getBlogInfo = (id: string) => apiBase.get<Blog>(`/blog/${id}`);

export const postBlogs = (params: Partial<PostBlogsParams>) =>
  apiBase.post<PostBlogsRes>(`/blogs?${getQueryParams(params)}`);
