import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin || 0};
  width: 100%;
`;

type Props = {
  margin: string;
};

export const Margin: React.FC<Props> = ({ margin, children }) => {
  return <Wrapper margin={margin}>{children}</Wrapper>;
};
