import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { FontSize, Color } from '../../lib/styles/variables';
import { ReactComponent as EyeOff } from '../../image/eye-off.svg';
import { ReactComponent as Eye } from '../../image/eye.svg';

import { addPosUser } from '../../lib/api/posUser';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FieldLabel = styled.p`
  font-size: ${FontSize.X_LARGE};
  line-height: 30px;
  color: #757575;
  margin-bottom: 8px;
`;

const InputField = styled.input`
  height: 40px;
  width: 80%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};

  ::placeholder {
    color: #bdbdbd;
  }
`;

const InputContentWrapper = styled.div``;

const InputFiledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EyeOffButton = styled.button`
  height: 24px;
  background: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  margin-right: 40px;
`;

const PassWord = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  flex-direction: row;
  align-items: stretch;
`;

const Margin = styled.div`
  margin-top: 40px;
`;

const ErrorText = styled.a`
  color: ${Color.RED};
`;

const PassWordTextField = ({
  name,
  isShow,
  handleClick,
  register,
}: {
  name: string;
  isShow: boolean;
  handleClick: () => void;
  register: any;
}) => (
  <InputFiledWrapper>
    <InputField
      type={isShow ? 'text' : 'password'}
      name={name}
      ref={register}
    />
    <EyeOffButton type="button" onClick={handleClick}>
      <EyeOff style={{ display: isShow ? 'none' : 'block' }} />
      <Eye style={{ display: isShow ? 'block' : 'none' }} />
    </EyeOffButton>
  </InputFiledWrapper>
);

export const AddPosUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const { register, errors, watch, handleSubmit, setError } = useForm();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(
    false,
  );

  const onSubmit = handleSubmit((input) => {
    const params = {
      login_id: input.userId,
      password: input.password,
      remarks: input.remarks,
    };
    addPosUser(id, params)
      .then(() => {
        history.goBack();
      })
      .catch((e) => {
        const { login_id } = e.errors;
        if (
          login_id &&
          login_id[0] === 'The login id has already been taken.'
        ) {
          setError(
            'userId',
            'registered',
            'ユーザーIDがすでに使用されています',
          );
        } else alert('登録に失敗しました');
      });
  });

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="追加" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: 4,
          minHeight: 'calc(100vh - 200px)',
          padding: '64px 64px 40px 64px',
        }}
      >
        <Form onSubmit={onSubmit}>
          <FieldLabel>ユーザーID</FieldLabel>
          <InputField
            type="text"
            name="userId"
            ref={register({
              required: '必須項目です',
              minLength: {
                value: 3,
                message: '3文字以上の英数字で入力してください',
              },
              pattern: {
                value: /^[A-Za-z0-9]+$/i,
                message: '3文字以上の英数字で入力してください',
              },
            })}
            placeholder="入力してください"
            style={{
              width: '40%',
            }}
          />
          {errors.userId && <ErrorText>{errors.userId.message}</ErrorText>}
          <PassWord>
            <InputContentWrapper style={{ flex: 1 }}>
              <FieldLabel>パスワード</FieldLabel>
              <PassWordTextField
                name="password"
                isShow={isShowPassword}
                register={register({
                  required: '必須項目です',
                  minLength: {
                    value: 8,
                    message: '8文字以上の英数字で入力してください',
                  },
                  pattern: {
                    value: /^[A-Za-z0-9]+$/i,
                    message: '8文字以上の英数字で入力してください',
                  },
                  validate: (value) => {
                    return (
                      (value && value === watch('confirmPassword')) ||
                      '入力されたパスワードが一致していません'
                    );
                  },
                })}
                handleClick={() => setIsShowPassword(!isShowPassword)}
              />
              {errors.password && (
                <ErrorText>{errors.password.message}</ErrorText>
              )}
            </InputContentWrapper>
            <InputContentWrapper style={{ flex: 1 }}>
              <FieldLabel>パスワード確認</FieldLabel>
              <PassWordTextField
                name="confirmPassword"
                isShow={isShowConfirmPassword}
                register={register({
                  required: '必須項目です',
                  minLength: {
                    value: 8,
                    message: '8文字以上の英数字で入力してください',
                  },
                  pattern: {
                    value: /^[A-Za-z0-9]+$/i,
                    message: '8文字以上の英数字で入力してください',
                  },
                })}
                handleClick={() =>
                  setIsShowConfirmPassword(!isShowConfirmPassword)
                }
              />
              {errors.confirmPassword && (
                <ErrorText>{errors.confirmPassword.message}</ErrorText>
              )}
            </InputContentWrapper>
          </PassWord>
          <Margin>
            <FieldLabel>備考</FieldLabel>
            <InputField
              type="text"
              name="remarks"
              ref={register({ required: true })}
              style={{
                width: '90%',
              }}
            />
          </Margin>
          {errors.remarks?.type === 'required' && (
            <ErrorText>必須項目です</ErrorText>
          )}
          <Margin>
            <SquareButton
              fontSize={15}
              height={40}
              width={136}
              text="登録"
              color="BLUE"
              type="submit"
            />
          </Margin>
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
