import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Table } from '../parts/lv1/Table';
import { PageNation } from '../parts/lv1/PageNation';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionsArea = styled.div`
  margin-bottom: 24px;
`;
const SaveBtn = styled.div`
  background: #4a90e2;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 0px;
  text-align: center;
  width: 136px;
`;

const EditBtn = styled.div`
  color: #4a90e2;
`;

const columns = [
  { header: '発生日', accessor: 'accessor1', width: 106 },
  { header: '従業員番号', accessor: 'accessor2', width: 85 },
  { header: '従業員名', accessor: 'accessor3', width: 128 },
  { header: '名目', accessor: 'accessor4', width: 200 },
  { header: '勘定科目', accessor: 'accessor5', width: 128 },
  { header: '金額', accessor: 'accessor6', width: 128 },
  { header: '備考', accessor: 'accessor7', minWidth: 100, width: 0 },
  { header: '', accessor: 'accessor8', width: 50 },
];

export const Expense = () => {
  const history = useHistory();

  const data = Array(220)
    .fill({})
    .map((_, i) => ({
      accessor1: '2020-02-02',
      accessor2: i + 1,
      accessor3: '田中　太郎',
      accessor4: '作業代',
      accessor5: '未払金',
      accessor6: '100,000,000円',
      accessor7: '',
      accessor8: (
        <EditBtn
          onClick={() => history.push(`/accounting/expense/edit/${i + 1}`)}
        >
          編集
        </EditBtn>
      ),
    }));

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="経費一覧" />
        </Margin>
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 0px 40px 67px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <ActionsArea>
          <SaveBtn onClick={() => history.push('/accounting/expense/register')}>
            新規作成
          </SaveBtn>
        </ActionsArea>
        <PageNation list={data}>
          {(list) => <Table data={list} columns={columns} />}
        </PageNation>
      </ShadedContainer>
    </Wrapper>
  );
};
