/* eslint no-plusplus: 0 */
import React, { useCallback, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { Color } from '../../lib/styles/variables';
import {
  getReservationsOfUser,
  GetReservationsOfUserRes,
} from '../../lib/api/reservation';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperShadow = styled.div`
  margin: 12px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperTable = styled.div<{ width: number; margin?: string }>`
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
`;

const StyledTable = styled.table`
  margin-bottom: 2rem;
  width: 100%;
  border-collapse: collapse;
  tr {
    th {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      background: #f1f1f1;
    }
    td {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-size: 14px;
    }
  }
`;

const ButtonLink = styled.button`
  color: ${Color.BLUE};
  background: none;
`;

export const ReservationCustomerStatus = () => {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const [userData, setUserData] = useState<GetReservationsOfUserRes | null>(
    null,
  );

  const links = [
    {
      name: '基本情報',
      to: `/reservation/customer/${customerId}/info`,
      isselected: false,
    },
    {
      name: '予約状況',
      to: `/reservation/customer/${customerId}/status`,
      isselected: true,
    },
  ];

  useEffect(() => {
    // TODO サロンID取得
    const salonId = 1;
    getReservationsOfUser(salonId, customerId)
      .then(({ data }) => {
        setUserData(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [customerId]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickLink = useCallback(
    (reservationId: string) => () => {
      history.push(
        `/reservation/customer/${customerId}/status/${reservationId}`,
      );
    },
    [history, customerId],
  );

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="山田 太朗" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} />
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperTable width={720} margin="0 0 36px 0">
          {userData && (
            <StyledTable>
              <thead>
                <tr>
                  <th>予約番号</th>
                  <th>予約者名</th>
                  <th>予約者名カナ</th>
                  <th>予約日時</th>
                  <th>来店時</th>
                </tr>
              </thead>
              <tbody>
                {userData.data.map((item) => {
                  return (
                    <tr key={item.reservation_number}>
                      <td>
                        <ButtonLink
                          type="button"
                          onClick={handleClickLink(item.reservation_number)}
                        >
                          {item.reservation_number}
                        </ButtonLink>
                      </td>
                      <td>{userData.user_name_kanji}</td>
                      <td>{userData.user_name_kana}</td>
                      <td>{item.visit_salon_time}</td>
                      <td>{item.visit_salon_time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          )}
        </WrapperTable>
      </WrapperShadow>
    </Wrapper>
  );
};

const WrapperEachInfo = styled.div`
  width: 300px;
`;

const Label = styled.div`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1.36087px;
  color: #757575;
`;

const Value = styled.div`
  margin-bottom: 36px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 1.63304px;
  color: #212121;
`;

export const EachInfo: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <WrapperEachInfo>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </WrapperEachInfo>
  );
};
