import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { SquareButton } from '../parts/lv3/SquareButton';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Color, FontSize } from '../../lib/styles/variables';
import { getAdmin, Admin as AdminType } from '../../lib/api/admin';
import { useSpinner } from '../../lib/hooks/useSpinner';
import { usePager } from '../../lib/hooks/usePager';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ::after {
    content: '';
    display: block;
    width: 494px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
`;

const ListContentArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 70%;
`;

const ListButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
`;

const ListContent = styled.div<{ isWide?: boolean }>`
  width: ${({ isWide }) => (isWide ? '100%' : '50%')};
`;

const ListContentTitle = styled.p`
  font-weight: bold;
  color: #757575;
  font-size: ${FontSize.MEDIUM};
  margin: 4px;
  letter-spacing: 1.0887px;
`;

const ListContentText = styled.p`
  color: ${Color.BRACK};
  font-size: ${FontSize.SMALL};
  letter-spacing: 0.952609px;
  width: 100%;
  margin: 4px;
  white-space: nowrap;
  overflow: hidden;
`;

export const Admin = () => {
  const [admins, setAdmins] = useState<AdminType[] | null>(null);
  const history = useHistory();
  const { loading, startLoading, finishLoading } = useSpinner();

  const handlePaganation = (index: number) => {
    startLoading();
    getAdmin(index)
      .then(({ data }) => {
        setAdmins(data.data);
      })
      .finally(() => {
        finishLoading();
      });
  };
  const { Pager, setLastPage } = usePager(handlePaganation);

  useEffect(() => {
    startLoading();
    getAdmin()
      .then(({ data }) => {
        setAdmins(data.data);
        setLastPage(data.last_page);
      })
      .finally(() => {
        finishLoading();
      });
  }, []);

  return (
    <Wrapper>
      <TopContentWrapper>
        <MainContentTitle text="管理者一覧" />
        <SquareButton
          fontSize={24}
          height={36}
          text="追加する"
          color="NO_BORDER"
          handleClick={() => history.push('/admin/add')}
        />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: 4,
          minHeight: 'calc(100vh - 200px)',
          padding: '24px 0',
        }}
      >
        <FlexWrapper>
          {!loading && admins
            ? admins.length >= 0
              ? admins.map((item) => (
                <ShadedContainer
                  key={item.id}
                  style={{
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                      borderRadius: 4,
                      width: 462,
                      height: 248,
                      margin: '16px',
                      padding: '40px',
                    }}
                >
                  <ListWrapper>
                    <ListContentArea>
                      <ListContent>
                        <ListContentTitle>ID</ListContentTitle>
                        <ListContentText>{item.id}</ListContentText>
                      </ListContent>
                      <ListContent>
                        <ListContentTitle>最終ログイン日</ListContentTitle>
                        <ListContentText>未登録</ListContentText>
                      </ListContent>
                      <ListContent isWide>
                        <ListContentTitle>店舗</ListContentTitle>
                        {item.manage_all_salons === 1 ? (
                          <ListContentText>全店</ListContentText>
                          ) : item.manage_salons ? (
                            <>
                              <ListContentText>
                                {item.manage_salons[0]
                                  ? item.manage_salons[0].name
                                  : ''}
                              </ListContentText>
                              <ListContentText>
                                {item.manage_salons[1]
                                  ? item.manage_salons[1].name
                                  : ''}
                              </ListContentText>
                            </>
                          ) : null}
                      </ListContent>
                      <ListContent isWide>
                        <ListContentTitle>備考</ListContentTitle>
                        <ListContentText>{item.remarks}</ListContentText>
                      </ListContent>
                    </ListContentArea>
                    <ListButtonArea>
                      <SquareButton
                        fontSize={16}
                        width={67}
                        height={31}
                        text="編集"
                        color="BLUE_BORDER"
                        handleClick={() =>
                            history.push({
                              pathname: '/admin/edit',
                              state: item,
                            })
                          }
                      />
                    </ListButtonArea>
                  </ListWrapper>
                </ShadedContainer>
                ))
              : 'アイテムがありません'
            : loading}
        </FlexWrapper>
        <Pager />
      </ShadedContainer>
    </Wrapper>
  );
};
