import React, { useState } from 'react';
import styled from 'styled-components';
import { SquareButton } from '../lv3/SquareButton';
import { Table } from './Table';
import { CheckBox } from '../lv3/CheckBoxWithText';

const Wrapper = styled.div`
  width: 1098px;
  padding: 32px 64px 40px 64px;
`;

const Title = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #757575;
  margin-bottom: 48px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

type Props = {
  closeModal: () => void;
};

export const ProductPublishModal: React.FC<Props> = ({ closeModal }) => {
  const [isPublish, setIsPublish] = useState<boolean[]>([false, false]);
  const [isRecomend, setIsRecomend] = useState<boolean[]>([false, false]);

  const size = {
    width: 14,
    height: 14,
  };

  const changePublish = (index: number) => {
    const nextArray = isPublish.concat();
    nextArray[index] = !nextArray[index];
    setIsPublish(nextArray);
  };

  const changeRecomend = (index: number) => {
    const nextArray = isRecomend.concat();
    nextArray[index] = !nextArray[index];
    setIsRecomend(nextArray);
  };

  const columns = [
    { header: 'ブランド', accessor: 'brand', width: 834 },
    { header: '掲載可否', accessor: 'isPublish', width: 68 },
    { header: 'おすすめ', accessor: 'recomend', width: 68 },
  ];

  const data = [
    {
      brand: 'Elenor',
      isPublish: (
        <CheckBox
          size={size}
          isChecked={isPublish[0]}
          handleClick={() => changePublish(0)}
        />
      ),
      recomend: (
        <CheckBox
          size={size}
          isChecked={isRecomend[0]}
          handleClick={() => changeRecomend(0)}
        />
      ),
    },
    {
      brand: 'Elenor',
      isPublish: (
        <CheckBox
          size={size}
          isChecked={isPublish[1]}
          handleClick={() => changePublish(1)}
        />
      ),
      recomend: (
        <CheckBox
          size={size}
          isChecked={isRecomend[1]}
          handleClick={() => changeRecomend(1)}
        />
      ),
    },
  ];

  return (
    <Wrapper>
      <Title>ブランド掲載</Title>
      <Table columns={columns} data={data} />
      <ButtonWrapper>
        <SquareButton
          text="登録"
          color="BLUE"
          height={56}
          width={160}
          fontSize={24}
          handleClick={() => closeModal()}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};
