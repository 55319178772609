import React from 'react';
import styled, { css } from 'styled-components';
import dayjs, {
  TimeHeight,
  ReservationCountHeight,
  CanReservationCountHeight,
  CellWidth,
  isBetween,
} from '../../../lib/utils/dayjs';

import { SelectBox } from '../lv3/SelectBox';
import { ScheduleType } from './ReservationTableBody';

const Wrapper = styled.div``;
const Area = css`
  display: flex;
`;
const TimeArea = styled.div`
  ${Area}
  height: ${TimeHeight};
`;
const ReservationCountArea = styled.div`
  ${Area}
  height: ${ReservationCountHeight}
`;
const CanReservationCountArea = styled.div`
  ${Area}
  height: ${CanReservationCountHeight};
`;

const Cell = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
`;
const Time = styled.div<{ colspan: number }>`
  ${Cell}
  min-width: calc(${CellWidth} * ${({ colspan }) => colspan});
`;
const ReservationCount = styled.div`
  ${Cell}
  min-width: ${CellWidth};
`;
const CanReservationCount = styled.div`
  ${Cell}
  min-width: ${CellWidth};
`;

type Props = {
  days: dayjs.Dayjs[];
  stepMinutes: number;
  schedules: ScheduleType[];
};

export const ReservationTableHeader: React.FC<Props> = ({
  days,
  stepMinutes,
  schedules,
}) => {
  const inHourCount = Number(60 / stepMinutes);
  const times = days
    .filter((_, v) => v % inHourCount === 0)
    .map((it) => (
      <Time key={`time-${it.format('HH:mm')}`} colspan={inHourCount}>
        {it.format('HH:mm')}
      </Time>
    ));

  return (
    <Wrapper>
      <TimeArea>{times}</TimeArea>
      <ReservationCountArea>
        {days.map((it) => (
          <ReservationCount key={`reservation-count-${it.format('HH:mm')}`}>
            {
              schedules.filter((s) => isBetween(it, s.startTime, s.endTime))
                .length
            }
          </ReservationCount>
        ))}
      </ReservationCountArea>
      <CanReservationCountArea>
        {days.map((it) => (
          <CanReservationCount
            key={`can-reservation-count-${it.format('HH:mm')}`}
          >
            <SelectBox
              control={{
                type: 'state',
                value: '0',
                onChange: () => {},
              }}
              style={{
                width: '47px',
                height: '22px',
                fontSize: '12px',
                border: 'all',
                padding: '0px 8px 0px 4px',
              }}
            >
              <option value="0">0</option>
            </SelectBox>
          </CanReservationCount>
        ))}
      </CanReservationCountArea>
    </Wrapper>
  );
};
