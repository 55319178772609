import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { Table } from '../parts/lv1/Table';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4a90e2;
  }
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const TableLabelContainer = styled.div`
  margin-bottom: 40px;
`;

export const CustomerReservation = () => {
  const history = useHistory();
  const { id } = useParams();

  const links = [
    { name: '基本情報', to: `/customer/${id}`, isselected: false },
    {
      name: '予約状況',
      to: `/customer/${id}/reservation`,
      isselected: true,
    },
  ];

  const columns = [
    { header: '予約番号', accessor: 'number', width: 150 },
    { header: '予約者名', accessor: 'name', width: 150 },
    { header: '予約者名カナ', accessor: 'nameKana', width: 150 },
    { header: '予約日時', accessor: 'reservationDate', width: 150 },
    { header: '来店時', accessor: 'visitDate', width: 150 },
  ];

  const data = [
    {
      number: (
        <Link to={`/customer/${id}/reservation/B431940637`}>B431940637</Link>
      ),
      name: '田中　太郎',
      nameKana: 'タナカ　タロウ',
      reservationDate: '2020-02-22 12:35',
      visitDate: '2020-02-22 12:35',
    },
    {
      number: (
        <Link to={`/customer/${id}/reservation/B431940637`}>B431940637</Link>
      ),
      name: '田中　太郎',
      nameKana: 'タナカ　タロウ',
      reservationDate: '2020-02-22 12:35',
      visitDate: '2020-02-22 12:35',
    },
    {
      number: (
        <Link to={`/customer/${id}/reservation/B431940637`}>B431940637</Link>
      ),
      name: '田中　太郎',
      nameKana: 'タナカ　タロウ',
      reservationDate: '2020-02-22 12:35',
      visitDate: '2020-02-22 12:35',
    },
  ];

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Wrapper>
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={handleClickArrowLeft}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text="山田太郎" />
        </Margin>
        <Navigation links={links} isSmall />
      </TopContent>
      <WrapperShadow>
        <TableLabelContainer>
          <Table columns={columns} data={data} />
        </TableLabelContainer>
      </WrapperShadow>
    </Wrapper>
  );
};
