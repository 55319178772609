import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { ShadedContainer } from '../layout/ShadedContainer';
import { PageNation } from '../parts/lv1/PageNation';
import { Table } from '../parts/lv1/Table';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:last-child {
    justify-content: flex-end;
  }
`;

const ActionsArea = styled.div`
  margin-bottom: 24px;
`;

const SaveBtn = styled.div`
  background: #4a90e2;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 0px;
  text-align: center;
  width: 136px;
`;

const EditBtn = styled.div`
  color: #4a90e2;
`;

const links = [
  {
    name: '売上部門',
    to: '/accounting/record/sales',
    isselected: false,
  },
  {
    name: '会計科目',
    to: '/accounting/record/accounting',
    isselected: true,
  },
];

const columns = [
  { header: '科目', accessor: 'accessor1', width: 200 },
  { header: '金額', accessor: 'accessor2', width: 200 },
  { header: '備考', accessor: 'accessor3', width: 565 },
  { header: '', accessor: 'accessor4', width: 50 },
];

export const RecordAccounting = () => {
  const history = useHistory();
  const data = Array(220)
    .fill({})
    .map((_, i) => ({
      accessor1: '計上科目名',
      accessor2: '10,000円',
      accessor3: '',
      accessor4: (
        <EditBtn
          onClick={() =>
            history.push(`/accounting/record/accounting/edit/${i + 1}`)
          }
        >
          編集
        </EditBtn>
      ),
    }));

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="会計科目" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 40px 40px 40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <ActionsArea>
          <SaveBtn
            onClick={() => history.push('/accounting/record/accounting/add')}
          >
            新規作成
          </SaveBtn>
        </ActionsArea>
        <PageNation list={data}>
          {(list) => <Table data={list} columns={columns} />}
        </PageNation>
      </ShadedContainer>
    </Wrapper>
  );
};
