import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { TextWithTitle } from '../parts/lv3/TextWithTitle';
import { SquareButton } from '../parts/lv3/SquareButton';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const MainContainer = styled.div`
  margin: 0 60px;
  display: flex;
`;

const ImgContainer = styled.div`
  padding-right: 216px;
`;

const InfomationContainer = styled.div``;

const Img = styled.div`
  div {
    width: 204px;
    height: 204px;
    border-radius: 102px;
    background: #979797;
    margin-bottom: 24px;
  }
`;

const ImgIDlabel = styled.p`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #757575;
  margin-bottom: 8px;
`;

const ImgID = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #212121;
`;

const MainInfomation = styled.div`
  width: 543px;
  height: 362px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const SubInfomation = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonArea = styled.div`
  width: 384px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const EnhancedTextWithTitle = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => (
  <TextWithTitle
    margin="4px"
    title={{
      text: title,
      fontSize: '24px',
      color: '#757575',
      weight: 'normal',
    }}
    text={{
      text,
      fontSize: '20px',
      color: '#212121',
      weight: 'bold',
    }}
  />
);

export const StoreDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const links = [
    { name: '基本情報', to: `/store/detail/${id}`, isselected: true },
    {
      name: '美容師リスト',
      to: `/store/detail/${id}/stylist`,
      isselected: false,
    },
    {
      name: '顧客リスト',
      to: `/store/detail/${id}/customer`,
      isselected: false,
    },
    {
      name: 'POSユーザー',
      to: `/store/detail/${id}/posuser`,
      isselected: false,
    },
  ];

  return (
    <Wrapper>
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={() => history.push('/store')}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text="ミラーボール渋谷店" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '80px 0 150px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <MainContainer>
          <ImgContainer>
            <Img>
              <div />
              <ImgIDlabel>店舗ID</ImgIDlabel>
              <ImgID>3217894</ImgID>
            </Img>
          </ImgContainer>

          <InfomationContainer>
            <MainInfomation>
              <div>
                <Margin margin="0 0 30px 0">
                  <EnhancedTextWithTitle
                    title="電話番号"
                    text="0220-222-2222"
                  />
                </Margin>
                <Margin margin="0 0 30px 0">
                  <EnhancedTextWithTitle title="営業時間" text="10:00~22:00" />
                </Margin>
                <Margin margin="0 0 30px 0">
                  <EnhancedTextWithTitle title="道案内" text="渋谷駅を出て右" />
                </Margin>
              </div>
              <div>
                <Margin margin="0 0 30px 0">
                  <EnhancedTextWithTitle
                    title="住所"
                    text="東京都渋谷区道玄坂2-2-2"
                  />
                </Margin>
                <Margin margin="0 0 30px 0">
                  <EnhancedTextWithTitle
                    title="アクセス方法"
                    text="JR渋谷駅徒歩10分"
                  />
                </Margin>
                <Margin margin="0 0 30px 0">
                  <EnhancedTextWithTitle title="座席数" text="1" />
                </Margin>
              </div>
            </MainInfomation>

            <SubInfomation>
              <Margin margin="0 0 64px 0">
                <EnhancedTextWithTitle
                  title="お客様画面での注意事項"
                  text="キャンセルは&#13;&#10;来店の1日前までにお願いいたします。"
                />
              </Margin>

              <Margin margin="0 0 24px 0">
                <EnhancedTextWithTitle title="お客様への質問" text="" />
              </Margin>

              <Margin margin="0 0 64px 0">
                <EnhancedTextWithTitle
                  title="見出し１"
                  text="ご来店は初めてですか？"
                />
              </Margin>
              <Margin margin="0 0 24px 0">
                <EnhancedTextWithTitle title="項目" text="ラジオボタン" />
              </Margin>
              <Margin margin="0 0 40px 0">
                <EnhancedTextWithTitle title="選択肢１" text="初めてです" />
              </Margin>
              <Margin margin="0 0 24px 0">
                <EnhancedTextWithTitle
                  title="見出し２"
                  text="hgeohgoehogheohgoheoghe&#13;&#10;ohgoehgeogheohgeohgoehgo"
                />
              </Margin>
              <Margin margin="0 0 40px 0">
                <EnhancedTextWithTitle title="項目" text="ラジオボタン" />
              </Margin>
              <Margin margin="0 0 40px 0">
                <EnhancedTextWithTitle title="選択肢１" text="ほげほげ" />
              </Margin>
            </SubInfomation>
          </InfomationContainer>
        </MainContainer>

        <ButtonArea>
          <SquareButton
            fontSize={24}
            width={160}
            height={56}
            borderRadius={4}
            color="BLUE_BORDER"
            text="編集"
            type="button"
            handleClick={() => history.push('/store/update')}
          />
          <SquareButton
            fontSize={24}
            width={160}
            height={56}
            borderRadius={4}
            color="RED"
            text="削除"
            type="button"
            handleClick={() => history.push('/store')}
          />
        </ButtonArea>
      </ShadedContainer>
    </Wrapper>
  );
};
