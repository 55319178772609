import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../lib/styles/variables';

const Wrapper = styled.div``;
const TitleArea = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 8px;
`;
const SendAtArea = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 16px;
`;
const BodyArea = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
  white-space: pre-line;
`;

const UrlText = styled.a`
  color: ${Color.BLUE};
`;

const regexpUrl = /(https?:\/\/[\S]+)/;

enum EConvertType {
  Url,
  Normal,
}

const judgeConvertType = (str: string) => {
  if (str.match(regexpUrl)) {
    return EConvertType.Url;
  }
  return EConvertType.Normal;
};

const convertBody = (body: string) => {
  const results = body.split(regexpUrl);
  return results.map((it) => {
    switch (judgeConvertType(it)) {
      case EConvertType.Url:
        return (
          <UrlText key={it} href={it} target="__blank">
            {it}
          </UrlText>
        );
      default:
        return <div key={it}>{it}</div>;
    }
  });
};

type Props = {
  title: string;
  sendAt: string;
  body: string;
  image?: string;
};

export const SpPreviewModal: React.FC<Props> = ({
  title,
  sendAt,
  body,
  image,
}) => {
  return (
    <Wrapper>
      <TitleArea>{title}</TitleArea>
      <SendAtArea>{sendAt}</SendAtArea>
      <BodyArea>{convertBody(body)}</BodyArea>
      {image && <img src={image} alt="img" style={{ width: 343 }} />}
    </Wrapper>
  );
};
