import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SalonMotPointForm } from '../parts/lv1/SalonMotPointForm';
import {
  deleteSalonMotPoint,
  getSalonMotPoint,
  updateSalonMotPoint,
} from '../../lib/api/salonMotPoints';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { useSpinner } from '../../lib/hooks/useSpinner';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const ButtonMargin = styled.div`
  margin-left: 24px;
`;

export const EditSalonMotPoint = () => {
  const history = useHistory();
  const { id } = useParams();
  const methods = useForm();
  const { selectedSalon } = useContext(AccountContext);
  const { loading, startLoading, finishLoading } = useSpinner();

  useEffect(() => {
    if (!selectedSalon) return;
    startLoading();
    getSalonMotPoint(selectedSalon, id)
      .then(({ data }) => {
        methods.reset({
          date: data.date,
          point: data.point,
        });
      })
      .finally(() => {
        finishLoading();
      });
  }, [selectedSalon, id]);

  const onSubmit = methods.handleSubmit((input) => {
    if (!selectedSalon) return;

    startLoading();

    const params = {
      salon_id: selectedSalon,
      date: input.date,
      point: input.point,
    };

    updateSalonMotPoint(selectedSalon, id, params)
      .then(({ data }) => {
        methods.reset({
          date: data.date,
          point: data.point,
        });
      })
      .finally(() => {
        finishLoading();
      });
  });

  const handleDelete = () => {
    if (!selectedSalon) return;
    deleteSalonMotPoint(selectedSalon, id).then(({ data }) => {
      if (data.deleted) history.goBack();
      else alert('削除に失敗しました');
    });
  };

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="予算・目標・MOT点数" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        {!loading ? (
          <Form onSubmit={onSubmit}>
            <SalonMotPointForm methods={methods} />
            <ButtonWrapper>
              <SquareButton
                fontSize={15}
                height={40}
                width={136}
                borderRadius={4}
                text="登録"
                color="BLUE"
                type="submit"
              />
              <ButtonMargin>
                <SquareButton
                  fontSize={15}
                  height={40}
                  width={136}
                  borderRadius={4}
                  text="削除"
                  color="RED"
                  type="button"
                  handleClick={handleDelete}
                />
              </ButtonMargin>
            </ButtonWrapper>
          </Form>
        ) : (
          loading
        )}
      </ShadedContainer>
    </Wrapper>
  );
};
