import apiBase from './apiBase';

export type SalonMotPointsRes = {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url?: string;
  next_page_url?: string;
  path: string;
  per_page: number;
  prev_page_url?: string;
  to: number;
  total: number;
  data: SalonMotPoint[];
};

export type SalonMotPoint = {
  id: number;
  salon_id: number;
  date: string;
  point: number;
};

// 一覧
export const getSalonMotPoints = (salonId: number, page: number) =>
  apiBase.get<SalonMotPointsRes>(
    `/salon/${salonId}/salonMotPoints?page=${page}`,
  );

// １件取得
export const getSalonMotPoint = (salonId: number, motPointId: number) =>
  apiBase.get<SalonMotPoint>(`/salon/${salonId}/salonMotPoint/${motPointId}`);

export type SalonMotPointParams = {
  date: string;
  point: number;
};

// 新規登録
export const createSalonMotPoint = (
  salonId: number,
  params: SalonMotPointParams,
) => apiBase.post<SalonMotPoint>(`/salon/${salonId}/salonMotPoint`, params);

export type UpdateSalonMotPointParams = {
  salon_id: number;
  date: string;
  point: number;
};

// 編集
export const updateSalonMotPoint = (
  salonId: number,
  motPointId: number,
  params: UpdateSalonMotPointParams,
) =>
  apiBase.put<SalonMotPoint>(
    `salon/${salonId}/salonMotPoint/${motPointId}`,
    params,
  );

export type DeleteSalonMotPointRes = {
  id: number;
  object: string;
  deleted: boolean;
};

// 削除
export const deleteSalonMotPoint = (salonId: number, motPointId: number) =>
  apiBase.delete<DeleteSalonMotPointRes>(
    `salon/${salonId}/salonMotPoint/${motPointId}`,
  );
