/* eslint no-plusplus: 0 */
import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { SquareButton } from '../parts/lv3/SquareButton';
import { DatePicker } from '../parts/lv3/DatePicker';
import { SelectBox } from '../parts/lv3/SelectBox';
import { FontSize, Color } from '../../lib/styles/variables';
import { Triangle } from '../parts/lv3/Triangle';
import { Text } from '../parts/lv3/TextWithTitle';
import { Margin } from '../parts/lv3/Margin';
import {
  Table,
  TableWrapper,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '../parts/lv1/Table';

const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 64px;
  margin-right: 16px;
  margin-left: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SelectBoxWrapper = styled.div``;

const DatePickerArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`;

const Hyphen = styled.span`
  font-size: ${FontSize.X_LARGE};
  font-weight: bold;
  line-height: 100%;
`;

const ChartWrapper = styled.div`
  margin-bottom: 64px;
`;

const ExpectWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ExpectSumArea = styled.div`
  margin-top: 24px;
  text-align: center;
`;

export const TableArea = styled.div`
  width: 100%;
  margin-top: 64px;
  & table {
    width: 100%;
    & th {
      text-align: center;
    }
  }
  & > table:first-child {
    & td {
      width: 50%;
    }
  }
  & .table_row_title {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
`;

const menuRateData = [
  { id: 1, category: 'カテゴリが入ります', rate: '90%' },
  { id: 2, category: 'カテゴリが入ります', rate: '60%' },
  { id: 3, category: 'カテゴリが入ります', rate: '30%' },
];

// TODO:: accessorはAPIに合わせる
const columns = [
  { header: '1月', accessor: 'month1', width: 71 },
  { header: '2月', accessor: 'month2', width: 71 },
  { header: '3月', accessor: 'month3', width: 71 },
  { header: '4月', accessor: 'month4', width: 71 },
  { header: '5月', accessor: 'month5', width: 71 },
  { header: '6月', accessor: 'month6', width: 71 },
  { header: '7月', accessor: 'month7', width: 71 },
  { header: '8月', accessor: 'month8', width: 71 },
  { header: '9月', accessor: 'month9', width: 71 },
  { header: '10月', accessor: 'month10', width: 71 },
  { header: '11月', accessor: 'month11', width: 71 },
  { header: '12月', accessor: 'month12', width: 71 },
];

const MonthlyData = Array(10).fill({
  month1: '12%',
  month2: '12%',
  month3: '12%',
  month4: '12%',
  month5: '12%',
  month6: '12%',
  month7: '12%',
  month8: '12%',
  month9: '12%',
  month10: '12%',
  month11: '12%',
  month12: '12%',
});

const rowTitles = {
  titles: [
    '売上に対しての原価率',
    '売上に対しての店販購入者比率',
    '新規再来率',
    '顧客再来比率',
    'スパ・トリートメント比率',
    '時間単価',
    '新規数',
    '再来数',
    '客単価',
    '直近の年間来店回数',
  ],
  width: 229,
};

const rankingColumns = [
  { header: '店舗クーポン使用ランキングbest3', accessor: 'coupon', width: 0 },
];

const rankingData = [
  { rank: '1位', coupon: '【学生限定】全メニュー30%OFF' },
  { rank: '2位', coupon: '【学生限定】全メニュー30%OFF' },
  { rank: '3位', coupon: '【学生限定】全メニュー30%OFF' },
];
const rankingRowTitles = {
  titles: ['1位', '2位', '3位'],
  width: 54,
};

const noUseCouponColumns = [
  { header: '未使用クーポン一覧', accessor: 'coupon', width: 0 },
];

const noUseCouponData = [
  { coupon: '【学生限定】全メニュー30%OFF' },
  { coupon: '【学生限定】全メニュー30%OFF' },
  { coupon: '【学生限定】全メニュー30%OFF' },
];

export const Profit = () => {
  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs()
      .add(1, 'month')
      .format('YYYY-MM-DD'),
  );

  const [storeName, setStoreName] = useState<string>('全店');

  const makeMockData = (
    length: number,
    range: { min: number; max: number },
  ): any[] => {
    const data = [];
    for (let index = 1; index < length + 1; index++) {
      let a =
        Math.floor(Math.random() * (range.max + 1 - range.min)) + range.min;
      data.push(a);
      a = 0;
    }
    return data;
  };

  const makeMockDate = () => {
    const array = [];
    for (let i = 1; i < 32; i++) {
      array.push(i.toString());
    }

    return array;
  };

  const skillProfitOptions: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '技術売上',
      style: {
        fontWeight: 'bold',
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
    },
    yAxis: {
      title: undefined,
    },
    xAxis: {
      gridLineWidth: 1,
      categories: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      crosshair: true,
    },
    series: [
      {
        color: '#d8d8d8',
        name: '予算',
        data: makeMockData(12, { min: 100, max: 500 }),
        type: 'column',
      },
      {
        color: Color.RED,
        name: '実績',
        data: makeMockData(12, { min: 150, max: 500 }),
        type: 'column',
      },
    ],
  };

  const profitOptions: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '物販＋EC売上',
      style: {
        fontWeight: 'bold',
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
    },
    yAxis: {
      title: undefined,
    },
    xAxis: {
      gridLineWidth: 1,
      categories: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      crosshair: true,
    },
    series: [
      {
        color: '#d8d8d8',
        name: '予算',
        data: makeMockData(12, { min: 100, max: 500 }),
        type: 'column',
      },
      {
        color: Color.BLUE,
        name: '実績',
        data: makeMockData(12, { min: 150, max: 500 }),
        type: 'column',
      },
    ],
  };

  const profitSumOptions: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '予実合計',
      style: {
        fontWeight: 'bold',
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
    },
    yAxis: {
      title: undefined,
    },
    xAxis: {
      gridLineWidth: 1,
      categories: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      crosshair: true,
    },
    series: [
      {
        color: '#d8d8d8',
        name: '予算',
        data: makeMockData(12, { min: 100, max: 500 }),
        type: 'column',
      },
      {
        color: Color.BRACK,
        name: '実績',
        data: makeMockData(12, { min: 150, max: 500 }),
        type: 'column',
      },
    ],
  };

  const missionOptions: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '当月目標進捗',
      style: {
        fontWeight: 'bold',
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
    },
    yAxis: {
      title: undefined,
      plotLines: [
        {
          value: 300,
          color: Color.RED,
          width: 5,
        },
      ],
    },
    xAxis: {
      gridLineWidth: 1,
      categories: makeMockDate(),
      crosshair: true,
    },
    series: [
      {
        color: '#212121',
        name: '実績',
        data: makeMockData(31, { min: 100, max: 500 }),
        type: 'column',
      },
    ],
  };

  return (
    <Wrapper>
      <TopContentWrapper>
        <MainContentTitle text="売上" />
        <SquareButton
          fontSize={16}
          width={242}
          height={31}
          text="CSVで出金情報を書き出す"
          color="BLUE_BORDER"
          handleClick={() => console.log(startDate, endDate, storeName)}
        />
      </TopContentWrapper>

      <SelectBoxWrapper>
        <SelectBox
          style={{
            width: '337px',
            fontSize: '24px',
            height: '36px',
            border: 'bottom',
          }}
          control={{
            type: 'state',
            value: storeName,
            onChange: (v) => setStoreName(v),
          }}
        >
          <option value="all">全店</option>
          <option value="shibuy">渋谷店</option>
          <option value="harajyuku">原宿店</option>
        </SelectBox>
      </SelectBoxWrapper>

      <DatePickerArea>
        <DatePicker
          date={startDate}
          handleChange={(date) => setStartDate(date)}
          style={{ fontSize: '20px', width: '158px', height: '30px' }}
        />
        <Hyphen> - </Hyphen>
        <DatePicker
          date={endDate}
          handleChange={(date) => setEndDate(date)}
          style={{ fontSize: '20px', width: '158px', height: '30px' }}
        />
        <Triangle size={14} />
      </DatePickerArea>

      <ChartWrapper>
        <HighchartsReact highcharts={Highcharts} options={skillProfitOptions} />
      </ChartWrapper>

      <ChartWrapper>
        <HighchartsReact highcharts={Highcharts} options={profitOptions} />
      </ChartWrapper>

      <ChartWrapper>
        <HighchartsReact highcharts={Highcharts} options={profitSumOptions} />
      </ChartWrapper>

      <ChartWrapper>
        <HighchartsReact highcharts={Highcharts} options={missionOptions} />
      </ChartWrapper>

      <ExpectWrapper>
        <Text fontSize={FontSize.TITLE}>当月着地予想</Text>
        <ExpectSumArea>
          <Text fontSize={FontSize.TITLE}>〇〇円</Text>
        </ExpectSumArea>
        <TableArea>
          <TableWrapper>
            <Thead>
              <Tr>
                <Th colSpan={2} width={undefined} minWidth={undefined}>
                  メニュー割合
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {menuRateData.map((it) => (
                <Tr key={it.id}>
                  <Td noPadding={undefined} gravity={undefined}>
                    {it.category}
                  </Td>
                  <Td noPadding={undefined} gravity="right">
                    {it.rate}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </TableWrapper>
          <Margin margin="65px 0px 0px 0px">
            <Table
              data={MonthlyData}
              columns={columns}
              rowTitles={rowTitles}
              gravity="right"
            />
          </Margin>
          <Margin margin="65px 0px 0px 0px">
            <Table
              data={rankingData}
              columns={rankingColumns}
              rowTitles={rankingRowTitles}
            />
          </Margin>
          <Margin margin="65px 0px 0px 0px">
            <Table data={noUseCouponData} columns={noUseCouponColumns} />
          </Margin>
        </TableArea>
      </ExpectWrapper>
    </Wrapper>
  );
};
