import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../lib/styles/variables';

const Item = styled.div`
  width: 337px;
  margin-bottom: 40px;
`;

const LeftItem = styled.div`
  width: 337px;
  margin-bottom: 40px;
  margin-left: 80px;
`;

const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #757575;
  margin-bottom: 6px;
`;

const FieldWrapper = styled.div`
  padding: 0 4px;
  border-bottom: 1px solid #979797;
  display: flex;
`;

const TextField = styled.input`
  width: 100%;
  font-size: 24px;
  height: 36px;
  border: none;
`;

const Unit = styled.span`
  font-size: 24px;
  line-height: 36px;
  color: #212121;
`;

const ItemWrapper = styled.div`
  display: flex;
`;

const ErrorText = styled.a`
  color: ${Color.RED};
`;

type Props = {
  methods: any; // useForm
};

export const MissionForm: React.FC<Props> = ({ methods }) => {
  return (
    <>
      <ItemWrapper>
        <Item>
          <FieldLabel>予算</FieldLabel>
          <FieldWrapper>
            <TextField
              type="number"
              placeholder="1,000,000"
              name="budget"
              ref={methods.register({ required: '必須項目です' })}
            />
            <Unit>円</Unit>
          </FieldWrapper>
          {methods.errors.budget && (
            <ErrorText>{methods.errors.budget.message}</ErrorText>
          )}
        </Item>
        <LeftItem>
          <FieldLabel>店舗購入者比率</FieldLabel>
          <FieldWrapper>
            <TextField
              type="number"
              placeholder="80"
              name="customerRate"
              ref={methods.register({ required: '必須項目です' })}
            />
            <Unit>%</Unit>
          </FieldWrapper>
          {methods.errors.customerRate && (
            <ErrorText>{methods.errors.customerRate.message}</ErrorText>
          )}
        </LeftItem>
      </ItemWrapper>
      <ItemWrapper>
        <Item>
          <FieldLabel>材料原価率</FieldLabel>
          <FieldWrapper>
            <TextField
              type="number"
              placeholder="80"
              name="costRate"
              ref={methods.register({ required: '必須項目です' })}
            />
            <Unit>%</Unit>
          </FieldWrapper>
          {methods.errors.costRate && (
            <ErrorText>{methods.errors.costRate.message}</ErrorText>
          )}
        </Item>

        <LeftItem>
          <FieldLabel>技術売上目標</FieldLabel>
          <FieldWrapper>
            <TextField
              type="number"
              placeholder="1,000,000"
              name="treatmentSalesTarget"
              ref={methods.register({ required: '必須項目です' })}
            />
            <Unit>円</Unit>
          </FieldWrapper>
          {methods.errors.treatmentSalesTarget && (
            <ErrorText>{methods.errors.treatmentSalesTarget.message}</ErrorText>
          )}
        </LeftItem>
      </ItemWrapper>
      <ItemWrapper>
        <Item>
          <FieldLabel>物販売上目標</FieldLabel>
          <FieldWrapper>
            <TextField
              type="number"
              placeholder="1,000,000"
              name="productSalesTarget"
              ref={methods.register({ required: '必須項目です' })}
            />
            <Unit>円</Unit>
          </FieldWrapper>
          {methods.errors.productSalesTarget && (
            <ErrorText>{methods.errors.productSalesTarget.message}</ErrorText>
          )}
        </Item>

        <LeftItem>
          <FieldLabel>売上目標</FieldLabel>
          <FieldWrapper>
            <TextField
              type="number"
              placeholder="2,000,000"
              name="totalSalesTarget"
              ref={methods.register({ required: '必須項目です' })}
            />
            <Unit>円</Unit>
          </FieldWrapper>
          {methods.errors.totalSalesTarget && (
            <ErrorText>{methods.errors.totalSalesTarget.message}</ErrorText>
          )}
        </LeftItem>
      </ItemWrapper>
    </>
  );
};
