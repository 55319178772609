import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: calc(100vh - 152px);
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorCode = styled.p`
  font-weight: bold;
  font-size: 104px;
  line-height: 156px;
  letter-spacing: 1.36087px;
  color: #212121;
`;

const NotFound = styled.p`
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 1.36087px;
  color: #212121;
`;

const Message = styled.p`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1.36087px;
  color: #212121;
  margin-top: 40px;
`;

export const ErrorPage = () => {
  return (
    <Wrapper>
      <ErrorCode>404</ErrorCode>
      <NotFound>not found</NotFound>
      <Message>お探しのページが見つかりませんでした。</Message>
    </Wrapper>
  );
};
