import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../lib/styles/variables';

export const TableWrapper = styled.table`
  border: 1px solid #979797;
  border-collapse: collapse;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  border: 1px solid #979797;
`;

export const Th = styled.th<{
  width?: number | undefined;
  minWidth: number | undefined;
}>`
  font-weight: bold;
  border: 1px solid #979797;
  background: ${Color.GRAY};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
  height: 38px;
  text-align: left;
  padding: 10px;
  font-size: 12px;
`;

export const Td = styled.td<{ noPadding?: boolean; gravity?: string }>`
  border: 1px solid #979797;
  height: 38px;
  padding: ${({ noPadding }) => (noPadding ? '0' : '10px')};
  font-size: 12px;
  text-align: ${({ gravity }) => gravity || 'left'};
`;

export type Row<T> = {
  original: T;
};
export type Column<T> = {
  header: string;
  accessor: keyof T | ((row: Row<T>) => JSX.Element | string | number) | string;
  width?: number;
  minWidth?: number;
  noPadding?: boolean;
};

type Props<T> = {
  columns: Column<T>[];
  // columns.accessorをkeyに持つobjectの配列を期待しています。動的なデータのため型を断念
  data: T[];
  // カラムには className="table_row_title"を付与
  rowTitles?: { titles: string[]; width: number; minWidth?: number };
  gravity?: string;
};

// TODO: セルにコンポーネントを受け取りたい

// TODO: any削除
// eslint-disable-next-line
export const Table = <T extends object | any>({
  columns,
  data,
  rowTitles,
  gravity,
}: Props<T>) => {
  const renderTbody = () =>
    data.map((item, index) => (
      // eslint-disable-next-line
      <Tr key={(item as any).id || index}>
        {rowTitles ? (
          <Td className="table_row_title" gravity={gravity}>
            {rowTitles.titles[index]}
          </Td>
        ) : null}
        {columns.map(({ accessor, noPadding }, columnIndex) => {
          if (typeof accessor === 'string') {
            return (
              <Td key={columnIndex} noPadding={noPadding} gravity={gravity}>
                {item[accessor as keyof T] || ''}
              </Td>
            );
          }
          if (typeof accessor === 'function') {
            return (
              <Td key={columnIndex} noPadding={noPadding} gravity={gravity}>
                {accessor({
                  original: item,
                })}
              </Td>
            );
          }
          return null;
        })}
      </Tr>
    ));

  return (
    <TableWrapper>
      <Thead>
        <Tr>
          {rowTitles ? (
            <Th width={rowTitles.width} minWidth={rowTitles.minWidth} />
          ) : null}
          {columns.map((item) => (
            <Th key={item.header} width={item.width} minWidth={item.minWidth}>
              {item.header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{renderTbody()}</Tbody>
    </TableWrapper>
  );
};
