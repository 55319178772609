import React, { FC } from 'react';
import styled from 'styled-components';
import { MonthlySalesDatum } from '../../../lib/api/stylist';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 6rem;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      th {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        background: #f1f1f1;
      }
      td {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-size: 14px;
      }
    }
  }
`;

const titles = [
  '店舗購入者比率',
  '新規再来率',
  '顧客再来比率',
  'スパ・トリートメント比率',
  '時間単価',
  '新規数',
  '再来数',
  '客単価',
];

type Props = {
  monthly_sales_data: MonthlySalesDatum[];
};

export const StylistDetailTableMonthly: FC<Props> = ({
  monthly_sales_data,
}) => {
  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th />
            {monthly_sales_data.map((item, index: number) => {
              return <th key={index}>{`${index + 1}月`}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {titles.map((title) => {
            return (
              <tr key={title}>
                <td>{title}</td>
                {monthly_sales_data.map((item) => {
                  if (title === '店舗購入者比率') {
                    return (
                      <td key={item.year_month}>
                        {`${item.purchase_product_rate}%`}
                      </td>
                    );
                  }
                  if (title === '新規再来率') {
                    return (
                      <td key={item.year_month}>
                        {`${item.new_customer_rate}%`}
                      </td>
                    );
                  }
                  if (title === '顧客再来比率') {
                    return (
                      <td key={item.year_month}>
                        {`${item.repeat_customer_rate}%`}
                      </td>
                    );
                  }
                  if (title === 'スパ・トリートメント比率') {
                    return (
                      <td key={item.year_month}>
                        {`${item.spa_treatment_rate}%`}
                      </td>
                    );
                  }
                  if (title === '時間単価') {
                    return <td key={item.year_month}>TODO円</td>;
                  }
                  if (title === '新規数') {
                    return (
                      <td key={item.year_month}>
                        {`${item.new_customer_number}%`}
                      </td>
                    );
                  }
                  if (title === '再来数') {
                    return (
                      <td key={item.year_month}>
                        {`${item.repeat_customer_number}%`}
                      </td>
                    );
                  }
                  if (title === '客単価') {
                    return (
                      <td key={item.year_month}>
                        {`${item.unit_price_per_customer}%`}
                      </td>
                    );
                  }
                  return '';
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};
