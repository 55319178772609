import getQueryParams from '../utils/getQueryParams';
import apiBase from './apiBase';
import { PageData } from './utils';

export type GetQuestionaryParams = {
  kanji_first_name: string;
  kanji_last_name: string;
  kana_first_name: string;
  kana_last_name: string;
  sex: string;
  age: number;
  last_menu: string;
  purchased_item: string;
  from_birth_day: string;
  to_birth_day: string;
  last_visit_day: string;
  days_after_last_visit: string;
  days_before_reservation: string;
  salon_visit_count: string;
};

export type QuestionaryType = {
  id: number;
  kana_name: string;
  kanji_name: string;
  sex: string;
  birth_day: string;
  visit_count: string;
};

export const searchQuestionary = (params: Partial<GetQuestionaryParams>) =>
  apiBase.get<QuestionaryType[]>(
    `/questionary/users?${getQueryParams(params)}`,
  );

export type PostQuestionaryParams = {
  target: {
    user_ids: string[];
  };
  title: string;
  url: string;
  remarks: string;
  grant_point: string;
};

export const createQuestionary = (params: PostQuestionaryParams) =>
  apiBase.post<{}>(`/questionary/user/`, params);

export type ListType = {
  page: number;
  sort_by_date: 'asc' | 'desc';
};

export type StylistQuestionaryType = {
  id: number;
  title: string;
  url: string;
  answer_number: string;
  remarks: string;
  created_at: string;
  updated_at: string;
};

export type ResGetStylistQuestionaries = {
  data: StylistQuestionaryType[];
} & PageData;

export const getStylistQuestionaries = (params: Partial<ListType>) =>
  apiBase.get<ResGetStylistQuestionaries>(
    `/questionaries/stylist?${getQueryParams(params)}`,
  );

export type CustomerQuestionaryType = {
  id: number;
  title: string;
  url: string;
  answer_number: string;
  grant_point: string;
  remarks: string;
  created_at: string;
  updated_at: string;
};

export type ResGetCustomerQuestionaries = {
  data: CustomerQuestionaryType[];
} & PageData;

export const getCustomerQuestionaries = (params: Partial<ListType>) =>
  apiBase.get<ResGetCustomerQuestionaries>(
    `/questionaries/user?${getQueryParams(params)}`,
  );

type GetTargetStylistParams = {
  send_target: string;
  stylist_id: string;
  salon_name: string;
};

export type Stylist = {
  id: number;
  user_kana_name: string;
};

type getTargetStylistRes = {
  target_stylists: Stylist[];
};

export const searchStylists = (params: GetTargetStylistParams) =>
  apiBase.get<getTargetStylistRes>(
    `/message/stylists?${getQueryParams(params)}`,
  );

export type PostQuestionaryStylistParams = {
  target: {
    stylist_ids: string[];
  };
  title: string;
  url: string;
  remarks: string;
};

export const createQuestionaryStylist = (
  params: PostQuestionaryStylistParams,
) => apiBase.post<{}>(`/questionary/stylist/`, params);
