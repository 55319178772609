import React from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Table } from '../parts/lv1/Table';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableStyle = styled.div`
  & tr > td:not(:first-child) {
    text-align: right;
  }
`;

const columns = [
  { header: 'グレード名', accessor: 'accessor1', width: 545 },
  { header: 'グレード給', accessor: 'accessor2', width: 257 },
  { header: '掛け率', accessor: 'accessor3', width: 200 },
];

export const GrateRate = () => {
  const data = Array(8)
    .fill({})
    .map((_, i) => ({
      accessor1: ['A１', 'S１', 'Z１'][i % 3],
      accessor2: ['0円', '25,000円'][i % 2],
      accessor3: `${1 * (i / 10)}`,
    }));

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="グレード・掛け率" />
        </Margin>
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 40px 40px 40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <TableStyle>
          <Table data={data} columns={columns} />
        </TableStyle>
      </ShadedContainer>
    </Wrapper>
  );
};
