/* eslint no-plusplus: 0 */
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackButton = styled.img`
  width: 40px;
  height: 40px;
`;

const NextButton = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 20px;
`;

const Dots = styled.img`
  width: 40px;
  height: 40px;
  padding: 10px;
  margin-left: 20px;
`;

const PageList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const PagingItemWrapper = styled.div``;

const Item = styled.li`
  margin-left: 20px;
`;

const Text = styled.a<{ active: boolean }>`
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? '#fff' : '#212121')};
  border-radius: ${({ active }) => (active ? '100%' : '0%')};
  background-color: ${({ active }) => (active ? '#212121' : '#fff')};
`;

type PagingNumberListProps = {
  page: number;
};

const displayPageNumbers = (
  total: number,
  current: number,
): PagingNumberListProps[] => {
  if (total === 0) return [];
  if (total <= 8)
    return Array.from(Array(total).keys()).map((value) => {
      return { page: value + 1 };
    });

  const pages = [];
  if (current - 1 <= 2) {
    for (let i = 1; i <= 5; i++) {
      pages.push({
        page: i,
      });
    }
    pages.push({ page: -1 });
    pages.push({ page: total });
  } else if (current + 1 >= total - 1) {
    pages.push({ page: 1 });
    pages.push({ page: -1 });
    for (let i = -4; i <= 0; i++) {
      pages.push({
        page: total + i,
      });
    }
  } else {
    pages.push({ page: 1 });
    pages.push({ page: -1 });

    for (let i = -1; i <= 1; i++) {
      pages.push({
        page: current + i,
      });
    }
    pages.push({ page: -1 });
    pages.push({ page: total });
  }

  return pages;
};

type Props = {
  sum: number;
  per: number;
  onChange: (e: { page: number }) => void;
};

export const Paging: React.FC<Props> = ({ sum, per, onChange }) => {
  const isFirstRender = React.useRef(true);
  const [currentPage, setPage] = React.useState(1);
  const totalPage: number = Math.ceil(sum / per);
  const [dispPages, setDispPages] = React.useState<PagingNumberListProps[]>(
    displayPageNumbers(totalPage, currentPage),
  );

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    onChange({ page: currentPage });
  }, [currentPage, dispPages]);

  const handleBack = (): void => {
    if (currentPage === 1) {
      return;
    }
    setPage(currentPage - 1);
    setDispPages(displayPageNumbers(totalPage, currentPage - 1));
  };

  const handleForward = (): void => {
    if (currentPage === totalPage) {
      return;
    }
    setPage(currentPage + 1);
    setDispPages(displayPageNumbers(totalPage, currentPage + 1));
  };

  const handleMove = (page: number): void => {
    setPage(page);
    setDispPages(displayPageNumbers(totalPage, page));
  };

  const PagingItem = ({ page }: { page: number }) => (
    <Item key={`paging-itm-${page}`} onClick={() => handleMove(page)}>
      <Text active={page === currentPage}>{page}</Text>
    </Item>
  );

  if (totalPage === 0) return null;
  return (
    <Wrapper>
      <BackButton
        src={`${process.env.PUBLIC_URL}/assets/arrow_circle_left.svg`}
        alt="1つ前のリストへ"
        onClick={() => handleBack()}
      />
      <PageList>
        {dispPages.map(({ page }, index) => {
          return (
            <PagingItemWrapper key={`wrapper-${index}`}>
              {page === -1 ? (
                <Dots
                  key={`dots-${index}`}
                  src={`${process.env.PUBLIC_URL}/assets/more_dots.svg`}
                  alt="more_dots"
                />
              ) : (
                <PagingItem key={`paging-${page}`} page={page} />
              )}
            </PagingItemWrapper>
          );
        })}
      </PageList>
      <NextButton
        src={`${process.env.PUBLIC_URL}/assets/arrow_circle_right.svg`}
        alt="1つ後のリストへ"
        onClick={() => handleForward()}
      />
    </Wrapper>
  );
};
