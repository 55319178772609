import { useState, useEffect } from 'react';
import { Brand, getAllBrand } from '../api/brand';

const useBrands = () => {
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    getAllBrand()
      .then(({ data }) => {
        setBrands(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, []);

  return brands;
};

export default useBrands;
