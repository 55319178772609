import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { DatePicker } from '../lv3/DatePicker';
import dayjs from '../../../lib/utils/dayjs';
import { Color } from '../../../lib/styles/variables';

const LeftItem = styled.div`
  width: 337px;
  margin-bottom: 40px;
  margin-left: 80px;
`;

const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #757575;
  margin-bottom: 6px;
`;

const TextField = styled.input`
  width: 100%;
  font-size: 24px;
  height: 36px;
  border: none;
  border-bottom: 1px solid #979797;
`;

const ItemWrapper = styled.div`
  display: flex;
`;

const DatePickerWrapper = styled.div`
  width: 337px;
`;

const Triangle = styled.span`
  line-height: 100%;
`;

const CurrentDateArea = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #979797;
`;

const ErrorText = styled.a`
  color: ${Color.RED};
`;

type Props = {
  methods: any;
};

export const SalonMotPointForm: React.FC<Props> = ({ methods }) => {
  return (
    <>
      <ItemWrapper>
        <DatePickerWrapper>
          <FieldLabel>日付</FieldLabel>
          <CurrentDateArea>
            <Controller
              as={(
                <DatePicker
                  id="date"
                  date={methods.date}
                  handleChange={(d) =>
                    methods.setValue('date', dayjs(d).format('YYYY-MM-DD'))
                  }
                  style={{ fontSize: '20px', width: '300px', height: '30px' }}
                />
              )}
              control={methods.control}
              name="date"
              rules={{
                required: '必須項目です',
              }}
            />
            <Triangle>▼</Triangle>
          </CurrentDateArea>
          {methods.errors.date && (
            <ErrorText>{methods.errors.date.message}</ErrorText>
          )}
        </DatePickerWrapper>
        <LeftItem>
          <FieldLabel>MOT数値</FieldLabel>
          <TextField
            type="number"
            id="point"
            name="point"
            ref={methods.register({ required: '必須項目です' })}
          />
          {methods.errors.point && (
            <ErrorText>{methods.errors.point.message}</ErrorText>
          )}
        </LeftItem>
      </ItemWrapper>
    </>
  );
};
