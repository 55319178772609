import { useState, useEffect } from 'react';
import { getPoint, Point } from '../api/point';

export const usePoint = () => {
  const [point, setPoint] = useState<Point[] | null>(null);

  useEffect(() => {
    getPoint().then(({ data }) => {
      setPoint(data.data);
    });
  }, []);

  return point;
};
