import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { TextField } from '../parts/lv3/TextField';
import { CheckBoxWithText } from '../parts/lv3/CheckBoxWithText';
import { ContentTable } from '../parts/lv1/ContentTable';
import { TextArea } from '../parts/lv3/TextArea';
import { postSalonCoupon, PostSalonCouponPayload } from '../../lib/api/coupon';
import { getStylists } from '../../lib/api/stylist';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  width: 296px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ErrorText = styled.p`
  color: red;
`;

type FormValue = Omit<PostSalonCouponPayload, 'salonId' | 'image'>;

export const AddShopCoupon = () => {
  const salonId = '1'; // Contextなどで取得
  const history = useHistory();
  const [image, setImage] = useState('');
  const [stylistIds, setStylistIds] = useState<number[]>([]);
  const { data: getStylistsRes } = useSWR('getStylists', getStylists);

  const { register, handleSubmit, watch, errors } = useForm<FormValue>({
    // debug用なので削除可
    defaultValues: {
      name: '店舗のクーポン名',
      price: 3500,
      minute: 60,
      description: 'クーポンの説明',
      menu_ids: [1, 2],
      is_public: true,
    },
  });

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );

  const onHandleSelect = useCallback(
    (stylistId: number) => {
      if (stylistIds.includes(stylistId)) {
        setStylistIds(stylistIds.filter((id) => id !== stylistId));
      } else {
        setStylistIds([...stylistIds, stylistId]);
      }
    },
    [stylistIds],
  );

  const onSubmit = useCallback(
    async (data: FormValue) => {
      await postSalonCoupon({
        ...data,
        salonId,
        stylist_ids: stylistIds,
        image: {
          base64: image,
        },
      });
    },
    [image, stylistIds],
  );

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="店舗クーポン登録" />
      </Margin>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentTable>
          <ContentTable.Body>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                クーポン名
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'name',
                    register: register({
                      required: '必須項目です',
                    }),
                  }}
                  style={{}}
                />
                <ErrorText>{errors.name?.message}</ErrorText>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                クーポン内容
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextArea
                  control={{
                    type: 'hook',
                    name: 'description',
                    register,
                  }}
                  style={{}}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                画像
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <input
                  type="file"
                  name="image.base64"
                  onChange={onFileChange}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                メニュー
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <Flex>
                  <CheckBoxWithText
                    id="cut"
                    text="カット"
                    isChecked
                    register={null}
                    size={{ height: 24 }}
                  />
                  <div style={{ marginLeft: '16px' }} />
                  <CheckBoxWithText
                    id="cut"
                    text="カラー"
                    isChecked
                    register={null}
                    size={{ height: 24 }}
                  />
                </Flex>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                価格
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <Flex>
                  <TextField
                    control={{
                      type: 'hook',
                      name: 'price',
                      register,
                    }}
                    style={{
                      width: '84px',
                    }}
                  />
                  円
                  <div style={{ marginLeft: '16px' }} />
                  <CheckBoxWithText
                    text="「 〜 」 を表示"
                    id="is_public"
                    size={{ height: 24 }}
                    register={register}
                    isChecked={watch('is_public')}
                  />
                </Flex>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                所用時間
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'minute',
                    register,
                  }}
                  style={{
                    width: '84px',
                  }}
                />
                分
              </ContentTable.Cell>
            </ContentTable.Row>
          </ContentTable.Body>
        </ContentTable>

        <p
          style={{
            marginTop: '16px',
          }}
        >
          対応可能なスタッフを選択してください
        </p>
        <Margin margin="4px" />
        <div
          style={{
            border: '1px solid #979797',
            padding: '8px',
          }}
        >
          <Flex>
            {getStylistsRes?.data.data.map((stylist) => (
              <div
                key={stylist.id}
                style={{
                  marginRight: '8px',
                }}
              >
                <CheckBoxWithText
                  text={stylist.user_name}
                  id="stylist_ids"
                  size={{ height: 24 }}
                  register={register}
                  isChecked={stylistIds.includes(stylist.id)}
                  handleClick={() => onHandleSelect(stylist.id)}
                />
              </div>
            ))}
          </Flex>
        </div>

        <ButtonWrapper>
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="登録"
            color="BLUE"
            type="submit"
            borderRadius={4}
          />
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="戻る"
            color="WHITE"
            borderRadius={4}
            type="button"
            handleClick={() => history.push('/content/coupon/stylist')}
          />
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};
