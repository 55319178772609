import apiBase from './apiBase';

export type Admin = {
  id: number;
  login_id: number;
  remarks: string | null;
  is_super_admin: 1 | 0;
  manage_all_salons: 1 | 0;
  manage_salons?: {
    id: number;
    salon_id: number;
    admin_id: number;
    created_at: string | null;
    updated_at: string | null;
    name: string;
    brand_id: number;
    phone_number: string;
    post_code: string;
    city: string;
    address: string;
    building_room: string;
    seat_number: number;
    open_time: string;
    sale_target: number;
    access_method: string;
    access_guide: string;
    introduction: string;
    notes: string;
    is_deleted: number;
  }[];
};

type GetAdminRes = {
  data: Admin[];
  last_page: number;
};

export const getAdmin = (pages?: number) =>
  apiBase.get<GetAdminRes>(`/admins?page=${pages}`);

type AddAdminParmas = {
  password: string;
  manage_all_salons: boolean;
  manage_salon_ids: number[];
  remarks: string;
};

export const addAdmin = (params: AddAdminParmas) =>
  apiBase.post('/admin', params);

type UpdateAdminParmas = {
  manage_all_salons: boolean;
  manage_salon_ids: number[];
  remarks: string;
};

export const updateAdmin = (params: UpdateAdminParmas, adminId: number) =>
  apiBase.put(`/admin/${adminId}`, params);

export const deleteAdmin = (adminId: number) =>
  apiBase.delete(`/admin/${adminId}`);
