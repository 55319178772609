import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { TextField } from '../parts/lv3/TextField';
import { CheckBoxWithText } from '../parts/lv3/CheckBoxWithText';
import { ContentTable } from '../parts/lv1/ContentTable';
import { TextArea } from '../parts/lv3/TextArea';
import {
  getCouponDetail,
  putStylistCoupon,
  PutStylistCouponPayload,
  Coupon,
  deleteCoupon,
} from '../../lib/api/coupon';
import { decodeBase64 } from '../../lib/utils/base64';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  width: 448px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const Flex = styled.div`
  display: flex;
`;

const ErrorText = styled.p`
  color: red;
`;

type FormValue = Omit<PutStylistCouponPayload, 'salonId' | 'image'> & {
  stylist_id: number;
};

export const EditStylistCoupon = () => {
  const salonId = '1'; // Contextなどで取得
  const history = useHistory();
  const { id: couponId } = useParams<{ id: string }>();
  const [image, setImage] = useState('');
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const { register, handleSubmit, watch, errors, reset } = useForm<FormValue>();

  const fetchCouponDetail = useCallback(async () => {
    const res = await getCouponDetail({
      salonId,
      couponId,
    });
    setCoupon(res.data);
    reset({
      stylist_id: res.data.stylist_id,
      name: res.data.name,
      price: res.data.price,
      minute: res.data.minute,
      description: res.data.description,
      menu_ids: res.data.menus.map((menu) => menu.id),
      is_public: res.data.is_public === 1,
    });
    const base64Url = await decodeBase64(res.data.image_url);
    setImage(base64Url);
  }, [reset, salonId, couponId]);

  useEffect(() => {
    fetchCouponDetail();
  }, [fetchCouponDetail]);

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );

  const onSubmit = useCallback(
    async (data: FormValue) => {
      await putStylistCoupon({
        ...data,
        couponId,
        salonId,
        image: {
          base64: image,
        },
      });
      history.push('/content/coupon/shop');
    },
    [image, couponId, history],
  );

  const onDelete = useCallback(async () => {
    await deleteCoupon(salonId, couponId);
    history.push('/content/coupon/shop');
  }, [salonId, couponId, history]);

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="美容師クーポン編集" />
      </Margin>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentTable>
          <ContentTable.Body>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px" required>
                スタイリストID
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'stylist_id',
                    register: register({
                      required: '必須項目です',
                    }),
                  }}
                  style={{
                    width: '170px',
                  }}
                />
                <ErrorText>{errors.stylist_id?.message}</ErrorText>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                クーポン名
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'name',
                    register,
                  }}
                  style={{}}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                クーポン内容
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextArea
                  control={{
                    type: 'hook',
                    name: 'description',
                    register,
                  }}
                  style={{}}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                画像
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <div style={{ marginBottom: '8px' }}>
                  <img src={coupon?.image_url} alt="" />
                </div>
                <input
                  type="file"
                  name="image.base64"
                  onChange={onFileChange}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                メニュー
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <Flex>
                  {coupon?.menus.map((menu) => (
                    <div key={menu.id} style={{ marginLeft: '16px' }}>
                      <CheckBoxWithText
                        id="cut"
                        text={menu.name}
                        isChecked
                        register={null}
                        size={{ height: 24 }}
                      />
                    </div>
                  ))}
                </Flex>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                価格
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <Flex>
                  <TextField
                    control={{
                      type: 'hook',
                      name: 'price',
                      register,
                    }}
                    style={{
                      width: '84px',
                    }}
                  />
                  円
                  <div style={{ marginLeft: '16px' }} />
                  <CheckBoxWithText
                    text="「 〜 」 を表示"
                    id="is_public"
                    size={{ height: 24 }}
                    register={register}
                    isChecked={watch('is_public')}
                  />
                </Flex>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                所用時間
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'minute',
                    register,
                  }}
                  style={{
                    width: '84px',
                  }}
                />
                分
              </ContentTable.Cell>
            </ContentTable.Row>
          </ContentTable.Body>
        </ContentTable>

        <ButtonWrapper>
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="登録"
            color="BLUE"
            type="submit"
            borderRadius={4}
          />
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="削除"
            color="RED"
            type="button"
            borderRadius={4}
            handleClick={onDelete}
          />
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="戻る"
            color="WHITE"
            borderRadius={4}
            type="button"
            handleClick={() => history.push('/content/coupon/stylist')}
          />
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};
