import { useState, useEffect } from 'react';
import {
  CustomerQuestionaryType,
  getCustomerQuestionaries,
  getStylistQuestionaries,
  ListType,
  StylistQuestionaryType,
} from '../api/questionaries';
import { PageData } from '../api/utils';

export const useStylistQuestionaries = (params: Partial<ListType>) => {
  const [questionaries, setQuestionaries] = useState<StylistQuestionaryType[]>(
    [],
  );
  const [page, setPage] = useState<PageData | null>(null);

  useEffect(() => {
    getStylistQuestionaries(params)
      .then(({ data }) => {
        setQuestionaries(data.data);
        setPage(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [params]);

  return { questionaries, page };
};

export const useCustomerQuestionaries = (params: Partial<ListType>) => {
  const [questionaries, setQuestionaries] = useState<CustomerQuestionaryType[]>(
    [],
  );
  const [page, setPage] = useState<PageData | null>(null);

  useEffect(() => {
    getCustomerQuestionaries(params)
      .then(({ data }) => {
        setQuestionaries(data.data);
        setPage(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [params]);

  return { questionaries, page };
};
