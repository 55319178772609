import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0 24px 24px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.36px;
  border-bottom: 1px solid #ccc;
`;

const WrapedLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 24px 0 24px 24px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.36px;
  border-bottom: 1px solid #ccc;
`;

const Icon = styled.img`
  margin-right: 16px;
`;

const Section = styled.ul<{ numberOfLink: number }>`
  height: 78px;
  overflow: hidden;
  transition: height 0.3s;
  border-bottom: 1px solid #ccc;
  &:hover {
    height: ${({ numberOfLink }) => 82 + 48 * numberOfLink}px;
  }
`;

const Item = styled.li`
  display: block;
  padding: 12px 0 12px 38px;
  font-size: 16px;
  letter-spacing: 1.09px;
  font-weight: normal;
`;

const Title = styled.p``;

type Props = {
  icon: string;
  title: string;
  numberOfLink: number;
  to?: string;
};

export const SideBarItem: React.FC<Props> = ({
  icon,
  title,
  to,
  numberOfLink,
  children,
}) => (
  <>
    {to !== undefined ? (
      <WrapedLink to={to}>
        <Icon
          src={`${process.env.PUBLIC_URL}/assets/nav/${icon}.svg`}
          width="25"
          height="25"
          alt={`${icon}`}
        />
        <Title>{title}</Title>
      </WrapedLink>
    ) : (
      <Section numberOfLink={numberOfLink}>
        <Wrapper>
          <Icon
            src={`${process.env.PUBLIC_URL}/assets/nav/${icon}.svg`}
            width="25"
            height="25"
            alt={`${icon}`}
          />
          <Title>{title}</Title>
        </Wrapper>
        {React.Children.map(children, (child) => (
          <Item key={String(child)}>{child}</Item>
        ))}
      </Section>
    )}
  </>
);
