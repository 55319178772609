import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { FontSize, Color } from '../../../lib/styles/variables';
import { SquareButton } from '../lv3/SquareButton';
import { RadioButtonWithText } from '../lv3/RadioButtonWithText';
import { SearchStoreParams } from '../../../lib/api/store';

const Wrapper = styled.form``;

const GridWrapper = styled.div`
  display: grid;
  grid:
    'name nameField area areaField' 42px
    'tel telField type typeField' 42px
    / 16% 30% 16% 38%;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TextField = styled.input<{ isLong?: boolean }>`
  border: 1px solid #979797;
  margin-left: 10px;
  width: ${({ isLong }) => (isLong ? '594px' : '170px')};
  height: 22px;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const RadioButtonArea = styled.div`
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

type Props = {
  onSubmit: (value: SearchStoreParams) => void;
};

export const StoreSearchForm: React.FC<Props> = ({ onSubmit }) => {
  const { register, handleSubmit, watch } = useForm<SearchStoreParams>({
    defaultValues: {
      name: '',
      phone_number: '',
      area: '',
      type: 'none',
    },
  });

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <GridWrapper>
        <GridItem gridArea="name">
          <Label>店舗名</Label>
        </GridItem>
        <GridItem gridArea="nameField">
          <TextField name="name" ref={register} />
        </GridItem>
        <GridItem gridArea="area">
          <Label>エリア</Label>
        </GridItem>
        <GridItem gridArea="areaField" isRightmost>
          <TextField name="area" ref={register} />
        </GridItem>
        <GridItem gridArea="tel" isBottom>
          <Label>電話番号</Label>
        </GridItem>
        <GridItem gridArea="telField" isBottom>
          <TextField name="phone_number" ref={register} />
        </GridItem>
        <GridItem gridArea="type" isBottom>
          <Label>種別</Label>
        </GridItem>
        <GridItem gridArea="typeField" isRightmost isBottom>
          <RadioButtonArea>
            <RadioButtonWithText
              text="選択なし"
              id=""
              register={register}
              isSelected={watch().type === ''}
              name="type"
            />
            <RadioButtonWithText
              text="店舗"
              id="店舗"
              register={register}
              isSelected={watch().type === '店舗'}
              name="type"
            />
            <RadioButtonWithText
              text="シェアサロン"
              id="シェアサロン"
              register={register}
              isSelected={watch().type === 'シェアサロン'}
              name="type"
            />
          </RadioButtonArea>
        </GridItem>
      </GridWrapper>
      <SquareButton
        fontSize={15}
        width={136}
        height={40}
        borderRadius={4}
        color="BLUE_BORDER"
        text="検索"
        type="submit"
      />
    </Wrapper>
  );
};
