import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      td {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-size: 14px;
      }
    }
  }
`;

const TdGray = styled.td`
  padding: 0.5rem;
  border: 1px solid #979797;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  background: #f1f1f1;
`;

type Props = {
  bank_name: string | null;
  bank_number: string | null;
  branch_store_name: string | null;
  branch_store_number: string | null;
  bank_account_name: string | null;
  bank_account_type: string | null;
  bank_account_number: string | null;
};

export const StylistDetailTableBank: FC<Props> = ({
  bank_name,
  bank_number,
  branch_store_name,
  branch_store_number,
  bank_account_name,
  bank_account_type,
  bank_account_number,
}) => {
  return (
    <Wrapper>
      <table>
        <tbody>
          <tr>
            <TdGray>金融機関名</TdGray>
            <td>{bank_name}</td>
            <TdGray>金融機関番号</TdGray>
            <td>{bank_number}</td>
          </tr>
          <tr>
            <TdGray>支店名</TdGray>
            <td>{branch_store_name}</td>
            <TdGray>支店番号</TdGray>
            <td>{branch_store_number}</td>
          </tr>
          <tr>
            <TdGray>口座名義人</TdGray>
            <td>{bank_account_name}</td>
            <TdGray>預金種目</TdGray>
            <td>{bank_account_type}</td>
          </tr>
          <tr>
            <TdGray>口座番号</TdGray>
            <td>{bank_account_number}</td>
            <TdGray />
            <td />
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
};
