import React, { useState } from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { ReservationTable } from '../parts/reservation/ReservationTable';
import dayjs from '../../lib/utils/dayjs';
import { DatePicker } from '../parts/lv3/DatePicker';
import { Triangle } from '../parts/lv3/Triangle';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const ReservationArea = styled.div``;
const ReservationActionArea = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ReservationManagementBtn = styled.div`
  background: #4a90e2;
  color: #ffffff;
  font-weight: 500;
  padding: 8px 16px;
  width: 100px;
`;

const CurrentDateArea = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: #212121;
  display: flex;
  align-items: center;
`;

const DatePickerLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const Reservation = () => {
  // TODO:: 変更
  const [day, setDay] = useState(dayjs('2019/07/24 00:00'));

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="シフト一覧" />
      </Margin>
      <ReservationArea>
        <ReservationActionArea>
          <ReservationManagementBtn>予約管理</ReservationManagementBtn>
          <CurrentDateArea>
            <DatePicker
              id="current-day"
              date={day.format('YYYY-MM-DD')}
              handleChange={(date) => setDay(dayjs(date))}
              style={{ fontSize: '20px', width: '158px', height: '30px' }}
            />
            <DatePickerLabel htmlFor="current-day">
              {`(${day.format('ddd')})`}
              <Triangle size={14} />
            </DatePickerLabel>
          </CurrentDateArea>
        </ReservationActionArea>
        <ReservationTable day={day} />
      </ReservationArea>
    </Wrapper>
  );
};
