import React from 'react';
import styled from 'styled-components';
import dayjs, { CellWidth, StylistHeight } from '../../../lib/utils/dayjs';
import { ScheduleType } from './ReservationTableBody';

const OverlayCell = styled.div<{ startIndex: number; colspan: number }>`
  left: calc(${CellWidth} * ${({ startIndex }) => startIndex});
  width: calc(${CellWidth} * ${({ colspan }) => colspan});
  height: calc(${StylistHeight} - 2px);
  border-right: 1px solid #979797;
  background: #f4ecf5;
  position: absolute;
  padding: 4px;
`;

const Label = styled.p`
  color: #212121;
  font-weight: 500;
  font-size: 12px;
`;

type Props = {
  days: dayjs.Dayjs[];
  schedules: ScheduleType[];
  name: string;
};
export const OverlaySchedule: React.FC<Props> = ({ days, schedules, name }) => {
  const cells = schedules.map((it) => {
    let startIndex = 0;
    let colspan = 1;
    const isSameDay = days.some((day) => {
      if (
        !day.isSame(dayjs(it.startTime)) &&
        !day.isAfter(dayjs(it.startTime))
      ) {
        startIndex += 1;
      }
      if (day.isSame(dayjs(it.endTime))) {
        return true;
      }
      if (day.isAfter(dayjs(it.startTime))) {
        colspan += 1;
      }
      return false;
    });
    const uniqueKey = `${it.startTime}-${it.endTime}-${startIndex}-${colspan}`;
    return { startIndex, colspan, uniqueKey, isSameDay };
  });
  return (
    <>
      {cells
        .filter((cell) => cell.isSameDay)
        .map((cell) => (
          <OverlayCell id={cell.uniqueKey} key={cell.uniqueKey} {...cell}>
            <Label>{name}</Label>
          </OverlayCell>
        ))}
    </>
  );
};
