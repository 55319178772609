/* eslint no-plusplus: 0 */
import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { DatePicker } from '../parts/lv3/DatePicker';
import { Triangle } from '../parts/lv3/Triangle';
import { Table } from '../parts/lv1/Table';
import { Margin } from '../parts/lv3/Margin';

const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 64px;
  margin-right: 16px;
  margin-left: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const DatePickerArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`;

export interface PerShopSearch {
  // TODO:: idに変更
  shopNames: string[];
}

const columns = [
  { header: '技術予算', accessor: 'accessor1', width: 106 },
  { header: '技術売上', accessor: 'accessor2', width: 106 },
  { header: '物販予算', accessor: 'accessor3', width: 106 },
  { header: '物販売上', accessor: 'accessor4', width: 106 },
  { header: '予算合計', accessor: 'accessor5', width: 106 },
  { header: '実績合計', accessor: 'accessor6', width: 106 },
  { header: '進捗率', accessor: 'accessor7', width: 106 },
  { header: '着地予想', accessor: 'accessor8', width: 106 },
];

const data = Array.from({ length: 4 }, (_, i) => ({
  accessor1: '300,000円',
  accessor2: '300,000円',
  accessor3: '300,000円',
  accessor4: '300,000円',
  accessor5: '600,000円',
  accessor6: '600,000円',
  accessor7: '100%',
  accessor8: '600,000円',
}));

const rowTitles = {
  titles: ['全店', 'Elenor 新宿', 'Elenor 銀座', 'tocca  難波'],
  width: 229,
};

export const Achievement = () => {
  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD'),
  );
  return (
    <Wrapper>
      <TopContentWrapper>
        <MainContentTitle text="実績" />
      </TopContentWrapper>

      <DatePickerArea>
        <DatePicker
          date={startDate}
          handleChange={(date) => setStartDate(date)}
          style={{ fontSize: '20px', width: '158px', height: '30px' }}
        />
        <Triangle size={14} />
      </DatePickerArea>
      <Margin margin="40px 0px 0px 0px">
        <Table data={data} columns={columns} rowTitles={rowTitles} />
      </Margin>
    </Wrapper>
  );
};
