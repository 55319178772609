/* eslint react/display-name: 0 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { getAllBrand } from '../../lib/api/brand';
import { useSpinner } from '../../lib/hooks/useSpinner';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const Button = styled.button`
  font-size: 12px;
  color: #4a90e2;
  background: #fff;
  width: 100%;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type BrandDataType = {
  brandName: string;
  editButton: any;
};

export const Brand = () => {
  const history = useHistory();
  const { loading, startLoading, finishLoading } = useSpinner();
  const [brandData, setBrandData] = useState<BrandDataType[]>([]);

  const EditButton = ({ brandId }: { brandId: number }) => {
    return (
      <Button onClick={() => history.push(`/store/brand/${brandId}/edit`)}>
        編集
      </Button>
    );
  };

  useEffect(() => {
    startLoading();
    getAllBrand()
      .then(({ data }) => {
        const brands =
          data?.map((brand) => {
            return {
              brandName: brand.name,
              editButton: <EditButton brandId={brand.id} />,
            };
          }) || [];
        setBrandData(brands);
      })
      .finally(() => {
        finishLoading();
      });
  }, []);

  const columns = [
    { header: 'ブランド名', accessor: 'brandName', width: 966 },
    { header: '', accessor: 'editButton', width: 60 },
  ];

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="ブランド" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px 40px 16px 40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Margin margin="0 0 16px 0">
          <SquareButton
            text="追加"
            width={136}
            height={40}
            fontSize={15}
            borderRadius={4}
            color="BLUE"
            handleClick={() => history.push('/store/brand/add')}
          />
        </Margin>
        <Form>
          {!loading ? (
            brandData.length > 0 ? (
              <Table columns={columns} data={brandData} />
            ) : (
              'ブランドがありません'
            )
          ) : (
            loading
          )}
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
