/* eslint react/display-name: 0 */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { Navigation } from '../parts/lv1/Navigation';
import { Paging } from '../parts/lv1/Paging';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { getSalonMotPoints } from '../../lib/api/salonMotPoints';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  font-size: 12px;
  color: #4a90e2;
  background: #fff;
`;

const ErrorText = styled.div`
  margin-top: 32px;
`;

type SalonMotPointTableData = {
  date: string;
  mot: number;
  editButton: any;
};

export const SalonMotPoint = () => {
  const history = useHistory();
  const { selectedSalon } = useContext(AccountContext);
  const [tableData, setTableData] = useState<SalonMotPointTableData[]>([]);
  const [total, setTotal] = useState(0);
  const [per, setPer] = useState(20);
  const [lastPage, setLastPage] = useState(1);

  const EditButton = ({ motId }: { motId: number }) => {
    return (
      <Button onClick={() => history.push(`/store/mission/mot/edit/${motId}`)}>
        編集
      </Button>
    );
  };

  const links = [
    { name: '予算・目標', to: `/store/mission`, isselected: false },
    { name: 'MOT点数', to: `/store/mission/mot`, isselected: true },
  ];

  const columns = [
    { header: '日付', accessor: 'date', width: 90 },
    { header: 'MOT点数', accessor: 'mot', width: 100 },
    { header: '', accessor: 'editButton', width: 60 },
  ];
  useEffect(() => {
    if (!selectedSalon) return;

    // ページ読み込み時のみのため、1固定
    getSalonMotPoints(selectedSalon, 1)
      .then(({ data }) => {
        setTotal(data.total);
        setPer(data.per_page);
        setLastPage(data.last_page);
        setTableData(
          data.data.map((mot) => {
            return {
              date: mot.date,
              mot: mot.point,
              editButton: <EditButton motId={mot.id} />,
            };
          }),
        );
      })
      .finally(() => {});
  }, [selectedSalon]);

  const onPageChanged = (index: number) => {
    if (!selectedSalon) return;
    if (index > lastPage) history.go(0); // リロード

    getSalonMotPoints(selectedSalon, index)
      .then(({ data }) => {
        setTotal(data.total);
        setPer(data.per_page);
        setTableData(
          data.data.map((mot) => {
            return {
              date: mot.date,
              mot: mot.point,
              editButton: <EditButton motId={mot.id} />,
            };
          }),
        );
      })
      .finally(() => {});
  };

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="MOT点数" />
        </Margin>
        <Navigation links={links} isSmall />
      </TopContent>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <SquareButton
          text="新規作成"
          width={136}
          height={40}
          fontSize={15}
          borderRadius={4}
          color="BLUE"
          handleClick={() => history.push('/store/mission/mot/add')}
        />
        {tableData.length > 0 ? (
          <Margin margin="24px 0 60px 0">
            <Table columns={columns} data={tableData} gravity="end" />
          </Margin>
        ) : (
          <ErrorText>登録されているMOT点数はありません</ErrorText>
        )}
        {total > per ? (
          <Paging
            sum={total}
            per={per}
            onChange={(value) => {
              onPageChanged(value.page);
            }}
          />
        ) : null}
      </ShadedContainer>
    </Wrapper>
  );
};
