import { useState, useEffect } from 'react';
import { getBlogInfo, Blog } from '../api/blog';

export const useBlogInfo = (id: string | null) => {
  const [blog, setBlog] = useState<Blog | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (id !== null) {
      getBlogInfo(id)
        .then(({ data }) => {
          setBlog(data);
        })
        .catch((e) => {
          setErrors(['エラーが発生しました']);
        });
    }
  }, [id]);

  return { blog, errors };
};
