import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const CustomInput = styled.input`
  margin-right: 4px;
  -webkit-appearance: radio;
`;

export const ReservationInputRadio: FC<{
  label: string;
  value: string;
  checked: boolean;
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, value, checked, onClick }) => {
  const handleClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onClick(e);
    },
    [onClick],
  );
  return (
    <Wrapper>
      <CustomInput
        type="radio"
        id={value}
        value={value}
        checked={checked}
        onChange={handleClick}
      />
      <label htmlFor={value}>{label}</label>
    </Wrapper>
  );
};
