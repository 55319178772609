import apiBase from './apiBase';
import getQueryParams from '../utils/getQueryParams';

export type Budget = {
  id: number;
  target_date: string;
  salon_id: string;
  salon_name: string;
  amount: string;
  marchandise_sales_target_amount: string;
};

export type GetBudgetParams = {
  from_date: string;
  to_date: string;
  phone_number: string;
  area: string;
  type: string;
};

export const getBudgetParams = {
  from_date: '',
  to_date: '',
  phone_number: '',
  area: '',
  type: '',
};

type GetBudgetRes = {
  data: Budget[];
};

export const getECBudget = (params: GetBudgetParams) =>
  apiBase.get<GetBudgetRes>(`/budgets?${getQueryParams(params)}`);

export type UpdateBudgetParams = {
  target_date: string;
  salon_id: string;
  amount: string;
  marchandise_sales_target_amount: string;
};
export const updateBudget = (params: UpdateBudgetParams, id: number) =>
  apiBase.put(`/budget/${id}`, params);
export const addBudget = (params: UpdateBudgetParams) =>
  apiBase.post('/budget', params);
export const deleteBudget = (id: number) => apiBase.delete(`/budget/${id}`);

export type GetMissionBudgetParams = {
  year: string;
};

export type MissionBudget = {
  salon_id: number;
  year_month: string;
  budget: number;
  purchase_product_customer_rate: number;
  material_cost_rate: number;
  treatment_sales_target: number;
  product_sales_target: number;
  total_sales_target: number;
};

export const getMissionBudget = (
  salonId: number,
  params: GetMissionBudgetParams,
) =>
  apiBase.get<MissionBudget[]>(
    `/salon/${salonId}/budgets?${getQueryParams(params)}`,
  );

export type UpdateMissionBudgetParams = {
  year_month: string;
  budget: number;
  purchase_product_customer_rate: number;
  material_cost_rate: number;
  treatment_sales_target: number;
  product_sales_target: number;
  total_sales_target: number;
};

// TOOD 向き先確認中
export const updateMissionBudget = (
  params: UpdateMissionBudgetParams,
  id: number,
) => apiBase.put<MissionBudget>(`/budget/${id}`, params);

export const addMissionBudget = (
  salonId: number,
  params: UpdateMissionBudgetParams,
) => apiBase.post<MissionBudget>(`/salon/${salonId}/budget`, params);
