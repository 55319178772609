import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { SelectBox } from '../parts/lv3/SelectBox';
import {
  getFacilities,
  updateFacilities,
  UpdateFacilityParams,
} from '../../lib/api/facility';
import useCategories from '../../lib/hooks/useCategories';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { useSpinner } from '../../lib/hooks/useSpinner';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const StoreSettingsArea = styled.div`
  min-width: 1080px;
`;

const MainContainer = styled.div`
  margin: 0 64px 0 64px;
  padding-top: 40px;
  position: relative;
`;

const TextField = styled.input<{ isLong?: boolean }>`
  border: 1px solid #979797;
  width: ${({ isLong }) => (isLong ? '430px' : '100px')};
  height: 22px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Button = styled.button`
  font-size: 12px;
  color: #e24a4a;
  background: #fff;
  width: 100%;
  align-items: center;
`;

const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 24px;
`;

const ButtonMargin = styled.div`
  margin-left: 24px;
`;

type StoreSetting = {
  name: string;
  category: string;
  acceptable: number;
  spot: number;
};

export const StoreSettings = () => {
  const { register, handleSubmit, control } = useForm<{
    storeSettings: StoreSetting[];
  }>({
    defaultValues: {
      storeSettings: [],
    },
  });

  const { fields, append, remove } = useFieldArray<{
    name: string;
    category: string;
    acceptable: number;
    spot: number;
  }>({
    control,
    name: 'storeSettings',
  });

  const categories = useCategories();
  const { selectedSalon } = useContext(AccountContext);
  const { loading, startLoading, finishLoading } = useSpinner();

  useEffect(() => {
    if (selectedSalon) {
      startLoading();
      getFacilities(selectedSalon)
        .then(({ data }) => {
          append(
            data.map((facility) => ({
              name: facility.name,
              category: facility.category_id.toString(),
              acceptable: facility.acceptable_number,
              spot: facility.spot_user_acceptable_number,
            })),
          );
        })
        .finally(() => {
          finishLoading();
        });
    }
  }, [selectedSalon]);

  const onSubmit = (input: { storeSettings: StoreSetting[] }) => {
    if (!selectedSalon) return;
    const params: UpdateFacilityParams[] = input.storeSettings.map(
      (facility, index) => {
        return {
          salon_id: selectedSalon,
          seq: index + 1,
          name: facility.name,
          service_category_id: Number(facility.category),
          acceptable_number: facility.acceptable,
          spot_user_acceptable_number: facility.spot,
        };
      },
    );

    updateFacilities(selectedSalon, params)
      .then(({ data }) => {})
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  };

  const columns = [
    { header: '設備名', accessor: 'name', width: 470 },
    { header: 'カテゴリ', accessor: 'category', width: 150 },
    { header: '受付可能数', accessor: 'acceptable', width: 110 },
    { header: 'スポット利用数', accessor: 'spot', width: 110 },
    { header: '', accessor: 'deleteButton', width: 60 },
  ];

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="設備設定" />
      </Margin>
      <StoreSettingsArea>
        <ShadedContainer
          style={{
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            padding: '0 0 64px 0',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <MainContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {!loading ? (
                <>
                  <Table
                    columns={columns}
                    data={fields.map((value, index) => {
                      return {
                        id: value.id,
                        name: (
                          <Controller
                            as={<TextField isLong />}
                            name={`storeSettings[${index}].name`}
                            control={control}
                            defaultValue={value.name || ''}
                          />
                        ),
                        category: (
                          <Controller
                            as={(
                              <SelectBox
                                control={{
                                  type: 'hook',
                                  name: `storeSettings[${index}].category`,
                                  register: register(),
                                  defaultValue: value.category || '',
                                }}
                                style={{
                                  width: '158px',
                                  fontSize: '12px',
                                  height: '30px',
                                  border: 'all',
                                  padding: '0 8px',
                                }}
                              >
                                {categories?.map((category) => (
                                  <option
                                    key={`option-${category.name}`}
                                    value={category.id}
                                  >
                                    {category.name}
                                  </option>
                                ))}
                              </SelectBox>
                            )}
                            name={`storeSettings[${index}].category`}
                            control={control}
                            defaultValue={value.category || ''}
                          />
                        ),
                        acceptable: (
                          <Controller
                            as={<TextField type="number" />}
                            name={`storeSettings[${index}].acceptable`}
                            control={control}
                            defaultValue={value.acceptable || ''}
                          />
                        ),
                        spot: (
                          <Controller
                            as={<TextField type="number" />}
                            name={`storeSettings[${index}].spot`}
                            control={control}
                            defaultValue={value.spot || ''}
                          />
                        ),
                        deleteButton: (
                          <Button
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            削除
                          </Button>
                        ),
                      };
                    })}
                  />
                  <SubmitButtonWrapper>
                    <SquareButton
                      fontSize={15}
                      width={136}
                      height={40}
                      borderRadius={4}
                      color="BLUE_BORDER"
                      text="追加"
                      type="button"
                      handleClick={() => {
                        append({
                          name: '',
                          category: '',
                          acceptable: 0,
                          spot: 0,
                        });
                      }}
                    />
                    <ButtonMargin>
                      <SquareButton
                        fontSize={15}
                        width={136}
                        height={40}
                        borderRadius={4}
                        color="BLUE"
                        text="保存"
                        type="submit"
                      />
                    </ButtonMargin>
                  </SubmitButtonWrapper>
                </>
              ) : (
                loading
              )}
            </Form>
          </MainContainer>
        </ShadedContainer>
      </StoreSettingsArea>
    </Wrapper>
  );
};
