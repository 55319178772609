/* eslint jsx-a11y/label-has-for: 0 */
/* eslint jsx-a11y/label-has-associated-control: 0 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { SelectBox } from '../parts/lv3/SelectBox';
import { SquareButton } from '../parts/lv3/SquareButton';
import { encodeBase64 } from '../../lib/utils/base64';
import { prefecuture as prefectureList } from '../../lib/constants/prefecture';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const MainContainer = styled.div`
  margin: 0 312px 0 64px;
  padding-top: 40px;
  position: relative;
`;

const Title = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #757575;
`;

const TextField = styled.input<{ isLong?: boolean }>`
  width: ${({ isLong }) => (isLong ? '100%' : '337px')};
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  line-height: 36px;
  padding: 3px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const FieldWrapper = styled.div`
  margin-bottom: 40px;
  width: 714px;
  justify-content: space-between;
`;

const RowContent = styled.div`
  display: flex;
`;

const TextArea = styled.textarea`
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  line-height: 36px;
  padding: 3px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddButton = styled.button`
  font-size: 24px;
  line-height: 36px;
  color: #4a90e2;
  background: #fff;
  margin-bottom: 40px;
`;

const DeleteButton = styled.button`
  font-size: 24px;
  line-height: 36px;
  color: #e24a4a;
  background: #fff;
  margin-bottom: 40px;
`;

const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgUploader = styled.div`
  position: absolute;
  top: 40px;
  right: 20.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    border: 1px dashed #d8d8d8;
    width: 130px;
    height: 130px;
  }

  > label {
    font-size: 20px;
    line-height: 30px;
    color: #4a90e2;
  }
`;

const Img = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 65px;
  border: 1px solid #979797;
  object-fit: cover;
`;

const UnsetImg = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 65px;
  border: 1px solid #979797;
  background: #d8d8d8;
`;

const PhoneNumberFieldWrapper = styled.div`
  width: 337px;
  display: flex;
  justify-content: space-between;
`;

const PhoneNumberField = styled.input`
  width: 102px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  line-height: 36px;
  padding: 3px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const OptionFieldWrapper = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  width: 860px;
`;

const OptionDeleteButton = styled.button`
  width: 200px;
  font-size: 24px;
  line-height: 36px;
  color: #e24a4a;
  background: #fff;
`;

type Question = {
  title: string;
  type: 'radio';
  choices: string[];
};

export const AddStore = () => {
  const [brand, setBrand] = useState('');
  const [questions, setQuestinos] = useState<Question[]>([
    { title: '', type: 'radio', choices: [''] },
  ]);
  const [img, setImg] = useState<string | null>(null);
  const [prefecuture, setprefecuture] = useState('');

  const AddQuestion = () => {
    const nextArray: Question[] = questions.concat({
      title: '',
      type: 'radio',
      choices: [''],
    });
    setQuestinos(nextArray);
  };

  const DeleteQuestion = (index: number) => {
    const nextArray: Question[] = questions.concat();
    nextArray.splice(index, 1);

    setQuestinos(nextArray);
  };

  const AddChoice = (index: number) => {
    const nextArray: Question[] = questions.concat();
    nextArray[index].choices.push('');
    setQuestinos(nextArray);
  };

  const handleFileChange = (file: any) => {
    encodeBase64(file).then((encoded) => {
      setImg(encoded);
    });
  };

  const DeleteOption = (questionIndex: number, optionIndex: number) => {
    const nextArray: Question[] = questions.concat();
    nextArray[questionIndex].choices.splice(optionIndex, 1);

    setQuestinos(nextArray);
  };

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="店舗登録" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '0 0 64px 0',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <MainContainer>
          <ImgUploader>
            <input
              type="file"
              style={{ display: 'none' }}
              id="img"
              onChange={(e: any) => handleFileChange(e.target.files[0])}
            />
            <div>
              {img ? <Img src={img} alt="profile_img" /> : <UnsetImg />}
            </div>
            <label htmlFor="img">写真追加</label>
          </ImgUploader>

          <FieldWrapper>
            <Title>店舗名</Title>
            <TextField placeholder="渋谷駅前" />
          </FieldWrapper>

          <FieldWrapper>
            <Title>ブランド</Title>
            <SelectBox
              control={{
                type: 'state',
                value: brand,
                onChange: (v) => setBrand(v),
              }}
              style={{
                width: '337px',
                fontSize: '24px',
                height: '36px',
                border: 'bottom',
              }}
            >
              <option value="">選択してください</option>
              <option value="elenor">Elenor</option>
            </SelectBox>
          </FieldWrapper>

          <RowContent>
            <FieldWrapper>
              <Title>電話番号</Title>
              <PhoneNumberFieldWrapper>
                <PhoneNumberField placeholder="080" />
                <PhoneNumberField placeholder="1234" />
                <PhoneNumberField placeholder="5678" />
              </PhoneNumberFieldWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <Title>都道府県</Title>
              <SelectBox
                control={{
                  type: 'state',
                  value: prefecuture,
                  onChange: (v) => setprefecuture(v),
                }}
                style={{
                  width: '337px',
                  fontSize: '24px',
                  height: '43px',
                  border: 'bottom',
                }}
              >
                <option value="">選択なし</option>
                {prefectureList.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </SelectBox>
            </FieldWrapper>
          </RowContent>

          <RowContent>
            <FieldWrapper>
              <Title>郵便番号</Title>
              <TextField placeholder="1234567" />
            </FieldWrapper>
            <FieldWrapper>
              <Title>建物名/部屋番号</Title>
              <TextField placeholder="test@yahoo.co.jp" />
            </FieldWrapper>
          </RowContent>

          <RowContent>
            <FieldWrapper>
              <Title>市区町村</Title>
              <TextField placeholder="文京区" />
            </FieldWrapper>
            <FieldWrapper>
              <Title>座席数</Title>
              <TextField placeholder="1" />
            </FieldWrapper>
          </RowContent>

          <FieldWrapper>
            <Title>営業日時</Title>
            <TextField placeholder="10:00 ~ 20:00" />
          </FieldWrapper>

          <FieldWrapper>
            <Title>アクセス方法</Title>
            <TextArea placeholder="渋谷駅から徒歩1分&#13;&#10;代々木駅から徒歩分" />
          </FieldWrapper>

          <FieldWrapper>
            <Title>紹介文</Title>
            <TextArea placeholder="カットが得意の店舗です&#13;&#10;ボブカットが得意なスタイリストが多くいます。" />
          </FieldWrapper>

          <FieldWrapper>
            <Title>お客様への注意事項</Title>
            <TextArea placeholder="キャンセルは&#13;&#10;来店の1日前までにお願いいたします。" />
          </FieldWrapper>

          <Margin margin="24px 0">
            <Title>お客様への質問</Title>
          </Margin>

          {questions.map((question, i) => (
            <>
              <RowContent key={i}>
                <FieldWrapper>
                  <Title>見出し</Title>
                  <TextField
                    placeholder="ご来店は初めてですか？"
                    value={question.title}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Title>項目</Title>
                  <SelectBox
                    control={{
                      type: 'state',
                      value: question.type,
                      onChange: (v) => console.log(v),
                    }}
                    style={{
                      width: '337px',
                      fontSize: '24px',
                      height: '43px',
                      border: 'bottom',
                    }}
                  >
                    <option value="radio">ラジオボタン</option>
                  </SelectBox>
                </FieldWrapper>
              </RowContent>

              {question.choices.map((choice, choiceIndex) => (
                <FieldWrapper key={choiceIndex}>
                  <Title>{`選択肢${choiceIndex + 1}`}</Title>
                  <OptionFieldWrapper>
                    <TextField
                      placeholder="初めてです。"
                      isLong
                      value={choice}
                    />
                    <OptionDeleteButton
                      onClick={() => DeleteOption(i, choiceIndex)}
                    >
                      選択肢の削除
                    </OptionDeleteButton>
                  </OptionFieldWrapper>
                </FieldWrapper>
              ))}
              <ButtonArea>
                <AddButton onClick={() => AddChoice(i)}>
                  ＋ 選択肢を追加
                </AddButton>
                <DeleteButton onClick={() => DeleteQuestion(i)}>
                  質問の削除
                </DeleteButton>
              </ButtonArea>
            </>
          ))}

          <AddButton onClick={() => AddQuestion()}>＋ 質問を追加</AddButton>
        </MainContainer>

        <SubmitButtonWrapper>
          <SquareButton
            fontSize={24}
            width={160}
            height={56}
            borderRadius={4}
            color="BLUE"
            text="登録"
            type="button"
            handleClick={() => alert('WIP: 店舗管理の修正待ち')}
          />
        </SubmitButtonWrapper>
      </ShadedContainer>
    </Wrapper>
  );
};
