import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { encodeBase64 } from '../../lib/utils/base64';
import { StylistDetailSelect } from '../parts/lv1/StylistDetailSelect';
import { StylistDetailInputText } from '../parts/lv1/StylistDetailInputText';
import { StylistDetailInputPassword } from '../parts/lv1/StylistDetailInputPassword';
import { getSalons, Salon } from '../../lib/api/salon';
import {
  updateStylist,
  ContractType,
  getStylistDetail,
  Stylist,
  deleteStylist,
  addStylist,
} from '../../lib/api/stylist';
import contractType from '../../lib/constants/contractType';
import { ValidationErrorMessage } from '../parts/lv3/ValidationErrorMessage';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const MainContainer = styled.div`
  margin: 0 312px 0 64px;
  padding-top: 40px;
  position: relative;
`;

const FieldWrapper = styled.div`
  margin-bottom: 40px;
`;

const Label = styled.label<{ isChecked: boolean }>`
  margin-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  color: ${({ isChecked }) => (isChecked ? '#757575' : '#D8D8D8')};
  input[type='radio'] {
    -webkit-appearance: radio;
    margin-right: 4px;
  }
`;

const RowContent = styled.div`
  display: flex;
`;

const RowContentSelect = styled.div`
  display: flex;
  flex: none;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin-right: 1rem;
  }
`;

const ImgUploader = styled.div`
  position: absolute;
  top: 40px;
  right: 20.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  border: 1px dashed #d8d8d8;
  width: 130px;
  height: 130px;
`;

const AddImage = styled.label`
  font-size: 20px;
  line-height: 30px;
  color: #4a90e2;
`;

const Img = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 65px;
  border: 1px solid #979797;
`;

const UnsetImg = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 65px;
  border: 1px solid #979797;
  background: #d8d8d8;
`;

const ContractTypeField = styled.div`
  > span {
    font-size: 20px;
    line-height: 30px;
    color: #757575;
  }

  > p {
    font-size: 24px;
  }
`;

const contractTypeOptions = [
  { value: 0, label: '正社員' },
  { value: 1, label: '業務委託' },
  { value: 2, label: 'シェアサロン利用' },
];

export const StylistDetailEdit = () => {
  const history = useHistory();
  const { id, stylistId } = useParams<{ id: string; stylistId: string }>();
  const [salons, setSalons] = useState<Salon[]>([]);
  const [stylist, setStylist] = useState<Partial<Stylist>>({});
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [rentChecked, setRentChecked] = useState<
    'スポット利用' | '月額固定賃料' | '月額売上歩合賃料'
  >('スポット利用');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isAdd = useMemo(() => {
    const { pathname } = history.location;
    return pathname.includes('add');
  }, [history]);

  useEffect(() => {
    if (!isAdd) {
      getStylistDetail(stylistId)
        .then(({ data }) => {
          const contract_type =
            typeof data.contract_type === 'string'
              ? (contractType.indexOf(data.contract_type) as ContractType)
              : data.contract_type;
          setStylist({ ...data, contract_type });
        })
        .catch((e) => {
          setErrorMessage('エラーが発生しました');
        });
    }
  }, [isAdd, stylistId]);

  useEffect(() => {
    getSalons().then(({ data }) => {
      setSalons(data.data);
    });
  }, []);

  const salonOptions = useMemo(() => {
    return salons.map((item) => {
      return { value: item.id, label: item.name };
    });
  }, [salons]);

  const handleChangeImage = (e: any) => {
    const file = e.target.files[0];
    encodeBase64(file).then((encoded) => {
      setStylist((prev) => {
        return { ...prev, image_url: encoded };
      });
    });
  };

  const handleChangeContractType = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = Number(e.target.value) as ContractType;
      setStylist((prev) => {
        return { ...prev, contract_type: value };
      });
    },
    [],
  );

  const handleChangeSalonMain = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, main_salon_id: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleChangeSalonSub = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, sub_salon_id: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleChangeEmploymentNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, employee_number: value };
      });
    },
    [],
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, user_name: value };
      });
    },
    [],
  );

  const handleChangeNameKana = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, user_kana_name: value };
      });
    },
    [],
  );

  const handleChangePhoneNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, phone_number: value };
      });
    },
    [],
  );

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, email: value };
      });
    },
    [],
  );

  const handleChangeEmergencyCall = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, emergency_phone_number: value };
      });
    },
    [],
  );

  const handleChangeHoliday = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return {
          ...prev,
          monthy_holiday_number: parseInt(value, 10) || undefined,
        };
      });
    },
    [],
  );

  const handleChangeBase = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, old_basic_salary: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleChangeBack = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, ec_feed_back_rate: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleChangeCommissionRate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, commission_rate: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleChangeFreeCommission = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return {
          ...prev,
          treatment_sale_free_commission: parseInt(value, 10),
        };
      });
    },
    [],
  );

  const handleChangeNameCommission = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return {
          ...prev,
          treatment_sale_name_commission: parseInt(value, 10),
        };
      });
    },
    [],
  );

  const handleChangeProductSaleCommission = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return {
          ...prev,
          product_sale_commission: parseInt(value, 10),
        };
      });
    },
    [],
  );

  const handleChangeHireDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, hire_date: value };
      });
    },
    [],
  );

  const handleChangeLeaveDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return { ...prev, leave_date: value };
      });
    },
    [],
  );

  const handleChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setPassword(value);
    },
    [],
  );

  const handleChangePasswordConfirm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setPasswordConfirm(value);
    },
    [],
  );

  const handleChangeRentChecked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value }: any = e.target;
      setRentChecked(value);
    },
    [],
  );

  const handleChangeFixedRent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return {
          ...prev,
          monthly_fixed_usage_fee: parseInt(value, 10),
        };
      });
    },
    [],
  );

  const handleChangeCommissionRent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setStylist((prev) => {
        return {
          ...prev,
          sales_commission_usage_fee: parseInt(value, 10),
        };
      });
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (password !== passwordConfirm) {
        setErrorMessage('パスワードが不適切です');
        return;
      }
      const params =
        stylist.contract_type === 0
          ? { ...stylist, smart_hr_id: stylist.employee_number }
          : { ...stylist };
      if (isAdd) {
        await addStylist({
          ...params,
          password,
          password_confirmation: passwordConfirm,
        });
        history.replace(`/store/detail/${id}/stylist`);
      } else {
        await updateStylist(params);
        history.replace(`/store/detail/${id}/stylist/detail/${stylist.id}`);
      }
    } catch (e) {
      setErrorMessage('エラーが発生しました');
    }
  }, [isAdd, stylist, history, id, password, passwordConfirm]);

  const handleClickDelete = useCallback(async () => {
    try {
      await deleteStylist();
      history.replace(`/store/detail/${id}/stylist/detail/${stylist.id}`);
    } catch (e) {
      setErrorMessage('エラーが発生しました');
    }
  }, [history, id]);

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text={isAdd ? '美容師追加' : '美容師編集'} />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '0 0 64px 0',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <MainContainer>
          {(stylist.contract_type === 0 ||
            stylist.contract_type === 1 ||
            stylist.contract_type === 2) && (
            <ImgUploader>
              <ImageWrapper>
                {stylist && stylist.image_url ? (
                  <Img src={stylist.image_url} alt="profile_img" />
                ) : (
                  <UnsetImg />
                )}
              </ImageWrapper>
              <AddImage htmlFor="img">写真追加</AddImage>
              <input
                type="file"
                style={{ display: 'none' }}
                id="img"
                onChange={handleChangeImage}
              />
            </ImgUploader>
          )}

          <FieldWrapper>
            {isAdd ? (
              <StylistDetailSelect
                value={stylist.contract_type}
                label="雇用形態"
                options={contractTypeOptions}
                onChange={handleChangeContractType}
                width="300px"
              />
            ) : (
              <ContractTypeField>
                <span>雇用形態</span>
                <p>
                  {stylist.contract_type && contractType[stylist.contract_type]}
                </p>
              </ContractTypeField>
            )}
          </FieldWrapper>

          {(stylist.contract_type === 0 ||
            stylist.contract_type === 1 ||
            stylist.contract_type === 2) && (
            <>
              <RowContentSelect>
                <FieldWrapper>
                  <StylistDetailSelect
                    value={stylist.main_salon_id}
                    label="所属店舗"
                    options={salonOptions}
                    onChange={handleChangeSalonMain}
                    width="170px"
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <StylistDetailSelect
                    value={stylist.sub_salon_id}
                    label="所属店舗（サブ）"
                    options={salonOptions}
                    onChange={handleChangeSalonSub}
                    width="170px"
                  />
                </FieldWrapper>
              </RowContentSelect>

              <RowContent>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.employee_number}
                    type="text"
                    label={
                      stylist.contract_type === 0 ? 'SmartHR ID' : '従業員番号'
                    }
                    placeholder="080456784321"
                    onChange={handleChangeEmploymentNumber}
                  />
                </FieldWrapper>
              </RowContent>

              <RowContent>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.user_name}
                    label="氏名"
                    placeholder="田中正人"
                    onChange={handleChangeName}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.user_kana_name}
                    label="氏名（フリガナ）"
                    placeholder="タナカマサト"
                    onChange={handleChangeNameKana}
                  />
                </FieldWrapper>
              </RowContent>

              <RowContent>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.phone_number}
                    type="number"
                    label="電話番号"
                    placeholder="080456784321"
                    onChange={handleChangePhoneNumber}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.email}
                    type="email"
                    label="メールアドレス"
                    placeholder="test@yahoo.co.jp"
                    onChange={handleChangeEmail}
                  />
                </FieldWrapper>
              </RowContent>
            </>
          )}

          <RowContent>
            {(stylist.contract_type === 1 || stylist.contract_type === 2) && (
              <FieldWrapper>
                <StylistDetailInputText
                  value={stylist.emergency_phone_number}
                  type="number"
                  label="緊急連絡先"
                  placeholder="09083073591"
                  onChange={handleChangeEmergencyCall}
                />
              </FieldWrapper>
            )}

            {stylist.contract_type === 0 && (
              <FieldWrapper>
                <StylistDetailInputText
                  value={stylist.monthy_holiday_number}
                  type="number"
                  label="月の休日数"
                  placeholder="5"
                  onChange={handleChangeHoliday}
                />
              </FieldWrapper>
            )}
          </RowContent>

          {stylist.contract_type === 2 && (
            <RowContent>
              <Label isChecked={rentChecked === 'スポット利用'}>
                <input
                  type="radio"
                  value="スポット利用"
                  checked={rentChecked === 'スポット利用'}
                  onChange={handleChangeRentChecked}
                />
                スポット利用
              </Label>
              <FieldWrapper>
                <Label isChecked={rentChecked === '月額固定賃料'}>
                  <input
                    type="radio"
                    value="月額固定賃料"
                    checked={rentChecked === '月額固定賃料'}
                    onChange={handleChangeRentChecked}
                  />
                  月額固定賃料
                </Label>
                <StylistDetailInputText
                  value={stylist.monthly_fixed_usage_fee}
                  type="number"
                  label=""
                  placeholder=""
                  unit="円"
                  disabled={rentChecked !== '月額固定賃料'}
                  onChange={handleChangeFixedRent}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Label isChecked={rentChecked === '月額売上歩合賃料'}>
                  <input
                    type="radio"
                    value="月額売上歩合賃料"
                    checked={rentChecked === '月額売上歩合賃料'}
                    onChange={handleChangeRentChecked}
                  />
                  月額売上歩合賃料
                </Label>
                <StylistDetailInputText
                  value={stylist.sales_commission_usage_fee}
                  type="number"
                  label=""
                  placeholder=""
                  unit="%"
                  disabled={rentChecked !== '月額売上歩合賃料'}
                  onChange={handleChangeCommissionRent}
                />
              </FieldWrapper>
            </RowContent>
          )}

          {(stylist.contract_type === 0 || stylist.contract_type === 1) && (
            <>
              <RowContent>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.old_basic_salary}
                    type="number"
                    label="旧基本給"
                    placeholder="240,000"
                    unit="円"
                    onChange={handleChangeBase}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.ec_feed_back_rate}
                    type="number"
                    label="EC売り上げのバック率"
                    placeholder="5"
                    unit="%"
                    onChange={handleChangeBack}
                  />
                </FieldWrapper>
              </RowContent>

              <RowContent>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.commission_rate}
                    type="number"
                    label="歩合率"
                    placeholder=""
                    unit="%"
                    onChange={handleChangeCommissionRate}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.treatment_sale_free_commission}
                    type="number"
                    label="施術売上フリー歩合"
                    placeholder=""
                    unit="%"
                    onChange={handleChangeFreeCommission}
                  />
                </FieldWrapper>
              </RowContent>

              <RowContent>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.treatment_sale_name_commission}
                    type="number"
                    label="施術売上指名歩合"
                    placeholder=""
                    unit="%"
                    onChange={handleChangeNameCommission}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.product_sale_commission}
                    type="number"
                    label="物販売上歩合"
                    placeholder=""
                    unit="%"
                    onChange={handleChangeProductSaleCommission}
                  />
                </FieldWrapper>
              </RowContent>
            </>
          )}

          {(stylist.contract_type === 0 ||
            stylist.contract_type === 1 ||
            stylist.contract_type === 2) && (
            <>
              <RowContent>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.hire_date || ''}
                    label="入社日"
                    placeholder="2019/2/2"
                    onChange={handleChangeHireDate}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <StylistDetailInputText
                    value={stylist.leave_date || ''}
                    label="退社日"
                    placeholder="2020/2/2"
                    onChange={handleChangeLeaveDate}
                  />
                </FieldWrapper>
              </RowContent>

              {isAdd && (
                <>
                  <FieldWrapper>
                    <StylistDetailInputPassword
                      label="パスワード"
                      onChange={handleChangePassword}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <StylistDetailInputPassword
                      label="パスワード再入力"
                      onChange={handleChangePasswordConfirm}
                    />
                  </FieldWrapper>
                </>
              )}
            </>
          )}

          {errorMessage && (
            <ValidationErrorMessage message={errorMessage} large />
          )}
        </MainContainer>

        {(stylist.contract_type === 0 ||
          stylist.contract_type === 1 ||
          stylist.contract_type === 2) && (
          <SubmitButtonWrapper>
            <SquareButton
              fontSize={24}
              width={160}
              height={56}
              borderRadius={4}
              color="BLUE"
              text={isAdd ? '登録' : '更新'}
              handleClick={handleSubmit}
            />
            {!isAdd && (
              <SquareButton
                fontSize={24}
                width={160}
                height={56}
                borderRadius={4}
                text="削除"
                color="RED"
                handleClick={handleClickDelete}
              />
            )}
          </SubmitButtonWrapper>
        )}
      </ShadedContainer>
    </Wrapper>
  );
};
