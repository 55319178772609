import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { SquareButton } from './SquareButton';

const ActionArea = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 24px;
  & > button:not(:first-child) {
    margin-top: 24px;
  }
`;

type SubmitActionType = {
  onCancel: () => void;
  onSubmit: () => void;
};

type Props = {
  ContentComponent: React.ReactNode;
  isOpen: boolean;
  closeModal: () => void;
  style?: {};
  shouldCloseOnOverlayClick?: boolean;
  actions?: SubmitActionType;
  btnLabel?: string;
};

export const SpModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  style,
  ContentComponent,
  shouldCloseOnOverlayClick = true,
  actions,
  btnLabel,
}) => {
  const baseStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '375px',
      overflow: 'scroll',
      height: '70vh',
    },
  };
  return (
    <ReactModal
      isOpen={isOpen}
      style={{ ...baseStyle, ...style }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={closeModal}
    >
      {ContentComponent}
      {actions && (
        <ActionArea>
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text={btnLabel ?? '登録'}
            color="BLUE"
            borderRadius={4}
            handleClick={actions.onSubmit}
          />
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="戻る"
            color="WHITE"
            borderRadius={4}
            handleClick={actions.onCancel}
          />
        </ActionArea>
      )}
    </ReactModal>
  );
};
