export enum Color {
  PINK = '#EE88CC',
  ORNAGE = '#F8BE5F',
  BLUE = '#4A90E2',
  RED = '#E24A4A',
  BRACK = '#212121',
  WHITE = '#ffffff',
  GRAY = '#F1F1F1',
}

// ブラウザデフォルトの 16px をベースにする
export enum FontSize {
  INHERIT = 'inherit',
  X_SMALL = '0.75rem', // 12px
  SMALL = '0.87rem', // 14px
  MEDIUM = '1rem', // 16px
  LARGE = '1.125rem', // 18px
  X_LARGE = '1.25rem', // 20px
  TITLE = '1.5rem', // 24px
  TITLE_LARGE = '2.5rem', // 40px
  CONTROL_MEDIUM = '1rem', // 16px
  CONTROL_LARGE = '1.5rem', // 24px
}
