import apiBase from './apiBase';

export type Menu = {
  id: number;
  salon_id: number;
  category_id: number;
  name: string;
  description: string;
  price: number;
  repair_cost: number;
  minute: number;
  reward_point: number;
  is_public: number;
  image_url: string;
};

export type Coupon = {
  id: number;
  stylist_id: number;
  salon_id: number;
  image_url: string;
  name: string;
  price: number;
  minute: number;
  description: string;
  reward_point: number;
  repair_cost: number;
  is_public: number;
  menus: Menu[];
};

export const getSalonCoupons = (salonId: string) => {
  return apiBase.get<Coupon[]>(`/salon/${salonId}/coupons`);
};

export type GetCouponDetailPayload = {
  salonId: string;
  couponId: string;
};
export type GetCouponDetailRes = Coupon;
export const getCouponDetail = ({
  salonId,
  couponId,
}: GetCouponDetailPayload) => {
  return apiBase.get<GetCouponDetailRes>(
    `/salon/${salonId}/coupon/${couponId}`,
  );
};

export type GetStylistCouponsPayload = {
  salonId: number;
  stylistId: number;
};
export type GetStylistCouponsRes = {
  data: Coupon[];
};
export const getStylistCoupons = ({
  salonId,
  stylistId,
}: GetStylistCouponsPayload) => {
  return apiBase.get<GetStylistCouponsRes>(
    `/salon/${salonId}/coupons?stylist_id=${stylistId}`,
  );
};

export type SearchCouponsPayload = {
  salonId: string;
  key: string;
};
export const searchCoupons = ({ salonId, key }: SearchCouponsPayload) => {
  return apiBase.get<Coupon[]>(`/salon/${salonId}/coupons?key=${key}`);
};

export type PostSalonCouponPayload = {
  salonId: string;
  name: string;
  price: number;
  minute: number;
  description: string;
  menu_ids: number[];
  is_public: boolean;
  stylist_ids: number[];
  image: {
    base64: string;
  };
};
export const postSalonCoupon = ({
  salonId,
  ...payload
}: PostSalonCouponPayload) => {
  return apiBase.post<Coupon>(`/salon/${salonId}/coupon`, payload);
};

export type PostStylistCouponPayload = {
  salonId: string;
  stylist_id: number;
  name: string;
  price: number;
  minute: number;
  description: string;
  menu_ids: number[];
  is_public: boolean;
  image: {
    base64: string;
  };
};
export const postStylistCoupon = ({
  salonId,
  ...payload
}: PostStylistCouponPayload) => {
  return apiBase.post<Coupon>(`/salon/${salonId}/coupon`, payload);
};

export type PutSalonCouponPayload = {
  salonId: string;
  couponId: string;
  name: string;
  price: number;
  minute: number;
  description: string;
  menu_ids: number[];
  is_public: boolean;
  stylist_ids: number[];
  image: {
    base64: string;
  };
};
export const putSalonCoupon = ({
  salonId,
  couponId,
  ...payload
}: PutSalonCouponPayload) => {
  return apiBase.put<Coupon>(`/salon/${salonId}/coupon/${couponId}`, payload);
};

export type PutStylistCouponPayload = {
  salonId: string;
  couponId: string;
  name: string;
  price: number;
  minute: number;
  description: string;
  menu_ids: number[];
  is_public: boolean;
  image: {
    base64: string;
  };
};
export const putStylistCoupon = ({
  salonId,
  couponId,
  ...payload
}: PutStylistCouponPayload) => {
  return apiBase.put<Coupon>(`/salon/${salonId}/coupon/${couponId}`, payload);
};

export type DeleteCouponRes = {
  id: number;
  object: 'coupon';
  deleted: boolean;
};
export const deleteCoupon = (salonId: string, couponId: string) => {
  return apiBase.delete<DeleteCouponRes>(
    `/salon/${salonId}/coupon/${couponId}`,
  );
};
