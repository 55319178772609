import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
`;

type TitleProps = {
  fontSize?: string;
  color?: string;
  margin?: string;
  width?: string;
  weight?: string;
};

export const Title = styled.p<TitleProps>`
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  color: ${({ color }) => color ?? '#212121'};
  margin-bottom: ${({ margin }) => margin ?? '0'};
  width: ${({ width }) => width ?? '100%'};
  font-weight: ${({ weight }) => weight ?? 'normal'};
`;

type TextProps = {
  fontSize?: string;
  color?: string;
  width?: string;
  weight?: string;
  clamp?: number | 'none';
};

export const Text = styled.p<TextProps>`
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  color: ${({ color }) => color ?? '#212121'};
  width: ${({ width }) => width ?? '100%'};
  font-weight: ${({ weight }) => weight ?? 'bold'};

  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => clamp || 'none'};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

type Props = {
  width?: string;
  margin?: string;
  title: {
    text: string;
    fontSize?: string;
    color?: string;
    weight?: string;
  };
  text: {
    text: string | string[] | number;
    fontSize?: string;
    color?: string;
    weight?: string;
    clamp?: number | 'none';
  };
};

export const TextWithTitle: React.FC<Props> = (props) => {
  const { width, margin, title, text } = props;

  return (
    <Wrapper width={width}>
      <Title
        fontSize={title?.fontSize}
        color={title?.color}
        margin={margin}
        weight={title.weight}
      >
        {title.text}
      </Title>
      <Text
        fontSize={text?.fontSize}
        color={text?.color}
        weight={text?.weight}
        clamp={text?.clamp}
      >
        {text.text}
      </Text>
    </Wrapper>
  );
};
