import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SelectBox } from '../parts/lv3/SelectBox';
import useBrands from '../../lib/hooks/useBrand';
import { useSalons } from '../../lib/hooks/useSalon';
import useCategories from '../../lib/hooks/useCategories';
import { useAllProducts } from '../../lib/hooks/useProduct';
import { ImageType, InputImage } from '../parts/lv3/InputImage';
import { SpModal } from '../parts/lv3/SpModal';
import dayjs from '../../lib/utils/dayjs';
import { SpTopicPreviewModal } from '../parts/lv3/SpTopicPreviewModal';
import { TextArea } from '../parts/lv3/TextArea';
import { ValidateErrorMessageList } from '../parts/lv3/ValidationErrorMessage';
import { createTopic, updateTopic } from '../../lib/api/topics';
import { useTopicInfo } from '../../lib/hooks/useTopic';
import { DescriptionType, init } from './CreateTopics';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Item = styled.div`
  width: 337px;
  margin-bottom: 40px;
`;

const FullItem = styled.div`
  width: 1337px;
  margin-bottom: 40px;
`;

const LeftItem = styled.div`
  width: 337px;
  margin-bottom: 40px;
  margin-left: 80px;
`;

const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #757575;
  margin-bottom: 6px;
`;

const FieldWrapper = styled.div`
  padding: 0 4px;
  border-bottom: 1px solid #979797;
  display: flex;
`;

const TextField = styled.input`
  width: 100%;
  font-size: 24px;
  height: 36px;
  border: none;
`;

const ItemWrapper = styled.div`
  display: flex;
`;

const AddDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #3478f6;
  background-color: transparent;
  text-align: left;
  margin-bottom: 64px;
`;

const DeleteDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #e24a4a;
  background-color: transparent;
  text-align: left;
  margin-bottom: 64px;
`;

export const EditTopics = () => {
  const history = useHistory();
  const brands = useBrands();
  const salons = useSalons();
  const categories = useCategories();
  const products = useAllProducts();
  const { id } = useParams<{ id: string }>();
  const topic = useTopicInfo(id);
  const [categoryId, setCategoryId] = useState(0);
  const [descriptions, setDescriptions] = useState<DescriptionType[]>([init]);
  const onChangeDescription = (description: DescriptionType, index: number) => {
    const copy = [...descriptions];
    copy[index] = description;
    setDescriptions(copy);
  };

  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    triggerValidation,
    reset,
  } = useForm({
    defaultValues: {
      title: '',
      product_id: 0,
      salon_id: 0,
      brand_id: 0,
    },
  });
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);

  const showPreview = async () => {
    const result = await triggerValidation();
    if (descriptions.some((description) => description.body.trim() === '')) {
      setErrorMessageList(['本文は必須項目です。']);
    } else {
      setErrorMessageList([]);
      if (result) {
        setIsOpen(true);
      }
    }
  };
  const onSubmit = handleSubmit((data) => {
    setErrorMessageList([]);
    const params = {
      ...data,
      ...{
        descriptions: descriptions.map((it) => {
          const image = it.image.base64 ? { image: it.image } : {};
          const image_url = it.image_url ? { image_url: it.image_url } : {};
          return { ...{ body: it.body }, ...image, ...image_url };
        }),
      },
    };
    updateTopic(id, params)
      .then(() => {
        history.push('/content/topics');
      })
      .catch(() => {
        setErrorMessageList(['エラーが発生しました']);
      });
  });

  useEffect(() => {
    if (topic !== null) {
      reset(topic);
      const topicDescriptions = topic.description.map((it) => {
        return {
          image: {
            name: it.image_url ? '画像あり' : '',
            base64: '',
          },
          image_url: it.image_url,
          body: it.body,
        };
      });
      setDescriptions(topicDescriptions);
    }
  }, [topic, brands, salons, products]);

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="トピック作成" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Form onSubmit={showPreview}>
          <ItemWrapper>
            <Item>
              <FieldLabel>ブランド</FieldLabel>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'brand_id',
                  register: register({
                    required: 'ブランドは選択必須項目です',
                  }),
                }}
                style={{
                  width: '337px',
                  fontSize: '24px',
                  height: '36px',
                  border: 'bottom',
                }}
              >
                <option value="">選択してください</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </SelectBox>
            </Item>
            <LeftItem>
              <FieldLabel>店舗</FieldLabel>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'salon_id',
                  register: register({ required: '店舗は選択必須項目です' }),
                }}
                style={{
                  width: '337px',
                  fontSize: '24px',
                  height: '36px',
                  border: 'bottom',
                }}
              >
                <option value="">選択してください</option>
                {salons.map((salon) => (
                  <option key={salon.id} value={salon.id}>
                    {salon.name}
                  </option>
                ))}
              </SelectBox>
            </LeftItem>
          </ItemWrapper>
          <ItemWrapper>
            <Item>
              <FieldLabel>紹介する商品カテゴリ</FieldLabel>
              <SelectBox
                control={{
                  type: 'state',
                  value: `${categoryId}`,
                  onChange: (v) => setCategoryId(+v),
                }}
                style={{
                  width: '337px',
                  fontSize: '24px',
                  height: '36px',
                  border: 'bottom',
                }}
              >
                <option value="">選択してください</option>
                {categories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </SelectBox>
            </Item>

            <LeftItem>
              <FieldLabel>紹介する商品</FieldLabel>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'product_id',
                  register: register({ required: '商品は選択必須項目です' }),
                }}
                style={{
                  width: '337px',
                  fontSize: '24px',
                  height: '36px',
                  border: 'bottom',
                }}
              >
                <option value="">選択してください</option>
                {products
                  .filter(
                    (product) =>
                      categoryId === 0 || product.category_id === categoryId,
                  )
                  .map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
              </SelectBox>
            </LeftItem>
          </ItemWrapper>
          <ItemWrapper>
            <FullItem>
              <FieldLabel>タイトル</FieldLabel>
              <FieldWrapper>
                <TextField
                  placeholder="入力してください"
                  name="title"
                  ref={register({ required: 'タイトルは必須項目です' })}
                />
              </FieldWrapper>
            </FullItem>
          </ItemWrapper>
          {descriptions.map((description, i) => {
            const onChangeBody = (body: string) => {
              const update = {
                ...description,
                body,
              };
              onChangeDescription(update, i);
            };
            const onChangeImage = (file: ImageType) => {
              const update = {
                ...description,
                image_url: '',
                image: file,
              };
              onChangeDescription(update, i);
            };
            return (
              <div key={i}>
                {i !== 0 && (
                  <DeleteDescription
                    onClick={() =>
                      setDescriptions([
                        ...descriptions.filter((_, index) => i !== index),
                      ])
                    }
                  >
                    削除
                  </DeleteDescription>
                )}
                <ItemWrapper>
                  <FullItem>
                    <FieldLabel>本文</FieldLabel>
                    <TextArea
                      control={{
                        type: 'state',
                        value: description.body,
                        onChange: (v) => onChangeBody(v),
                      }}
                      style={{
                        width: '785px',
                        height: '148px',
                      }}
                      placeholder="入力してください"
                    />
                  </FullItem>
                </ItemWrapper>
                <ItemWrapper>
                  <FullItem>
                    <FieldLabel>画像</FieldLabel>
                    <InputImage
                      image={description.image}
                      onChange={onChangeImage}
                      index={i}
                    />
                  </FullItem>
                </ItemWrapper>
              </div>
            );
          })}
          <AddDescription
            onClick={() => setDescriptions([...descriptions, init])}
          >
            <img src={`${process.env.PUBLIC_URL}/assets/plus.svg`} alt="plus" />
            本文を追加
          </AddDescription>
          <ValidateErrorMessageList
            errors={errors}
            errorMessageList={errorMessageList}
          />
          <SquareButton
            fontSize={24}
            height={56}
            width={160}
            text="プレビュー"
            color="BLUE"
            handleClick={showPreview}
          />
        </Form>
      </ShadedContainer>
      <SpModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        ContentComponent={(
          <>
            <SpTopicPreviewModal
              {...getValues()}
              sendAt={dayjs().format('YYYY/MM/DD')}
              salon={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                salons.find((salon) => salon.id === +getValues().salon_id)!
              }
              product={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                products.find(
                  (product) => product.id === +getValues().product_id,
                )!
              }
              onClose={() => setIsOpen(false)}
              descriptions={descriptions}
            />
            <ValidateErrorMessageList
              errors={{}}
              errorMessageList={errorMessageList}
            />
          </>
        )}
        actions={{
          onSubmit,
          onCancel: () => setIsOpen(false),
        }}
      />
    </Wrapper>
  );
};
