export const getDayArray = (): number[] => {
  const dayArray: number[] = [];
  for (let index = 1; index < 32; index += 1) {
    dayArray.push(index);
  }

  return dayArray;
};

export const getMonthArray = (): number[] => {
  const monthArray: number[] = [];
  for (let index = 1; index < 13; index += 1) {
    monthArray.push(index);
  }
  return monthArray;
};

export const getYearArray = (): number[] => {
  const now = new Date();
  const year = now.getFullYear();
  const yearArray: number[] = [];
  for (let index = year - 100; index < year; index += 1) {
    yearArray.push(index);
  }

  return yearArray;
};

export const getHourList = (): number[] => {
  const hourArray: number[] = [];
  for (let index = 1; index < 24; index += 1) {
    hourArray.push(index);
  }
  return hourArray;
};

export const getMinuteList = (): number[] => {
  const minuteArray: number[] = [];
  for (let index = 1; index < 61; index += 1) {
    minuteArray.push(index);
  }
  return minuteArray;
};
