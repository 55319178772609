import { useState, useEffect } from 'react';
import { getTopicInfo, Topic } from '../api/topics';

export const useTopicInfo = (id: string | null) => {
  const [topic, setTopic] = useState<Topic | null>(null);

  useEffect(() => {
    if (id !== null) {
      getTopicInfo(id)
        .then(({ data }) => {
          setTopic(data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [id]);

  return topic;
};
