import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  strong {
    margin-left: 24px;
  }
`;

const StylistArea = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const StylistName = styled.p`
  color: #424242;
`;

const StylistReservationCountInput = styled.input`
  border: 1px solid #979797;
  box-sizing: border-box;
  width: 343px;
  height: 40px;
  margin-left: 64px;
`;

const SubmitBtn = styled.p`
  background: #4a90e2;
  border-radius: 4px;
  padding: 8px;
  width: 109px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-top: 40px;
`;

interface StylistType {
  id: number;
  name: string;
  reservationCount: string;
}

const mockStylist = [
  { id: 1, name: '青空　一郎', reservationCount: '0' },
  { id: 2, name: '青空　一郎', reservationCount: '0' },
  { id: 3, name: '青空　一郎', reservationCount: '0' },
  { id: 4, name: '青空　一郎', reservationCount: '0' },
  { id: 5, name: '青空　一郎', reservationCount: '0' },
  { id: 6, name: '青空　一郎', reservationCount: '0' },
  { id: 7, name: '青空　一郎', reservationCount: '0' },
  { id: 8, name: '青空　一郎', reservationCount: '0' },
  { id: 9, name: '青空　一郎', reservationCount: '0' },
  { id: 10, name: '青空　一郎', reservationCount: '0' },
  { id: 11, name: '青空　一郎', reservationCount: '0' },
  { id: 12, name: '青空　一郎', reservationCount: '0' },
  { id: 13, name: '青空　一郎', reservationCount: '0' },
  { id: 14, name: '青空　一郎', reservationCount: '0' },
  { id: 15, name: '青空　一郎', reservationCount: '0' },
];

export const ReservationSetting = () => {
  const history = useHistory();
  const [stylists, setStylists] = useState(mockStylist);
  const onSubmit = () => {
    console.log({ stylists });
  };

  const onChange = (stylist: StylistType) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const copyStylists = stylists.map((it) => {
      if (it.id !== stylist.id) {
        return it;
      }
      return {
        ...it,
        reservationCount: event.target.value,
      };
    });
    setStylists(copyStylists);
  };

  return (
    <Wrapper>
      <TopContentWrapper onClick={() => history.push('/reservation')}>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="美容師ごとの受付可能数" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 64px 32px 64px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        {stylists.map((it) => (
          <StylistArea key={it.id}>
            <StylistName>{it.name}</StylistName>
            <StylistReservationCountInput
              type="number"
              value={it.reservationCount}
              onChange={onChange(it)}
            />
          </StylistArea>
        ))}
        <SubmitBtn onClick={onSubmit}>保存</SubmitBtn>
      </ShadedContainer>
    </Wrapper>
  );
};
