import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { FontSize } from '../../lib/styles/variables';
import { addBrand } from '../../lib/api/brand';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FieldLabel = styled.p`
  font-size: ${FontSize.X_LARGE};
  line-height: 30px;
  color: #757575;
  margin-bottom: 8px;
`;

const InputField = styled.input`
  height: 40px;
  width: 80%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};

  ::placeholder {
    color: #bdbdbd;
  }
`;

const Margin = styled.div`
  margin-top: 24px;
`;

export const AddBrand = () => {
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      brandName: '',
    },
  });

  const onSubmit = handleSubmit((form) => {
    addBrand(form.brandName).then(() => {
      history.goBack();
    });
  });

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="ブランド追加" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px 40px 16px 40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Form onSubmit={onSubmit}>
          <FieldLabel>ブランド名</FieldLabel>
          <InputField
            type="text"
            name="brandName"
            ref={register}
            style={{
              width: '80%',
            }}
          />
          <Margin>
            <SquareButton
              fontSize={15}
              height={40}
              width={136}
              borderRadius={4}
              text="登録"
              color="BLUE"
              type="submit"
            />
          </Margin>
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
