import React, { FC, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { StylistProductSale } from '../../../lib/api/stylist';

type Props = {
  product_sales: StylistProductSale[];
};

export const StylistDetailSalesChartProduct: FC<Props> = ({
  product_sales,
}) => {
  const data = useMemo(() => {
    return product_sales.map((item) => {
      return {
        name: item.year_month,
        target: Math.floor(item.product_sales_target_amount / 10000),
        result: Math.floor(item.product_sales_amount / 10000),
      };
    });
  }, [product_sales]);

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart data={data} margin={{ top: 100, left: 20, bottom: 100 }}>
        <XAxis dataKey="name" axisLine={false} tickLine={false} tickMargin={20}>
          <Label
            value="物販＋EC売上"
            offset={320}
            position="top"
            style={{ fontSize: '24px', fontWeight: 'bold' }}
          />
        </XAxis>
        <YAxis
          domain={[0, 1000]}
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tickFormatter={(value) => value.toLocaleString()}
        >
          <Label value="（万円）" offset={40} position="top" />
        </YAxis>
        <CartesianGrid vertical={false} style={{ color: '#979797' }} />
        <Legend verticalAlign="top" align="right" height={60} />
        <Tooltip formatter={(value) => `${value.toLocaleString()}円`} />
        <Bar name="目標" dataKey="target" fill="#D8D8D8" />
        <Bar name="実績" dataKey="result" fill="#4A90E2" />
      </BarChart>
    </ResponsiveContainer>
  );
};
