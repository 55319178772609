import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SelectBox } from '../parts/lv3/SelectBox';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Item = styled.div`
  width: 337px;
  margin-bottom: 40px;
`;

const LeftItem = styled.div`
  width: 337px;
  margin-bottom: 40px;
  margin-left: 80px;
`;

const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #757575;
  margin-bottom: 6px;
`;

const FieldWrapper = styled.div`
  padding: 0 4px;
  border-bottom: 1px solid #979797;
  display: flex;
`;

const TextField = styled.input`
  width: 100%;
  font-size: 24px;
  height: 36px;
  border: none;
`;

const Unit = styled.span`
  font-size: 24px;
  line-height: 36px;
  color: #212121;
`;

const ItemWrapper = styled.div`
  display: flex;
`;

export const AddMaterial = () => {
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      materialCode: '',
      materialName: '',
      category: 'default',
      dealer: 'default',
      price: '',
    },
  });

  const onSubmit = handleSubmit((data) => console.log(data));

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="材料登録" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Form onSubmit={onSubmit}>
          <ItemWrapper>
            <Item>
              <FieldLabel>商品名</FieldLabel>
              <FieldWrapper>
                <TextField
                  placeholder="入力してください"
                  name="materialCode"
                  ref={register}
                />
              </FieldWrapper>
            </Item>
            <LeftItem>
              <FieldLabel>商品名</FieldLabel>
              <FieldWrapper>
                <TextField
                  placeholder="入力してください"
                  name="materialName"
                  ref={register}
                />
              </FieldWrapper>
            </LeftItem>
          </ItemWrapper>
          <ItemWrapper>
            <Item>
              <FieldLabel>カテゴリー</FieldLabel>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'category',
                  register,
                }}
                style={{
                  width: '337px',
                  fontSize: '24px',
                  height: '36px',
                  border: 'bottom',
                }}
              >
                <option value="default">選択してください</option>
              </SelectBox>
            </Item>

            <LeftItem>
              <FieldLabel>ディーラー</FieldLabel>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'dealer',
                  register,
                }}
                style={{
                  width: '337px',
                  fontSize: '24px',
                  height: '36px',
                  border: 'bottom',
                }}
              >
                <option value="default">選択してください</option>
              </SelectBox>
            </LeftItem>
          </ItemWrapper>
          <ItemWrapper>
            <Item>
              <FieldLabel>単価（税抜き）</FieldLabel>
              <FieldWrapper>
                <TextField
                  placeholder="入力してください"
                  name="price"
                  ref={register}
                />
                <Unit>円</Unit>
              </FieldWrapper>
            </Item>
          </ItemWrapper>
          <SquareButton
            fontSize={24}
            height={56}
            width={160}
            text="登録"
            color="BLUE"
            type="submit"
          />
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
