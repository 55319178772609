import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { PostRateForm } from '../parts/lv1/PostRateForm';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

export const EditPostRate = () => {
  const history = useHistory();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      shop: 'elenor',
      grade: 'elenor',
      distributionRate: '1',
      designationRate: '',
      freelanceRate: '',
    },
  });

  const [employeeId, setEmployeeId] = useState('1234567');
  const onSubmit = handleSubmit((data) => console.log({ ...data, employeeId }));

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="役職・分配率編集" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: 4,
          minHeight: 'calc(100vh - 200px)',
          padding: '0px 64px 40px 64px',
        }}
      >
        <PostRateForm
          register={register}
          onSubmit={onSubmit}
          employeeId={employeeId}
          setEmployeeId={setEmployeeId}
          onDelete={() => console.log('削除！')}
        />
      </ShadedContainer>
    </Wrapper>
  );
};
