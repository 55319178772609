import React from 'react';
import styled from 'styled-components';
import { FontSize } from '../../../lib/styles/variables';
import { ShiftTimeHeight, StylistHeight } from '../../../lib/utils/dayjs';

const Wrapper = styled.div`
  min-width: 200px;
  border: 1px solid #979797;
  border-right: 2px solid #979797;

  & > div {
    border-bottom: 1px solid #979797;
    padding: 12px 8px;
    display: flex;
    align-items: baseline;
    font-weight: bold;
    flex-flow: column;
  }
`;

const Time = styled.div`
  height: ${ShiftTimeHeight};
`;
const Stylist = styled.div`
  height: ${StylistHeight};
`;

const Label = styled.p`
  font-size: ${FontSize.SMALL};
  color: #212121;
`;

interface StylistType {
  id: number;
  name: string;
}

type Props = {
  stylists: StylistType[];
};

export const ShiftTableLeftBar: React.FC<Props> = ({ stylists }) => {
  return (
    <Wrapper>
      <Time />
      {stylists.map((it) => (
        <Stylist key={it.id}>
          <Label>{it.name}</Label>
        </Stylist>
      ))}
    </Wrapper>
  );
};
