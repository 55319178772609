import apiBase from './apiBase';

export type BusinessHours = {
  salon_id: number;
  date: string;
  from_time: string;
  to_time: string;
  is_holiday: boolean;
}[];

export const getBusinessHoursList = (salonId: number, yearMonth: string) =>
  apiBase.get<BusinessHours>(
    `/salon/${salonId}/businessHoursList?year_month=${yearMonth}`,
  );

export const updateBusinessHours = (salonId: number, params: BusinessHours) =>
  apiBase.put(`/salon/${salonId}/businessHoursList`, params);
