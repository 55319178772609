import { useState, useEffect } from 'react';
import { getMessageInfoToCustomer, MessageInfo } from '../api/message';

export const useMessageInfo = (id: string | null) => {
  const [message, setMessage] = useState<MessageInfo | null>(null);

  useEffect(() => {
    if (id !== null) {
      getMessageInfoToCustomer(id)
        .then(({ data }) => {
          setMessage(data);
        })
        .catch(() => {
          alert('エラーが発生しました');
        });
    }
  }, [id]);

  return message;
};
