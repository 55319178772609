import React from 'react';
import styled from 'styled-components';

const Icon = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  ::after {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ size }) => size / 2}px;
    border-top: ${({ size }) => size / 2}px solid #212121;
    border-bottom: ${({ size }) => size / 2}px solid transparent;
    border-right: ${({ size }) => size / 2}px solid transparent;
    border-left: ${({ size }) => size / 2}px solid transparent;
  }
`;

export const Triangle = ({ size }: { size: number }) => {
  return <Icon size={size} />;
};
