import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { StoreSearchForm } from '../parts/lv1/StoreSearchForm';
import { TextWithTitle } from '../parts/lv3/TextWithTitle';
import {
  searchStore,
  SearchStoreParams,
  StoreInList,
} from '../../lib/api/store';
import { useSpinner } from '../../lib/hooks/useSpinner';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const StoreCardsArea = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  min-width: 988px;
`;

const StoreCard = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Img = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 102px;
    height: 102px;
    background: #d8d8d8;
    border: 1px solid #979797;
    border-radius: 51px;
  }

  span {
    width: 102px;
    height: 102px;
    background: #d8d8d8;
    border: 1px solid #979797;
    border-radius: 51px;
  }
`;

const InfoContainer = styled.div`
  height: 100px;
  width: 70%;
  margin-left: 40px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
`;

export const Store = () => {
  const history = useHistory();
  const [searchResult, setSearchResult] = useState<StoreInList[] | null>(null);
  const { loading, startLoading, finishLoading } = useSpinner();

  const ImageContent = ({ url }: { url: string | null }) => {
    if (url === null || url === '') return <span />;
    return <img src={url} alt="お店の画像" />;
  };

  const onSubmit = (params: SearchStoreParams) => {
    startLoading();
    searchStore(params)
      .then(({ data }) => {
        setSearchResult(data.data);
      })
      .finally(() => {
        finishLoading();
      });
  };

  useEffect(() => {
    startLoading();
    searchStore({
      name: '',
      phone_number: '',
      area: '',
      type: '',
    })
      .then(({ data }) => {
        setSearchResult(data.data);
      })
      .finally(() => {
        finishLoading();
      });
  }, []);

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="店舗一覧" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 64px 16px 64px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <StoreSearchForm onSubmit={onSubmit} />
        <StoreCardsArea>
          {!loading
            ? searchResult && searchResult.length >= 1
              ? searchResult.map(
                  ({ id, name, image_url, address, phone_number }) => (
                    <ShadedContainer
                      key={id}
                      style={{
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                        margin: '0 32px 32px 0',
                        padding: '24px 24px 24px 16px',
                        width: 462,
                        height: 145,
                        borderRadius: 4,
                      }}
                      handleClick={() => history.push(`/store/detail/${id}`)}
                    >
                      <StoreCard>
                        <Img>
                          <ImageContent url={image_url} />
                        </Img>
                        <InfoContainer>
                          <TextWithTitle
                            width="30%"
                            margin="11px"
                            title={{
                              text: 'ID',
                              fontSize: '16px',
                              color: '#757575',
                              weight: 'bold',
                            }}
                            text={{
                              text: id.toString(),
                              fontSize: '14px',
                              color: '#212121',
                              weight: 'normal',
                            }}
                          />
                          <TextWithTitle
                            width="65%"
                            title={{
                              text: '店舗名',
                              fontSize: '16px',
                              color: '#757575',
                              weight: 'bold',
                            }}
                            text={{
                              text: name,
                              fontSize: '14px',
                              color: '#212121',
                              weight: 'normal',
                              clamp: 2,
                            }}
                          />
                          <TextWithTitle
                            width="30%"
                            title={{
                              text: 'エリア',
                              fontSize: '16px',
                              color: '#757575',
                              weight: 'bold',
                            }}
                            text={{
                              text: address,
                              fontSize: '14px',
                              color: '#212121',
                              weight: 'normal',
                              clamp: 1,
                            }}
                          />
                          <TextWithTitle
                            width="65%"
                            margin="4px"
                            title={{
                              text: '電話番号',
                              fontSize: '16px',
                              color: '#757575',
                              weight: 'bold',
                            }}
                            text={{
                              text: phone_number,
                              fontSize: '14px',
                              color: '#212121',
                              weight: 'normal',
                            }}
                          />
                        </InfoContainer>
                      </StoreCard>
                    </ShadedContainer>
                  ),
                )
              : '店舗がありません'
            : loading}
        </StoreCardsArea>
      </ShadedContainer>
    </Wrapper>
  );
};
