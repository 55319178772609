import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { AddStoreMaterial } from '../parts/lv1/AddStoreMaterial';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

export const AddShareSalon = () => {
  const history = useHistory();
  const onSubmit = () => {
    console.log('clicked');
  };

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="サロン商品登録" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <AddStoreMaterial
          item="default"
          price=""
          mode="add"
          onChange={onSubmit}
        />
      </ShadedContainer>
    </Wrapper>
  );
};
