import React, { useCallback, useState, FC, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { updatePersonInCharge } from '../../lib/api/reservation';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 12px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const Label = styled.div`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1.36087px;
  color: #757575;
`;

const CustomerId = styled.div`
  margin-bottom: 36px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.63304px;
  color: #212121;
`;

const WrapperInput = styled.div<{ width: number; margin?: string }>`
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
  position: relative;
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  line-height: 36px;
  width: 100%;

  ::placeholder {
    color: #bdbdbd;
  }
`;

// TODO APIから取得
const suggestOptions = [
  { value: '1000002', label: '1000002' },
  { value: '1000003', label: '1000003' },
  { value: '1000004', label: '1000004' },
];

export const ReservationEditFix = () => {
  const history = useHistory<{ reservationNumber: string }>();
  const { id } = useParams<{ id: string }>();
  const [isSuggestShown, setIsSuggestShown] = useState(false);
  const [stylistId, setStylistId] = useState<string>('');
  const [memo, setMemo] = useState('');

  const reservationNumber = useMemo(() => {
    const { state } = history.location;
    if (state) {
      return state.reservationNumber;
    }
    return '不明';
  }, [history]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickRegister = useCallback(async () => {
    try {
      const stylistIdAsNum = parseInt(stylistId, 10);
      if (Number.isNaN(stylistIdAsNum)) {
        alert('担当者IDに不備があります');
        return;
      }
      if (!memo) {
        alert('備考を入力してください');
        return;
      }
      const params = { id: stylistIdAsNum, memo };
      await updatePersonInCharge(id, params);
      history.goBack();
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [history, id, stylistId, memo]);

  const handleChangeStylistId = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStylistId(e.target.value);
    },
    [],
  );

  const handleChangeMemo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMemo(e.target.value);
    },
    [],
  );

  const handleFocus = useCallback(() => {
    setIsSuggestShown(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsSuggestShown(false);
    }, 1000);
  }, []);

  const handleClickOption = useCallback((value: string) => {
    setStylistId(value);
    setIsSuggestShown(false);
  }, []);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="お直し" />
          </Margin>
        </WrapperGoBack>
      </WrapperNavigation>
      <WrapperShadow>
        <Label>予約番号</Label>
        <CustomerId>{reservationNumber}</CustomerId>

        <Label>担当者ID</Label>
        <WrapperInput width={300} margin="0 0 36px 0">
          <StyledInput
            type="number"
            placeholder="従業員番号を入力"
            value={stylistId}
            onChange={handleChangeStylistId}
            onMouseDown={handleFocus}
            onBlur={handleBlur}
            required
          />
          {isSuggestShown && (
            <ModalSuggest
              options={suggestOptions}
              onClick={handleClickOption}
            />
          )}
        </WrapperInput>

        <Label>備考</Label>
        <WrapperInput width={400} margin="0 0 36px 0">
          <StyledInput type="text" onChange={handleChangeMemo} required />
        </WrapperInput>

        <SquareButton
          fontSize={24}
          width={160}
          height={56}
          text="登録"
          color="BLUE"
          handleClick={handleClickRegister}
        />
      </WrapperShadow>
    </Wrapper>
  );
};

const WrapperModalSuggest = styled.div`
  position: absolute;
  z-index: 100;
  width: 200px;
  height: 200px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #979797;
  box-sizing: border-box;
  overflow: scroll;
`;

const Option = styled.div`
  width: 100%;
  padding-bottom: 4px;
`;

const ModalSuggest: FC<{
  options: any[];
  onClick: (value: string) => void;
}> = ({ options, onClick }) => {
  const handleClick = useCallback(
    (value: string) => () => {
      onClick(value);
    },
    [onClick],
  );

  return (
    <WrapperModalSuggest>
      {options.map((item) => {
        return (
          <Option key={item.value} onClick={handleClick(item.value)}>
            {item.label}
          </Option>
        );
      })}
    </WrapperModalSuggest>
  );
};
