import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '../lv3/TextField';

const Wrapper = styled.div`
  width: 168px;
  height: 80px;
  padding: 8px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const MainText = styled.a``;

const Button = styled.a`
  color: #4a90e2;
`;

const TextWrapper = styled.div`
  margin: 8px 8px 8px 0;
  border-bottom: 1px solid #979797;
`;

type AddColorModalProps = {
  closeModal: () => void;
  inputColorCode: (code: string) => void;
};

export const AddColorModal: React.FC<AddColorModalProps> = ({
  closeModal,
  inputColorCode,
}) => {
  const [color, setColor] = useState<string>('');

  const handleAddButtonClick = () => {
    inputColorCode(color);
    closeModal();
  };

  const AddColorButton = () => {
    return <Button onClick={handleAddButtonClick}>追加</Button>;
  };

  return (
    <Wrapper>
      <Header>
        <MainText>color code</MainText>
        <AddColorButton />
      </Header>
      <TextWrapper>
        <TextField
          control={{
            type: 'state',
            value: color,
            onChange: (v) => setColor(v),
          }}
          style={{
            width: '132px',
            height: '22px',
            fontSize: '12px',
            border: 'none',
          }}
        />
      </TextWrapper>
    </Wrapper>
  );
};
