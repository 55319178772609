import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { ArrowIcon } from '../lv3/ArrowIcon';
import { SelectBox } from '../lv3/SelectBox';
import { SquareButton } from '../lv3/SquareButton';
import { DatePicker } from '../lv3/DatePicker';
import { getHourList, getMinuteList } from '../../../lib/utils/date';
import {
  Customer,
  postMessageToStylist,
  PostMessageToStylistParams,
  PostMessageToCustomerParams,
  postMessageToCustomer,
} from '../../../lib/api/message';

const Wrapper = styled.div`
  width: 1098px;
  height: 472px;
  padding: 24px;
`;

const FormBox = styled.div`
  width: 100%;
  border: 1px solid #979797;
  box-sizing: border-box;
  display: flex;
`;

const LabelArea = styled.div`
  height: 100%;
  width: 145px;
`;

const Label = styled.div<{ isBig?: boolean }>`
  width: 100%;
  height: ${({ isBig }) => (isBig ? '191px' : '42px')};
  background: #f1f1f1;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const TextFieldArea = styled.div`
  height: 100%;
  width: 905px;
`;

const TextFieldWrapper = styled.div<{ isBig?: boolean }>`
  width: 100%;
  height: ${({ isBig }) => (isBig ? '191px' : '42px')};
  background: #fff;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const TextField = styled.input`
  width: 885px;
  height: 22px;
  border: 1px solid #979797;
`;

const TextArea = styled.div<{ isStylist?: boolean }>`
  width: ${({ isStylist }) => (isStylist ? '885px' : '750px')};
  height: 148px;

  > p {
    width: 100%;
    text-align: right;
    height: 18px;
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    height: 138px;
  }
`;

const ArrowIconWrapper = styled.div`
  padding: 6px;
  border: 1px solid #979797;
  margin: 0 10px;
`;

const VariableArea = styled.div`
  padding: 10px 10px;
  border: 1px solid #979797;
  height: 138px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 33px;
`;

const SelectBoxArea = styled.div`
  display: flex;
  align-items: center;

  > div {
    padding: 6px;
    border: 1px solid #979797;
    margin-right: 10px;
  }

  > span {
    margin-right: 10px;
  }
`;

const FileFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileField = styled.label`
  width: 96px;
  height: 22px;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileName = styled.p`
  font-size: 12px;
  margin-left: 8px;
  line-height: 22px;
  color: #212121;
`;

const ButtonArea = styled.div<{ isEdit?: boolean }>`
  width: ${({ isEdit }) => (isEdit ? '428px' : '282px')};
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const InsertVariableButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  background: #fff;
`;

type Props = {
  closeModal: () => void;
  isEdit?: boolean;
  isStylist?: boolean;
  sendType: string | undefined;
  salonId?: number | null;
  stylistIds?: number[] | null;
  checkUsers?: CustomerWithIsChecked[] | null;
};

type CustomerWithIsChecked = {
  isChecked: boolean;
  customer: Customer;
};

type FormData = {
  hour: string;
  minute: string;
  title: string;
  body: string;
  lint_to?: string;
};

export const CreateMessageModal: React.FC<Props> = ({
  closeModal,
  isEdit,
  isStylist = false,
  sendType,
  salonId = null,
  stylistIds = null,
  checkUsers = null,
}) => {
  const [img, setImg] = useState<string | null>(null);
  const [imgBase64, setImgBase64] = useState<string | ArrayBuffer | null>(null);
  const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const { register, watch, getValues, setValue, handleSubmit } = useForm<
    FormData
  >({
    defaultValues: {
      hour: '',
      minute: '',
      title: '',
      body: '',
      lint_to: '',
    },
  });

  const InsertVariableList = [
    { text: '性', value: '#first_name#' },
    { text: '名', value: '#last_name#' },
    { text: 'セイ', value: '#birthday#' },
    { text: 'メイ', value: '#first_name_kana#' },
    { text: '誕生日', value: '#last_name_kana#' },
  ];

  const insertVariable = (value: string) => {
    const { body } = getValues();
    setValue('body', body + value);
  };

  const getBase64 = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      setImgBase64(reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };

  const _onSubmit = () => {
    if (isStylist) {
      submitStylist();
    } else {
      submitCustomer();
    }
  };

  const submitStylist = handleSubmit((defaultValues) => {
    const params: PostMessageToStylistParams = {
      target: {
        salon_all: sendType === 'salon_all',
        salon_id: salonId,
      },
      title: defaultValues.title,
      body: defaultValues.body,
      send_at: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
    };
    if (img !== null) {
      params.image = { base64: imgBase64 };
    }
    if (sendType === 'stylist_id') {
      // 従業員配信
      params.target.stylist_ids = stylistIds;
    }
    postMessageToStylist(params).then(({ data }) => {
      closeModal();
    });
  });

  const submitCustomer = handleSubmit((defaultValues) => {
    const ids: number[] = [];
    checkUsers?.forEach((user) => {
      ids.push(user.customer.id);
    });
    const params: PostMessageToCustomerParams = {
      target: {
        user_ids: ids,
      },
      title: defaultValues.title,
      body: defaultValues.body,
      send_at: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
    };
    if (img !== null) {
      params.image = { base64: imgBase64 };
    }
    postMessageToCustomer(params).then(({ data }) => {
      closeModal();
    });
  });

  return (
    <Wrapper>
      <FormBox>
        <LabelArea>
          <Label>件名</Label>
          <Label isBig>内容</Label>
          <Label>配信日時</Label>
          <Label>画像</Label>
        </LabelArea>
        <TextFieldArea>
          <TextFieldWrapper>
            <TextField name="title" ref={register} />
          </TextFieldWrapper>
          <TextFieldWrapper isBig>
            <TextArea isStylist={isStylist}>
              <p>
                あと
                {500 - watch('body').length}
                文字
              </p>
              <textarea name="body" ref={register} />
            </TextArea>
            {isStylist ? null : (
              <>
                <ArrowIconWrapper>
                  <ArrowIcon
                    style={{
                      height: 12,
                      width: 12,
                      color: '#757575',
                    }}
                    direction="LEFT"
                  />
                </ArrowIconWrapper>
                <VariableArea>
                  {InsertVariableList.map((variable) => (
                    <InsertVariableButton
                      key={variable.value}
                      onClick={() => insertVariable(variable.value)}
                    >
                      {variable.text}
                    </InsertVariableButton>
                  ))}
                </VariableArea>
              </>
            )}
          </TextFieldWrapper>
          <TextFieldWrapper>
            <SelectBoxArea>
              <div>
                <DatePicker
                  date={date}
                  handleChange={(value) => setDate(value)}
                  style={{ fontSize: '12px', width: '110px', height: '18px' }}
                />
              </div>
              <div>
                <SelectBox
                  control={{
                    type: 'hook',
                    name: 'hour',
                    register,
                  }}
                  style={{
                    width: '84px',
                    fontSize: '12px',
                    height: '18px',
                  }}
                >
                  <option value="">--</option>
                  {getHourList().map((hour) => (
                    <option value={hour} key={hour}>
                      {hour}
                    </option>
                  ))}
                </SelectBox>
              </div>
              <span>：</span>
              <div>
                <SelectBox
                  control={{
                    type: 'hook',
                    name: 'minute',
                    register,
                  }}
                  style={{
                    width: '84px',
                    fontSize: '12px',
                    height: '18px',
                  }}
                >
                  <option value="">--</option>
                  {getMinuteList().map((minute) => (
                    <option value={minute} key={minute}>
                      {minute}
                    </option>
                  ))}
                </SelectBox>
              </div>
            </SelectBoxArea>
          </TextFieldWrapper>
          <TextFieldWrapper>
            <input
              type="file"
              style={{ display: 'none' }}
              id="img"
              onChange={(e: any) => {
                setImg(
                  e.target.files[0].name ?? 'データの読み取りに失敗しました',
                );
                getBase64(e.target.files[0]);
              }}
            />
            <FileFieldWrapper>
              <FileField htmlFor="img">ファイルを選択</FileField>
              <FileName>{img ?? 'ファイルが選択されていません'}</FileName>
            </FileFieldWrapper>
          </TextFieldWrapper>
        </TextFieldArea>
      </FormBox>
      <ButtonArea isEdit={isEdit}>
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="登録"
          borderRadius={4}
          color="BLUE"
          handleClick={_onSubmit}
        />
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="戻る"
          borderRadius={4}
          color="WHITE"
          handleClick={() => closeModal()}
        />
        {isEdit ? (
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="削除"
            borderRadius={4}
            color="RED"
            handleClick={() => closeModal()}
          />
        ) : null}
      </ButtonArea>
    </Wrapper>
  );
};
