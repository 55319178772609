import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { SquareButton } from '../lv3/SquareButton';
import { SelectBox } from '../lv3/SelectBox';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Item = styled.div`
  width: 337px;
  margin-bottom: 40px;
`;

const LeftItem = styled.div`
  width: 337px;
  margin-bottom: 40px;
  margin-left: 80px;
`;

const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #757575;
  margin-bottom: 6px;
`;

const FieldWrapper = styled.div`
  padding: 0 4px;
  border-bottom: 1px solid #979797;
  display: flex;
`;

const TextField = styled.input`
  width: 100%;
  font-size: 24px;
  height: 36px;
  border: none;
`;

const Unit = styled.span`
  font-size: 24px;
  line-height: 36px;
  color: #212121;
`;

const ItemWrapper = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const ButtonMargin = styled.div`
  margin-left: 24px;
`;

export const AddStoreMaterial = ({
  item,
  price,
  mode,
  onChange,
}: {
  item: string;
  price: string;
  mode: 'add' | 'edit';
  onChange: () => void;
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      item,
      price,
    },
  });

  const onSubmit = handleSubmit((data) => {
    console.log(data);
    onChange();
  });

  return (
    <Form onSubmit={onSubmit}>
      <ItemWrapper>
        <Item>
          <FieldLabel>商品</FieldLabel>
          <SelectBox
            control={{
              type: 'hook',
              name: 'item',
              register,
            }}
            style={{
              width: '337px',
              fontSize: '24px',
              height: '36px',
              border: 'bottom',
            }}
          >
            <option value="default">選択してください</option>
          </SelectBox>
        </Item>
        <LeftItem>
          <FieldLabel>価格</FieldLabel>
          <FieldWrapper>
            <TextField
              placeholder="入力してください"
              name="price"
              ref={register}
            />
            <Unit>円</Unit>
          </FieldWrapper>
        </LeftItem>
      </ItemWrapper>
      <ButtonWrapper>
        <SquareButton
          fontSize={24}
          height={56}
          width={160}
          text="登録"
          color="BLUE"
          type="submit"
        />
        {mode === 'edit' ? (
          <ButtonMargin>
            <SquareButton
              fontSize={24}
              height={56}
              width={160}
              text="削除"
              color="RED"
              type="button"
            />
          </ButtonMargin>
        ) : null}
      </ButtonWrapper>
    </Form>
  );
};
