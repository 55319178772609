import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Navigation } from '../parts/lv1/Navigation';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Color, FontSize } from '../../lib/styles/variables';
import { StylistDetailLeftInfo } from '../parts/lv1/StylistDetailTableLeftInfo';
import {
  Stylist,
  CustomerOfStylist,
  getStylistDetail,
  getCustomersOfStylist,
} from '../../lib/api/stylist';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperContent = styled.div`
  display: flex;
`;

const RightContent = styled.div`
  margin-bottom: 2rem;
`;

const WrapperCustomerCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CustomerCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 600px;
  margin-bottom: 1rem;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  color: ${Color.BRACK};
  font-size: ${FontSize.TITLE};
`;

const Gender = styled.div`
  color: #757575;
  font-size: ${FontSize.SMALL};
  margin-left: 16px;
`;

export const CustomersOfStylist = () => {
  const { id, stylistId } = useParams<{ id: string; stylistId: string }>();
  const history = useHistory();

  const [stylist, setStylist] = useState<Stylist | null>(null);
  const [customers, setCustomers] = useState<CustomerOfStylist[]>([]);

  const links = useMemo(
    () => [
      {
        name: '基本情報',
        to: `/store/detail/${id}/stylist/detail/${stylistId}`,
        isselected: false,
      },
      {
        name: '顧客リスト',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/customer`,
        isselected: true,
      },
      {
        name: '売上実績',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/sales`,
        isselected: false,
      },
      {
        name: '評価',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation`,
        isselected: false,
      },
      {
        name: '手当',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/allowance`,
        isselected: false,
      },
      {
        name: '報酬保証',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/reward`,
        isselected: false,
      },
      {
        name: '賞与',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/bonus`,
        isselected: false,
      },
      {
        name: '歩合',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/commission`,
        isselected: false,
      },
    ],
    [id, stylistId],
  );

  useEffect(() => {
    getStylistDetail(stylistId)
      .then(({ data }) => {
        setStylist(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [id, stylistId]);

  useEffect(() => {
    getCustomersOfStylist(stylistId)
      .then(({ data }) => {
        setCustomers(data.data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [id, stylistId]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="美容師詳細" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} />
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperContent>
          {stylist && (
            <StylistDetailLeftInfo
              img_url={stylist.image_url}
              employeeNumber={stylist.id}
              contractType={stylist.contract_type}
            />
          )}

          <RightContent>
            <WrapperCustomerCards>
              {customers.map((item) => (
                <Link
                  key={item.id}
                  to={`/store/detail/${id}/stylist/detail/${stylistId}/customer/detail/${item.id}`}
                >
                  <CustomerCard>
                    <TextContainer>
                      <Name>{item.full_name}</Name>
                      <Gender>{item.sex}</Gender>
                    </TextContainer>
                    <ArrowIcon
                      style={{
                        height: 20,
                        width: 10,
                        color: '#757575',
                      }}
                      direction="RIGHT"
                    />
                  </CustomerCard>
                </Link>
              ))}
            </WrapperCustomerCards>
          </RightContent>
        </WrapperContent>
      </WrapperShadow>
    </Wrapper>
  );
};
