import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Autosuggest from 'react-autosuggest';
import { FontSize } from '../../../lib/styles/variables';
import { SquareButton } from '../lv3/SquareButton';
import { SelectBox } from '../lv3/SelectBox';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FieldLabel = styled.p`
  font-size: ${FontSize.X_LARGE};
  line-height: 30px;
  color: #757575;
  margin-bottom: 8px;
`;

const InputField = styled.input`
  height: 40px;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};

  ::placeholder {
    color: #bdbdbd;
  }
`;

const FormItemArea = styled.div`
  width: 337px;
  position: relative;
  & .react-autosuggest__input {
    height: 40px;
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #979797;
    font-size: 1.5rem;
  }

  & .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 77px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  & .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  & .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  & .footer {
    margin: 9px 20px;
    font-size: 12px;
    color: #777;
  }
`;
const FormItemRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  & > div:first-child {
    margin-right: 80px;
  }
`;

const ActionsArea = styled.div`
  margin-top: 40px;
  display: flex;
  & > button:first-child {
    margin-right: 24px;
  }
`;

const Unit = styled.div<{ label: string }>`
  display: flex;
  border-bottom: 1px solid #979797;
  &>input {
    border-bottom: none;
  }
  &::after {
    content: "${({ label }) => label}";
    font-size: 24px;
    color: #212121;
  }
`;

const languages = [
  {
    id: 101,
    name: 'スタッフ名が入ります',
  },
  {
    id: 102,
    name: '田中 太郎',
  },
  {
    id: 103,
    name: 'ID入力中サジェスト',
  },
];

const getSuggestions = (value: string) => {
  const escapedValue = value.trim();

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(`^${escapedValue}`, 'i');
  const suggestions = languages.filter((language) =>
    regex.test(`${language.id}`),
  );
  return suggestions;
};

type Props = {
  register: any;
  defaultDate?: string;
  employeeId: string;
  setEmployeeId: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  onDelete?: () => void;
};

export const PostRateForm: React.FC<Props> = ({
  register,
  defaultDate,
  employeeId,
  setEmployeeId,
  onSubmit,
  onDelete,
}) => {
  const [startDate, setStartDate] = useState<string>(
    dayjs(defaultDate).format('YYYY-MM-DD'),
  );

  const [suggestions, setSuggestions] = useState(languages);
  return (
    <Form onSubmit={onSubmit}>
      <FormItemRow>
        <FormItemArea>
          <FieldLabel>従業員ID</FieldLabel>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={({ value }) =>
              setSuggestions(getSuggestions(value))
            }
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={(suggestion) => `${suggestion.id}`}
            renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
            inputProps={{
              placeholder: '従業員番号を入力',
              value: employeeId,
              onChange: (_, { newValue }) => setEmployeeId(newValue),
            }}
          />
        </FormItemArea>
        <FormItemArea>
          <FieldLabel>店舗</FieldLabel>
          <SelectBox
            control={{
              type: 'hook',
              name: 'shop',
              register,
            }}
            style={{
              width: '337px',
              fontSize: '24px',
              height: '36px',
              border: 'bottom',
            }}
          >
            <option value="">選択してください</option>
            <option value="elenor">Elenor</option>
          </SelectBox>
        </FormItemArea>
      </FormItemRow>
      <FormItemRow>
        <FormItemArea>
          <FieldLabel>グレード</FieldLabel>
          <SelectBox
            control={{
              type: 'hook',
              name: 'grade',
              register,
            }}
            style={{
              width: '337px',
              fontSize: '24px',
              height: '36px',
              border: 'bottom',
            }}
          >
            <option value="">選択してください</option>
            <option value="elenor">Elenor</option>
          </SelectBox>
        </FormItemArea>
        <FormItemArea>
          <FieldLabel>分配率</FieldLabel>
          <InputField
            type="text"
            name="distributionRate"
            ref={register}
            placeholder="0.9"
          />
        </FormItemArea>
      </FormItemRow>
      <FormItemRow>
        <FormItemArea>
          <FieldLabel>指名プラス歩合</FieldLabel>
          <Unit label="%">
            <InputField
              type="text"
              name="designationRate"
              ref={register}
              placeholder="50"
            />
          </Unit>
        </FormItemArea>
        <FormItemArea>
          <FieldLabel>フリープラス歩合</FieldLabel>
          <Unit label="%">
            <InputField
              type="text"
              name="freelanceRate"
              ref={register}
              placeholder=""
            />
          </Unit>
        </FormItemArea>
      </FormItemRow>
      <ActionsArea>
        <SquareButton
          fontSize={15}
          height={40}
          width={136}
          text="登録"
          color="BLUE"
          type="submit"
          borderRadius={4}
        />
        {onDelete && (
          <SquareButton
            fontSize={15}
            height={40}
            width={136}
            text="削除"
            color="RED"
            handleClick={onDelete}
            borderRadius={4}
          />
        )}
      </ActionsArea>
    </Form>
  );
};
