import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { useStylistQuestionaries } from '../../lib/hooks/useQuestionary';
import { Color } from '../../lib/styles/variables';
import { copyClipBoard } from '../../lib/api/utils';
import { ListType } from '../../lib/api/questionaries';
import { SelectBox } from '../parts/lv3/SelectBox';
import { usePager } from '../../lib/hooks/usePager';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CopyLink = styled.a`
  color: ${Color.BLUE};
`;

const MenuArea = styled.div`
  margin: 24px 0 32px 0;
  display: flex;
`;

const SelectBoxArea = styled.div`
  border: 1px solid #e0e0e0;
  font-size: 11px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  margin-left: 24px;
  position: relative;
`;

export const QuestionnaireStylist = () => {
  const history = useHistory();
  const [params, setParams] = useState<ListType>({
    page: 1,
    sort_by_date: 'desc',
  });
  const { questionaries, page } = useStylistQuestionaries(params);

  const handlePagenation = (index: number) => {
    setParams({ ...params, page: index });
  };
  const { Pager, setLastPage } = usePager(handlePagenation);

  useEffect(() => {
    setLastPage(page?.last_page || 0);
  }, [page]);

  const links = [
    {
      name: 'お客様',
      to: '/content/questionnaire/customer',
      isselected: false,
    },
    { name: '従業員', to: '/content/questionnaire/stylist', isselected: true },
  ];

  const columns = [
    { header: 'アンケート名', accessor: 'title', width: 350 },
    { header: '回答件数', accessor: 'answer_number', width: 76 },
    { header: 'アンケートURL', accessor: 'url', width: 433 },
    { header: '', accessor: 'copyUrl', width: 128 },
  ];

  const handleClickCopy = (targetStr: string) => () => {
    const result = copyClipBoard(targetStr);
    if (result) {
      alert('コピーしました');
    }
  };

  const data = questionaries
    .map((it) => ({ ...it, answer_number: `${it.answer_number}件` }))
    .map((it) => ({
      ...it,
      copyUrl: (
        <CopyLink onClick={handleClickCopy(it.url)}>URLをコピーする</CopyLink>
      ),
    }));

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="アンケート管理" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <MenuArea>
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="新規作成"
          borderRadius={4}
          color="BLUE"
          handleClick={() =>
            history.push('/content/questionnaire/stylist/filter')
          }
        />
        <SelectBoxArea>
          <SelectBox
            control={{
              type: 'state',
              onChange: (value) =>
                setParams({
                  ...params,
                  sort_by_date: value as ListType['sort_by_date'],
                }),
              value: params.sort_by_date,
            }}
            style={{}}
          >
            <option value="desc">作成日時が新しい</option>
            <option value="asc">作成日時が古い</option>
          </SelectBox>
        </SelectBoxArea>
      </MenuArea>
      <Table columns={columns} data={data} />
      <Pager />
    </Wrapper>
  );
};
