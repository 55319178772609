import React, { useState } from 'react';
import styled from 'styled-components';
import { FontSize, Color } from '../../../lib/styles/variables';
import { SquareButton } from '../lv3/SquareButton';
import { TextField } from '../lv3/TextField';
import { SelectBox } from '../lv3/SelectBox';
import { PostRateSearch } from '../../pages/PostRate';

const Wrapper = styled.form``;

const GridWrapper = styled.div`
  display: grid;
  grid:
    'furigana furiganaField storeName storeNameField' 42px
    'name nameField id idField' 42px
    / 16% 30% 16% 38%;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

type GridArea =
  | 'furigana'
  | 'furiganaField'
  | 'name'
  | 'nameField'
  | 'id'
  | 'idField'
  | 'storeName'
  | 'storeNameField'
  | 'term'
  | 'termField'
  | 'button';

type GridItemProps = {
  gridArea: GridArea;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const NameInputLabel = styled.p`
  margin: 0 5px 0 10px;
`;

const SelectWrapper = styled.div`
  margin-left: 10px;
`;

type Props = {
  setSearch: React.Dispatch<React.SetStateAction<PostRateSearch>>;
};

export const PostRateSearchForm: React.FC<Props> = ({ setSearch }) => {
  const [searchItem, setSearchItem] = useState<PostRateSearch>({
    sei: '',
    mei: '',
    shop: '',
    grade: '',
  });

  const updateSearch = (newValue: Partial<PostRateSearch>) => {
    setSearchItem({ ...searchItem, ...newValue });
  };
  const onSearch = () => {
    setSearch(searchItem);
  };
  return (
    <Wrapper>
      <GridWrapper>
        <GridItem gridArea="furigana">
          <Label>従業員名（カナ）</Label>
        </GridItem>
        <GridItem gridArea="furiganaField">
          <NameInputLabel>セイ</NameInputLabel>
          <TextField
            control={{
              type: 'state',
              value: searchItem.sei,
              onChange: (v) => updateSearch({ sei: v }),
            }}
            style={{ width: '84px', height: '22px', fontSize: '12px' }}
          />
          <NameInputLabel>メイ</NameInputLabel>
          <TextField
            control={{
              type: 'state',
              value: searchItem.mei,
              onChange: (v) => updateSearch({ mei: v }),
            }}
            style={{ width: '84px', height: '22px', fontSize: '12px' }}
          />
        </GridItem>
        <GridItem gridArea="storeName">
          <Label>店舗名</Label>
        </GridItem>
        <GridItem gridArea="storeNameField" isRightmost>
          <SelectWrapper>
            <SelectBox
              control={{
                type: 'state',
                value: searchItem.shop,
                onChange: (v) => updateSearch({ shop: v }),
              }}
              style={{
                width: '170px',
                height: '22px',
                fontSize: '12px',
                border: 'all',
                padding: '0px 6px',
              }}
            >
              <option value="">全店</option>
              <option value="tocca千葉">tocca千葉</option>
              <option value="DUNO神戸三宮">DUNO神戸三宮</option>
              <option value="tocca津田沼">tocca津田沼</option>
              <option value="Eleanor大宮">Eleanor大宮</option>
              <option value="Eleanor銀座">Eleanor銀座</option>
            </SelectBox>
          </SelectWrapper>
        </GridItem>
        <GridItem gridArea="name" isBottom>
          <Label>グレード</Label>
        </GridItem>
        <GridItem gridArea="nameField" isBottom>
          <SelectWrapper>
            <SelectBox
              control={{
                type: 'state',
                value: searchItem.grade,
                onChange: (v) => updateSearch({ grade: v }),
              }}
              style={{
                width: '170px',
                height: '22px',
                fontSize: '12px',
                border: 'all',
                padding: '0px 6px',
              }}
            >
              <option value="">選択してください</option>
              <option value="T6">T6</option>
              <option value="D3">D3</option>
            </SelectBox>
          </SelectWrapper>
        </GridItem>
        <GridItem gridArea="id" isBottom>
          <Label />
        </GridItem>
        <GridItem gridArea="idField" isBottom isRightmost />
      </GridWrapper>

      <SquareButton
        fontSize={15}
        width={136}
        height={40}
        borderRadius={4}
        color="BLUE_BORDER"
        text="検索"
        handleClick={onSearch}
      />
    </Wrapper>
  );
};
