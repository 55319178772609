/* eslint no-plusplus: 0 */
/* eslint array-callback-return: 0 */
/* eslint no-shadow: 0 */
import React from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Table } from '../parts/lv1/Table';
import { usePoint } from '../../lib/hooks/usePoint';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;

export const Point = () => {
  const point = usePoint();
  const sortedPoint = point ? point.sort((a, b) => a.id - b.id) : [];

  const makeColumns = () => {
    const columns: any[] = [];

    sortedPoint.map((item) => {
      columns.push({
        header: `${item.year}年${item.month}月`,
        accessor: `${item.year}${item.month}`,
        width: 100,
      });
    });

    return columns;
  };

  const rowTitles = {
    titles: [
      '自社ポイント付与数',
      '自社ポイント付与対象売上高（税抜）',
      '自社ポイント使用数（当月）',
      '自社ポイント消滅数（当月）',
      '自社ポイント発行残高（有効ポイントのみ）',
    ],
    width: 260,
  };

  type titleKey =
    | 'granted_point'
    | 'total_sale_amount'
    | 'used_amount'
    | 'disappeared_amount'
    | 'issued_balance';

  const rowTitleKeys: titleKey[] = [
    'granted_point',
    'total_sale_amount',
    'used_amount',
    'disappeared_amount',
    'issued_balance',
  ];

  const makeData = () => {
    return rowTitleKeys.map((key) => {
      const row: any = {};
      sortedPoint.forEach((point) => {
        row[point.year.toString() + point.month.toString()] = point[key];
      });
      return row;
    });
  };

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="ポイント" />
        </Margin>
      </TopContent>
      <TableWrapper>
        {point ? (
          <Table
            columns={makeColumns()}
            data={makeData()}
            rowTitles={rowTitles}
          />
        ) : null}
      </TableWrapper>
    </Wrapper>
  );
};
