import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Triangle } from '../lv3/Triangle';

const Wrapper = styled.div`
  position: relative;
`;

const CustomSelect = styled.select`
  width: 100%;
  height: 22px;
  padding: 4px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #979797;
`;

const WrapperTriangle = styled.div`
  position: absolute;
  right: 8px;
  top: 4px;
`;

export const ReservationSelect: FC<{
  options: { label: string; value: string }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  required?: boolean;
  placeholder?: string;
}> = ({ options, onChange, value, required, placeholder }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <CustomSelect
        onChange={handleChange}
        required={required}
        value={value || ''}
      >
        <option value="">{placeholder}</option>
        {options.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </CustomSelect>
      <WrapperTriangle>
        <Triangle size={10} />
      </WrapperTriangle>
    </Wrapper>
  );
};
