import { useState, useEffect } from 'react';
import { getAllProducts, Product } from '../api/product';

export const useAllProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    getAllProducts()
      .then(({ data }) => {
        setProducts(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, []);

  return products;
};
