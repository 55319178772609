import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../lib/styles/variables';

type Options = {
  fontSize: number;
  width?: number;
  height: number;
  text: string;
  borderRadius?: number;
  handleClick?: () => void;
  color:
    | 'RED'
    | 'BLUE'
    | 'BLUE_BORDER'
    | 'WHITE'
    | 'NO_BORDER'
    | 'RED_BORDER'
    | 'BRACK';
  type?: 'button' | 'submit' | 'reset';
  fontWeight?: 'bold' | 'normal';
};

type ButtonProps = {
  fontSize: Options['fontSize'];
  fontWeight: Options['fontWeight'];
  width: Options['width'];
  height: Options['height'];
  color: Options['color'];
  borderRadius: Options['borderRadius'];
};

const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize}px;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => height}px;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : 'none'};
  font-weight: ${({ fontWeight }) => fontWeight};

  &[color='RED'] {
    color: ${Color.WHITE};
    background-color: ${Color.RED};
  }

  &[color='RED_BORDER'] {
    color: ${Color.RED};
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.RED};
  }

  &[color='BLUE'] {
    color: ${Color.WHITE};
    background-color: ${Color.BLUE};
  }

  &[color='BLUE_BORDER'] {
    color: ${Color.BLUE};
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.BLUE};
  }

  &[color='WHITE'] {
    color: ${Color.BRACK};
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.BRACK};
  }

  &[color='NO_BORDER'] {
    color: ${Color.BLUE};
    background-color: ${Color.WHITE};
    border: none;
  }

  &[color='BRACK'] {
    color: ${Color.WHITE};
    background-color: ${Color.BRACK};
    border: none;
  }
`;

export const SquareButton: React.FC<Options> = (props) => {
  const {
    fontSize,
    width,
    height,
    text,
    handleClick,
    color,
    borderRadius,
    type,
    fontWeight,
  } = props;

  return (
    <Button
      type={type || 'button'}
      fontSize={fontSize}
      width={width}
      height={height}
      color={color}
      borderRadius={borderRadius}
      fontWeight={fontWeight || 'normal'}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};
