/* eslint react/display-name: 0 */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Table } from '../parts/lv1/Table';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { ModalContext } from '../../lib/contexts/ModalContext';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const StyledButton = styled.button`
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a90e2;
  background: #fff;
`;

const ModalWrapper = styled.div`
  width: 834px;
  height: 285px;
  padding: 40px;
`;

const ModalTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
`;

const TextField = styled.div`
  margin-top: 40px;
  > p {
    font-size: 20px;
    line-height: 30px;
    color: #757575;
    margin-bottom: 6px;
  }
  > input {
    width: 100%;
    font-size: 24px;
    padding: 3px 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #979797;
    ::placeholder {
      color: #bdbdbd;
    }
  }
`;

const ModalButtonArea = styled.div`
  margin-top: 24px;
  display: flex;
`;

const Spacer = styled.span`
  width: 1px;
  margin-right: 40px;
`;

type ModalProps = {
  isEdit?: boolean;
  closeModal: () => void;
};

const CategoryModal: React.FC<ModalProps> = ({ isEdit, closeModal }) => {
  return (
    <ModalWrapper>
      <ModalTitle>カテゴリ登録</ModalTitle>
      <TextField>
        <p>カテゴリ名</p>
        <input placeholder="ブランド名を入力してください" />
      </TextField>
      <ModalButtonArea>
        <SquareButton
          text="登録"
          color="BLUE"
          width={136}
          height={40}
          borderRadius={4}
          fontSize={15}
          handleClick={() => closeModal()}
        />
        {isEdit ? (
          <>
            <Spacer />
            <SquareButton
              text="削除"
              color="RED"
              width={136}
              height={40}
              borderRadius={4}
              fontSize={15}
              handleClick={() => closeModal()}
            />
          </>
        ) : null}
      </ModalButtonArea>
    </ModalWrapper>
  );
};

export const EcCategory = () => {
  const { setModal, closeModal } = useContext(ModalContext);

  const EnhancedButton = () => {
    return (
      <StyledButton
        onClick={() =>
          setModal({
            ContentComponent: () => <CategoryModal closeModal={closeModal} />,
            isExistCloseButton: true,
          })
        }
      >
        編集
      </StyledButton>
    );
  };

  const columns = [
    { header: 'カテゴリ名', accessor: 'categoryName', width: 944 },
    { header: '', accessor: 'editButton', width: 66 },
  ];

  const data = [{ categoryName: 'ワックス', editButton: <EnhancedButton /> }];

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="カテゴリ登録" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <SquareButton
          text="追加"
          color="BLUE"
          width={136}
          height={40}
          borderRadius={4}
          fontSize={15}
          handleClick={() =>
            setModal({
              ContentComponent: () => (
                <CategoryModal isEdit closeModal={closeModal} />
              ),
              isExistCloseButton: true,
            })
          }
        />
        <Margin margin="16px 0 0 0">
          <Table columns={columns} data={data} />
        </Margin>
      </ShadedContainer>
    </Wrapper>
  );
};
