import React from 'react';
import styled from 'styled-components';

type InputProps = {
  width?: string;
  height?: string;
  fontSize?: string;
  border?: string;
};

const Area = styled.textarea<InputProps>`
  border: ${({ border }) => border ?? '1px solid #979797'};
  padding-left: 10px;
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '100%'};
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  min-height: 89px;
`;

type Props = {
  control:
    | {
        type: 'hook';
        name: string;
        register: any;
      }
    | {
        type: 'state';
        value: string;
        onChange: (v: string) => void;
      };
  style: InputProps;
  placeholder?: string;
};

export const TextArea: React.FC<Props> = (props) => {
  const { control, style, placeholder } = props;

  return (
    <>
      {control.type === 'hook' ? (
        <Area
          name={control.name}
          ref={control.register}
          width={style.width}
          height={style.height}
          fontSize={style.fontSize}
          border={style.border}
          placeholder={placeholder ?? ''}
        />
      ) : (
        <Area
          value={control.value}
          onChange={(e: any) => control.onChange(e.target.value)}
          width={style.width}
          height={style.height}
          fontSize={style.fontSize}
          border={style.border}
          placeholder={placeholder ?? ''}
        />
      )}
    </>
  );
};
