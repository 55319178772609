import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import App from './App';
import GlobalStyle from './lib/styles/global-style';
import 'dayjs/locale/ja';
import { AccountProvider } from './components/provider/AccountProvider';

dayjs.locale('ja');

ReactDOM.render(
  <>
    <AccountProvider>
      <GlobalStyle />
      <App />
    </AccountProvider>
  </>,
  document.getElementById('root'),
);
