import React, { useState } from 'react';
import { ModalContext, SetModalParams } from '../../lib/contexts/ModalContext';

import { Modal } from '../parts/lv3/Modal';

export const ModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ContentConponent, setContentComponent] = useState<React.FC | null>(
    null,
  );
  const [isExistcloseButton, setExistCloseButton] = useState(false);
  const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(
    false,
  );
  const [customStyle, setCustomStyle] = useState({});

  const setModal = (params: SetModalParams) => {
    setIsOpen(true);
    setContentComponent(params.ContentComponent);
    setExistCloseButton(params.isExistCloseButton || false);
    setShouldCloseOnOverlayClick(params.shouldCloseOnOverlayClick || false);
    setCustomStyle(params.customStyle || modalStyles);
  };

  const closeModal = () => setIsOpen(false);

  const modalStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.12)',
      top: '88px',
      right: '294px',
    },
    content: {
      maxHeight: '70vh',
      maxWidth: 'calc(100vw - 294px - 48px)',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroud: '#fff',
      overflow: 'scroll',
      padding: 0,
    },
  };

  return (
    <ModalContext.Provider value={{ setModal, closeModal, isOpen }}>
      <>
        {children}
        {isOpen && (
          <Modal
            ContentComponent={ContentConponent || (() => <></>)}
            isOpen={isOpen}
            style={customStyle}
            closeModal={closeModal}
            isExistCloseButton={isExistcloseButton}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          />
        )}
      </>
    </ModalContext.Provider>
  );
};
