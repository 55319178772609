/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Color } from '../../lib/styles/variables';
import { ReservationSelect } from '../parts/lv1/ReservationSelect';
import { CheckBox } from '../parts/lv3/CheckBoxWithText';
import { Navigation } from '../parts/lv1/Navigation';
import {
  deleteReservation,
  getReservationDetail,
  Reservation,
  updateReservation,
} from '../../lib/api/reservation';
import { ReservationInputText } from '../parts/lv1/ReservationInputText';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:last-child {
    width: auto;
  }
`;

const WrapperShadow = styled.div`
  margin: 12px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperTable = styled.div`
  width: 100%;
`;

const Required = styled.span`
  color: ${Color.RED};
  font-weight: bold;
`;

const WrapperTableLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
`;

const OpenCustomerDetail = styled.button`
  background: none;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.63304px;
  color: #4a90e2;
`;

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 2rem;
  border-collapse: collapse;
  tr {
    td {
      padding: 12px;
      border: 1px solid #979797;
      font-weight: bold;
      font-size: 12px;
      :nth-child(2n + 1) {
        width: 144px;
        background: #f1f1f1;
      }
    }
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  margin-bottom: 2rem;
  padding: 4px;
  border: 1px solid #979797;
`;

const WrapperFlex = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

const WrapperInput = styled.div<{ width: number; margin?: string }>`
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperButtonLeft = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 1rem;
  }
`;

const Question = styled.div`
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 12px;
`;

const Answer = styled.div`
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 12px;
`;

export const ReservationEdit = () => {
  const history = useHistory();
  const { id, customerId, reservationId } = useParams<any>();
  const [isSelected, setIsSelected] = useState(false);
  const [reservation, setReservation] = useState<Reservation | null>(null);

  const links = [
    {
      name: '予約情報',
      to: `/reservation/customer/${customerId}/status/${reservationId}`,
      isselected: true,
    },
    {
      name: 'カルテ',
      to: `/reservation/customer/${customerId}/status/${reservationId}/karte`,
      isselected: false,
    },
  ];

  useEffect(() => {
    const rId = id || reservationId;
    getReservationDetail(rId)
      .then(({ data }) => {
        setReservation(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [id, reservationId]);

  const makeDate = useCallback((time: string) => {
    const date = new Date(time);
    const dateAsString = date.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return dateAsString;
  }, []);

  const makeTime = useCallback((time: string) => {
    const date = new Date(time);
    const timeAsString = date.toLocaleTimeString('ja-JP', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return timeAsString;
  }, []);

  const calcTimeDiff = useCallback((time: string, time2: string) => {
    const date = new Date(time);
    const date2 = new Date(time2);
    const diff = date2.getTime() - date.getTime();
    const minutes = Math.floor(diff / 1000 / 60);
    return minutes;
  }, []);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleChangeStaff = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => () => {
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev, stylist_id: parseInt(value, 10) };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => () => {
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          const start = new Date(prev.visit_salon_time).getTime();
          const finish = new Date(prev.finish_time).getTime();
          const dateStart = new Date(value);
          const dateFinish = new Date(value);
          dateStart.setTime(start);
          dateFinish.setTime(finish);
          return {
            ...prev,
            visit_salon_time: dateStart.toLocaleString(),
            finish_time: dateFinish.toLocaleString(),
          };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeStartTime = useCallback(
    (e: any) => () => {
      setReservation((prev) => {
        if (prev) {
          const dateStart = new Date(prev.visit_salon_time);
          return {
            ...prev,
            visit_salon_time: dateStart.toLocaleString(),
          };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeFinishTime = useCallback(
    (e: any) => () => {
      setReservation((prev) => {
        if (prev) {
          const dateStart = new Date(prev.finish_time);
          return {
            ...prev,
            finish_time: dateStart.toLocaleString(),
          };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeCoupon = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // TODO API修正後対応
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeCategory = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // TODO API修正後対応
      const { value } = e.target;

      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeMenu = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // TODO API修正後対応
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeRequest = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev, request: value };
        }
        return prev;
      });
    },
    [],
  );

  const handleClickCheckbox = useCallback(() => {
    setIsSelected((prev) => !prev);
  }, []);

  const handleClickUpdate = useCallback(async () => {
    try {
      if (reservation == null) {
        throw new Error('エラーが発生しました');
      }
      const rId = id || reservationId;
      const params = { ...reservation };
      await updateReservation(rId, params);
      history.goBack();
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [reservation, id, reservationId, history]);

  const handleClickCancel = useCallback(async () => {
    try {
      const rId = id || reservationId;
      await deleteReservation(rId);
      history.goBack();
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [id, reservationId, history]);

  const handleClickOpenCustomerDetail = useCallback(() => {
    history.push(`/reservation/${id}/customer/${customerId}`);
  }, [history, id, customerId]);

  const handleClickFix = useCallback(() => {
    const rId = id || reservationId;
    history.push(`/reservation/${rId}/fix`, {
      reservationNumber: reservation?.reservation_number,
    });
  }, [history, id, reservationId, reservation]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="予約詳細" />
          </Margin>
        </WrapperGoBack>
        {reservationId && <Navigation links={links} />}
      </WrapperNavigation>
      {reservation && (
        <WrapperShadow>
          <WrapperTable>
            <Margin margin="0 0 8px 0">
              <WrapperTableLabel>
                <Label>
                  予約情報
                  <Required> ※必須項目</Required>
                </Label>
                <OpenCustomerDetail onClick={handleClickOpenCustomerDetail}>
                  顧客情報を開く
                </OpenCustomerDetail>
              </WrapperTableLabel>
            </Margin>
            <StyledTable>
              <tbody>
                <tr>
                  <td>予約番号</td>
                  <td>{reservation.reservation_number}</td>
                  <td>ステータス</td>
                  <td>予約</td>
                </tr>
                <tr>
                  <td>
                    スタッフ
                    <Required> ※</Required>
                  </td>
                  <td>
                    <WrapperFlex margin="12px 0">
                      <WrapperInput width={200} margin="0 12px 0 0">
                        <ReservationSelect
                          options={[
                            {
                              value: String(reservation.stylist.id),
                              label: reservation.stylist.user_name,
                            },
                          ]}
                          value={String(reservation.stylist.id)}
                          onChange={handleChangeStaff}
                          required
                          placeholder="選択してください"
                        />
                      </WrapperInput>
                      <CheckBox
                        size={{ width: 24, height: 24 }}
                        isChecked={isSelected}
                        handleClick={handleClickCheckbox}
                      />
                      指名予約
                    </WrapperFlex>
                  </td>
                  <td>予約経路</td>
                  <td>TODO</td>
                </tr>
                <tr>
                  <td>
                    来店日時
                    <Required> ※</Required>
                  </td>
                  <td colSpan={3}>
                    <WrapperInput width={200}>
                      <ReservationInputText
                        value={makeDate(reservation.visit_salon_time)}
                        onChange={handleChangeDate}
                      />
                    </WrapperInput>

                    <WrapperFlex margin="12px 0">
                      <WrapperFlex margin="0 12px 0 0">
                        開始時刻：
                        <WrapperInput width={136}>
                          <ReservationSelect
                            options={[
                              {
                                value: makeTime(reservation.visit_salon_time),
                                label: makeTime(reservation.visit_salon_time),
                              },
                            ]}
                            onChange={handleChangeStartTime}
                            value={makeTime(reservation.visit_salon_time)}
                            required
                          />
                        </WrapperInput>
                      </WrapperFlex>
                      <WrapperFlex>
                        終了時刻：
                        <WrapperInput width={136}>
                          <ReservationSelect
                            options={[
                              {
                                value: makeTime(reservation.finish_time),
                                label: makeTime(reservation.finish_time),
                              },
                            ]}
                            onChange={handleChangeFinishTime}
                            value={makeTime(reservation.finish_time)}
                            required
                          />
                        </WrapperInput>
                      </WrapperFlex>
                    </WrapperFlex>

                    <WrapperFlex>
                      {`所要時間：${calcTimeDiff(
                        reservation.visit_salon_time,
                        reservation.finish_time,
                      )}分`}
                    </WrapperFlex>
                  </td>
                </tr>
                <tr>
                  <td>クーポン</td>
                  <td colSpan={3}>
                    <WrapperInput width={530}>
                      <ReservationSelect
                        options={[{ value: 'todo', label: 'todo' }]}
                        onChange={handleChangeCoupon}
                        placeholder="クーポンを選択"
                      />
                    </WrapperInput>
                  </td>
                </tr>
                <tr>
                  <td>メニュー</td>
                  <td colSpan={3}>
                    <WrapperFlex>
                      <WrapperInput width={260} margin="0 12px 0 0">
                        <ReservationSelect
                          options={[{ value: 'todo', label: 'todo' }]}
                          onChange={handleChangeCategory}
                          placeholder="カテゴリを選択"
                        />
                      </WrapperInput>
                      <WrapperInput width={260}>
                        <ReservationSelect
                          options={[{ value: 'todo', label: 'todo' }]}
                          onChange={handleChangeMenu}
                          placeholder="メニューを選択"
                        />
                      </WrapperInput>
                    </WrapperFlex>
                  </td>
                </tr>
              </tbody>
            </StyledTable>

            <Label>予約者情報</Label>
            <StyledTable>
              <tbody>
                <tr>
                  <td>お客様名ID</td>
                  <td colSpan={3}>{reservation.user.id}</td>
                </tr>
                <tr>
                  <td>お客様名（カナ）</td>
                  <td>
                    {`${reservation.user.kana_first_name} ${reservation.user.kana_last_name}`}
                  </td>
                  <td>お客様名</td>
                  <td>
                    {`${reservation.user.kanji_first_name} ${reservation.user.kanji_last_name}`}
                  </td>
                </tr>
                <tr>
                  <td>電話番号</td>
                  <td>{reservation.user.phone_number}</td>
                  <td>性別</td>
                  <td>{reservation.user.sex}</td>
                </tr>
                <tr>
                  <td>来店回数</td>
                  <td colSpan={3}>
                    {`${reservation.user.salon_visit_count}回`}
                  </td>
                </tr>
                <tr>
                  <td>お客様メモ</td>
                  <td colSpan={3}>{reservation.memo}</td>
                </tr>
                <tr>
                  <td>前回の施術情報</td>
                  <td colSpan={3}>{reservation.person_in_charge.memo}</td>
                </tr>
              </tbody>
            </StyledTable>

            <Label>お支払い情報</Label>
            <StyledTable>
              <tbody>
                <tr>
                  <td>利用ポイント</td>
                  <td>{reservation.payment.use_point || '利用なし'}</td>
                </tr>
                <tr>
                  <td>合計金額</td>
                  <td>{`${reservation.payment.price.toLocaleString()}円`}</td>
                </tr>
                <tr>
                  <td>お支払い予定金額</td>
                  <td>{`${reservation.payment.payment_amount.toLocaleString()}円`}</td>
                </tr>
                <tr>
                  <td>付与予定ポイント</td>
                  <td>{reservation.payment.reward_point}</td>
                </tr>
              </tbody>
            </StyledTable>
          </WrapperTable>

          <Label>要望</Label>
          <StyledTextArea
            value={reservation.request}
            rows={10}
            onChange={handleChangeRequest}
          />

          <Label>質問・回答</Label>
          <div>
            {reservation.qa.length === 0 && <Question>なし</Question>}
            {reservation.qa.map((item) => {
              return (
                <div key={item.id}>
                  <Question>{item.question}</Question>
                  <Answer>{item.answer}</Answer>
                </div>
              );
            })}
          </div>

          <WrapperButton>
            <WrapperButtonLeft>
              <SquareButton
                fontSize={24}
                width={160}
                height={56}
                text="更新"
                color="BLUE"
                handleClick={handleClickUpdate}
              />
              <SquareButton
                fontSize={24}
                width={160}
                height={56}
                text="予約取消"
                color="RED"
                handleClick={handleClickCancel}
              />
            </WrapperButtonLeft>

            <SquareButton
              fontSize={24}
              width={160}
              height={56}
              text="お直し"
              color="WHITE"
              handleClick={handleClickFix}
            />
          </WrapperButton>
        </WrapperShadow>
      )}
    </Wrapper>
  );
};
