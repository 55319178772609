import Cookies from 'js-cookie';

const cookieOpts = { expires: 365 * 2 };

export const getItem = (key: string): string | null | undefined =>
  Cookies.get(key);

export function setItem(key: string, value: string): void {
  Cookies.set(key, value, cookieOpts);
}

export function removeItem(key: string): void {
  Cookies.remove(key, cookieOpts);
}

export default {
  getItem,
  setItem,
  removeItem,
};
