/* eslint @typescript-eslint/no-non-null-assertion: 0 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { encodeBase64 } from '../../../lib/utils/base64';
import { SelectBox } from '../lv3/SelectBox';
import { CheckBoxWithText } from '../lv3/CheckBoxWithText';
import { SquareButton } from '../lv3/SquareButton';

const Wrapper = styled.form`
  width: 1098px;
  height: 1176px;
  padding: 20px 90px 40px 62px;
`;

const Title = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #757575;
`;

const ImageArea = styled.div`
  margin: 30px 0 40px;
`;

const ImageList = styled.div`
  display: flex;
  margin-top: 30px;
`;

const SmallImgList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 240px;
`;

const ImgWrapper = styled.div<{ isBig?: boolean }>`
  height: ${({ isBig }) => (isBig ? '240px' : '64px')};
  width: ${({ isBig }) => (isBig ? '240px' : '64px')};
  margin-left: ${({ isBig }) => (isBig ? '0' : '24px')};
  position: relative;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const DeleteButton = styled.button`
  height: 16px;
  width: 16px;
  padding: 0;
  position: absolute;
  top: -8px;
  right: -8px;
  background: rgba(0, 0, 0, 0);
`;

const AddImgButton = styled.label`
  height: 64px;
  width: 64px;
  background: #ffffff;
  border: 1px solid #212121;
  font-size: 32px;
  color: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
`;

const Form = styled.div`
  width: 790px;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const FieldContainer = styled.div``;

const SelectBoxWrapper = styled.div`
  border: 1px solid #e0e0e0;
  padding: 0 16px;
  margin-top: 13px;
`;

const TextField = styled.input<{ width?: string }>`
  width: ${({ width }) => width ?? '377px'};
  font-size: 24px;
  line-height: 36px;
  color: #bdbdbd;
  padding: 4px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  color: #212121;
`;

const CapaAndUnit = styled.div`
  display: flex;
  justify-content: space-between;
  width: 377px;
`;

const Price = styled.div`
  width: 377px;
  border-bottom: 1px solid #979797;
  display: flex;
  input {
    border: none;
    font-size: 24px;
    line-height: 36px;
    color: #212121;
    padding: 4px 0;
    width: 100%;
  }
  span {
    font-size: 24px;
    line-height: 36px;
    color: #212121;
  }
`;

const AskingPrice = styled.div`
  width: 377px;
  display: flex;
  p {
    font-size: 24px;
    line-height: 36px;
    color: #212121;
    width: 100%;
  }
  span {
    font-size: 24px;
    line-height: 36px;
    color: #212121;
  }
`;

const SubmitButtonWrapper = styled.div`
  margin-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  span {
    width: 1px;
    margin-right: 40px;
  }
`;

type ImageItemProps = {
  isBig?: boolean;
  src?: string;
  deleteImg: (index: number) => void;
  index: number;
};

const ImageWithButton: React.FC<ImageItemProps> = ({
  isBig,
  src,
  deleteImg,
  index,
}) => {
  return (
    <div>
      <ImgWrapper isBig={isBig}>
        <Img src={src} />
        <DeleteButton onClick={() => deleteImg(index)}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/cross_mark.svg`}
            alt="cross_mark"
          />
        </DeleteButton>
      </ImgWrapper>
    </div>
  );
};

export const AddProductModal: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const [imgList, setImgList] = useState([
    'https://images.unsplash.com/photo-1515513284006-9a59075694b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1515513284006-9a59075694b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1515513284006-9a59075694b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80',
  ]);

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      maker: '',
      category: '',
      JANcode: '',
      orderId: '',
      productName: '',
      capacity: '',
      unit: '',
      price: '',
      isRegister: false,
    },
  });

  const handleFileChange = (file: any) => {
    encodeBase64(file).then((encoded) => {
      const nextImgList = imgList.concat(encoded);
      setImgList(nextImgList);
    });
  };

  const deleteImg = (i: number) => {
    const nextImgList = imgList.concat();
    nextImgList.splice(i, 1);
    setImgList(nextImgList);
  };

  const onSubmit = handleSubmit((value) => {
    console.log(value);
  });

  return (
    <Wrapper onSubmit={onSubmit}>
      <Title>商品詳細</Title>
      <ImageArea>
        <Title>掲載画像</Title>
        <ImageList>
          <ImageWithButton
            isBig
            src={imgList[0]}
            deleteImg={deleteImg}
            index={0}
          />
          <SmallImgList>
            <input
              id="img"
              type="file"
              onChange={(e: any) => handleFileChange(e.target.files![0])}
              style={{ display: 'none' }}
            />
            {imgList.map((value, i) => {
              if (i === 0) return null;
              return (
                <ImageWithButton
                  key={i}
                  src={value}
                  deleteImg={deleteImg}
                  index={i}
                />
              );
            })}
            <AddImgButton htmlFor="img" key="add_buttom">
              ＋
            </AddImgButton>
          </SmallImgList>
        </ImageList>
      </ImageArea>

      <Form>
        <RowContainer>
          <FieldContainer>
            <Title>メーカー</Title>
            <SelectBoxWrapper>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'maker',
                  register,
                }}
                style={{
                  width: '343px',
                  height: '40px',
                }}
              >
                <option value="">取引業者を選択してください</option>
                <option value="toyota">トヨタ</option>
                <option value="matsuda">マツダ</option>
              </SelectBox>
            </SelectBoxWrapper>
          </FieldContainer>

          <FieldContainer>
            <Title>カテゴリ</Title>
            <SelectBoxWrapper>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'category',
                  register,
                }}
                style={{
                  width: '343px',
                  height: '40px',
                }}
              >
                <option value="">カテゴリーを選択してください</option>
                <option value="toyota">男</option>
                <option value="matsuda">女</option>
              </SelectBox>
            </SelectBoxWrapper>
          </FieldContainer>
        </RowContainer>

        <RowContainer>
          <FieldContainer>
            <Title>JANコード</Title>
            <TextField
              name="JANcode"
              ref={register}
              placeholder="入力してください"
            />
          </FieldContainer>
          <FieldContainer>
            <Title>注文行番号</Title>
            <TextField name="orderId" ref={register} placeholder="2345678" />
          </FieldContainer>
        </RowContainer>

        <RowContainer>
          <FieldContainer>
            <Title>商品名</Title>
            <TextField
              name="productName"
              ref={register}
              placeholder="ワックス"
            />
          </FieldContainer>
          <FieldContainer>
            <CapaAndUnit>
              <FieldContainer>
                <Title>内容量</Title>
                <TextField
                  name="capacity"
                  ref={register}
                  placeholder="500"
                  width="246px"
                />
              </FieldContainer>
              <FieldContainer>
                <Title>単位</Title>
                <TextField
                  name="unit"
                  ref={register}
                  placeholder="kg"
                  width="109px"
                />
              </FieldContainer>
            </CapaAndUnit>
          </FieldContainer>
        </RowContainer>

        <RowContainer>
          <FieldContainer>
            <Title>販売価格</Title>
            <Price>
              <input name="price" ref={register} placeholder="10,000" />
              <span>円</span>
            </Price>
          </FieldContainer>
          <FieldContainer>
            <Title>希望価格</Title>
            <AskingPrice>
              <p>10,000</p>
              <span>円</span>
            </AskingPrice>
          </FieldContainer>
        </RowContainer>

        <FieldContainer>
          <Title>オープン商品</Title>
          <CheckBoxWithText
            text="登録する"
            id="isRegister"
            size={{ width: 377, height: 36 }}
            register={register}
            isChecked={watch('isRegister')}
          />
        </FieldContainer>
      </Form>

      <SubmitButtonWrapper>
        {isEdit ? (
          <>
            <SquareButton
              text="登録"
              color="BLUE"
              type="submit"
              height={56}
              width={160}
              fontSize={24}
            />
            <span />
            <SquareButton
              text="削除"
              color="RED"
              type="button"
              height={56}
              width={160}
              fontSize={24}
            />
          </>
        ) : (
          <SquareButton
            text="登録"
            color="BLUE"
            type="submit"
            height={56}
            width={160}
            fontSize={24}
          />
        )}
      </SubmitButtonWrapper>
    </Wrapper>
  );
};
