import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FontSize } from '../../lib/styles/variables';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { getSalonDetail, Salon } from '../../lib/api/salon';

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  min-width: 768px;
  height: 88px;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 2;
`;

const Title = styled(Link)`
  font-size: ${FontSize.TITLE};
  font-weight: bold;
  margin-left: 24px;
  letter-spacing: 1.63px;
`;

const RightContent = styled.div`
  display: flex;
  margin-right: 32px;
`;

const Badge = styled.p`
  font-weight: bold;
  margin-right: 48px;
`;

const LogoutButton = styled.button`
  color: #4a90e2;
  background: #fff;
  border: none;
`;

type Props = {
  login_id: string;
  logout: () => void;
};

export const Header: React.FC<Props> = ({ login_id, logout }) => {
  const history = useHistory();
  const { selectedSalon } = useContext(AccountContext);

  const [salon, setSalon] = useState<Salon | null>(null);

  useEffect(() => {
    if (selectedSalon) {
      getSalonDetail(selectedSalon)
        .then(({ data }) => {
          setSalon(data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [selectedSalon]);

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <Wrapper>
      <Title to="/">Mirroball System</Title>
      <RightContent>
        {salon && <Badge>{salon.name}</Badge>}
        <Badge>
          ID：
          {login_id}
        </Badge>
        <LogoutButton onClick={handleLogout}>ログアウト</LogoutButton>
      </RightContent>
    </Wrapper>
  );
};

export default Header;
