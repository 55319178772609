import React from 'react';
import styled from 'styled-components';
import { useParams, useLocation, useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import dayjs, { getTime } from '../../lib/utils/dayjs';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { ShadedContainer } from '../layout/ShadedContainer';
import { FontSize } from '../../lib/styles/variables';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SelectBox } from '../parts/lv3/SelectBox';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FieldLabel = styled.p`
  font-size: ${FontSize.X_LARGE};
  line-height: 30px;
  color: #757575;
  margin-bottom: 8px;
  margin-top: 40px;
`;

const InputField = styled.input`
  height: 40px;
  width: 80%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};

  ::placeholder {
    color: #bdbdbd;
  }
`;

const InputTextField = styled.textarea`
  height: 72px;
  width: 80%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};
  line-height: 36px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const Margin = styled.div`
  margin-top: 40px;
`;

const StartDateArea = styled.div`
  display: flex;
`;
const EndDateArea = styled.div`
  display: flex;
  margin-top: 16px;
`;
const Label = styled.p`
  font-weight: 500;
  color: #212121;
  margin-right: 8px;
`;

const mockStylist = {
  id: 1,
  name: '青空一郎',
};

const mockShift = {
  startTime: '09:00',
  endTime: '16:00',
};

const stepMinutes = 30; // スケジュールに表示する刻み時間(分)
const salesStartTime = '9:00'; // 営業開始日 (各店舗によって異なる場合はfetchする)
const salesEndTime = '22:00'; // 営業終了日 (各店舗によって異なる場合はfetchする)

export const ShiftSetting = () => {
  const history = useHistory();
  const { stylistId } = useParams();
  // TODO:: stylistIdからスタイリスト情報を取得
  const stylist = mockStylist;
  const location = useLocation();
  const date = new URLSearchParams(location.search).get('date');
  // TODO:: date情報からその日の出勤時間を取得

  console.log({ stylistId, date });

  const currentDay = dayjs(date || undefined);
  const [startHour, startMinute] = salesStartTime.split(':');
  const [endHour, endMinute] = salesEndTime.split(':');
  const days = getTime(
    currentDay,
    stepMinutes,
    +startHour,
    +startMinute,
    +endHour,
    +endMinute,
  );

  const { register, handleSubmit } = useForm({
    defaultValues: {
      startDate: mockShift.startTime,
      endDate: mockShift.endTime,
      timeSelectView: '',
      modalView: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    console.log({ data });
  });

  const getSelect = (name: string) => (
    <SelectBox
      control={{
        type: 'hook',
        name,
        register,
      }}
      style={{
        width: '84px',
        height: '22px',
        fontSize: '12px',
        border: 'all',
        padding: '0px 8px',
      }}
    >
      {days.map((it) => (
        <option key={it.format('HH:mm')} value={it.format('HH:mm')}>
          {it.format('HH:mm')}
        </option>
      ))}
    </SelectBox>
  );

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.push('/shift')}
        />
        <MainContentTitle
          text={`${currentDay.format('YYYY-MM-DD（ddd）')} ${stylist.name}`}
        />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px 40px 16px 40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Form onSubmit={onSubmit}>
          <StartDateArea>
            <Label>開始時間</Label>
            {getSelect('startDate')}
          </StartDateArea>
          <EndDateArea>
            <Label>終了時間</Label>
            {getSelect('endDate')}
          </EndDateArea>
          <FieldLabel>お客様の日時選択での表示</FieldLabel>
          <InputField
            type="text"
            name="timeSelectView"
            ref={register}
            style={{
              width: '80%',
            }}
            placeholder="何も入力していない場合は○または×"
          />
          <FieldLabel>モーダルの内容</FieldLabel>
          <InputTextField
            name="modalView"
            ref={register}
            style={{
              width: '80%',
            }}
            placeholder="こちらまでご連絡お願いします。&#13;&#10;080xxxxxxxx"
            rows={2}
          />
          <Margin>
            <SquareButton
              fontSize={15}
              height={40}
              width={136}
              borderRadius={4}
              text="設定"
              color="BLUE"
              type="submit"
            />
          </Margin>
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
