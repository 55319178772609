import React from 'react';
import styled from 'styled-components';
import { Product } from '../../../lib/api/product';
import { DescriptionType } from '../../pages/CreateTopics';

const Wrapper = styled.div``;
const CloseArea = styled.div`
  text-align: right;
`;
const TitleArea = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 8px;
`;
const SalonArea = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 4px;
  color: #696969;
`;

const SendAtArea = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 24px;
  color: #696969;
`;
const BodyArea = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
  white-space: pre-line;
`;

const ProductArea = styled.div`
  width: 343px;
  display: flex;
`;

const ProductContentArea = styled.div`
  padding: 8px;
  width: 100%;
`;

const ProductTitle = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #212121;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #212121;
  text-align: right;
`;

type Props = {
  title: string;
  sendAt: string;
  descriptions: DescriptionType[];
  salon?: { name: string };
  product?: Product;
  onClose: () => void;
};

export const SpTopicPreviewModal: React.FC<Props> = ({
  title,
  sendAt,
  descriptions,
  salon,
  product,
  onClose,
}) => {
  return (
    <Wrapper>
      <CloseArea onClick={onClose}>
        <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="plus" />
      </CloseArea>
      <TitleArea>{title}</TitleArea>
      <SalonArea>
        投稿者：
        {salon?.name ?? ''}
      </SalonArea>
      <SendAtArea>{sendAt}</SendAtArea>
      {descriptions.map((description, i) => {
        return (
          <div key={i}>
            <BodyArea>{description.body}</BodyArea>
            {description.image.base64 && (
              <img
                src={description.image.base64}
                alt="img"
                style={{ width: '100%', marginBottom: '24px' }}
              />
            )}
            {description.image_url && (
              <img
                src={description.image_url}
                alt="img"
                style={{ width: '100%', marginBottom: '24px' }}
              />
            )}
          </div>
        );
      })}
      <ProductArea>
        {product && (
          <>
            <img src={product.image_url} alt="img" style={{ width: 76 }} />
            <ProductContentArea>
              <ProductTitle>{product.name}</ProductTitle>
              <ProductPrice>{`${product.price.toLocaleString()}円（税込み）`}</ProductPrice>
            </ProductContentArea>
          </>
        )}
      </ProductArea>
    </Wrapper>
  );
};
