import apiBase from './apiBase';

export type PosUserData = {
  id: number;
  login_id: string;
  salon_id: number;
  remarks: string;
  last_login?: string;
};

export type GetPosUserRes = {
  data: PosUserData[];
  current_page: number;
  total: number;
  per_page: number;
  first_page_url: string;
  last_page_url: string;
  prev_page_url?: string;
  next_page_url?: string;
};

export const getAllPosUsers = (salonId: number) =>
  apiBase.get<GetPosUserRes>(`/salon/${salonId}/posUsers`);

export type createPosUserParams = {
  login_id: string;
  password: string;
  remarks: string;
};

export const addPosUser = (salonId: number, params: createPosUserParams) =>
  apiBase.post(`/salon/${salonId}/posUser`, params);

export const deletePosUser = (salonId: number, userId: number) =>
  apiBase.delete(`/salon/${salonId}/posUser/${userId}`);
