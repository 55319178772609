import apiBase from './apiBase';

export type Brand = {
  id: number;
  name: string;
};

export const getAllBrand = () => apiBase.get<Brand[]>('/brands');

export const getBrand = (id: number) => apiBase.get<Brand>(`/brand/${id}`);

export const addBrand = (name: string) => apiBase.post('/brand', { name });

export const updateBrand = (name: string, id: number) =>
  apiBase.put(`/brand/${id}`, { name });

export const deleteBrand = (id: number) => apiBase.delete(`/brand/${id}`);
