import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { SquareButton } from '../parts/lv3/SquareButton';
import { AccountingInputText } from '../parts/lv1/AccountingInputText ';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperShadow = styled.div`
  margin: 12px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperTable = styled.div<{ width: number; margin?: string }>`
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
  overflow: scroll;
`;

const StyledTable = styled.table`
  margin-bottom: 2rem;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  tr {
    th {
      width: 200px;
      padding: 12px;
      border: 1px solid #979797;
      font-size: 12px;
      font-weight: normal;
      text-align: left;
    }
    td {
      width: 200px;
      padding: 4px;
      border: 1px solid #979797;
      font-size: 14px;
    }
  }
`;

export const AccountingCommissionDm = () => {
  const history = useHistory();

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickSave = useCallback(() => {
    alert('TODO');
  }, []);

  const links = useMemo(
    () => [
      {
        name: '正社員',
        to: `/accounting/commission/regular`,
        isselected: false,
      },
      {
        name: '店長',
        to: `/accounting/commission/manager`,
        isselected: false,
      },
      {
        name: 'DM',
        to: `/accounting/commission/dm`,
        isselected: true,
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="歩合給・賞与" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} flexEnd />
      </WrapperNavigation>
      <WrapperShadow>
        <Margin margin="0 0 24px 0 ">
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="保存"
            color="BLUE"
            borderRadius={4}
            handleClick={handleClickSave}
          />
        </Margin>

        <WrapperTable width={1000} margin="0 0 36px 0">
          <StyledTable>
            <thead>
              <tr>
                <th />
                <th>2,000,000</th>
                <th>3,000,000</th>
                <th>4,000,000</th>
                <th>5,000,000</th>
                <th>6,000,000</th>
                <th>7,000,000</th>
              </tr>
            </thead>
            <tbody>
              <tr />
              <tr>
                <td>店舗予算(技術売）</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>原価率</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>顧客再来率</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>店販比率</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>トリートメント比率</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>店舗・時間単価</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>悪コミ率</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>集客採用SNS</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>MOT点数</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>合計</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </StyledTable>
        </WrapperTable>

        <WrapperTable width={1000} margin="0 0 36px 0">
          <StyledTable>
            <thead />
            <tbody>
              <tr>
                <td colSpan={2}>利益率２０％以上25%未満</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td colSpan={2}>利益率２５％以上</td>
                {Array.from(Array(6).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </StyledTable>
        </WrapperTable>
      </WrapperShadow>
    </Wrapper>
  );
};
