import { useState, useEffect } from 'react';
import { getCategories, GetCategoriesRes } from '../api/category';

const useCategories = () => {
  const [categories, setCategories] = useState<GetCategoriesRes | null>(null);

  useEffect(() => {
    getCategories().then(({ data }) => {
      setCategories(data);
    });
  }, []);

  return categories;
};

export default useCategories;
