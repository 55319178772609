import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Color } from '../../../lib/styles/variables';
import { MotPoint, getMotPoints } from '../../../lib/api/stylist';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const StyledTable = styled.table`
  margin-bottom: 2rem;
  border-collapse: collapse;
  tr {
    th {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      background: #f1f1f1;
    }
    td {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-size: 14px;
    }
  }
`;

const ButtonEdit = styled.button`
  color: ${Color.BLUE};
  background: none;
`;

const WrapperPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Color.BLUE};
  background: none;
`;

const Index = styled.button<{ isCurrent: boolean }>`
  width: 30px;
  margin: 0 1rem;
  height: 30px;
  border-radius: 30px;
  background: ${({ isCurrent }) => (isCurrent ? '#000' : 'none')};
  color: ${({ isCurrent }) => (isCurrent ? '#fff' : '#000')};
`;

const WrapperIndexes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StylistEvaluationMot: FC = () => {
  const history = useHistory();
  const { stylistId } = useParams<{ id: string; stylistId: string }>();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [motPoints, setMotPoints] = useState<MotPoint[]>([]);

  useEffect(() => {
    getMotPoints(stylistId)
      .then(({ data }) => {
        setMotPoints(data.data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [stylistId]);

  const indexes = useMemo(() => {
    const numberOfIndexes = Math.ceil(motPoints.length / 20) || 1;
    return Array.from(Array(numberOfIndexes).keys());
  }, [motPoints]);

  const handleClickIndex = useCallback(
    (index: number) => () => {
      setCurrentIndex(index);
    },
    [],
  );

  const handleClickEdit = useCallback(
    (evaluationId: number) => () => {
      history.push(`mot/${evaluationId}/edit`);
    },
    [history],
  );

  return (
    <Wrapper>
      <StyledTable>
        <thead>
          <tr>
            <th>日付</th>
            <th>MOT点数</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {motPoints.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.date}</td>
                <td>{item.point}</td>
                <td>
                  <ButtonEdit type="button" onClick={handleClickEdit(item.id)}>
                    編集
                  </ButtonEdit>
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      <WrapperPagination>
        <img
          src={`${process.env.PUBLIC_URL}/assets/arrow_circle_left.svg`}
          alt="arrow_circle_left"
        />

        <WrapperIndexes>
          {indexes.map((item) => {
            return (
              <Index
                key={item}
                isCurrent={item === currentIndex}
                onClick={handleClickIndex(item)}
              >
                {item + 1}
              </Index>
            );
          })}
        </WrapperIndexes>

        <img
          src={`${process.env.PUBLIC_URL}/assets/arrow_circle_right.svg`}
          alt="arrow_circle_right"
        />
      </WrapperPagination>
    </Wrapper>
  );
};
