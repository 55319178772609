import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router';
import { Navigation } from '../parts/lv1/Navigation';
import { TextWithTitle } from '../parts/lv3/TextWithTitle';
import { StylistDetailTableCommission } from '../parts/lv1/StylistDetailTableCommission';
import { StylistDetailTableMenu } from '../parts/lv1/StylistDetailTableMenu';
import { StylistDetailTableRepeat } from '../parts/lv1/StylistDetailTableRepeat';
import { StylistDetailTableBank } from '../parts/lv1/StylistDetailTableBank';
import { SquareButton } from '../parts/lv3/SquareButton';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { StylistDetailLeftInfo } from '../parts/lv1/StylistDetailTableLeftInfo';
import { getStylistDetail, Stylist } from '../../lib/api/stylist';
import { getSalons, Salon } from '../../lib/api/salon';
import { StylistDetailTableWorkingCondition } from '../parts/lv1/StylistDetailTableWorkingCondition';
import { useSpinner } from '../../lib/hooks/useSpinner';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperContent = styled.div`
  display: flex;
`;

const RightContent = styled.div`
  margin-bottom: 2rem;
`;

const TWTArea = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 68px;
  margin-bottom: 80px;
`;

const TWTWrapper = styled.div`
  width: 50%;
  margin-bottom: 30px;
`;

const DateWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
`;

const EnhancedTWT = ({
  text,
  title,
}: {
  text: string | number;
  title: string;
}) => {
  return (
    <TWTWrapper>
      <TextWithTitle
        width="100%"
        margin="4px"
        title={{
          text: title,
          fontSize: '24px',
          color: '#757575',
        }}
        text={{
          text,
          fontSize: '20px',
          color: '#212121',
        }}
      />
    </TWTWrapper>
  );
};

export const StylistDetail = () => {
  const { id, stylistId } = useParams<{ id: string; stylistId: string }>();
  const history = useHistory();

  const [stylist, setStylist] = useState<Stylist | null>(null);
  const [salons, setSalons] = useState<Salon[]>([]);

  const { loading, finishLoading, startLoading } = useSpinner();

  const links = useMemo(
    () => [
      {
        name: '基本情報',
        to: `/store/detail/${id}/stylist/detail/${stylistId}`,
        isselected: true,
      },
      {
        name: '顧客リスト',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/customer`,
        isselected: false,
      },
      {
        name: '売上実績',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/sales`,
        isselected: false,
      },
      {
        name: '評価',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation`,
        isselected: false,
      },
      {
        name: '手当',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/allowance`,
        isselected: false,
      },
      {
        name: '報酬保証',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/reward`,
        isselected: false,
      },
      {
        name: '賞与',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/bonus`,
        isselected: false,
      },
      {
        name: '歩合',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/commission`,
        isselected: false,
      },
    ],
    [id, stylistId],
  );

  useEffect(() => {
    startLoading();
    getStylistDetail(stylistId)
      .then(({ data }) => {
        finishLoading();
        setStylist(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [id, stylistId]);

  useEffect(() => {
    getSalons()
      .then(({ data }) => {
        setSalons(data.data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [id, stylistId]);

  const salonName = useCallback(
    (salonId: number) => {
      const index = salons.findIndex((item) => item.id === salonId);
      if (index > -1) {
        return salons[index].name;
      }
      return '';
    },
    [salons],
  );

  const handleClickEdit = useCallback(() => {
    history.push(`/store/detail/${id}/stylist/detail/${stylistId}/edit`);
  }, [history, id, stylistId]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="美容師詳細" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} />
      </WrapperNavigation>
      {stylist ? (
        <WrapperShadow>
          <WrapperContent>
            <StylistDetailLeftInfo
              img_url={stylist.image_url}
              employeeNumber={stylist.id}
              contractType={stylist.contract_type}
            />
            <RightContent>
              <TWTArea>
                <EnhancedTWT text={stylist.user_name} title="氏名" />
                <EnhancedTWT
                  text={salonName(stylist.main_salon_id)}
                  title="所属店舗（メイン）"
                />
                <EnhancedTWT
                  text={stylist.user_kana_name}
                  title="氏名（フリガナ）"
                />
                <EnhancedTWT
                  text={salonName(stylist.sub_salon_id)}
                  title="所属店舗（サブ）"
                />
                <EnhancedTWT text={stylist.id} title="従業員番号" />
                <EnhancedTWT text={stylist.email} title="メールアドレス" />
                <EnhancedTWT text={stylist.phone_number} title="電話番号" />
                {stylist.contract_type === 0 && (
                  <EnhancedTWT
                    text={stylist.monthy_holiday_number}
                    title="休日数"
                  />
                )}
                <EnhancedTWT
                  text={stylist.emergency_phone_number}
                  title="緊急連絡先"
                />
              </TWTArea>

              {(stylist.contract_type === 0 || stylist.contract_type === 1) && (
                <StylistDetailTableCommission
                  ec_feed_back_rate={stylist.ec_feed_back_rate}
                  commission_rate={stylist.commission_rate}
                  treatment_sale_free_commission={
                    stylist.treatment_sale_free_commission
                  }
                  treatment_sale_name_commission={
                    stylist.treatment_sale_name_commission
                  }
                  product_sale_commission={stylist.product_sale_commission}
                />
              )}

              <StylistDetailTableMenu rate_of_menus={stylist.menu_rate} />

              <StylistDetailTableRepeat
                repeat_rate={stylist.repeat_rate}
                unit_price_per_hour={stylist.unit_price_per_hour}
              />

              <StylistDetailTableBank
                bank_name={stylist.bank_name}
                bank_number={stylist.bank_number}
                branch_store_name={stylist.branch_store_name}
                branch_store_number={stylist.branch_store_number}
                bank_account_name={stylist.bank_account_name}
                bank_account_type={stylist.bank_account_type}
                bank_account_number={stylist.bank_account_number}
              />

              {stylist.contract_type === 0 && (
                <StylistDetailTableWorkingCondition
                  working_conditions={stylist.working_conditions}
                />
              )}

              <EnhancedTWT text={stylist.contract_status} title="契約状況" />

              <DateWrapper>
                <EnhancedTWT text={stylist.hire_date || ''} title="入社日" />
                <EnhancedTWT text={stylist.leave_date || ''} title="退社日" />
              </DateWrapper>
            </RightContent>
          </WrapperContent>
          <WrapperButton>
            <SquareButton
              fontSize={30}
              width={282}
              height={78}
              text="編集"
              color="BLUE_BORDER"
              fontWeight="bold"
              handleClick={handleClickEdit}
            />
          </WrapperButton>
        </WrapperShadow>
      ) : (
        <Margin margin="64px">{loading}</Margin>
      )}
    </Wrapper>
  );
};
