/* eslint react/display-name: 0 */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { MissionSearchForm } from '../parts/lv1/MissionSearchForm';
import { Navigation } from '../parts/lv1/Navigation';
import dayjs from '../../lib/utils/dayjs';
import { getMissionBudget, GetMissionBudgetParams } from '../../lib/api/budget';
import { useSpinner } from '../../lib/hooks/useSpinner';
import { AccountContext } from '../../lib/contexts/AccountContext';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  font-size: 12px;
  color: #4a90e2;
  background: #fff;
`;

type BudgetTableDataType = {
  month: string;
  badged?: string;
  storePurchaserRate?: string;
  costRate?: string;
  skillMission?: string;
  itemsMission?: string;
  sumMission?: string;
  editButton: any;
};

export const Mission = () => {
  const history = useHistory();
  const { selectedSalon } = useContext(AccountContext);
  const { loading, startLoading, finishLoading } = useSpinner();
  const [budget, setBudget] = useState<BudgetTableDataType[]>([]);
  const current = dayjs();
  const currentYear = current.year();
  const currentYearMonth = current.format('YYYY-MM');

  const EditButton = ({ month }: { month: string }) => {
    return (
      <Button onClick={() => history.push(`/store/mission/edit/${month}`)}>
        編集
      </Button>
    );
  };

  const AddButton = ({ month }: { month: string }) => {
    return (
      <Button onClick={() => history.push(`/store/mission/add/${month}`)}>
        登録
      </Button>
    );
  };

  useEffect(() => {
    if (!selectedSalon) return;
    startLoading();
    getMissionBudget(selectedSalon, { year: currentYear.toString() })
      .then(({ data }) => {
        const tableData = Array(12)
          .fill(0)
          .map((_, index) => {
            const month = dayjs(`${currentYear}-${index + 1}`);
            const target = data.find((it) =>
              it.year_month.includes(month.format('YYYY-MM')),
            );
            return target
              ? {
                  month: month.format('M月'),
                  badged: `${target.budget.toLocaleString()}円`,
                  storePurchaserRate: `${target.purchase_product_customer_rate *
                    100}%`,
                  costRate: `${target.material_cost_rate * 100}%`,
                  skillMission: `${target.treatment_sales_target.toLocaleString()}円`,
                  itemsMission: `${target.product_sales_target.toLocaleString()}円`,
                  sumMission: `${target.total_sales_target.toLocaleString()}円`,
                  editButton: <EditButton month={target.year_month} />,
                }
              : {
                  month: month.format('M月'),
                  editButton: <AddButton month={month.format('YYYY-MM')} />,
                };
          });

        setBudget(tableData);
      })
      .finally(() => {
        finishLoading();
      });
  }, [selectedSalon]);

  const onSubmit = (params: GetMissionBudgetParams) => {
    if (!selectedSalon) return;
    startLoading();
    getMissionBudget(selectedSalon, params)
      .then(({ data }) => {
        const tableData = Array(12)
          .fill(0)
          .map((_, index) => {
            const month = dayjs(`${currentYear}-${index + 1}`);
            const target = data.find((it) =>
              it.year_month.includes(month.format('YYYY-MM')),
            );
            return target
              ? {
                  month: month.format('M月'),
                  badged: `${target.budget.toLocaleString()}円`,
                  storePurchaserRate: `${target.purchase_product_customer_rate *
                    100}%`,
                  costRate: `${target.material_cost_rate * 100}%`,
                  skillMission: `${target.treatment_sales_target.toLocaleString()}円`,
                  itemsMission: `${target.product_sales_target.toLocaleString()}円`,
                  sumMission: `${target.total_sales_target.toLocaleString()}円`,
                  editButton: <EditButton month={target.year_month} />,
                }
              : {
                  month: month.format('M月'),
                  editButton: <AddButton month={month.format('YYYY-MM')} />,
                };
          });
        setBudget(tableData);
      })
      .finally(() => {
        finishLoading();
      });
  };

  const links = [
    { name: '予算・目標', to: `/store/mission`, isselected: true },
    { name: 'MOT点数', to: `/store/mission/mot`, isselected: false },
  ];

  const columns = [
    { header: '対象月', accessor: 'month', width: 73 },
    { header: '予算', accessor: 'badged', width: 150 },
    { header: '店舗購入者比率', accessor: 'storePurchaserRate', width: 150 },
    { header: '材料原価率', accessor: 'costRate', width: 150 },
    { header: '技術売上目標', accessor: 'skillMission', width: 150 },
    { header: '物販売上目標', accessor: 'itemsMission', width: 150 },
    { header: '合計売上目標', accessor: 'sumMission', width: 150 },
    { header: '', accessor: 'editButton', width: 60 },
  ];

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="予算・目標・MOT点数" />
        </Margin>
        <Navigation links={links} isSmall />
      </TopContent>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <SquareButton
          text="追加"
          width={136}
          height={40}
          fontSize={15}
          borderRadius={4}
          color="BLUE"
          handleClick={() =>
            history.push(`/store/mission/add/${currentYearMonth}`)
          }
        />
        <Margin margin="40px 0 24px 0">
          <MissionSearchForm currentYear={currentYear} onChange={onSubmit} />
        </Margin>
        {!loading ? (
          <Table columns={columns} data={budget} gravity="end" />
        ) : (
          loading
        )}
      </ShadedContainer>
    </Wrapper>
  );
};
