import React from 'react';
import styled from 'styled-components';
import { Color, FontSize } from '../../../lib/styles/variables';

const Title = styled.strong`
  color: ${Color.BRACK};
  font-size: ${FontSize.TITLE};
  line-height: 36px;
`;

type Props = {
  text: string;
};

export const MainContentTitle: React.FC<Props> = ({ text }) => {
  return <Title>{text}</Title>;
};
