import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { FontSize, Color } from '../../lib/styles/variables';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SelectBox } from '../parts/lv3/SelectBox';
import { RadioButtonWithText } from '../parts/lv3/RadioButtonWithText';
import { CreateMessageLink } from '../parts/lv1/CreateMessageLink';
import { Table } from '../parts/lv1/Table';
import {
  QuestionaryType,
  searchQuestionary,
} from '../../lib/api/questionaries';
import { CheckBox, CheckBoxWithText } from '../parts/lv3/CheckBoxWithText';
import { getToggle } from '../../lib/utils/array';
import { getYearArray, getMonthArray, getDayArray } from '../../lib/utils/date';
import { zeroPadding } from '../../lib/utils/string';
import { ValidateErrorMessageList } from '../parts/lv3/ValidationErrorMessage';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridWrapper = styled.div`
  display: grid;
  grid:
    'furigana furiganaField name nameField' 42px
    'gender genderField era eraField' 42px
    'lastTreatment lastTreatmentField purchased purchasedField' 42px
    'birthday birthdayField birthdayField birthdayField' 42px
    'visited visitedField visitedField visitedField' 42px
    'totalVisited totalVisitedField totalVisitedField totalVisitedField' 42px
    / 147px 400px 147px 1fr;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const ButtonWrapper = styled.div`
  width: 296px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const TextField = styled.input`
  width: 84px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #979797;
  margin-left: 10px;
`;

const SmallText = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  margin-left: 10px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const SelectBoxWrapper = styled.div`
  margin-left: 10px;
  padding: 2px;
  border: 1px solid #979797;
`;

const TableWrapper = styled.div`
  margin: 20px 0;

  > p {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 6px;
  }
`;

const SearchResult = styled.div`
  display: flex;
  align-items: center;
  & > div:last-child {
    margin-left: 40px;
  }
`;

export const FilterQuestionnaireCustomer = () => {
  const [searchResult, setSearchResult] = useState<QuestionaryType[] | null>(
    null,
  );
  const [selectUserIds, setSelectUserIds] = useState<number[]>([]);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);
  const history = useHistory();
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      kanji_first_name: '',
      kanji_last_name: '',
      kana_first_name: '',
      kana_last_name: '',
      sex: '',
      last_menu: '',
      purchased_item: '',
      days_after_last_visit: '',
      days_before_reservation: '',
      salon_visit_count: '',

      beforeBrithYear: '',
      beforeBrithMonth: '',
      beforeBrithDay: '',
      afterBrithYear: '',
      afterBrithMonth: '',
      afterBrithDay: '',
      era: '',
      visit_type: 'lastVisited',
    },
  });

  const columns = [
    { header: '配信可否', accessor: 'deliverable', width: 78 },
    { header: 'お客様名（カナ）', accessor: 'kana_name', width: 150 },
    { header: 'お客様名', accessor: 'kanji_name', width: 150 },
    { header: '性別', accessor: 'sex', width: 55 },
    { header: '生年月日', accessor: 'birth_day', width: 97 },
    { header: '来店回数', accessor: 'visit_count', width: 97 },
    { header: '前回来店日', accessor: 'last_visit_date', width: 97 },
  ];

  const handleSearch = handleSubmit((value) => {
    const from_birth_day = (() => {
      if (
        value.beforeBrithYear &&
        value.beforeBrithMonth &&
        value.beforeBrithDay
      )
        return `${value.beforeBrithYear}-${zeroPadding(
          value.beforeBrithMonth,
          2,
        )}-${zeroPadding(value.beforeBrithDay, 2)}`;
      if (value.era) {
        return `${value.era}-01-01`;
      }
      return undefined;
    })();
    const to_birth_day = (() => {
      if (value.afterBrithYear && value.afterBrithMonth && value.afterBrithDay)
        return `${value.afterBrithYear}-${zeroPadding(
          value.afterBrithMonth,
          2,
        )}-${zeroPadding(value.afterBrithDay, 2)}`;
      if (value.era) {
        return `${+value.era + 1}-01-01`;
      }
      return undefined;
    })();

    const converted = {
      kanji_first_name: value.kanji_first_name,
      kanji_last_name: value.kanji_last_name,
      kana_first_name: value.kana_first_name,
      kana_last_name: value.kana_last_name,
      sex: value.sex,
      last_menu: value.last_menu,
      purchased_item: value.purchased_item,
      days_after_last_visit: value.days_after_last_visit,
      days_before_reservation: value.days_before_reservation,
      salon_visit_count: value.salon_visit_count,
      from_birth_day,
      to_birth_day,
    };

    searchQuestionary(converted)
      .then(({ data }) => {
        setSearchResult(data);
      })
      .catch((e) => {
        setErrorMessageList(['エラーが発生しました']);
      });
  });

  const tableData = !searchResult
    ? []
    : searchResult.map((it) => ({
        ...it,
        deliverable: (
          <CheckBox
            size={{ width: 24, height: 24 }}
            isChecked={selectUserIds.some((userId) => userId === it.id)}
            handleClick={() =>
              setSelectUserIds(getToggle(it.id, selectUserIds))
            }
          />
        ),
      }));

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="アンケート管理" />
      </Margin>
      <GridWrapper>
        <GridItem gridArea="furigana">
          <Label>お客様名（カナ）</Label>
        </GridItem>
        <GridItem gridArea="furiganaField">
          <SmallText>セイ</SmallText>
          <TextField name="kana_first_name" ref={register} />
          <SmallText>メイ</SmallText>
          <TextField name="kana_last_name" ref={register} />
        </GridItem>

        <GridItem gridArea="name">
          <Label>お客様名</Label>
        </GridItem>
        <GridItem gridArea="nameField" isRightmost>
          <SmallText>性</SmallText>
          <TextField name="kanji_first_name" ref={register} />
          <SmallText>名</SmallText>
          <TextField name="kanji_last_name" ref={register} />
        </GridItem>

        <GridItem gridArea="gender">
          <Label>性別</Label>
        </GridItem>
        <GridItem gridArea="genderField">
          <RadioButtonWrapper>
            <RadioButtonWithText
              text="選択なし"
              id="none"
              name="sex"
              register={register}
              isSelected={watch().sex === ''}
            />
            <RadioButtonWithText
              text="男性"
              id="男性"
              name="sex"
              register={register}
              isSelected={watch().sex === '男性'}
            />
            <RadioButtonWithText
              text="女性"
              id="女性"
              name="sex"
              register={register}
              isSelected={watch().sex === '女性'}
            />
            <RadioButtonWithText
              text="その他"
              id="その他"
              name="sex"
              register={register}
              isSelected={watch().sex === 'その他'}
            />
          </RadioButtonWrapper>
        </GridItem>

        <GridItem gridArea="era">
          <Label>年代</Label>
        </GridItem>
        <GridItem gridArea="eraField" isRightmost>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'era',
                register,
              }}
              style={{
                width: '110px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getYearArray().map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
        </GridItem>

        <GridItem gridArea="lastTreatment">
          <Label>前回施術内容</Label>
        </GridItem>
        <GridItem gridArea="lastTreatmentField">
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'last_menu',
                register,
              }}
              style={{
                width: '170px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              <option value="カット">カット</option>
              <option value="カラー">カラー</option>
              <option value="パーマ">パーマ</option>
              <option value="トリートメント">トリートメント</option>
              <option value="ヘッドスパ">ヘッドスパ</option>
            </SelectBox>
          </SelectBoxWrapper>
        </GridItem>

        <GridItem gridArea="purchased">
          <Label>過去購入商品</Label>
        </GridItem>
        <GridItem gridArea="purchasedField" isRightmost>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'purchased_item',
                register,
              }}
              style={{
                width: '170px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              <option value="シャンプー">シャンプー</option>
              <option value="トリートメント">トリートメント</option>
              <option value="アウトバストリートメント">
                アウトバストリートメント
              </option>
              <option value="アイテム">アイテム</option>
              <option value="コスメ">コスメ</option>
              <option value="サプリ">サプリ</option>
              <option value="ワックス">ワックス</option>
            </SelectBox>
          </SelectBoxWrapper>
        </GridItem>

        <GridItem gridArea="birthday">
          <Label>誕生日</Label>
        </GridItem>
        <GridItem gridArea="birthdayField" isRightmost>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'beforeBrithYear',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getYearArray().map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>年</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'beforeBrithMonth',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getMonthArray().map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>月</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'beforeBrithDay',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getDayArray().map((day) => (
                <option value={day} key={day}>
                  {day}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>日</SmallText>
          <SmallText>から</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'afterBrithYear',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getYearArray().map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>年</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'afterBrithMonth',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getMonthArray().map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>月</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'afterBrithDay',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getDayArray().map((day) => (
                <option value={day} key={day}>
                  {day}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>日</SmallText>
        </GridItem>

        <GridItem gridArea="visited">
          <Label>来店日時</Label>
        </GridItem>
        <GridItem gridArea="visitedField" isRightmost>
          <RadioButtonWrapper>
            <RadioButtonWithText
              text="前回来店から"
              id="lastVisited"
              name="visit_type"
              register={register}
              isSelected={watch().visit_type === 'lastVisited'}
            />
            <TextField name="days_after_last_visit" ref={register} />
            <SmallText>日後</SmallText>
          </RadioButtonWrapper>
          <RadioButtonWrapper>
            <RadioButtonWithText
              text="次回予約から"
              id="nextResarve"
              name="visit_type"
              register={register}
              isSelected={watch().visit_type === 'nextResarve'}
            />
            <TextField name="days_before_reservation" ref={register} />
            <SmallText>日前</SmallText>
          </RadioButtonWrapper>
        </GridItem>

        <GridItem gridArea="totalVisited" isBottom>
          <Label>総来店回数</Label>
        </GridItem>
        <GridItem gridArea="totalVisitedField" isRightmost isBottom>
          <TextField name="salon_visit_count" ref={register} />
        </GridItem>
      </GridWrapper>
      <ValidateErrorMessageList
        errors={{}}
        errorMessageList={errorMessageList}
      />
      <ButtonWrapper>
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="検索"
          color="BLUE_BORDER"
          borderRadius={4}
          handleClick={handleSearch}
        />
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="戻る"
          color="WHITE"
          borderRadius={4}
          handleClick={() => history.push('/content/questionnaire/customer')}
        />
      </ButtonWrapper>
      {searchResult ? (
        <>
          <CreateMessageLink
            path={`/content/questionnaire/customer/create?id=${selectUserIds.join(
              ',',
            )}`}
          />
          <TableWrapper>
            <SearchResult>
              検索結果：
              {searchResult.length}
              件
              <CheckBoxWithText
                text="全て選択する"
                size={{ height: 30 }}
                isChecked={selectUserIds.length === searchResult.length}
                handleClick={() =>
                  selectUserIds.length === searchResult.length
                    ? setSelectUserIds([])
                    : setSelectUserIds(searchResult.map((it) => it.id))
                }
              />
            </SearchResult>
            <Table columns={columns} data={tableData} />
          </TableWrapper>
          <CreateMessageLink
            path={`/content/questionnaire/customer/create?id=${selectUserIds.join(
              ',',
            )}`}
          />
        </>
      ) : null}
    </Wrapper>
  );
};
