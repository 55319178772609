import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '../../components/parts/lv3/ArrowIcon';

const Wrapper = styled.div`
  padding: 26px 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowIconWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  border: 1px solid #212121;
  display: grid;
  place-items: center;
`;

const IndexNumberWrapper = styled.div<{ isSelected: boolean }>`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background: ${({ isSelected }) => (isSelected ? '#212121' : '#fff')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#212121')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

export const usePager = (handlePaganation: (index: number) => void) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(0);

  const onPagenation = (index: number) => {
    handlePaganation(index);
    setCurrentPage(index);
  };

  const goNext = () => {
    if (currentPage < lastPage) {
      handlePaganation(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const goBack = () => {
    if (currentPage > 1) {
      handlePaganation(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const renderIndexNumber = () => {
    const indexNumber = [];

    for (
      let index = currentPage;
      index < currentPage + 5 && index <= lastPage;
      index += 1
    ) {
      indexNumber.push(
        <IndexNumberWrapper
          isSelected={index === currentPage}
          key={index}
          onClick={() => onPagenation(index)}
        >
          {index}
        </IndexNumberWrapper>,
      );
    }

    const diff = 6 - indexNumber.length;
    for (let index = 1; index < diff; index += 1) {
      if (currentPage - index > 0) {
        indexNumber.unshift(
          <IndexNumberWrapper
            isSelected={false}
            key={currentPage - index}
            onClick={() => onPagenation(currentPage - index)}
          >
            {currentPage - index}
          </IndexNumberWrapper>,
        );
      }
    }

    if (currentPage + 4 < lastPage) {
      indexNumber.push(
        <IndexNumberWrapper isSelected={false}>...</IndexNumberWrapper>,
      );
    }

    return indexNumber;
  };

  const Pager: React.FC = () => {
    return (
      <Wrapper>
        {lastPage ? (
          <>
            {' '}
            <ArrowIconWrapper onClick={goBack}>
              <ArrowIcon
                direction="LEFT"
                style={{ width: 8, height: 14, color: '#212121' }}
              />
            </ArrowIconWrapper>
            {renderIndexNumber()}
            <ArrowIconWrapper onClick={goNext}>
              <ArrowIcon
                direction="RIGHT"
                style={{ width: 8, height: 14, color: '#212121' }}
              />
            </ArrowIconWrapper>
          </>
        ) : null}
      </Wrapper>
    );
  };

  return { Pager, setLastPage };
};
