export type PageData = {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
};

export const copyClipBoard = (targetStr: string) => {
  // div 生成
  const tmp = document.createElement('div');
  tmp.textContent = targetStr;
  // body に追加
  document.body.appendChild(tmp);
  // 要素を選択
  document.getSelection()?.selectAllChildren(tmp);
  // クリップボードにコピー
  const result = document.execCommand('copy');
  // 要素削除
  document.body.removeChild(tmp);

  return result;
};
