import { createContext } from 'react';

export type SetModalParams = {
  ContentComponent: React.FC;
  isExistCloseButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  customStyle?: {};
};

export const ModalContext = createContext<{
  setModal: (params: SetModalParams) => void;
  closeModal: () => void;
  isOpen: boolean;
}>({
  setModal: null as any,
  closeModal: null as any,
  isOpen: null as any,
});
