export const getDayTextColor = (day: number) => {
  switch (day) {
    case 0:
      return '#E24A4A';
    case 6:
      return '#4A90E2';
    default:
      return '#212121';
  }
};
