import dayjs from 'dayjs'; // eslint-disable-line import/no-extraneous-dependencies
import 'dayjs/locale/ja'; // eslint-disable-line import/no-extraneous-dependencies

dayjs.locale('ja');
export default dayjs;

export const getTime = (
  day: dayjs.Dayjs,
  step: number,
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
): dayjs.Dayjs[] => {
  const days = [] as dayjs.Dayjs[];
  const startDay = day.set('hour', startHour).set('minute', startMinute);
  const endDay = day.set('hour', endHour).set('minute', endMinute);
  for (let i = 0; ; i += 1) {
    if (i > 100) break; // 無限ループ防止
    const stepDay = startDay.add(step * i, 'minute');
    if (stepDay.isSame(endDay)) break;
    days.push(stepDay);
  }
  return days;
};

export const getMonthOfDateList = (day: dayjs.Dayjs): dayjs.Dayjs[] => {
  const days = [] as dayjs.Dayjs[];
  for (let i = 0; ; i += 1) {
    if (i > 100) break; // 無限ループ防止
    const stepDay = day.startOf('month').add(i, 'day');
    days.push(stepDay);
    if (day.daysInMonth() === stepDay.date()) break;
  }
  return days;
};

export const isBetween = (
  day: dayjs.Dayjs,
  startTime: string,
  endTime: string,
) =>
  (day.isAfter(dayjs(startTime)) && day.isBefore(dayjs(endTime))) ||
  day.isSame(dayjs(startTime));

export const isSameDate = (day: dayjs.Dayjs, compareDay: dayjs.Dayjs) =>
  day.format('YYYYMMDD') === compareDay.format('YYYYMMDD');

export const getWeeks = () => {
  const day = dayjs('1970-2-1'); // 日曜日
  return Array(7)
    .fill(0)
    .map((_, i) => ({
      index: day.add(i, 'day').format('d'),
      str: day.add(i, 'day').format('ddd'),
    }));
};

export const toYmd = (day: dayjs.Dayjs) => day.format('YYYY-MM-DD');
export const toH = (day: dayjs.Dayjs) => day.format('hh');
export const toM = (day: dayjs.Dayjs) => day.format('mm');

export const TimeHeight = '32px';
export const ReservationCountHeight = '40px';
export const CanReservationCountHeight = '72px';
export const StylistHeight = '52px';
export const CellWidth = '64px';

export const ShiftTimeHeight = '56px';
export const ShiftCellWidth = '114px';
