/* eslint react/display-name: 0 */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { SquareButton } from '../lv3/SquareButton';
import { ModalContext } from '../../../lib/contexts/ModalContext';
import { CreateMessageModal } from './CreateMessageModal';
import { Customer } from '../../../lib/api/message';

const Wrapper = styled.div`
  width: 100%;
  height: 108px;
  padding: 20px 0;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  margin-bottom: 10px;
`;

type CustomerWithIsChecked = {
  isChecked: boolean;
  customer: Customer;
};

export const CreateMessageLink = ({
  path,
  isStylist,
  sendType,
  salonId = null,
  stylistIds = null,
  checkUsers = null,
}: {
  path?: string;
  isStylist?: boolean;
  sendType?: string;
  salonId?: number | null;
  stylistIds?: number[] | null;
  checkUsers?: CustomerWithIsChecked[] | null;
}) => {
  const history = useHistory();
  const { setModal, closeModal } = useContext(ModalContext);

  return (
    <Wrapper>
      <Text>
        {`チェックボッスクスで指定した${
          isStylist ? '従業員' : 'お客様'
        }に一括でメッセージを送信します`}
      </Text>
      <SquareButton
        fontSize={15}
        width={136}
        height={40}
        text="メッセージを作成"
        color="BLUE"
        borderRadius={4}
        handleClick={() => {
          if (path) {
            history.push(path);
          } else {
            setModal({
              ContentComponent: () => (
                <CreateMessageModal
                  isStylist={isStylist}
                  sendType={sendType}
                  salonId={salonId}
                  stylistIds={stylistIds}
                  checkUsers={checkUsers}
                  closeModal={() => closeModal()}
                />
              ),
            });
          }
        }}
      />
    </Wrapper>
  );
};
