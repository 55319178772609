import * as React from 'react';
import styled from 'styled-components';
import { Body } from './Body';
import { Cell } from './Cell';
import { Header } from './Header';
import { Row } from './Row';
import { HeaderCell } from './HeaderCell';

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  background-color: #ffffff;
`;

export type Props = {
  children: React.ReactNode;
};

export const ContentTable = ({ children }: Props) => (
  <Container>{children}</Container>
);

ContentTable.Header = Header;
ContentTable.Body = Body;
ContentTable.Row = Row;
ContentTable.Cell = Cell;
ContentTable.HeaderCell = HeaderCell;
