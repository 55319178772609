import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../lib/styles/variables';

// TODO:: accessor名はAPIに合わせる
const columns = [
  { header: '', accessor: '', minWidth: 200, colSpan: 3 },
  { header: 'FX部門名（店舗コード）', accessor: 'accessor1', minWidth: 200 },
  { header: '借方ＦＸ科目コード', accessor: 'accessor2', minWidth: 200 },
  {
    header: '借方ＦＸ口座（補助）コード',
    accessor: 'accessor3',
    minWidth: 200,
  },
  { header: '借方ＦＸ取引先コード', accessor: 'accessor4', minWidth: 200 },
  { header: '貸方ＦＸ科目コード', accessor: 'accessor5', minWidth: 200 },
  { header: '貸方ＦＸ口座名（補助）', accessor: 'accessor6', minWidth: 200 },
  { header: '貸方ＦＸ取引先コード', accessor: 'accessor7', minWidth: 200 },
  { header: '課税区分', accessor: 'accessor8', minWidth: 200 },
  { header: '税率', accessor: 'accessor9', minWidth: 200 },
  { header: '事業区分', accessor: 'accessor10', minWidth: 200 },
];

const data = [
  {
    accessor1: `${
      columns.find((it) => it.accessor === 'accessor1')?.header
    }が入ります`,
    accessor2: `${
      columns.find((it) => it.accessor === 'accessor2')?.header
    }が入ります`,
    accessor3: `${
      columns.find((it) => it.accessor === 'accessor3')?.header
    }が入ります`,
    accessor4: `${
      columns.find((it) => it.accessor === 'accessor4')?.header
    }が入ります`,
    accessor5: `${
      columns.find((it) => it.accessor === 'accessor5')?.header
    }が入ります`,
    accessor6: `${
      columns.find((it) => it.accessor === 'accessor6')?.header
    }が入ります`,
    accessor7: `${
      columns.find((it) => it.accessor === 'accessor7')?.header
    }が入ります`,
    accessor8: `${
      columns.find((it) => it.accessor === 'accessor8')?.header
    }が入ります`,
    accessor9: `${
      columns.find((it) => it.accessor === 'accessor9')?.header
    }が入ります`,
    accessor10: `${
      columns.find((it) => it.accessor === 'accessor10')?.header
    }が入ります`,
  },
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

const Wrapper = styled.table`
  border: 1px solid #979797;
  border-collapse: collapse;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  border: 1px solid #979797;
  & td[rowspan] {
    text-align: center;
  }
  & td[rowspan].row-title {
    font-weight: bold;
  }
`;

const Th = styled.th<{
  minWidth: number | undefined;
}>`
  font-weight: bold;
  border: 1px solid #979797;
  background: ${Color.GRAY};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
  height: 38px;
  text-align: left;
  padding: 10px;
  font-size: 12px;
`;

const Td = styled.td<{
  minWidth: number | undefined;
}>`
  border: 1px solid #979797;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
  height: 38px;
  padding: 10px;
  font-size: 12px;
`;

const creditTdElm = [
  <>
    <Td className="row-title" minWidth={undefined} rowSpan={4}>
      クレジット
    </Td>
    <Td minWidth={undefined} rowSpan={1}>
      Airpay
    </Td>
  </>,
  <>
    <Td minWidth={undefined} rowSpan={1}>
      stera
    </Td>
  </>,
  <>
    <Td minWidth={undefined} rowSpan={1}>
      paypay
    </Td>
  </>,
  <>
    <Td minWidth={undefined} rowSpan={1}>
      linepay
    </Td>
  </>,
];

const pointElm = [
  <>
    <Td className="row-title" minWidth={undefined} rowSpan={4}>
      ポイント
    </Td>
    <Td minWidth={undefined} rowSpan={1}>
      リクルート
    </Td>
  </>,
  <>
    <Td minWidth={undefined} rowSpan={1}>
      楽天
    </Td>
  </>,
  <>
    <Td minWidth={undefined} rowSpan={1}>
      eParkポイント
    </Td>
  </>,
  <>
    <Td minWidth={undefined} rowSpan={1}>
      オズモール
    </Td>
  </>,
];

const rowTitles = [
  <>
    <Td className="row-title" minWidth={140} rowSpan={10}>
      技術売上預かり
    </Td>
    <Td className="row-title" minWidth={96} rowSpan={1}>
      現金
    </Td>
    <Td minWidth={401} rowSpan={1} />
  </>,
  ...creditTdElm,
  <>
    <Td className="row-title" minWidth={undefined} rowSpan={1}>
      お客様未払い
    </Td>
    <Td minWidth={undefined} rowSpan={1} />
  </>,
  ...pointElm,
  <>
    <Td className="row-title" minWidth={undefined} rowSpan={9}>
      技術売上
    </Td>
    <Td className="row-title" minWidth={undefined} rowSpan={1}>
      現金
    </Td>
    <Td minWidth={undefined} rowSpan={1} />
  </>,
  ...creditTdElm,
  ...pointElm,
  <>
    <Td className="row-title" minWidth={undefined} rowSpan={5}>
      物販売上
    </Td>
    <Td className="row-title" minWidth={undefined} rowSpan={1}>
      現金
    </Td>
    <Td minWidth={undefined} rowSpan={1} />
  </>,
  ...creditTdElm,
  <>
    <Td className="row-title" minWidth={undefined} rowSpan={5}>
      物販売上預かり
    </Td>
    <Td className="row-title" minWidth={undefined} rowSpan={1}>
      現金
    </Td>
    <Td minWidth={undefined} rowSpan={1} />
  </>,
  ...creditTdElm,
  <>
    <Td className="row-title" minWidth={undefined} rowSpan={5}>
      スポット利用者売上
    </Td>
    <Td className="row-title" minWidth={undefined} rowSpan={1}>
      現金
    </Td>
    <Td minWidth={undefined} rowSpan={1} />
  </>,
  ...creditTdElm,
];

export const FxLinkingTable = () => {
  const renderTbody = () =>
    data.map((item, index) => (
      <Tr key={index}>
        {rowTitles[index]}
        {Object.values(data[0]).map((it, i) => (
          <Td key={i} minWidth={undefined}>
            {it}
          </Td>
        ))}
      </Tr>
    ));
  return (
    <Wrapper>
      <Thead>
        <Tr>
          {columns.map((item) => (
            <Th
              key={item.accessor}
              minWidth={item.minWidth}
              colSpan={item.colSpan || 1}
            >
              {item.header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{renderTbody()}</Tbody>
    </Wrapper>
  );
};
