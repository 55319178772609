import React from 'react';
import styled from 'styled-components';
import dayjs, { getMonthOfDateList } from '../../../lib/utils/dayjs';

import { ShiftTableHeader } from './ShiftTableHeader';
import { ShiftTableLeftBar } from './ShiftTableLeftBar';
import { ShiftTableBody } from './ShiftTableBody';

const Wrapper = styled.div`
  display: flex;
`;

const ShiftTableArea = styled.div`
  overflow-x: scroll;
  border-top: 1px solid #979797;
`;

const stylistMockData = [
  {
    id: 1,
    name: 'フリー',
    canShiftCount: 1,
    schedules: [
      {
        startTime: '2019/07/24 9:00',
        endTime: '2019/07/24 10:30',
      },
    ],
    shift: [
      {
        shiftStartTime: '2020-08-01 9:00',
        shiftEndTime: '2020-08-01 16:00',
      },
      {
        shiftStartTime: '2020-08-02 9:00',
        shiftEndTime: '2020-08-02 16:00',
      },
    ],
  },
  {
    id: 2,
    name: '青空　一郎',
    canShiftCount: 1,
    schedules: [
      {
        startTime: '2019/07/24 9:00',
        endTime: '2019/07/24 10:00',
      },
      {
        startTime: '2019/07/24 10:00',
        endTime: '2019/07/24 11:00',
      },
    ],
    shift: [],
  },
  {
    id: 3,
    name: '青空　二郎',
    canShiftCount: 1,
    schedules: [
      {
        startTime: '2019/07/24 13:00',
        endTime: '2019/07/24 14:00',
      },
    ],
    shift: [],
  },
  {
    id: 4,
    name: '青空　三郎',
    canShiftCount: 1,
    schedules: [],
    shift: [],
  },
  {
    id: 5,
    name: '青空　四郎',
    canShiftCount: 1,
    schedules: [],
    shift: [],
  },
];

type Props = {
  day: dayjs.Dayjs;
};

export const ShiftTable: React.FC<Props> = ({ day }) => {
  const days = getMonthOfDateList(day);

  return (
    <Wrapper>
      <ShiftTableLeftBar stylists={stylistMockData} />
      <ShiftTableArea>
        <ShiftTableHeader days={days} />
        <ShiftTableBody days={days} stylists={stylistMockData} />
      </ShiftTableArea>
    </Wrapper>
  );
};
