import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ style: Props['style'] }>`
  box-shadow: ${({ style }) => style.boxShadow};
  width: ${({ style }) => (style.width ? `${style.width}px` : 'auto')};
  height: ${({ style }) => (style.height ? `${style.height}px` : 'auto')};
  border-radius: ${({ style }) =>
    style.borderRadius ? `${style.borderRadius}px` : 'auto'};
  min-height: ${({ style }) => (style.minHeight ? style.minHeight : 'auto')};
  min-width: ${({ style }) => (style.minWidth ? style.minWidth : 'auto')};
  margin: ${({ style }) => (style.margin ? style.margin : 0)};
  padding: ${({ style }) => (style.padding ? style.margin : 0)};
`;

type Props = {
  style: {
    boxShadow: string;
    width?: number;
    height?: number;
    minHeight?: string;
    minWidth?: string;
    borderRadius?: number;
    margin?: string;
    padding?: string;
  };
  handleClick?: () => void;
};

export const ShadedContainer: React.FC<Props> = ({
  children,
  style,
  handleClick,
}) => {
  return (
    <Wrapper style={style} onClick={handleClick}>
      {children}
    </Wrapper>
  );
};
