import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SalonMotPointForm } from '../parts/lv1/SalonMotPointForm';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { createSalonMotPoint } from '../../lib/api/salonMotPoints';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form``;

export const AddSalonMotPoint = () => {
  const history = useHistory();
  const { selectedSalon } = useContext(AccountContext);
  const methods = useForm();

  const onSubmit = methods.handleSubmit((input) => {
    if (!selectedSalon) return;
    const params = {
      date: input.date,
      point: input.point,
    };

    createSalonMotPoint(selectedSalon, params).then(({ data }) => {
      history.replace(`/store/mission/mot/edit/${data.id}`);
    });
  });

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="予算・目標・MOT点数" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Form onSubmit={onSubmit}>
          <SalonMotPointForm methods={methods} />
          <SquareButton
            fontSize={15}
            height={40}
            width={136}
            borderRadius={4}
            text="登録"
            color="BLUE"
            type="submit"
          />
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
