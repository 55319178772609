import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SideBarItem } from '../parts/lv3/SideBarItem';

type NavItemListType = {
  title: string;
  icon: string;
  links: { to: string; text: string }[];
  to?: string;
}[];

const navItemList: NavItemListType = [
  {
    title: '売上管理',
    icon: 'profit',
    links: [
      { to: '/per-shop', text: '店舗毎売上と入出金' },
      { to: '/', text: '実績' },
      { to: '/achievement', text: '全店予実一覧' },
    ],
  },
  {
    title: '予約管理',
    icon: 'booking',
    links: [
      { to: '/reservation', text: '予約一覧' },
      { to: '/shift', text: 'シフト調整' },
    ],
  },
  {
    title: '店舗管理',
    icon: 'store',
    links: [
      { to: '/store', text: '店舗一覧' },
      { to: '/store/add', text: '店舗追加' },
      { to: '/store/settings', text: '設備設定' },
      { to: '/store/brand', text: 'ブランド' },
      { to: '/store/date', text: '営業時間設定' },
      { to: '/store/mission', text: '予算・目標設定' },
    ],
  },
  {
    title: '顧客管理',
    icon: 'customer',
    links: [],
    to: '/customer',
  },
  {
    title: 'EC管理',
    icon: 'ec',
    links: [
      { to: '/ec/products', text: 'EC商品一覧' },
      { to: '/ec/category', text: 'カテゴリ' },
      { to: '/ec/carousel', text: 'カルーセル' },
    ],
  },
  {
    title: '管理者設定',
    icon: 'admin',
    links: [],
    to: '/admin',
  },
  {
    title: 'コンテンツ管理',
    icon: 'content',
    links: [
      { to: '/content/point', text: 'ポイント' },
      { to: '/content/coupon/shop', text: 'クーポン' },
      { to: '/content/material', text: '材料' },
      { to: '/content/message/customer', text: 'メッセージ' },
      { to: '/content/questionnaire/customer', text: 'アンケート' },
      { to: '/content/topics', text: 'ブログ' },
    ],
  },
  {
    title: '経理管理',
    icon: 'accounting',
    links: [
      // TODO:: 削除。「to」フィールドがmapのkeyになっているので、一旦適当なパラメーターを付与
      { to: '/accounting', text: '請求書一覧' },
      { to: '/accounting/expense', text: '経費一覧' },
      { to: '/accounting/fx-linking', text: '会計ソフトFX連携' },
      { to: '/accounting/grade-rate', text: 'グレード・掛け率' },
      { to: '/accounting/post-rate', text: '役職・分配率' },
      { to: '/accounting/commission/regular', text: '歩合給・賞与' },
      { to: '/accounting/record/sales', text: '計上科目' },
      { to: '/?6', text: 'EC売上 ' },
    ],
  },
];

const Wrapper = styled.div`
  min-width: 294px;
  display: inline-block;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  z-index: 1;
  position: fixed;
  top: 88px;
  bottom: 0;
  right: 0;
  overflow: scroll;
`;

type Props = {};

export const SideBar: React.FC<Props> = () => (
  <Wrapper>
    {navItemList.map((navItem) => (
      <SideBarItem
        icon={navItem.icon}
        title={navItem.title}
        to={navItem.to}
        key={navItem.icon}
        numberOfLink={navItem.links.length}
      >
        {navItem.links.map((link, _) => {
          return (
            <Link to={link.to} key={link.to}>
              {link.text}
            </Link>
          );
        })}
      </SideBarItem>
    ))}
  </Wrapper>
);
