import apiBase from './apiBase';

export type Facility = {
  salon_id: number;
  seq: number;
  name: string;
  category_id: number;
  category_name: string;
  acceptable_number: number;
  spot_user_acceptable_number: number;
};

export type UpdateFacilityParams = {
  salon_id: number;
  seq: number;
  name: string;
  service_category_id: number;
  acceptable_number: number;
  spot_user_acceptable_number: number;
};

export const getFacilities = (id: number) =>
  apiBase.get<Facility[]>(`/salon/${id}/facility`);

export const updateFacilities = (id: number, params: UpdateFacilityParams[]) =>
  apiBase.put<Facility[]>(`/salon/${id}/facility`, params);
