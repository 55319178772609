import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { FontSize, Color } from '../../lib/styles/variables';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import {
  createQuestionary,
  QuestionaryType,
  searchQuestionary,
} from '../../lib/api/questionaries';
import { ValidateErrorMessageList } from '../parts/lv3/ValidationErrorMessage';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
  max-height: calc(100vh - 88px);
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridWrapper = styled.div`
  display: grid;
  grid:
    'name nameField' 42px
    'url urlField' 42px
    'point pointField' 42px
    'other otherField' 42px
    / 147px 1fr;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

const TableWrapper = styled.div`
  margin: 20px 0;

  > p {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 6px;
  }
`;

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const TextField = styled.input<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '170px' : '643px')};
  height: 22px;
  background: #ffffff;
  border: 1px solid #979797;
  margin-left: 10px;
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  width: 296px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const SmallText = styled.div`
  font-size: 10px;
  line-height: 18px;
  color: #212121;
  margin-left: 10px;
`;

export const CreateQuestionnaireCustomer = () => {
  const history = useHistory();
  const location = useLocation();
  const ids = new URLSearchParams(location.search).get('id');
  const [users, setUsers] = useState<QuestionaryType[]>([]);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      title: '',
      url: '',
      remarks: '',
      grant_point: '',
    },
  });

  const columns = [
    { header: 'お客様名（カナ）', accessor: 'kana_name', width: 150 },
    { header: 'お客様名', accessor: 'kanji_name', width: 150 },
    { header: '性別', accessor: 'sex', width: 55 },
    { header: '生年月日', accessor: 'birth_day', width: 97 },
    { header: '来店回数', accessor: 'visit_count', width: 97 },
    { header: '前回来店日', accessor: 'last_visit_date', width: 97 },
  ];

  useEffect(() => {
    searchQuestionary({})
      .then(({ data }) => {
        const userIdList = ids?.split(',') ?? [];
        setUsers(
          data.filter((it) => userIdList.some((userId) => +userId === it.id)),
        );
      })
      .catch((e) => {
        setErrorMessageList(['エラーが発生しました']);
      });
  }, []);

  const onSubmit = handleSubmit((value) => {
    const userIdList = ids?.split(',') ?? [];
    const params = {
      ...value,
      target: {
        user_ids: userIdList,
      },
    };
    createQuestionary(params)
      .then(() => {
        history.push('/content/questionnaire/customer');
      })
      .catch((e) => {
        setErrorMessageList(['エラーが発生しました']);
      });
  });

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="アンケート作成" />
      </Margin>
      <GridWrapper>
        <GridItem gridArea="name">
          <Label>アンケート名</Label>
        </GridItem>
        <GridItem gridArea="nameField" isRightmost>
          <TextField
            name="title"
            ref={register({ required: 'アンケート名は必須項目です' })}
            placeholder=""
          />
          <SmallText>管理画面での名称で公開画面には表示されません</SmallText>
        </GridItem>

        <GridItem gridArea="url">
          <Label>URL</Label>
        </GridItem>
        <GridItem gridArea="urlField" isRightmost>
          <TextField
            name="url"
            ref={register({ required: 'URLは必須項目です' })}
            placeholder=""
          />
        </GridItem>

        <GridItem gridArea="point">
          <Label>付与ポイント</Label>
        </GridItem>
        <GridItem gridArea="pointField" isRightmost placeholder="">
          <TextField
            name="grant_point"
            ref={register({
              required: '付与ポイントは必須項目です',
              pattern: {
                message: '付与ポイントは半角数値で入力してください',
                value: /[0-9]+/,
              },
            })}
            isSmall
          />
        </GridItem>

        <GridItem gridArea="other" isBottom>
          <Label>備考</Label>
        </GridItem>
        <GridItem gridArea="otherField" isRightmost isBottom placeholder="">
          <TextField
            name="remarks"
            ref={register({ required: '備考は必須項目です' })}
          />
        </GridItem>
      </GridWrapper>
      <ValidateErrorMessageList
        errors={errors}
        errorMessageList={errorMessageList}
      />
      <ButtonWrapper>
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="登録"
          color="BLUE"
          borderRadius={4}
          handleClick={onSubmit}
        />
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="削除"
          color="RED"
          borderRadius={4}
          handleClick={() =>
            history.push('/content/questionnaire/customer/filter')
          }
        />
      </ButtonWrapper>

      <Margin margin="40px 0 12px 0">
        <MainContentTitle text="対象ユーザー" />
      </Margin>
      <TableWrapper>
        <p>
          件数：
          {users.length}
          件
        </p>
        <Table columns={columns} data={users} />
      </TableWrapper>
    </Wrapper>
  );
};
