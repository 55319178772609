import React, { useState } from 'react';
import styled from 'styled-components';
import { SpinnerCircular } from 'spinners-react';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner: React.FC = () => {
  return (
    <Wrapper>
      <SpinnerCircular
        size={100}
        thickness={100}
        speed={200}
        color="#4A90E2"
        secondaryColor="rgba(255, 255, 255, 1)"
      />
    </Wrapper>
  );
};

export const useSpinner = () => {
  const [loading, setLoading] = useState<typeof Spinner | null>(null);

  const finishLoading = () => {
    setLoading(null);
  };

  const startLoading = () => {
    setLoading(Spinner);
  };

  return { loading, startLoading, finishLoading };
};
