import React, { useState } from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { ShiftTable } from '../parts/shift/ShiftTable';
import dayjs from '../../lib/utils/dayjs';
import { ShiftActions } from '../parts/shift/ShiftActions';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const ShiftArea = styled.div``;

const MonthSelectArea = styled.div`
  display: flex;
`;
const MonthSelectBtn = styled.div<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive
      ? `
  background: #212121;
  color: #FFFFFF;
`
      : ''}
  border-radius: 4px;
  border: 1px solid #212121;
  border-radius: 4px;
  padding: 8px 16px;
  width: 140px;
  margin-right: 16px;
`;

export const Shift = () => {
  const current = dayjs();
  // TODO:: 変更
  const [day, setDay] = useState(current);
  const showMonth = 6;

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="シフト調整" />
      </Margin>
      <ShiftArea>
        <MonthSelectArea>
          {Array(showMonth)
            .fill(0)
            .map((_, i) => (
              <MonthSelectBtn
                isActive={
                  day.format('YYYY-MM') ===
                  current.add(i, 'month').format('YYYY-MM')
                }
                key={i}
                onClick={() => setDay(current.add(i, 'month'))}
              >
                {current.add(i, 'month').format('YYYY年MM月')}
              </MonthSelectBtn>
            ))}
        </MonthSelectArea>
        <ShiftActions day={day} />
        <ShiftTable day={day} />
      </ShiftArea>
    </Wrapper>
  );
};
