import React, { useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Routes } from './Routes';
import { AccountContext } from './lib/contexts/AccountContext';
import { ModalProvider } from './components/provider/ModalProvider';

ReactModal.setAppElement('#root');

type AppProps = {};

const App: React.FC<AppProps> = () => {
  const { mountAccount } = useContext(AccountContext);

  useEffect(() => {
    mountAccount();
  }, []);

  return (
    <ModalProvider>
      <Routes />
    </ModalProvider>
  );
};

export default App;
