/* eslint react/display-name: 0 */

import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Table } from '../parts/lv1/Table';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SelectBox } from '../parts/lv3/SelectBox';
import { ModalContext } from '../../lib/contexts/ModalContext';
import { AddProductModal } from '../parts/lv1/AddProductModal';
import { ProductDescriptionModal } from '../parts/lv1/ProductDescriptionModal';
import { ProductPublishModal } from '../parts/lv1/ProductPublishModal';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBox = styled.div`
  display: flex;
  border-bottom: 1px solid #4a90e2;
`;

const Img = styled.img``;

const TextField = styled.input`
  width: 343px;
  height: 30px;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1.36087px;
  color: #bdbdbd;
  border: none;
  margin-left: 8px;
`;

const StyledButton = styled.button`
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a90e2;
  background: #fff;
`;

export const EcProducts = () => {
  const [query, setQuery] = useState('');
  const [isPublic, setIsPublic] = useState<string[]>(() => {
    const array: any[] = [];
    [1].map(() => array.push('private'));

    return array;
  });

  const { setModal, closeModal } = useContext(ModalContext);

  const EnhancedButton = ({
    accessor,
  }: {
    accessor: 'detail' | 'description' | 'publish';
  }) => {
    return (
      <StyledButton
        onClick={() =>
          setModal({
            ContentComponent: () => {
              if (accessor === 'detail') {
                return <AddProductModal isEdit />;
              }
              if (accessor === 'description') {
                return <ProductDescriptionModal closeModal={closeModal} />;
              }
              if (accessor === 'publish') {
                return <ProductPublishModal closeModal={closeModal} />;
              }
              return <AddProductModal />;
            },
            isExistCloseButton: true,
          })
        }
      >
        編集
      </StyledButton>
    );
  };

  const EnhancedSelectBox = ({ index }: { index: number }) => {
    return (
      <SelectBox
        control={{
          type: 'state',
          value: isPublic[index],
          onChange: (v: string) => {
            const nextArray = isPublic.concat();
            nextArray[index] = v;
            setIsPublic(nextArray);
          },
        }}
        style={{
          width: '70px',
          fontSize: '12px',
          height: '18px',
          border: 'bottom',
        }}
      >
        <option value="public">公開</option>
        <option value="private">非公開</option>
      </SelectBox>
    );
  };

  const columns = [
    { header: '商品名', accessor: 'name', width: 319 },
    { header: 'カテゴリー', accessor: 'category', width: 91 },
    { header: '販売価格', accessor: 'sellingPrice', width: 98 },
    { header: '希望価格', accessor: 'askingPrice', width: 81 },
    { header: 'メーカー', accessor: 'maker', width: 78 },
    { header: '内容量', accessor: 'capacity', width: 105 },
    { header: '商品詳細', accessor: 'detail', width: 65 },
    { header: '商品説明', accessor: 'description', width: 68 },
    { header: '掲載', accessor: 'publish', width: 60 },
    { header: '公開', accessor: 'release', width: 76 },
  ];

  const data = [
    {
      name: 'アリミノ メン フリーズキープグリース',
      category: 'ワックス',
      sellingPrice: '10,000円',
      askingPrice: '10,000円',
      maker: 'アリミノ',
      capacity: '100 g',
      detail: <EnhancedButton accessor="detail" />,
      description: <EnhancedButton accessor="description" />,
      publish: <EnhancedButton accessor="publish" />,
      release: <EnhancedSelectBox index={0} />,
    },
  ];

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="EC商品一覧" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '32px 25px 40px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <TopContent>
          <SearchBox>
            <Img
              src={`${process.env.PUBLIC_URL}/assets/search.svg`}
              alt="search_icon"
            />
            <TextField
              value={query}
              onChange={(e: any) => setQuery(e.target.value)}
              placeholder="検索（商品名/カテゴリ/ブランド）"
            />
          </SearchBox>
          <SquareButton
            text="EC商品の追加"
            color="BLUE"
            width={136}
            height={40}
            borderRadius={4}
            fontSize={15}
            handleClick={() =>
              setModal({
                ContentComponent: () => <AddProductModal />,
                isExistCloseButton: true,
              })
            }
          />
        </TopContent>

        <Table columns={columns} data={data} />
      </ShadedContainer>
    </Wrapper>
  );
};
