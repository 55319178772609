/* eslint react/display-name: 0 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { getMessagesToStylist, Message } from '../../lib/api/message';
import { usePager } from '../../lib/hooks/usePager';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MessageStylist = () => {
  const history = useHistory();
  const [messages, setMessages] = useState<Message[] | null>(null);

  const handlePagenation = () => {
    getMessagesToStylist().then(({ data }) => {
      setMessages(data.data);
      setLastPage(data.last_page);
    });
  };

  const { Pager, setLastPage } = usePager(handlePagenation);

  const links = [
    { name: 'お客様', to: '/content/message/customer', isselected: false },
    { name: '従業員', to: '/content/message/stylist', isselected: true },
  ];

  const columns = [
    { header: '件名', accessor: 'name', width: 665 },
    { header: '配信人数', accessor: 'targetCount', width: 68 },
    { header: '送信日時', accessor: 'date', width: 136 },
    { header: '送信済', accessor: 'isSend', width: 60 },
  ];

  const makeData = (value: Message[]) => {
    return value.map((message) => ({
      name: message.title,
      targetCount: message.sent_amount,
      date: message.send_at,
      isSend: message.is_sent ? '送信済み' : '',
    }));
  };

  useEffect(() => {
    getMessagesToStylist().then(({ data }) => {
      setMessages(data.data);
      setLastPage(data.last_page);
    });
  }, []);

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="メッセージ" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <Margin margin="24px 0 32px 0">
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="新規作成"
          borderRadius={4}
          color="BLUE"
          handleClick={() => history.push('/content/message/stylist/filter')}
        />
      </Margin>
      {messages ? <Table columns={columns} data={makeData(messages)} /> : null}
      <Pager />
    </Wrapper>
  );
};
