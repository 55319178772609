import React from 'react';
import styled from 'styled-components';

const ScrollWrapper = styled.div`
  margin-top: 88px;
  margin-right: 294px;
  overflow-x: scroll;
`;

const ScrollInner = styled.div`
  display: inline-block;
  min-width: 1146px;
  width: 100%;
`;

const MainContent = styled.div`
  display: inline-block;
  width: 100%;
`;

type Props = {};

export const MainContainer: React.FC<Props> = ({ children }) => (
  <ScrollWrapper>
    <ScrollInner>
      <MainContent>{children}</MainContent>
    </ScrollInner>
  </ScrollWrapper>
);
