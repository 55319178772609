import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const CustomInput = styled.input`
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #979797;

  ::placeholder {
    color: #bdbdbd;
  }
`;

export const ReservationInputText: FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
}> = ({ onChange, value, placeholder }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <CustomInput
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={value || ''}
      />
    </Wrapper>
  );
};
