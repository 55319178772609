/* eslint consistent-return: off */
import React, { useState, useCallback } from 'react';
import { AccountContext } from '../../lib/contexts/AccountContext';
import {
  AccountType,
  login as loginRequest,
  getMe,
  AuthParams,
  UpdateProfileParams,
  updateProfile,
} from '../../lib/api/auth';
import storage from '../../lib/storage';

export const AccountProvider: React.FC = ({ children }) => {
  const [account, setAccount] = useState<AccountType | null>(null);
  const [isMountAccount, setIsMountAccount] = useState<boolean>(false);
  const [selectedSalon, setSelectedSalon] = useState<number | null>(null);

  const mountAccount = () => {
    const token = storage.getItem('access_token');
    const salonId = storage.getItem('selected_salon');

    if (salonId) {
      setSelectedSalon(parseInt(salonId, 10));
    } else {
      setSelectedSalon(null);
    }

    if (token !== undefined && token !== null) {
      return getMe()
        .then(({ data }) => {
          setAccount(data);
        })
        .finally(() => {
          setIsMountAccount(true);
        });
    }
    setIsMountAccount(true);
  };

  const login = (params: AuthParams) =>
    loginRequest(params).then(({ data }) => {
      storage.setItem('access_token', data.token.access_token);
      setAccount(data.profile);
    });

  const logout = (): void => {
    setAccount(null);
    storage.removeItem('access_token');
    setSelectedSalon(null);
    storage.removeItem('selected_salon');
  };

  const updateAccount = (params: UpdateProfileParams) =>
    updateProfile(params).then(({ data }) => {
      setAccount(data);
    });

  const selectSalon = useCallback((salonId: number) => {
    storage.setItem('selected_salon', String(salonId));
    setSelectedSalon(salonId);
  }, []);

  return (
    <AccountContext.Provider
      value={{
        account,
        isMountAccount,
        selectedSalon,
        login,
        logout,
        mountAccount,
        updateAccount,
        selectSalon,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
