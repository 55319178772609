import React from 'react';
import styled, { css } from 'styled-components';
import dayjs, {
  ShiftTimeHeight,
  ShiftCellWidth,
} from '../../../lib/utils/dayjs';

const Wrapper = styled.div``;
const Area = css`
  display: flex;
`;
const DateArea = styled.div`
  ${Area}
  height: ${ShiftTimeHeight};
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  min-width: ${ShiftCellWidth};
  flex-flow: column;
`;

enum ECellStyle {
  Saturday,
  Sunday,
  Other,
}

const getColor = (style: ECellStyle) => {
  switch (style) {
    case ECellStyle.Saturday:
      return '#4A90E2';
    case ECellStyle.Sunday:
      return '#E24A4A';
    default:
      return '#212121';
  }
};

const getCellStyle = (day: dayjs.Dayjs) => {
  switch (day.format('d')) {
    case '0':
      return ECellStyle.Sunday;
    case '6':
      return ECellStyle.Saturday;
    default:
      return ECellStyle.Other;
  }
};

const Day = styled.div<{ cellStyle: ECellStyle }>`
  color: ${({ cellStyle }) => getColor(cellStyle)};
  font-weight: bold;
  font-size: 14px;
`;
const Time = styled.div<{ cellStyle: ECellStyle }>`
  font-size: 10px;
  color: ${({ cellStyle }) => getColor(cellStyle)};
  font-weight: 500;
`;

type Props = {
  days: dayjs.Dayjs[];
};

export const ShiftTableHeader: React.FC<Props> = ({ days }) => {
  const times = days.map((it) => (
    <Date key={`time-${it.format('DD')}`}>
      <Day cellStyle={getCellStyle(it)}>{it.format('DD(ddd)')}</Day>
      {/* TODO:: 変更 */}
      <Time cellStyle={getCellStyle(it)}>09:00-23:00</Time>
    </Date>
  ));

  return (
    <Wrapper>
      <DateArea>{times}</DateArea>
    </Wrapper>
  );
};
