import React, { useState } from 'react';
import styled from 'styled-components';
import { SquareButton } from '../lv3/SquareButton';
import { PerShopSearch } from '../../pages/PerShop';
import { CheckBoxWithText } from '../lv3/CheckBoxWithText';
import { getToggle } from '../../../lib/utils/array';

const Wrapper = styled.form``;
const SearchTitleArea = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

const SearchTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #212121;
  margin-right: 16px;
`;

const SearchWrapper = styled.div`
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 19px 16px;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-flow: wrap;
  & > div:not(:last-child) {
    margin-right: 72px;
  }
`;

const mockShops = Array.from({ length: 18 }, (_, i) => ({
  id: i,
  name: ['渋谷', '新宿'][i % 2],
}));

type Props = {
  setSearch: React.Dispatch<React.SetStateAction<PerShopSearch>>;
};

export const PerShopSearchForm: React.FC<Props> = ({ setSearch }) => {
  const [selectedShopIds, setSelectedShopIds] = useState<number[]>([]);
  const onSearch = () => {
    setSearch({
      shopNames: mockShops
        .filter((it) => selectedShopIds.includes(it.id))
        .map((it) => it.name),
    });
  };
  const toggle = (id: number) => () => {
    setSelectedShopIds(getToggle(id, selectedShopIds));
  };

  const isAllChecked = mockShops.length === selectedShopIds.length;
  const allCheck = () => {
    if (isAllChecked) {
      setSelectedShopIds([]);
    } else {
      setSelectedShopIds(mockShops.map((it) => it.id));
    }
  };

  return (
    <Wrapper>
      <SearchTitleArea>
        <SearchTitle>店舗選択</SearchTitle>
        <CheckBoxWithText
          text="全てチェックする"
          size={{ height: 24 }}
          isChecked={isAllChecked}
          handleClick={allCheck}
        />
      </SearchTitleArea>
      <SearchWrapper>
        {mockShops.map((it) => (
          <CheckBoxWithText
            key={it.id}
            text={it.name}
            size={{ height: 24 }}
            handleClick={toggle(it.id)}
            isChecked={selectedShopIds.includes(it.id)}
          />
        ))}
      </SearchWrapper>

      <SquareButton
        fontSize={15}
        width={136}
        height={40}
        borderRadius={4}
        color="BLUE_BORDER"
        text="検索"
        handleClick={onSearch}
      />
    </Wrapper>
  );
};
