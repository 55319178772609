import apiBase from './apiBase';
import getQueryParams from '../utils/getQueryParams';

type GetTopicsParams = {
  brand_name: string;
  salon_name: string;
  from_date: string;
  to_date: string;
};

export type TopicDescription = {
  image?: {
    base64: string;
  };
  image_url?: string;
  body: string;
};

export type Topic = {
  id: number;
  title: string;
  is_public: number;
  created_at: string;
  salon_name: string;
  salon_id: number;
  product_id: number;
  brand_name: string;
  description: TopicDescription[];
};

type GetTopicsRes = {
  data: Topic[];
};

export const getTopics = (params: Partial<GetTopicsParams>) =>
  apiBase.get<GetTopicsRes>(`/topics?${getQueryParams(params)}`);

export const getTopicInfo = (id: string) => apiBase.get<Topic>(`/topic/${id}`);

type PostTopic = {
  title: string;
  product_id: number;
  salon_id: number;
  brand_id: number;
  descriptions: TopicDescription[];
};
export const createTopic = (params: PostTopic) =>
  apiBase.post<GetTopicsRes>(`/topic`, params);

export const updateTopic = (id: string, params: PostTopic) =>
  apiBase.put<GetTopicsRes>(`/topic/${id}`, params);
