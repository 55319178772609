import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { Color, FontSize } from '../../lib/styles/variables';
import { CheckBoxWithText } from '../parts/lv3/CheckBoxWithText';
import { SelectBox } from '../parts/lv3/SelectBox';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const TopGridWrapper = styled.div`
  height: 270px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  grid-template-rows: 1fr 1fr 2.5fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas:
    'reservation reservationField status statusField'
    'staff staffField ReservedRoute ReservedRouteField'
    'visitDate visitDateField visitDateField visitDateField'
    'coupon couponField couponField couponField'
    'menu menuField menuField menuField';
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isLeft?: boolean;
  isTop?: boolean;
  isBottom?: boolean;
};

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-top: ${({ isTop }) => (isTop ? '1px solid #979797' : 'none')};
  border-left: ${({ isLeft }) => (isLeft ? '1px solid #979797' : 'none')};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const VisitDateField = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: 'visitDateFieldLeft visitDateFieldRight';
  grid-area: visitDateField;
`;

const VisitDateFieldLeft = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas:
    'visitDateFieldDate .'
    'visitDateFieldStartDate visitDateFieldEndDate'
    'visitDateFieldTimeRequired whitespace';
  grid-area: visitDateFieldLeft;
`;

const TextField = styled.input<{ isLong?: boolean }>`
  border: 1px solid #979797;
  margin: 0 10px;
  width: ${({ isLong }) => (isLong ? '594px' : '170px')};
  height: 22px;
`;

const RequestTextField = styled.textarea`
  border: 1px solid #979797;
  width: 100%;
  height: 152px;
  resize: none;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const TitleLabel = styled.div`
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  margin: 10px;
`;

const SubTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  margin: 20px 0;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 48px;
  margin-bottom: 40px;
`;

const Question = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
`;

const Answer = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
`;

const ReservationInformationGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 2.5fr;
  gap: 1px 1px;
  grid-template-areas:
    'customerId customerIdField customerIdField customerIdField'
    'customerNameKana customerNameKanaField customerName customerNameField'
    'tel telField sex sexField'
    'visitCount visitCountField visitCountField visitCountField'
    'customerMemo customerMemoField customerMemoField customerMemoField'
    'treatmentInformation treatmentInformationField treatmentInformationField treatmentInformationField';
`;

const PaymentInformationGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas:
    'usagePoint usagePointField'
    'totalFee totalFeeField'
    'paymentAmount paymentAmountField'
    'plannedPoint plannedPointField';
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: 'update cancel . . repair';
`;

const UpdateButton = styled.button`
  width: 160px;
  height: 56px;
  background: #4a90e2;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1.63304px;
  color: #ffffff;
`;

const CancelButton = styled.button`
  width: 160px;
  height: 56px;
  background: #e24a4a;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1.63304px;
  color: #ffffff;
`;

const RepairButton = styled.button`
  width: 160px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #212121;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1.63304px;
  color: #212121;
`;

const Required = styled.span`
  color: ${Color.RED};
  font-weight: bold;
`;

const WrapperTableLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OpenCustomerDetail = styled.button`
  background: none;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.63304px;
  color: #4a90e2;
`;

export const CustomerReservationDetail = () => {
  const history = useHistory();
  const { id, customerId } = useParams();

  const [coupon, setCoupon] = useState<string>('');

  const links = [
    {
      name: '予約情報',
      to: `/customer/${id}/reservation/${id}`,
      isselected: true,
    },
    {
      name: 'カルテ',
      to: `/customer/${id}/reservation/${id}/karte`,
      isselected: false,
    },
  ];

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickOpenCustomerDetail = useCallback(() => {
    history.push(`/reservation/${id}/customer/${customerId}`);
  }, [history, id, customerId]);

  return (
    <Wrapper>
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={handleClickArrowLeft}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text="予約状況" />
        </Margin>
        <Navigation links={links} isSmall />
      </TopContent>
      <WrapperShadow>
        <Margin margin="0 0 8px 0">
          <WrapperTableLabel>
            <TitleLabel>
              予約情報
              <Required> ※必須項目</Required>
            </TitleLabel>
            <OpenCustomerDetail onClick={handleClickOpenCustomerDetail}>
              顧客情報を開く
            </OpenCustomerDetail>
          </WrapperTableLabel>
        </Margin>
        <TopGridWrapper>
          <GridItem gridArea="reservation" isTop isLeft>
            <Label>予約状況</Label>
          </GridItem>
          <GridItem gridArea="reservationField" isTop>
            <Text>B431940637</Text>
          </GridItem>
          <GridItem gridArea="status" isTop>
            <Label>ステータス</Label>
          </GridItem>
          <GridItem gridArea="statusField" isTop>
            <Text>予約</Text>
          </GridItem>
          <GridItem gridArea="staff" isLeft>
            <Label>スタッフ</Label>
          </GridItem>
          <GridItem gridArea="staffField">
            <TextField name="staff" />
            <CheckBoxWithText
              text="指名予約"
              id="staff"
              size={{ width: 100, height: 30 }}
            />
          </GridItem>
          <GridItem gridArea="ReservedRoute">
            <Label>予約経路</Label>
          </GridItem>
          <GridItem gridArea="ReservedRouteField">
            <Text>予約経路</Text>
          </GridItem>
          <GridItem gridArea="visitDate" isLeft>
            <Label>来店日時</Label>
          </GridItem>
          <VisitDateField gridArea="visitDateField">
            <VisitDateFieldLeft gridArea="visitDateFieldLeft">
              <GridItem gridArea="visitDateFieldDate" isBottom isRightmost>
                <TextField name="visitDateFieldDate" />
              </GridItem>
              <GridItem gridArea="visitDateFieldStartDate" isBottom isRightmost>
                <Text>開始時間：</Text>
                <SelectBox
                  control={{
                    type: 'state',
                    value: coupon,
                    onChange: (v) => setCoupon(v),
                  }}
                  style={{
                    width: '120px',
                    fontSize: '12px',
                    height: '22px',
                    border: 'bottom',
                    margin: '0 0 0 10px',
                  }}
                >
                  <option value="">選択してください</option>
                  <option value="elenor">11:00</option>
                </SelectBox>
              </GridItem>
              <GridItem gridArea="visitDateFieldEndDate" isBottom isRightmost>
                <Text>終了時間：</Text>
                <Text>13：00</Text>
              </GridItem>
              <GridItem gridArea="visitDateFieldTimeRequired" isRightmost>
                <Text>所要時間：</Text>
                <SelectBox
                  control={{
                    type: 'state',
                    value: coupon,
                    onChange: (v) => setCoupon(v),
                  }}
                  style={{
                    width: '120px',
                    fontSize: '12px',
                    height: '22px',
                    border: 'bottom',
                    margin: '0 0 0 10px',
                  }}
                >
                  <option value="">選択してください</option>
                  <option value="elenor">90分</option>
                </SelectBox>
              </GridItem>
              <GridItem gridArea="whitespace" isRightmost />
            </VisitDateFieldLeft>
            <GridItem gridArea="visitDateFieldRight" />
          </VisitDateField>
          <GridItem gridArea="coupon" isLeft>
            <Label>クーポン</Label>
          </GridItem>
          <GridItem gridArea="couponField">
            <SelectBox
              control={{
                type: 'state',
                value: coupon,
                onChange: (v) => setCoupon(v),
              }}
              style={{
                width: '530px',
                fontSize: '12px',
                height: '22px',
                border: 'bottom',
                margin: '0 0 0 10px',
              }}
            >
              <option value="">選択してください</option>
              <option value="elenor">
                カット＋オーガニックリタッチカラー￥4000
              </option>
              <option value="elenor">
                カット＋オーガニックリタッチカラー￥4000
              </option>
              <option value="elenor">
                カット＋オーガニックリタッチカラー￥4000
              </option>
            </SelectBox>
          </GridItem>
          <GridItem gridArea="menu" isLeft>
            <Label>メニュー</Label>
          </GridItem>
          <GridItem gridArea="menuField">
            <SelectBox
              control={{
                type: 'state',
                value: coupon,
                onChange: (v) => setCoupon(v),
              }}
              style={{
                width: '260',
                fontSize: '12px',
                height: '22px',
                border: 'bottom',
                margin: '0 0 0 10px',
              }}
            >
              <option value="">カテゴリを選択</option>
              <option value="elenor">
                カット＋オーガニックリタッチカラー￥4000
              </option>
            </SelectBox>
            <SelectBox
              control={{
                type: 'state',
                value: coupon,
                onChange: (v) => setCoupon(v),
              }}
              style={{
                width: '260',
                fontSize: '12px',
                height: '22px',
                border: 'bottom',
                margin: '0 0 0 10px',
              }}
            >
              <option value="">メニューを選択</option>
              <option value="elenor">
                カット＋オーガニックリタッチカラー￥4000
              </option>
            </SelectBox>
          </GridItem>
        </TopGridWrapper>
        <SubTitle>予約者情報</SubTitle>
        <ReservationInformationGridWrapper>
          <GridItem gridArea="customerId" isTop isLeft>
            <Label>お客様ID</Label>
          </GridItem>
          <GridItem gridArea="customerIdField" isTop>
            <Text>123456</Text>
          </GridItem>
          <GridItem gridArea="customerNameKana" isLeft>
            <Label>お客様名（カナ）</Label>
          </GridItem>
          <GridItem gridArea="customerNameKanaField">
            <Text>マルヤマサトコ</Text>
          </GridItem>
          <GridItem gridArea="customerName">
            <Label>お客様名</Label>
          </GridItem>
          <GridItem gridArea="customerNameField">
            <Text>丸山聡子</Text>
          </GridItem>
          <GridItem gridArea="tel" isLeft>
            <Label>電話番号</Label>
          </GridItem>
          <GridItem gridArea="telField">
            <Text>0901234567</Text>
          </GridItem>
          <GridItem gridArea="sex">
            <Label>性別</Label>
          </GridItem>
          <GridItem gridArea="sexField">
            <Text>女性</Text>
          </GridItem>
          <GridItem gridArea="visitCount" isLeft>
            <Label>来店回数</Label>
          </GridItem>
          <GridItem gridArea="visitCountField">
            <Text>54回</Text>
          </GridItem>
          <GridItem gridArea="customerMemo" isLeft>
            <Label>お客様メモ</Label>
          </GridItem>
          <GridItem gridArea="customerMemoField">
            <Text>
              ああああああああああああああああああああああああああああああ
            </Text>
          </GridItem>
          <GridItem gridArea="treatmentInformation" isLeft>
            <Label>前回の施術情報</Label>
          </GridItem>
          <GridItem gridArea="treatmentInformationField">
            <Text>【来店日時】2020年1月12日（日）</Text>
          </GridItem>
        </ReservationInformationGridWrapper>
        <SubTitle>お支払い情報</SubTitle>
        <PaymentInformationGridWrapper>
          <GridItem gridArea="usagePoint" isLeft isTop>
            <Label>利用ポイント</Label>
          </GridItem>
          <GridItem gridArea="usagePointField" isTop>
            <Text>利用なし</Text>
          </GridItem>
          <GridItem gridArea="totalFee" isLeft>
            <Label>合計金額</Label>
          </GridItem>
          <GridItem gridArea="totalFeeField">
            <Text>4.000円</Text>
          </GridItem>
          <GridItem gridArea="paymentAmount" isLeft>
            <Label>お支払い予定金額</Label>
          </GridItem>
          <GridItem gridArea="paymentAmountField">
            <Text>4.000円</Text>
          </GridItem>
          <GridItem gridArea="plannedPoint" isLeft>
            <Label>付与予定ポイント</Label>
          </GridItem>
          <GridItem gridArea="plannedPointField">
            <Text>81</Text>
          </GridItem>
        </PaymentInformationGridWrapper>
        <SubTitle>要望</SubTitle>
        <RequestTextField />
        <SubTitle>質問・回答</SubTitle>
        <QuestionContainer>
          <Question>当店のご利用は初めてですか？</Question>
          <Answer>いいえ</Answer>
        </QuestionContainer>
        <QuestionContainer>
          <Question>ショートカットは好きですか</Question>
          <Answer>はい</Answer>
        </QuestionContainer>
        <ButtonContainer>
          <GridItem gridArea="update" isBottom>
            <UpdateButton type="button">更新</UpdateButton>
          </GridItem>
          <GridItem gridArea="cancel" isBottom isRightmost>
            <CancelButton type="button">予約取消</CancelButton>
          </GridItem>
          <GridItem gridArea="repair" isBottom isRightmost>
            <RepairButton type="button">お直し</RepairButton>
          </GridItem>
        </ButtonContainer>
      </WrapperShadow>
    </Wrapper>
  );
};
