import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';
import { FontSize } from '../../../lib/styles/variables';
import {
  TimeHeight,
  ReservationCountHeight,
  CanReservationCountHeight,
  StylistHeight,
} from '../../../lib/utils/dayjs';
import { SelectBox } from '../lv3/SelectBox';

const Wrapper = styled.div`
  min-width: 200px;
  border: 1px solid #979797;
  border-right: 2px solid #979797;

  & > div {
    border-bottom: 1px solid #979797;
    padding: 8px;
    display: flex;
    align-items: baseline;
    font-weight: bold;
    flex-flow: column;
  }
`;

const Time = styled.div`
  height: ${TimeHeight};
`;
const ReservationCount = styled.div`
  height: ${ReservationCountHeight};
`;
const CanReservationCount = styled.div`
  height: ${CanReservationCountHeight};
  justify-content: space-between;
`;
const Stylist = styled.div`
  height: ${StylistHeight};
`;

const Label = styled.p`
  font-size: ${FontSize.SMALL};
  color: #212121;
`;

const Btn = css`
  color: #212121;
  padding: 4px 8px;
  font-size: ${FontSize.X_SMALL};
  font-weight: 500;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`;
const BtnArea = styled.div`
  display: flex;
`;

const SettingBtn = styled.div`
  ${Btn}
  margin-right: 16px;
`;

const ReverseBtn = styled.div`
  ${Btn}
`;

const StylistCanReservationCountArea = styled.div`
  display: flex;
`;

const StylistCanReservationCount = styled.div`
  font-size: ${FontSize.X_SMALL};
  color: #212121;
  font-weight: 500;
`;

interface StylistType {
  id: number;
  name: string;
  canReservationCount: number;
}

type Props = {
  stylists: StylistType[];
};

export const ReservationTableLeftBar: React.FC<Props> = ({ stylists }) => {
  const history = useHistory();
  return (
    <Wrapper>
      <Time />
      <ReservationCount>
        <Label>予約数</Label>
      </ReservationCount>
      <CanReservationCount>
        <Label>残り受付可能数</Label>
        <BtnArea>
          <SettingBtn onClick={() => history.push('/reservation/setting')}>
            設定
          </SettingBtn>
          <ReverseBtn>戻す</ReverseBtn>
        </BtnArea>
      </CanReservationCount>
      {stylists.map((it) => (
        <Stylist key={it.id}>
          <Label>{it.name}</Label>
          <StylistCanReservationCountArea>
            <StylistCanReservationCount>
              受付可能数：
            </StylistCanReservationCount>
            <SelectBox
              control={{
                type: 'state',
                value: '0',
                onChange: () => {},
              }}
              style={{
                width: '47px',
                height: '16px',
                fontSize: '12px',
                border: 'all',
                padding: '0px 8px 0px 4px',
              }}
            >
              <option value="0">0</option>
            </SelectBox>
          </StylistCanReservationCountArea>
        </Stylist>
      ))}
    </Wrapper>
  );
};
