import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Triangle } from '../lv3/Triangle';

const WrapperSelect = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
  position: relative;
  margin-right: 2rem;
`;

const SelectCustom = styled.select`
  width: 100%;
  height: 2rem;
  border: none;
  border-bottom: 1px solid #979797;
  font-size: 24px;
`;

const Label = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: #757575;
`;

const WrapperTriangle = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  background: #fff;
  height: 100%;
  padding: 0 0.4rem;
`;

export const StylistDetailSelect: FC<{
  value: string | number | undefined;
  label: string;
  options: { label: string; value: string | number }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  width?: string;
}> = ({ value = '', label, options, onChange, width = '100%' }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e);
    },
    [onChange],
  );

  return (
    <WrapperSelect width={width}>
      <Label>{label}</Label>
      <SelectCustom onChange={handleChange} required value={value}>
        <option value="">選択してください</option>
        {options.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </SelectCustom>
      <WrapperTriangle>
        <Triangle size={14} />
      </WrapperTriangle>
    </WrapperSelect>
  );
};
