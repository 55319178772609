import apiBase from './apiBase';

export type SearchStoreParams = {
  name: string;
  phone_number: string;
  area: string;
  type: string;
};

export type StoreInList = {
  id: number;
  name: string;
  image_url: string | null;
  brand_id: number;
  phone_number: string;
  post_code: string;
  city: string;
  address: string;
  building_room: string;
  seat_number: number;
  open_time: string;
  sale_target: number;
  access_method: string;
  access_guide: string;
  introduction?: string;
  introduction_title: string;
  introduction_body: string;
  notes: string;
};

export type SearchStoreRes = {
  data: StoreInList[];
};

export const searchStore = (params: SearchStoreParams) =>
  apiBase.get<SearchStoreRes>(
    `/salons?name=${params.name}&phone_number=${params.phone_number}&area=${params.area}`,
  );

export type GetStoreRes = {
  id: number;
  name: string;
  brand_id: number;
  phone_number: string;
  post_code: string;
  city: string;
  address: string;
  building_room: string;
  seat_number: number;
  open_time: string;
  sale_target: number;
  access_method: string;
  access_guide: string;
  introduction: string;
  notes: string;
  brand_name: string;
  images: {
    id: number;
    salon_id: number;
    seq: number;
    image_url: string;
  }[];
  questions: {
    id: number;
    salon_id: number;
    seq: number;
    question: string;
    type: 'select' | 'radio';
    answers: {
      id: number;
      salon_id: number;
      salon_question_id: number;
      seq: number;
      answer: string;
    }[];
  }[];
};

export const getStore = (storeId: number) =>
  apiBase.get<GetStoreRes>(`/salon/${storeId}`);

export type GetAllStoreRes = {
  id: number;
  name: string;
}[];

export const getAllStore = () => apiBase.get<GetAllStoreRes>('/salons/all');

export type UpdateStoreParams = {
  memo: string;
  introduction: string;
  notes: string;
  questions: {
    question: string;
    type: string;
    answers: string[];
  }[];
  images: [
    | {
        image_url: string;
      }
    | {
        base64: string;
      },
  ];
};

export const updateStore = (params: UpdateStoreParams, storeId: number) =>
  apiBase.put(`/salon/${storeId}`, params);
