import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { FontSize, Color } from '../../lib/styles/variables';
import { customerMockData } from '../../lib/utils/customerMockData';
import { CustomerSearchForm } from '../parts/lv1/CustomerSearchForm';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const MainContainer = styled.div`
  margin: 0 67px;
`;

const CustomerCardsArea = styled.div`
  margin-top: 32px;
`;

const CustomerCard = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  span {
    color: #757575;
    font-size: ${FontSize.SMALL};
    margin-bottom: 8px;
  }

  p {
    color: ${Color.BRACK};
    font-size: ${FontSize.TITLE};
  }
`;

export const CustomerOfStore = () => {
  const history = useHistory();
  const { id } = useParams();

  const links = [
    { name: '基本情報', to: `/store/detail/${id}`, isselected: false },
    {
      name: '美容師リスト',
      to: `/store/detail/${id}/stylist`,
      isselected: false,
    },
    {
      name: '顧客リスト',
      to: `/store/detail/${id}/customer`,
      isselected: true,
    },
    {
      name: 'POSユーザー',
      to: `/store/detail/${id}/posuser`,
      isselected: false,
    },
  ];

  return (
    <Wrapper>
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={() => history.push('/store')}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text="ミラーボール渋谷店" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '80px 0 150px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <MainContainer>
          {/* <CustomerSearchForm /> */}
          <CustomerCardsArea>
            {customerMockData.map((item) => (
              <ShadedContainer
                key={item.id}
                style={{
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                  padding: '40px 40px 40px 24px',
                  width: 462,
                  height: 145,
                  borderRadius: 4,
                }}
                handleClick={() => history.push(`/customer/${item.id}`)}
              >
                <CustomerCard>
                  <TextContainer>
                    <span>{item.gender}</span>
                    <p>{item.name}</p>
                  </TextContainer>
                  <ArrowIcon
                    style={{
                      height: 20,
                      width: 10,
                      color: '#757575',
                    }}
                    direction="RIGHT"
                  />
                </CustomerCard>
              </ShadedContainer>
            ))}
          </CustomerCardsArea>
        </MainContainer>
      </ShadedContainer>
    </Wrapper>
  );
};
