import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SelectBox } from '../lv3/SelectBox';
import { GetMissionBudgetParams } from '../../../lib/api/budget';

const FieldWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  width: 20%;
`;

type MissionSearchFormProps = {
  currentYear: number;
  onChange: (params: GetMissionBudgetParams) => void;
};

export const MissionSearchForm = ({
  currentYear,
  onChange,
}: MissionSearchFormProps) => {
  const [year, setYear] = useState(currentYear.toString());
  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    const first = 2019;
    const count: number = currentYear - first + 2;
    const list = Array(count)
      .fill(0)
      .map((_, index) => {
        return (first + index).toString();
      });
    setYears(list);
  }, []);

  const handleChange = (y: string) => {
    setYear(y);
    onChange({
      year: y,
    });
  };

  return (
    <FieldWrapper>
      <SelectBox
        control={{
          type: 'state',
          value: year,
          onChange: handleChange,
        }}
        style={{
          width: '100px',
          fontSize: '24px',
          height: '36px',
        }}
      >
        {years.map((y) => {
          return (
            <option key={`option-year-${y}`} value={y.toString()}>
              {`${y}年`}
            </option>
          );
        })}
      </SelectBox>
    </FieldWrapper>
  );
};
