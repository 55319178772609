import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { FontSize, Color } from '../../../lib/styles/variables';
import { SquareButton } from '../lv3/SquareButton';
import { TextField } from '../lv3/TextField';
import { Margin } from '../lv3/Margin';
import { SelectBox } from '../lv3/SelectBox';
import { DatePicker } from '../lv3/DatePicker';
import { Table } from './Table';

const Wrapper = styled.form``;

const GridWrapper = styled.div`
  display: grid;
  grid:
    'furigana furiganaField name nameField' 42px
    'id idField storeName storeNameField' 42px
    'term termField termField termField' 42px
    'button button button button' 60px
    / 145px 280px 145px 1fr;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

type GridArea =
  | 'furigana'
  | 'furiganaField'
  | 'name'
  | 'nameField'
  | 'id'
  | 'idField'
  | 'storeName'
  | 'storeNameField'
  | 'term'
  | 'termField'
  | 'button';

type GridItemProps = {
  gridArea: GridArea;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const NameInputLabel = styled.p`
  margin: 0 5px 0 10px;
`;

const StoreNameFieldWrapper = styled.div`
  margin-left: 10px;
  border: 1px solid #979797;
  padding: 0 8px;
`;

const DatePickerWrapper = styled.div`
  margin: 0 10px;
  border: 1px solid #979797;
  padding: 0 8px;
`;

const Triangle = styled.span`
  line-height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  width: 282px;
`;

export const AccountingSearchForm = () => {
  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs()
      .add(1, 'month')
      .format('YYYY-MM-DD'),
  );

  const { register, handleSubmit } = useForm({
    defaultValues: {
      furi: '',
      gana: '',
      firstName: '',
      lastName: '',
      id: '',
      storeName: '',
    },
  });

  const onSubmit = handleSubmit((data) => console.log(data));

  const columns = [
    { header: '期間', accessor: 'term', width: 106 },
    { header: '従業員番号', accessor: 'id', width: 85 },
    { header: '従業員名', accessor: 'name', width: 106 },
    { header: '商品名', accessor: 'product', width: 604 },
    { header: '販売個数', accessor: 'count', width: 68 },
    { header: '合計金額', accessor: 'price', width: 113 },
  ];

  const data = [
    {
      term: '2020-02-02',
      id: '1234323',
      name: '虹村形兆',
      product: '接待費ホゲホゲ',
      count: '100個',
      price: '1,000,000円',
    },
  ];

  return (
    <Wrapper onSubmit={onSubmit}>
      <GridWrapper>
        <GridItem gridArea="furigana">
          <Label>従業員名（カナ）</Label>
        </GridItem>
        <GridItem gridArea="furiganaField">
          <NameInputLabel>セイ</NameInputLabel>
          <TextField
            control={{
              type: 'hook',
              name: 'furi',
              register,
            }}
            style={{ width: '84px', height: '22px', fontSize: '12px' }}
          />
          <NameInputLabel>メイ</NameInputLabel>
          <TextField
            control={{
              type: 'hook',
              name: 'furi',
              register,
            }}
            style={{ width: '84px', height: '22px', fontSize: '12px' }}
          />
        </GridItem>
        <GridItem gridArea="name">
          <Label>従業員名</Label>
        </GridItem>
        <GridItem gridArea="nameField" isRightmost>
          <NameInputLabel>性</NameInputLabel>
          <TextField
            control={{
              type: 'hook',
              name: 'firstName',
              register,
            }}
            style={{ width: '84px', height: '22px', fontSize: '12px' }}
          />
          <NameInputLabel>名</NameInputLabel>
          <TextField
            control={{
              type: 'hook',
              name: 'lastName',
              register,
            }}
            style={{ width: '84px', height: '22px', fontSize: '12px' }}
          />
        </GridItem>
        <GridItem gridArea="id">
          <Label>従業員ID</Label>
        </GridItem>
        <GridItem gridArea="idField">
          <Margin margin="0 0 0 10px">
            <TextField
              control={{
                type: 'hook',
                name: 'id',
                register,
              }}
              style={{ width: '170px', height: '22px', fontSize: '12px' }}
            />
          </Margin>
        </GridItem>
        <GridItem gridArea="storeName">
          <Label>店舗名</Label>
        </GridItem>
        <GridItem gridArea="storeNameField" isRightmost>
          <StoreNameFieldWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'storeName',
                register,
              }}
              style={{
                width: '170px',
                height: '22px',
                fontSize: '12px',
              }}
            >
              <option value="all">全店</option>
              <option value="shibuya">渋谷店</option>
              <option value="harajyuku">原宿店</option>
            </SelectBox>
          </StoreNameFieldWrapper>
        </GridItem>
        <GridItem gridArea="term">
          <Label>期間</Label>
        </GridItem>
        <GridItem gridArea="termField" isRightmost>
          <DatePickerWrapper>
            <DatePicker
              date={startDate}
              handleChange={(date) => setStartDate(date)}
              style={{ fontSize: '12px', width: '170px', height: '22px' }}
            />
            <Triangle>▼</Triangle>
          </DatePickerWrapper>
          から
          <DatePickerWrapper>
            <DatePicker
              date={endDate}
              handleChange={(date) => setEndDate(date)}
              style={{ fontSize: '12px', width: '170px', height: '22px' }}
            />
            <Triangle>▼</Triangle>
          </DatePickerWrapper>
        </GridItem>
        <GridItem gridArea="button" isRightmost isBottom>
          <ButtonWrapper>
            <SquareButton
              fontSize={15}
              width={136}
              height={40}
              borderRadius={4}
              color="BLUE_BORDER"
              text="検索"
              type="submit"
            />
            <SquareButton
              fontSize={15}
              width={136}
              height={40}
              borderRadius={4}
              color="WHITE"
              text="戻る"
              type="button"
            />
          </ButtonWrapper>
        </GridItem>
      </GridWrapper>

      <Table columns={columns} data={data} />
    </Wrapper>
  );
};
