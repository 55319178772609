import apiBase from './apiBase';
import { PageData } from './utils';

type SalonImage = {
  seq: number;
  image_url: string;
};

type Answer = {
  id: number;
  salon_id: number;
  salon_question_id: number;
  seq: number;
  answer: string;
};

export type Question = {
  id: number;
  salon_id: number;
  seq: number;
  question: string;
  type: string;
  answers: Answer[];
};

export type Salon = {
  id: number;
  name: string;
  image_url: string;
  brand_id: number;
  phone_number: string;
  post_code: string;
  city: string;
  address: string;
  building_room: string;
  seat_number: number;
  open_hour: number;
  open_minute: number;
  close_hour: number;
  close_minute: number;
  holiday: string;
  cancelable_before_hour: number;
  access_method: string;
  access_guide: string;
  introduction_title: string;
  introduction_body: string;
  is_share_salon: number;
  notes: string;
  review_average_point: number;
  review_count: number;
  deleted_at: string | null;
  brand_name: string;
  images: SalonImage[];
  questions: Question[];
};

type ResponseGetSalons = {
  data: Salon[];
} & PageData;

export const getSalons = () => apiBase.get<ResponseGetSalons>(`/salons`);

export const getSalonDetail = (id: number) =>
  apiBase.get<Salon>(`/salon/${id}`);
