import apiBase from './apiBase';

export type Product = {
  id: number;
  manufacturer_id: number;
  category_id: number;
  jan_code: string;
  order_line: string;
  name: string;
  capacity: number;
  capacity_unit: string;
  sticker_price: number;
  price: number;
  is_open: boolean;
  point_return_amount: number;
  image_url: string;
  description_headline: string;
};

type GetProductsRes = {
  data: Product[];
  last_page: number;
};

type GetProductsParams = {
  key?: string;
  page?: number;
};

export const getProducts = ({ key, page }: GetProductsParams) =>
  apiBase.get<GetProductsRes>(`/products?key=${key || ''}`);
export const getAllProducts = () =>
  apiBase.get<Product[]>(`/products?paginate=false`);

export type GetProductByIdRes = {
  manufacturer_name: number;
  category_name: string;
  images: {
    id: number;
    product_id: number;
    seq: number;
    image_url: string;
  }[];
  description: {
    headline: string;
    contents: {
      id: number;
      product_id: number;
      seq: number;
      subtitle: string;
      image_url: string;
      body: string;
    }[];
  };
} & Product;

export const getProductById = (productId: number) =>
  apiBase.get<GetProductByIdRes>(`/product/${productId}`);

type AddProductParams = {
  // manufacturer_idとcategory_idにstringは含まれない
  manufacturer_id: number | string;
  category_id: number | string;
  jan_code: string;
  order_line: string;
  name: string;
  capacity: number;
  capacity_unit: string;
  sticker_price: number;
  price: number;
  is_open: boolean;
  description: {
    headline?: string;
    contents?: {
      subtitle: string;
      body: string;
      base64?: string;
      image_url?: string;
    }[];
  };
  images: { [key in string]: string }[];
};

export const addProduct = (params: Partial<AddProductParams>) =>
  apiBase.post('/product', params);

export const updateProduct = (
  params: Partial<AddProductParams>,
  productId: number,
) => apiBase.put(`/product/${productId}`, params);

export const deleteProduct = (productId: number) =>
  apiBase.delete(`/product/${productId}`);

type GetIsBrandPostingRes = {
  data: {
    brand_id: number;
    brand_name: string;
    is_show: boolean;
    is_recommend: boolean;
  }[];
};

export const getIsBrandPosting = (productId: number) =>
  apiBase.get<GetIsBrandPostingRes>(`/product/${productId}/brand`);

type UpdateIsBrandPostingParams = {
  data: {
    brand_id: number;
    is_show: boolean;
    is_recommend: boolean;
  }[];
};

export const updateIsBrandPosting = (
  params: UpdateIsBrandPostingParams,
  productId: number,
) => apiBase.put(`/product/${productId}/brand`, params);
