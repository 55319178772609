/* eslint no-plusplus: 0 */
import React, { useCallback, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { Color } from '../../lib/styles/variables';
import {
  getUser,
  getUserOrders,
  User,
  UserOrder,
  getUserPurchasedItems,
  UserPurchasedItem,
} from '../../lib/api/user';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperShadow = styled.div`
  margin: 12px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperBasicInfo = styled.div`
  margin: 12px 0;
`;

const WrapperFlex = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperTable = styled.div<{ width: number; margin?: string }>`
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
`;

const StyledTable = styled.table`
  margin-bottom: 2rem;
  width: 100%;
  border-collapse: collapse;
  tr {
    th {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      background: #f1f1f1;
    }
    td {
      padding: 0.5rem;
      border: 1px solid #979797;
      font-size: 14px;
      width: 200px;
    }
  }
`;
const ButtonLink = styled.button`
  color: ${Color.BLUE};
  background: none;
`;

export const ReservationCustomerInfo = () => {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();

  const [user, setUser] = useState<User | null>(null);
  const [userOrders, setUserOrders] = useState<UserOrder[]>([]);
  const [userPurchasedItems, setUserPurchasedItems] = useState<
    UserPurchasedItem[]
  >([]);

  const links = [
    {
      name: '基本情報',
      to: `/reservation/customer/${customerId}/info`,
      isselected: true,
    },
    {
      name: '予約状況',
      to: `/reservation/customer/${customerId}/status`,
      isselected: false,
    },
  ];

  useEffect(() => {
    getUser(parseInt(customerId, 10))
      .then(({ data }) => {
        setUser(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [customerId]);

  useEffect(() => {
    getUserOrders(parseInt(customerId, 10))
      .then(({ data }) => {
        setUserOrders(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [customerId]);

  useEffect(() => {
    getUserPurchasedItems(parseInt(customerId, 10))
      .then(({ data }) => {
        setUserPurchasedItems(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [customerId]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickLink = useCallback(
    (orderId: number) => () => {
      // TODO
      alert(orderId);
    },
    [],
  );

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            {user && (
              <MainContentTitle
                text={`${user.kanji_first_name} ${user.kanji_last_name}`}
              />
            )}
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} />
      </WrapperNavigation>
      {user && (
        <WrapperShadow>
          <WrapperBasicInfo>
            <WrapperFlex>
              <EachInfo label="ID" value={user.id} />
              <EachInfo label="生年月日" value={user.birth_day || '不明'} />
            </WrapperFlex>

            <WrapperFlex>
              <EachInfo label="電話番号" value={user.phone_number || '不明'} />
              <EachInfo label="総来店回数" value={user.salon_visit_count} />
            </WrapperFlex>

            <WrapperFlex>
              <EachInfo
                label="紹介された"
                value={`${user.invitor_user.kanji_first_name} ${user.invitor_user.kanji_last_name}`}
              />
              <EachInfo
                label="担当者"
                value={user.stylist_in_charge.user_name}
              />
            </WrapperFlex>
          </WrapperBasicInfo>

          <WrapperTable width={720} margin="0 0 36px 0">
            <Label>紹介した</Label>
            <StyledTable>
              <thead>
                <tr>
                  <th>紹介来店日時</th>
                  <th>名前</th>
                  <th>担当者</th>
                </tr>
              </thead>
              <tbody>
                {user.invited_users.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.first_visited_date}</td>
                      <td>{`${item.kanji_first_name} ${item.kanji_last_name}`}</td>
                      <td>{item.stylist_in_charge.user_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </WrapperTable>

          <WrapperTable width={720} margin="0 0 36px 0">
            <Label>EC購入履歴</Label>
            <StyledTable>
              <thead>
                <tr>
                  <th>購入日</th>
                  <th>注文ID</th>
                  <th>注文の合計金額</th>
                </tr>
              </thead>
              <tbody>
                {userOrders.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.created_at.split(' ')[0]}</td>
                      <td>
                        <ButtonLink
                          type="button"
                          onClick={handleClickLink(item.id)}
                        >
                          {item.id}
                        </ButtonLink>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {`${item.product_total_price.toLocaleString()}円`}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </WrapperTable>

          <WrapperTable width={720} margin="0 0 36px 0">
            <Label>店舗購入履歴</Label>
            <StyledTable>
              <thead>
                <tr>
                  <th>購入日</th>
                  <th>商品名</th>
                  <th>価格</th>
                </tr>
              </thead>
              <tbody>
                {userPurchasedItems.map((item) => {
                  return (
                    <tr key={`${item.date}-${item.name}`}>
                      <td>{item.date}</td>
                      <td>{item.name}</td>
                      <td style={{ textAlign: 'right' }}>
                        {`${item.price.toLocaleString()}円`}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </WrapperTable>
        </WrapperShadow>
      )}
    </Wrapper>
  );
};

const WrapperEachInfo = styled.div`
  width: 300px;
`;

const Label = styled.div`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1.36087px;
  color: #757575;
`;

const Value = styled.div`
  margin-bottom: 36px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 1.63304px;
  color: #212121;
`;

export const EachInfo: FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => {
  return (
    <WrapperEachInfo>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </WrapperEachInfo>
  );
};
