/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Color } from '../../lib/styles/variables';
import { ReservationSelect } from '../parts/lv1/ReservationSelect';
import { ReservationInputText } from '../parts/lv1/ReservationInputText';
import { ReservationInputRadio } from '../parts/lv1/ReservationInputRadio';
import { CheckBox } from '../parts/lv3/CheckBoxWithText';
import { addReservation, Reservation } from '../../lib/api/reservation';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 12px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperTable = styled.div`
  width: 100%;
`;

const Required = styled.span`
  color: ${Color.RED};
  font-weight: bold;
`;

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 2rem;
  border-collapse: collapse;
  tr {
    td {
      padding: 12px;
      border: 1px solid #979797;
      font-weight: bold;
      font-size: 12px;
      :nth-child(2n + 1) {
        width: 144px;
        background: #f1f1f1;
      }
    }
  }
`;

const Label = styled.div`
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
`;

const WrapperFlex = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

const WrapperInput = styled.div<{ width: number; margin?: string }>`
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
`;

const WrapperRadio = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 4px;
  border: 1px solid #979797;
`;

export const ReservationAdd = () => {
  const [gender, setGender] = useState<'male' | 'female' | 'other'>('male');
  const [isSelected, setIsSelected] = useState(false);
  const [reservation, setReservation] = useState<Reservation | null>(null);

  const salonId = useMemo(() => {
    // TODO サロンIDをどこから取得するか
    return 1;
  }, []);

  const history = useHistory();

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleChangeStaff = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => () => {
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev, stylist_id: parseInt(value, 10) };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => () => {
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          const start = new Date(prev.visit_salon_time).getTime();
          const finish = new Date(prev.finish_time).getTime();
          const dateStart = new Date(value);
          const dateFinish = new Date(value);
          dateStart.setTime(start);
          dateFinish.setTime(finish);
          return {
            ...prev,
            visit_salon_time: dateStart.toLocaleString(),
            finish_time: dateFinish.toLocaleString(),
          };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeStartTime = useCallback(
    (e: any) => () => {
      setReservation((prev) => {
        if (prev) {
          const dateStart = new Date(prev.visit_salon_time);
          return {
            ...prev,
            visit_salon_time: dateStart.toLocaleString(),
          };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeFinishTime = useCallback(
    (e: any) => () => {
      setReservation((prev) => {
        if (prev) {
          const dateStart = new Date(prev.finish_time);
          return {
            ...prev,
            finish_time: dateStart.toLocaleString(),
          };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeCoupon = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // TODO API修正後対応
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeCategory = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // TODO API修正後対応
      const { value } = e.target;

      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeMenu = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // TODO API修正後対応
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangePayment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // TODO API修正後対応
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangePreviousInfo = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // TODO API修正後対応
      const { value } = e.target;
      setReservation((prev) => {
        if (prev) {
          return { ...prev };
        }
        return prev;
      });
    },
    [],
  );

  const handleChangeCustomerId = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => () => {
      // TODO API修正後対応
      alert('TODO');
    },
    [],
  );

  const handleChangeNameKana = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => () => {
      // TODO API修正後対応
      alert('TODO');
    },
    [],
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => () => {
      // TODO API修正後対応
      alert('TODO');
    },
    [],
  );

  const handleChangePhonNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => () => {
      // TODO API修正後対応
      alert('TODO');
    },
    [],
  );

  const handleChangeMemo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => () => {
      // TODO API修正後対応
      alert('TODO');
    },
    [],
  );

  const handleClickGender = useCallback(
    (g: 'male' | 'female' | 'other') => () => {
      setGender(g);
    },
    [],
  );

  const handleClickCheckbox = useCallback(() => {
    setIsSelected((prev) => !prev);
  }, []);

  const handleClickAdd = useCallback(async () => {
    try {
      // TODO 予約追加
      const params = {} as any;
      const res = addReservation(salonId, params);
    } catch (e) {
      alert('エラーが発生しました');
    }
    history.goBack();
  }, [history, salonId]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="新規予約" />
          </Margin>
        </WrapperGoBack>
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperTable>
          <Margin margin="0 0 12px 0">
            <Label>
              予約情報
              <Required> ※必須項目</Required>
            </Label>
          </Margin>
          <StyledTable>
            <tbody>
              <tr>
                <td>
                  スタッフ
                  <Required> ※</Required>
                </td>
                <td>
                  <WrapperFlex margin="12px 0">
                    <WrapperInput width={200} margin="0 12px 0 0">
                      <ReservationSelect
                        options={[{ value: 'todo', label: 'todo' }]}
                        onChange={handleChangeStaff}
                        required
                        placeholder="選択してください"
                      />
                    </WrapperInput>
                    <CheckBox
                      size={{ width: 24, height: 24 }}
                      isChecked={isSelected}
                      handleClick={handleClickCheckbox}
                    />
                    指名予約
                  </WrapperFlex>
                </td>
              </tr>
              <tr>
                <td>
                  来店日時
                  <Required> ※</Required>
                </td>
                <td>
                  <WrapperInput width={200}>
                    <ReservationInputText onChange={handleChangeDate} />
                  </WrapperInput>

                  <WrapperFlex margin="12px 0">
                    <WrapperFlex margin="0 12px 0 0">
                      開始時刻：
                      <WrapperInput width={136}>
                        <ReservationSelect
                          options={[{ value: 'todo', label: 'todo' }]}
                          onChange={handleChangeStartTime}
                          required
                        />
                      </WrapperInput>
                    </WrapperFlex>
                    <WrapperFlex>
                      終了時刻：
                      <WrapperInput width={136}>
                        <ReservationSelect
                          options={[{ value: 'todo', label: 'todo' }]}
                          onChange={handleChangeFinishTime}
                          required
                        />
                      </WrapperInput>
                    </WrapperFlex>
                  </WrapperFlex>

                  <WrapperFlex>
                    所要時間：
                    <WrapperInput width={136}>
                      <ReservationSelect
                        options={[{ value: 'todo', label: 'todo' }]}
                        onChange={handleChangeStaff}
                        required
                      />
                    </WrapperInput>
                  </WrapperFlex>
                </td>
              </tr>
              <tr>
                <td>クーポン</td>
                <td>
                  <WrapperInput width={530}>
                    <ReservationSelect
                      options={[{ value: 'todo', label: 'todo' }]}
                      onChange={handleChangeCoupon}
                      placeholder="クーポンを選択"
                    />
                  </WrapperInput>
                </td>
              </tr>
              <tr>
                <td>メニュー</td>
                <td>
                  <WrapperFlex>
                    <WrapperInput width={260} margin="0 12px 0 0">
                      <ReservationSelect
                        options={[{ value: 'todo', label: 'todo' }]}
                        onChange={handleChangeCategory}
                        placeholder="カテゴリを選択"
                      />
                    </WrapperInput>
                    <WrapperInput width={260}>
                      <ReservationSelect
                        options={[{ value: 'todo', label: 'todo' }]}
                        onChange={handleChangeMenu}
                        placeholder="メニューを選択"
                      />
                    </WrapperInput>
                  </WrapperFlex>
                </td>
              </tr>
            </tbody>
          </StyledTable>

          <Label>予約者情報</Label>
          <StyledTable>
            <tbody>
              <tr>
                <td>お客様名ID</td>
                <td colSpan={3}>
                  <WrapperInput width={200}>
                    <ReservationInputText
                      onChange={handleChangeCustomerId}
                      placeholder="入力してください"
                    />
                  </WrapperInput>
                </td>
              </tr>
              <tr>
                <td>お客様名（カナ）</td>
                <td>
                  <WrapperInput width={200}>
                    <ReservationInputText
                      onChange={handleChangeNameKana}
                      placeholder="入力してください"
                    />
                  </WrapperInput>
                </td>
                <td>お客様名</td>
                <td>
                  <WrapperInput width={200}>
                    <ReservationInputText
                      onChange={handleChangeName}
                      placeholder="入力してください"
                    />
                  </WrapperInput>
                </td>
              </tr>
              <tr>
                <td>電話番号</td>
                <td>
                  <WrapperInput width={200}>
                    <ReservationInputText
                      onChange={handleChangePhonNumber}
                      placeholder="入力してください"
                    />
                  </WrapperInput>
                </td>
                <td>性別</td>
                <td>
                  <WrapperRadio>
                    <ReservationInputRadio
                      value="male"
                      label="男性"
                      checked={gender === 'male'}
                      onClick={handleClickGender('male')}
                    />
                    <ReservationInputRadio
                      value="female"
                      label="女性"
                      checked={gender === 'female'}
                      onClick={handleClickGender('female')}
                    />
                    <ReservationInputRadio
                      value="other"
                      label="その他"
                      checked={gender === 'other'}
                      onClick={handleClickGender('other')}
                    />
                  </WrapperRadio>
                </td>
              </tr>
              <tr>
                <td>お客様メモ</td>
                <td colSpan={3}>
                  <WrapperInput width={200}>
                    <ReservationInputText
                      onChange={handleChangeMemo}
                      placeholder="入力してください"
                    />
                  </WrapperInput>
                </td>
              </tr>
              <tr>
                <td>前回の施術情報</td>
                <td colSpan={3}>
                  <WrapperInput width={800}>
                    <StyledTextArea
                      rows={10}
                      onChange={handleChangePreviousInfo}
                    />
                  </WrapperInput>
                </td>
              </tr>
            </tbody>
          </StyledTable>

          <Label>お支払い情報</Label>
          <StyledTable>
            <tbody>
              <tr>
                <td>お支払い予定金額</td>
                <td>
                  <WrapperInput width={200}>
                    <ReservationInputText
                      onChange={handleChangePayment}
                      placeholder="入力してください"
                    />
                  </WrapperInput>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </WrapperTable>

        <SquareButton
          fontSize={24}
          width={160}
          height={56}
          text="予約"
          color="BLUE"
          handleClick={handleClickAdd}
        />
      </WrapperShadow>
    </Wrapper>
  );
};
