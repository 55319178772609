import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { FontSize, Color } from '../../../lib/styles/variables';
import { SquareButton } from '../lv3/SquareButton';
import { RadioButtonWithText } from '../lv3/RadioButtonWithText';
import { SelectBox } from '../lv3/SelectBox';
import { prefecuture } from '../../../lib/constants/prefecture';
import { GetUsersParams } from '../../../lib/api/user';

const Wrapper = styled.form`
  max-width: 959px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid:
    'name nameField email emailField' 42px
    'tel telField gender genderField' 42px
    'address addressField addressField addressField' 42px
    / 145px 280px 145px 1fr;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TextField = styled.input<{ isLong?: boolean }>`
  border: 1px solid #979797;
  margin-left: 10px;
  width: ${({ isLong }) => (isLong ? '594px' : '170px')};
  height: 22px;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const RadioButtonArea = styled.div`
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SelectBoxWrapper = styled.div`
  height: 22px;
  border: 1px solid #979797;
  padding: 0 8px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

type Props = {
  handleSearch: (value: GetUsersParams) => void;
};

export const CustomerSearchForm: React.FC<Props> = ({ handleSearch }) => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone_number: '',
      type: 'none',
      city: '',
    },
  });

  const onSubmit = handleSubmit((data) => handleSearch(data));

  return (
    <Wrapper onSubmit={onSubmit}>
      <GridWrapper>
        <GridItem gridArea="name">
          <Label>氏名</Label>
        </GridItem>
        <GridItem gridArea="nameField">
          <TextField name="name" ref={register} />
        </GridItem>
        <GridItem gridArea="email">
          <Label>メールアドレス</Label>
        </GridItem>
        <GridItem gridArea="emailField" isRightmost>
          <TextField name="email" ref={register} />
        </GridItem>
        <GridItem gridArea="tel">
          <Label>電話番号</Label>
        </GridItem>
        <GridItem gridArea="telField">
          <TextField name="phone_number" ref={register} />
        </GridItem>
        <GridItem gridArea="gender">
          <Label>性別</Label>
        </GridItem>
        <GridItem gridArea="genderField" isRightmost>
          <RadioButtonArea>
            <RadioButtonWithText
              text="選択なし"
              id="none"
              register={register}
              isSelected={watch().type === ''}
              name="type"
            />
            <RadioButtonWithText
              text="男性"
              id="男性"
              register={register}
              isSelected={watch().type === '男性'}
              name="type"
            />
            <RadioButtonWithText
              text="女性"
              id="女性"
              register={register}
              isSelected={watch().type === '女性'}
              name="type"
            />
            <RadioButtonWithText
              text="その他"
              id="その他"
              register={register}
              isSelected={watch().type === 'その他'}
              name="type"
            />
          </RadioButtonArea>
        </GridItem>
        <GridItem gridArea="address" isBottom>
          <Label>住所</Label>
        </GridItem>
        <GridItem gridArea="addressField" isRightmost isBottom>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'city',
                register,
              }}
              style={{
                width: '170px',
                fontSize: '12px',
              }}
            >
              <option value="">選択なし</option>
              {prefecuture.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
        </GridItem>
      </GridWrapper>
      <SquareButton
        fontSize={15}
        width={136}
        height={40}
        borderRadius={4}
        color="BLUE_BORDER"
        text="検索"
        type="submit"
      />
    </Wrapper>
  );
};
