import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { StylistDetailInputText } from '../parts/lv1/StylistDetailInputText';
import { StylistDetailSelect } from '../parts/lv1/StylistDetailSelect';
import { SquareButton } from '../parts/lv3/SquareButton';
import { addReward, Reward } from '../../lib/api/stylist';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperContent = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  width: 600px;
  margin-right: 40px;
  margin-bottom: 40px;
`;

export const StylistRewardAdd = () => {
  const history = useHistory();

  const { stylistId } = useParams<{
    stylistId: string;
  }>();

  const [reward, setReward] = useState<Partial<Reward>>({});

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleChangeSubject = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setReward((prev) => {
        return { ...prev, subject: value };
      });
    },
    [],
  );

  const handleChangeSalon = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setReward((prev) => {
        return { ...prev, salon_id: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleChangeFromDate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setReward((prev) => {
        return { ...prev, from_date: value };
      });
    },
    [],
  );

  const handleChangeToDate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setReward((prev) => {
        return { ...prev, to_date: value };
      });
    },
    [],
  );

  const handleChangeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setReward((prev) => {
        return { ...prev, amount: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const params = { ...reward };
      await addReward(stylistId, params);
      history.goBack();
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [history, reward, stylistId]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="報酬保証登録" />
          </Margin>
        </WrapperGoBack>
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperContent>
          <FieldWrapper>
            <StylistDetailInputText
              value={reward.subject}
              label="内容"
              placeholder="入力してください"
              onChange={handleChangeSubject}
            />
          </FieldWrapper>

          <FieldWrapper>
            <StylistDetailSelect
              value={reward.salon_id}
              label="店舗"
              options={[
                { value: 'TODO', label: 'TODO' },
                { value: '2', label: '渋谷店' },
                { value: '3', label: '原宿店' },
              ]}
              onChange={handleChangeSalon}
            />
          </FieldWrapper>

          <FieldWrapper>
            <StylistDetailInputText
              value={reward.amount}
              label="補償額"
              placeholder="10000"
              unit="円"
              onChange={handleChangeAmount}
            />
          </FieldWrapper>

          <FieldWrapper>
            <StylistDetailSelect
              value={reward.from_date}
              label="保証開始期間"
              options={[{ value: 'TODO', label: 'TODO' }]}
              onChange={handleChangeFromDate}
            />
          </FieldWrapper>

          <FieldWrapper>
            <StylistDetailSelect
              value={reward.to_date}
              label="保証終了期間"
              options={[{ value: 'TODO', label: 'TODO' }]}
              onChange={handleChangeToDate}
            />
          </FieldWrapper>

          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="登録"
            borderRadius={4}
            color="BLUE"
            handleClick={handleSubmit}
          />
        </WrapperContent>
      </WrapperShadow>
    </Wrapper>
  );
};
