import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { Navigation } from '../parts/lv1/Navigation';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TitleMargin = styled.div`
  width: 20%;
  margin: 0 0 12px 0;
`;

const TableMargin = styled.div`
  margin 24px 0 0 0;
`;

const Button = styled.button`
  font-size: 12px;
  color: #4a90e2;
  background: #fff;
`;

const LeftColumn = styled.div`
  width: 100%;
  text-align: right;
`;

export const StoreBasisAmount = () => {
  const history = useHistory();

  const EditButton = () => {
    return (
      <Button onClick={() => history.push(`/content/material/basis/edit`)}>
        編集
      </Button>
    );
  };

  const Amount = ({ amount }: { amount: number }) => {
    return <LeftColumn>{`${amount.toLocaleString()}個`}</LeftColumn>;
  };

  const links = [
    { name: '材料一覧', to: `/content/material/`, isselected: false },
    {
      name: 'ディーラー一覧',
      to: `/content/material/dealer`,
      isselected: false,
    },
    { name: '店舗基準量', to: `/content/material/basis`, isselected: true },
    { name: '店舗販売', to: `/content/material/storefront`, isselected: false },
    {
      name: 'シェアサロン利用者',
      to: `/content/material/share`,
      isselected: false,
    },
  ];

  const columns = [
    { header: '商品名', accessor: 'itemName', width: 765 },
    { header: '基準量', accessor: 'basis', width: 200 },
    { header: '', accessor: 'editButton', width: 60 },
  ];

  const data = [
    {
      itemName: 'ワックス',
      basis: <Amount amount={1234567} />,
      editButton: <EditButton />,
    },
  ];

  return (
    <Wrapper>
      <TopContent>
        <TitleMargin>
          <MainContentTitle text="材料管理" />
        </TitleMargin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <SquareButton
          text="基準量の登録"
          width={136}
          height={40}
          fontSize={15}
          borderRadius={4}
          color="BLUE"
          handleClick={() => history.push(`/content/material/basis/add`)}
        />
        <TableMargin>
          <Table columns={columns} data={data} />
        </TableMargin>
      </ShadedContainer>
    </Wrapper>
  );
};
