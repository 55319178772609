import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { Navigation } from '../parts/lv1/Navigation';
import { Paging } from '../parts/lv1/Paging';
import { getMaterials, ResponseGetMaterials } from '../../lib/api/material';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TitleMargin = styled.div`
  width: 20%;
  margin: 0 0 12px 0;
`;

const TableMargin = styled.div`
  margin 24px 0 60px 0;
`;

const Button = styled.button`
  font-size: 12px;
  color: #4a90e2;
  background: #fff;
`;

const LeftColumn = styled.div`
  width: 100%;
  text-align: right;
`;

export const ContentMaterial = () => {
  const [
    materialsResponse,
    setMaterialsResponse,
  ] = useState<ResponseGetMaterials | null>(null);
  const history = useHistory();

  const EditButton = () => {
    return (
      <Button onClick={() => history.push(`/content/material/edit`)}>
        編集
      </Button>
    );
  };

  const Price = ({ price }: { price: number }) => {
    return <LeftColumn>{`${price.toLocaleString()}円`}</LeftColumn>;
  };

  const links = [
    { name: '材料一覧', to: `/content/material/`, isselected: true },
    {
      name: 'ディーラー一覧',
      to: `/content/material/dealer`,
      isselected: false,
    },
    { name: '店舗基準量', to: `/content/material/basis`, isselected: false },
    { name: '店舗販売', to: `/content/material/storefront`, isselected: false },
    {
      name: 'シェアサロン利用者',
      to: `/content/material/share`,
      isselected: false,
    },
  ];

  const columns = [
    { header: '商品コード', accessor: 'code', width: 150 },
    { header: '商品名', accessor: 'name', width: 250 },
    { header: 'カテゴリー', accessor: 'category_name', width: 164 },
    { header: 'ディーラー名', accessor: 'dealer_name', width: 250 },
    { header: '単価（税抜き）', accessor: 'price', width: 144 },
    { header: '', accessor: 'editButton', width: 60 },
  ];

  useEffect(() => {
    // FIXME @konojunya このページのどこからsalonIdをとるのか
    getMaterials('1').then(setMaterialsResponse);
  }, []);

  return (
    <Wrapper>
      <TopContent>
        <TitleMargin>
          <MainContentTitle text="材料" />
        </TitleMargin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <SquareButton
          text="材料登録"
          width={136}
          height={40}
          fontSize={15}
          borderRadius={4}
          color="BLUE"
          handleClick={() => history.push(`/content/material/add`)}
        />
        <TableMargin>
          <Table
            columns={columns}
            data={(materialsResponse?.data ?? []).map((material) => ({
              ...material,
              price: <Price price={material.order_price} />,
              editButton: <EditButton />,
            }))}
          />
        </TableMargin>
        <Paging
          sum={materialsResponse?.total ?? 20}
          per={materialsResponse?.per_page ?? 20}
          // eslint-disable-next-line
          onChange={(number) => console.log(number)}
        />
      </ShadedContainer>
    </Wrapper>
  );
};
