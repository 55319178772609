import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      th {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        background: #f1f1f1;
      }
      td {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-size: 14px;
      }
    }
  }
`;

const headers = [
  'ECバック率',
  '歩合率',
  '施術売上フリー歩合',
  '施術売上指名歩合',
  '物販売上歩合',
];

type Props = {
  ec_feed_back_rate: number;
  commission_rate: number;
  treatment_sale_free_commission: number;
  treatment_sale_name_commission: number;
  product_sale_commission: number;
};

export const StylistDetailTableCommission: FC<Props> = ({
  ec_feed_back_rate,
  commission_rate,
  treatment_sale_free_commission,
  treatment_sale_name_commission,
  product_sale_commission,
}) => {
  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            {headers.map((item) => {
              return <th key={item}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{`${ec_feed_back_rate}%`}</td>
            <td>{`${commission_rate}%`}</td>
            <td>{`${treatment_sale_free_commission}%`}</td>
            <td>{`${treatment_sale_name_commission}%`}</td>
            <td>{`${product_sale_commission}%`}</td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
};
