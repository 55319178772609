import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { MissionForm } from '../parts/lv1/MissionForm';
import { AccountContext } from '../../lib/contexts/AccountContext';
import {
  getMissionBudget,
  updateMissionBudget,
  UpdateMissionBudgetParams,
} from '../../lib/api/budget';
import { useSpinner } from '../../lib/hooks/useSpinner';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EditMission = () => {
  const history = useHistory();
  const { month } = useParams();
  const methods = useForm();
  const { selectedSalon } = useContext(AccountContext);
  const { loading, startLoading, finishLoading } = useSpinner();

  useEffect(() => {
    if (!selectedSalon) return;
    startLoading();
    const [y, m] = month.split('-');
    getMissionBudget(selectedSalon, { year: y })
      .then(({ data }) => {
        const res = data.find((value) => value.year_month.includes(month)); // TODO
        if (!res) {
          if (m > 12 || m === '') history.replace('/store/mission');
          else history.replace(`/store/mission/add/${month}`);
          return;
        }
        methods.reset({
          budget: res.budget,
          customerRate: res.purchase_product_customer_rate * 100,
          costRate: res.material_cost_rate * 100,
          treatmentSalesTarget: res.treatment_sales_target,
          productSalesTarget: res.product_sales_target,
          totalSalesTarget: res.total_sales_target,
        });
      })
      .finally(() => finishLoading());
  }, [selectedSalon, month]);

  const onSubmit = methods.handleSubmit((input) => {
    if (!selectedSalon) {
      alert('時間をおいて再度お試しください');
      return;
    }
    const params: UpdateMissionBudgetParams = {
      year_month: month,
      budget: input.budget,
      purchase_product_customer_rate: Number(input.customerRate) * 0.01,
      material_cost_rate: Number(input.costRate) * 0.01,
      treatment_sales_target: input.treatmentSalesTarget,
      product_sales_target: input.productSalesTarget,
      total_sales_target: input.totalSalesTarget,
    };

    updateMissionBudget(params, selectedSalon)
      .then(({ data }) => {
        methods.reset({
          budget: data.budget,
          customerRate: data.purchase_product_customer_rate * 100,
          costRate: data.material_cost_rate * 100,
          treatmentSalesTarget: data.treatment_sales_target,
          productSalesTarget: data.product_sales_target,
          totalSalesTarget: data.total_sales_target,
        });
      })
      .catch((e) => {
        // TODO エラーメッセージは確認できてない
        const errorMessage = e.error;
        const errorsMessage = e.errors;
        const message =
          errorsMessage !== undefined
            ? '必須項目が入力されていません'
            : errorMessage.message || '登録に失敗しました';
        alert(message);
      });
  });

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="予算・目標・MOT点数" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Form onSubmit={onSubmit}>
          {!loading ? (
            <>
              <MissionForm methods={methods} />
              <SquareButton
                fontSize={15}
                height={40}
                width={136}
                borderRadius={4}
                text="編集"
                color="BLUE"
                type="submit"
              />
            </>
          ) : (
            loading
          )}
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
