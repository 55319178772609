import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import {
  addMissionBudget,
  UpdateMissionBudgetParams,
} from '../../lib/api/budget';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { MissionForm } from '../parts/lv1/MissionForm';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AddMission = () => {
  const history = useHistory();
  const { month } = useParams();
  useEffect(() => {
    const m = month.split('-')[1];
    if (m > 12 || m === '') history.replace('/store/mission');
    else history.replace(`/store/mission/add/${month}`);
  }, [month]);

  const methods = useForm();
  const { selectedSalon } = useContext(AccountContext);

  const onSubmit = methods.handleSubmit((input) => {
    if (!selectedSalon) {
      alert('時間をおいて再度お試しください');
      return;
    }
    const params: UpdateMissionBudgetParams = {
      year_month: month,
      budget: input.budget,
      purchase_product_customer_rate: Number(input.customerRate) * 0.01,
      material_cost_rate: Number(input.costRate) * 0.01,
      treatment_sales_target: input.treatmentSalesTarget,
      product_sales_target: input.productSalesTarget,
      total_sales_target: input.totalSalesTarget,
    };

    addMissionBudget(selectedSalon, params)
      .then(({ data }) => {
        methods.reset({
          budget: data.budget,
          customerRate: data.purchase_product_customer_rate * 100,
          costRate: data.material_cost_rate * 100,
          treatmentSalesTarget: data.treatment_sales_target,
          productSalesTarget: data.product_sales_target,
          totalSalesTarget: data.total_sales_target,
        });
      })
      .catch((e) => {
        // TODO エラーメッセージは確認できてない
        const errorMessage = e.error;
        const errorsMessage = e.errors;
        const message =
          errorsMessage !== undefined
            ? '必須項目が入力されていません'
            : errorMessage.message || '登録に失敗しました';
        alert(message);
      });
  });

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="予算・目標・MOT点数" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Form onSubmit={onSubmit}>
          <MissionForm methods={methods} />
          <SquareButton
            fontSize={15}
            height={40}
            width={136}
            borderRadius={4}
            text="登録"
            color="BLUE"
            type="submit"
          />
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
