/* eslint consistent-return: 0 */
import React from 'react';
import styled from 'styled-components';
import { Triangle } from './Triangle';

type SelectBoxAreaProps = {
  width?: string;
  border?: 'bottom' | 'all';
  padding?: string;
  margin?: string;
  background?: string;
};

const getBorder = (border: SelectBoxAreaProps['border']) => {
  if (border === 'all') {
    return `
      border: 1px solid #979797;
      border-radius: 4px;
    `;
  }
  if (border === 'bottom') {
    return `
      border-bottom: 1px solid #979797;
    `;
  }
  if (border === undefined) {
    return `
      border: none;
    `;
  }
};

const SelectBoxArea = styled.div<SelectBoxAreaProps>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  align-items: center;
  background: ${({ background }) => background ?? '#fff'};
  padding: ${({ padding }) => padding ?? '0'};
  margin: ${({ margin }) => margin ?? '0'};

  ${({ border }) => getBorder(border)}
`;

const SelectField = styled.select<{
  fontSize?: string;
  height?: string;
  background?: string;
}>`
  width: 90%;
  padding-right: 4px;
  flex-grow: 1;
  background: ${({ background }) => background ?? '#fff'};
  line-height: ${({ height }) => height ?? 'auto'};
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  border: none;
`;

type Props = {
  control:
    | {
        type: 'hook';
        name: string;
        register: any;
        defaultValue?: string;
      }
    | {
        type: 'state';
        value: string;
        onChange: (v: string) => void;
      };
  style: {
    width?: string;
    fontSize?: string;
    height?: string;
    border?: 'bottom' | 'all';
    padding?: string;
    margin?: string;
    size?: number;
    background?: string;
  };
};

export const SelectBox: React.FC<Props> = (props) => {
  const { control, style, children } = props;

  return (
    <SelectBoxArea
      width={style.width}
      border={style.border}
      padding={style.padding}
      margin={style.margin}
      background={style.background}
    >
      {control.type === 'hook' ? (
        <SelectField
          name={control.name}
          ref={control.register}
          defaultValue={control.defaultValue}
          fontSize={style.fontSize}
          height={style.height}
          background={style.background}
        >
          {children}
        </SelectField>
      ) : (
        <SelectField
          value={control.value}
          onChange={(event) => control.onChange(event.target.value)}
          fontSize={style.fontSize}
          height={style.height}
          background={style.background}
        >
          {children}
        </SelectField>
      )}
      <Triangle size={style.size ?? 14} />
    </SelectBoxArea>
  );
};
