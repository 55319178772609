// prettier-ignore
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { RouteProps } from 'react-router';
import { Header } from './components/layout/Header';
import { MainContainer } from './components/layout/MainContainer';
import { SideBar } from './components/layout/SideBar';

import { Profit } from './components/pages/Profit';
import { Admin } from './components/pages/Admin';
import { AddAdmin } from './components/pages/AddAdmin';
import { EditAdmin } from './components/pages/EditAdmin';
import { Customer } from './components/pages/Customer';
import { Indevelopment } from './components/pages/InDevelopment';
import { CustomerDetail } from './components/pages/CustomerDetail';
import { Accounting } from './components/pages/Accounting';
import { EcProducts } from './components/pages/EcProducts';
import { EcCategory } from './components/pages/EcCategory';
import { EcCarousel } from './components/pages/EcCarousel';
import { Store } from './components/pages/Store';
import { Reservation } from './components/pages/Reservation';
import { Shift } from './components/pages/Shift';
import { ShiftSetting } from './components/pages/ShiftSetting';
import { AddStore } from './components/pages/AddStore';
import { UpdateStore } from './components/pages/UpdateStore';
import { StoreDetail } from './components/pages/StoreDetail';
import { Stylist } from './components/pages/Stylist';
import { StylistDetail } from './components/pages/StylistDetail';
import { CustomersOfStylist } from './components/pages/CustomersOfStylist';
import { StylistSales } from './components/pages/StylistSales';
import { StylistEvaluation } from './components/pages/StylistEvaluation';
import { CustomerOfStore } from './components/pages/CustomerOfStore';
import { Brand } from './components/pages/Brand';
import { Mission } from './components/pages/Mission';
import { Point } from './components/pages/Point';
import { MessageCustomer } from './components/pages/MessageCustomer';
import { MessageStylist } from './components/pages/MessageStylist';
import { FilterMessageCustomer } from './components/pages/FilterMessageCustomer';
import { FilterMessageStylist } from './components/pages/FilterMessageStylist';
import { QuestionnaireCustomer } from './components/pages/QuestionnaireCustomer';
import { QuestionnaireStylist } from './components/pages/QuestionnaireStylist';
import { FilterQuestionnaireCustomer } from './components/pages/FilterQuestionnaireCustomer';
import { CreateQuestionnaireCustomer } from './components/pages/CreateQuestionnaireCustomer';
import { FilterQuesionnaireStylist } from './components/pages/FilterQuestionnaireStylist';
import { CreateQuestionnaireStylist } from './components/pages/CreateQuestionnaireStylist';
import { Topics } from './components/pages/Topics';
import { Blog } from './components/pages/Blog';
import { PosUser } from './components/pages/PosUser';
import { AddPosUser } from './components/pages/AddPosUser';
import { StoreSettings } from './components/pages/StoreSettings';
import { AddBrand } from './components/pages/AddBrand';
import { EditBrand } from './components/pages/EditBrand';
import { ReservationSetting } from './components/pages/ReservationSetting';
import { StylistDetailEdit } from './components/pages/StylistDetailEdit';
import { StylistEvaluationEdit } from './components/pages/StylistEvaluationEdit';
import { StylistEvaluationMsEdit } from './components/pages/StylistEvaluationMsEdit';
import { StylistEvaluationMotEdit } from './components/pages/StylistEvaluationMotEdit';
import { StylistAllowance } from './components/pages/StylistAllowance';
import { StylistAllowanceEdit } from './components/pages/StylistAllowanceEdit';
import { StylistReward } from './components/pages/StylistReward';
import { StylistRewardAdd } from './components/pages/StylistRewardAdd';
import { StylistBonus } from './components/pages/StylistBonus';
import { StylistBonusEdit } from './components/pages/StylistBonusEdit';
import { StylistCommission } from './components/pages/StylistCommission';
import { StylistCommissionEdit } from './components/pages/StylistCommissionEdit';
import { FxLinking } from './components/pages/FxLinking';
import { Expense } from './components/pages/Expense';
import { ExpenseRegister } from './components/pages/ExpenseRegister';
import { ExpenseUpdate } from './components/pages/ExpenseUpdate';
import { StoreDateSettings } from './components/pages/StoreDateSettings';
import { AddMission } from './components/pages/AddMission';
import { EditMission } from './components/pages/EditMission';
import { SalonMotPoint } from './components/pages/SalonMotPoint';
import { AddSalonMotPoint } from './components/pages/AddSalonMotPoint';
import { EditSalonMotPoint } from './components/pages/EditSalonMotPoint';
import { PostRate } from './components/pages/PostRate';
import { AddPostRate } from './components/pages/AddPostRate';
import { EditPostRate } from './components/pages/EditPostRate';
import { GrateRate } from './components/pages/GrateRate';
import { ReservationAdd } from './components/pages/ReservationAdd';
import { ReservationEdit } from './components/pages/ReservationEdit';
import { ReservationEditFix } from './components/pages/ReservationEditFix';
import { ReservationCustomerInfo } from './components/pages/ReservationCustomerInfo';
import { ReservationCustomerStatus } from './components/pages/ReservationCustomerStatus';
import { CustomerKarte } from './components/pages/CustomerKarte';
import { RecordSales } from './components/pages/RecordSales';
import { AddRecordSales } from './components/pages/AddRecordSales';
import { EditRecordSales } from './components/pages/EditRecordSales';
import { RecordAccounting } from './components/pages/RecordAccounting';
import { AddRecordAccounting } from './components/pages/AddRecordAccounting';
import { EditRecordAccounting } from './components/pages/EditRecordAccounting';
import { PerShop } from './components/pages/PerShop';
import { Achievement } from './components/pages/Achievement';
import { CustomerReservation } from './components/pages/CustomerReservation';
import { CustomerReservationDetail } from './components/pages/CustomerReservationDetail';
import { AccountingCommissionRegular } from './components/pages/AccountingCommissionRegular';
import { AccountingCommissionManager } from './components/pages/AccountingCommissionManager';
import { AccountingCommissionDm } from './components/pages/AccountingCommissionDm';
import { CustomerOrder } from './components/pages/CustomerOrder';
import { ContentMaterial } from './components/pages/ContentMaterial';
import { AddMaterial } from './components/pages/AddMaterial';
import { EditMaterial } from './components/pages/EditMaterial';
import { ShareSalon } from './components/pages/ShareSalon';
import { AddShareSalon } from './components/pages/AddShareSalon';
import { EditShareSalon } from './components/pages/EditShareSalon';
import { StoreFrontMaterial } from './components/pages/StoreFrontMaterial';
import { AddStoreFrontMaterial } from './components/pages/AddStoreFrontMaterial';
import { EditStoreFrontMaterial } from './components/pages/EditStoreFrontMaterial';
import { StoreBasisAmount } from './components/pages/StoreBasisAmount';
import { AddStoreBasisAmount } from './components/pages/AddStoreBasisAmount';
import { EditStoreBasisAmount } from './components/pages/EditStoreBasisAmount';
import { Dealer } from './components/pages/Dealer';
import { AccountContext } from './lib/contexts/AccountContext';
import { ModalContext } from './lib/contexts/ModalContext';
import { ErrorPage } from './components/pages/Error';
import { Login } from './components/pages/Login';
import { SelectSalon } from './components/pages/SelectSalon';
import { ContentStylistCoupons } from './components/pages/ContentStylistCoupons';
import { ContentShopCoupons } from './components/pages/ContentShopCoupons';
import { AddStylistCoupon } from './components/pages/AddStylistCoupon';
import { AddShopCoupon } from './components/pages/AddShopCoupon';
import { EditShopCoupon } from './components/pages/EditShopCoupon';
import { EditStylistCoupon } from './components/pages/EditStylistCoupon';
import { CreateMessageCustomer } from './components/pages/CreateMessageCustomer';
import { CreateMessageStylist } from './components/pages/CreateMessageStylist';
import { CreateTopics } from './components/pages/CreateTopics';
import { EditTopics } from './components/pages/EditTopics';
import { EditMessageCustomer } from './components/pages/EditMessageCustomer';

const GridWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;

const PrivateRoute: React.FC<RouteProps & { isLogin: boolean }> = ({
  isLogin,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const CloseModalWhenTransition: React.FC<any> = ({ children }) => {
  const { closeModal } = useContext(ModalContext);

  useEffect(() => {
    closeModal();
  }, [closeModal]);

  return children;
};

export const Routes = () => {
  const { account, isMountAccount, logout } = useContext(AccountContext);

  if (!isMountAccount) {
    return null;
  }

  return (
    <>
      {/* prettier-ignore */}
      <BrowserRouter>
        <CloseModalWhenTransition>
          <Switch>
            <Route exact path="/login" component={Login} />
            <GridWrapper>
              <PrivateRoute isLogin={account !== null}>
                <Header
                  login_id={account ? account.login_id : ""}
                  logout={logout}
                />
                <MainContainer>
                  <Switch>
                    <Route exact path="/select-salon" component={SelectSalon} />
                    <Route exact path="/" component={Profit} />
                    <Route exact path="/per-shop" component={PerShop} />
                    <Route exact path="/achievement" component={Achievement} />
                    <Route exact path="/admin" component={Admin} />
                    <Route exact path="/admin/add" component={AddAdmin} />
                    <Route exact path="/admin/edit/:id" component={EditAdmin} />
                    <Route exact path="/customer" component={Customer} />
                    <Route exact path="/customer/:id" component={CustomerDetail} />
                    <Route exact path="/customer/:id/reservation" component={CustomerReservation} />
                    <Route exact path="/customer/:id/reservation/:id" component={CustomerReservationDetail} />
                    <Route exact path="/customer/:id/reservation/:reservationId/karte" component={CustomerKarte} />
                    <Route exact path="/customer/:customerId/reservation/:id" component={CustomerReservationDetail} />
                    <Route exact path="/customer/:id/order/:id/" component={CustomerOrder} />

                    {/* 経理管理 */}
                    <Route exact path="/accounting" component={Accounting} />
                    <Route exact path="/accounting/fx-linking" component={FxLinking} />
                    <Route exact path="/accounting/expense" component={Expense} />
                    <Route exact path="/accounting/expense/register" component={ExpenseRegister} />
                    <Route exact path="/accounting/expense/edit/:id" component={ExpenseUpdate} />
                    <Route exact path="/accounting/grade-rate" component={GrateRate} />
                    <Route exact path="/accounting/post-rate" component={PostRate} />
                    <Route exact path="/accounting/post-rate/add" component={AddPostRate} />
                    <Route exact path="/accounting/post-rate/edit/:id" component={EditPostRate} />
                    <Route exact path="/accounting/record/sales" component={RecordSales} />
                    <Route exact path="/accounting/record/sales/add" component={AddRecordSales} />
                    <Route exact path="/accounting/record/sales/edit/:id" component={EditRecordSales} />
                    <Route exact path="/accounting/record/accounting" component={RecordAccounting} />
                    <Route exact path="/accounting/record/accounting/add" component={AddRecordAccounting} />
                    <Route exact path="/accounting/record/accounting/edit/:id" component={EditRecordAccounting} />

                    <Route exact path="/accounting/commission/regular" component={AccountingCommissionRegular} />
                    <Route exact path="/accounting/commission/manager" component={AccountingCommissionManager} />
                    <Route exact path="/accounting/commission/dm" component={AccountingCommissionDm} />

                    {/* EC管理 */}
                    <Route exact path="/ec/products" component={EcProducts} />
                    <Route exact path="/ec/category" component={EcCategory} />
                    <Route exact path="/ec/carousel" component={EcCarousel} />

                    {/* 予約一覧 */}
                    <Route exact path="/reservation" component={Reservation} />
                    <Route exact path="/reservation/setting" component={ReservationSetting} />
                    <Route exact path="/reservation/add" component={ReservationAdd} />
                    <Route exact path="/reservation/:id" component={ReservationEdit} />
                    <Route exact path="/reservation/:id/fix" component={ReservationEditFix} />
                    <Route exact path="/reservation/customer/:customerId/info" component={ReservationCustomerInfo} />
                    <Route exact path="/reservation/customer/:customerId/status" component={ReservationCustomerStatus} />
                    <Route exact path="/reservation/customer/:customerId/status/:reservationId" component={ReservationEdit} />
                    <Route exact path="/reservation/customer/:customerId/status/:reservationId/karte" component={CustomerKarte} />

                    {/* シフト一覧 */}
                    <Route exact path="/shift" component={Shift} />
                    <Route exact path="/shift/setting/:stylistId" component={ShiftSetting} />

                    {/* 店舗管理 */}
                    <Route exact path="/store" component={Store} />
                    <Route exact path="/store/add" component={AddStore} />
                    <Route exact path="/store/update" component={UpdateStore} />
                    <Route exact path="/store/detail/:id" component={StoreDetail} />
                    <Route exact path="/store/detail/:id/customer" component={CustomerOfStore} />
                    <Route exact path="/store/detail/:id/posuser" component={PosUser} />
                    <Route exact path="/store/detail/:id/posuser/add" component={AddPosUser} />

                    {/* 美容師一覧 */}
                    <Route exact path="/store/detail/:id/stylist" component={Stylist} />
                    
                    {/* 美容師追加 */}
                    <Route exact path="/store/detail/:id/stylist/add" component={StylistDetailEdit} />

                    {/* 美容師詳細 */}
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId" component={StylistDetail} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/edit" component={StylistDetailEdit} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/customer" component={CustomersOfStylist} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/customer/detail/:customerId" component={CustomerKarte} />

                    {/* 美容師詳細（売上実績） */}
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/sales" component={StylistSales} />

                    {/* 美容師詳細（評価） */}
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/evaluation/add" component={StylistEvaluationEdit} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/evaluation/:evaluationId/edit" component={StylistEvaluationEdit} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/evaluation/ms/add" component={StylistEvaluationMsEdit} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/evaluation/ms/:evaluationId/edit" component={StylistEvaluationMsEdit} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/evaluation/mot/add" component={StylistEvaluationMotEdit} />
                    <Route exact path="/store/detail/:id/stylist/detail/:stylistId/evaluation/mot/:evaluationId/edit" component={StylistEvaluationMotEdit} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/evaluation" component={StylistEvaluation} />

                    {/* 美容師詳細（手当） */}
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/allowance/add" component={StylistAllowanceEdit} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/allowance/:allowanceId/edit" component={StylistAllowanceEdit} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/allowance" component={StylistAllowance} />

                    {/* 美容師詳細（報酬保証） */}
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/reward/add" component={StylistRewardAdd} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/reward" component={StylistReward} />

                    {/* 美容師詳細（賞与） */}
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/bonus/add" component={StylistBonusEdit} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/bonus/:bonusId/edit" component={StylistBonusEdit} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/bonus" component={StylistBonus} />

                    {/* 美容師詳細（歩合） */}
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/commission/add" component={StylistCommissionEdit} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/commission/:commissionId/edit" component={StylistCommissionEdit} />
                    <Route path="/store/detail/:id/stylist/detail/:stylistId/commission" component={StylistCommission} />

                    <Route exact path="/store/brand" component={Brand} />
                    <Route exact path="/store/brand/add" component={AddBrand} />
                    <Route exact path="/store/brand/:id/edit" component={EditBrand} />
                    <Route exact path="/store/mission" component={Mission} />
                    <Route exact path="/store/mission/add/:month" component={AddMission} />
                    <Route exact path="/store/mission/edit/:month" component={EditMission} />
                    <Route exact path="/store/mission/mot" component={SalonMotPoint} />
                    <Route exact path="/store/mission/mot/add" component={AddSalonMotPoint} />
                    <Route exact path="/store/mission/mot/edit/:id" component={EditSalonMotPoint} />
                    <Route exact path="/store/settings" component={StoreSettings} />
                    <Route exact path="/store/date" component={StoreDateSettings} />
                    <Route exact path="/content/point" component={Point} />

                    <Route exact path="/content/coupon/shop" component={ContentShopCoupons} />
                    <Route exact path="/content/coupon/shop/add" component={AddShopCoupon} />
                    <Route exact path="/content/coupon/shop/:id/edit" component={EditShopCoupon} />

                    <Route exact path="/content/coupon/stylist" component={ContentStylistCoupons} />
                    <Route exact path="/content/coupon/stylist/add" component={AddStylistCoupon} />
                    <Route exact path="/content/coupon/stylist/:id/edit" component={EditStylistCoupon} />

                    <Route exact path="/content/material" component={ContentMaterial} />
                    <Route exact path="/content/material/add" component={AddMaterial} />
                    <Route exact path="/content/material/edit" component={EditMaterial} />
                    <Route exact path="/content/material/dealer" component={Dealer} />
                    <Route exact path="/content/material/basis" component={StoreBasisAmount} />
                    <Route exact path="/content/material/basis/add" component={AddStoreBasisAmount} />
                    <Route exact path="/content/material/basis/edit" component={EditStoreBasisAmount} />
                    <Route exact path="/content/material/storefront" component={StoreFrontMaterial} />
                    <Route exact path="/content/material/storefront/add" component={AddStoreFrontMaterial} />
                    <Route exact path="/content/material/storefront/edit" component={EditStoreFrontMaterial} />
                    <Route exact path="/content/material/share" component={ShareSalon} />
                    <Route exact path="/content/material/share/add" component={AddShareSalon} />
                    <Route exact path="/content/material/share/edit" component={EditShareSalon} />

                    <Route exact path="/content/message/customer" component={MessageCustomer} />
                    <Route exact path="/content/message/customer/filter" component={FilterMessageCustomer} />
                    <Route exact path="/content/message/customer/create" component={CreateMessageCustomer} />
                    <Route exact path="/content/message/customer/edit/:id" component={EditMessageCustomer} />
                    <Route exact path="/content/message/stylist" component={MessageStylist} />
                    <Route exact path="/content/message/stylist/filter" component={FilterMessageStylist} />
                    <Route exact path="/content/message/stylist/create" component={CreateMessageStylist} />
                    <Route exact path="/content/questionnaire/customer" component={QuestionnaireCustomer} />
                    <Route exact path="/content/questionnaire/customer/filter" component={FilterQuestionnaireCustomer} />
                    <Route exact path="/content/questionnaire/customer/create" component={CreateQuestionnaireCustomer} />
                    <Route exact path="/content/questionnaire/stylist" component={QuestionnaireStylist} />
                    <Route exact path="/content/questionnaire/stylist/filter" component={FilterQuesionnaireStylist} />
                    <Route exact path="/content/questionnaire/stylist/create" component={CreateQuestionnaireStylist} />

                    <Route exact path="/content/topics" component={Topics} />
                    <Route exact path="/content/topics/create" component={CreateTopics} />
                    <Route exact path="/content/topics/edit/:id" component={EditTopics} />
                    <Route exact path="/content/blog" component={Blog} />
                    <Route exact path="/error" component={ErrorPage} />
                    <Route component={ErrorPage} />

                    <Route component={Indevelopment} />
                  </Switch>
                </MainContainer>
                <SideBar />
              </PrivateRoute>
            </GridWrapper>
          </Switch>
        </CloseModalWhenTransition>
      </BrowserRouter>
    </>
  );
};
