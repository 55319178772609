import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { FontSize, Color } from '../../lib/styles/variables';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SelectBox } from '../parts/lv3/SelectBox';
import { getHourList } from '../../lib/utils/date';
import { TextArea } from '../parts/lv3/TextArea';
import dayjs, { toH, toM, toYmd } from '../../lib/utils/dayjs';
import { DatePicker } from '../parts/lv3/DatePicker';
import { encodeBase64 } from '../../lib/utils/base64';
import { SpModal } from '../parts/lv3/SpModal';
import { SpPreviewModal } from '../parts/lv3/SpPreviewModal';
import {
  deleteMessageToCustomer,
  postMessageToCustomer,
  putMessageToCustomer,
} from '../../lib/api/message';
import { useQuery } from '../../lib/hooks/useQuery';
import { ValidateErrorMessageList } from '../parts/lv3/ValidationErrorMessage';
import { useMessageInfo } from '../../lib/hooks/useMessages';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridWrapper = styled.div`
  width: 1052px;
  display: grid;
  grid:
    'title titleField' 42px
    'content contentField' 191px
    'time timeField' 42px
    'image imageField' 42px
    / 147px 904px;
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  margin-bottom: 16px;
`;

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const ButtonWrapper = styled.div`
  width: 452px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const TextField = styled.input`
  width: 100%;
  height: 22px;
  background: #ffffff;
  border: 1px solid #979797;
  margin-left: 10px;
  margin-right: 10px;
`;

const SelectBoxWrapper = styled.div`
  margin-left: 10px;
  padding: 2px;
  border: 1px solid #979797;
`;

const ContentCharCount = styled.p`
  margin-left: 724px;
  font-size: 10px;
`;
const ContentArea = styled.div`
  margin-left: 10px;
  width: 100%;
  margin-right: 10px;
`;
const ContentTextArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TextInsertArea = styled.div`
  width: 56px;
  padding: 7px;
  border: 1px solid #979797;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  flex-flow: column;
`;
const TextInsertItem = styled.div`
  font-size: 12px;
`;

const DateTimeArea = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const FileFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

const FileField = styled.label`
  width: 96px;
  height: 22px;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
`;

const FileName = styled.p`
  font-size: 12px;
  margin-left: 8px;
  line-height: 22px;
  color: #212121;
`;

type FormType = {
  title: string;
  body: string;
  hour: string;
  minute: string;
  image_url: string;
};

export const EditMessageCustomer = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const message = useMessageInfo(id);
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    errors,
    reset,
  } = useForm<FormType>({
    defaultValues: {
      title: '',
      body: '',
      hour: '01',
      minute: '00',
      image_url: '',
    },
  });
  const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [img, setImg] = useState<{ name: string; file: string }>({
    name: '',
    file: '',
  });
  useEffect(() => {
    if (message === null) return;
    reset({
      ...message,
      hour: toH(dayjs(message.send_at)),
      minute: toM(dayjs(message.send_at)),
    });
    setDate(toYmd(dayjs(message.send_at)));
    if (message.image_url) {
      setImg({
        name: '登録済み',
        file: '',
      });
    }
  }, [message]);

  const onSubmit = handleSubmit((value) => {
    const params = {
      title: value.title,
      body: value.body,
      ...{
        image: img.file
          ? {
              base64: img.file,
            }
          : undefined,
        send_at: `${date} ${value.hour}:${value.minute}:00`,
      },
      image_url: getValues('image_url'),
    };

    putMessageToCustomer(id, params)
      .then(({ data }) => {
        setIsOpen(false);
        history.push('/content/message/customer');
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  });

  const handleFileChange = (file: any) => {
    encodeBase64(file).then((encoded) => {
      setImg({ name: file.name, file: encoded });
    });
    setValue('image_url', '');
  };
  const [isOpen, setIsOpen] = useState(false);

  const watchBody = watch('body');
  const charCount = 500 - watchBody.length;
  const handlePlaceholder = (word: string) => () => {
    setValue('body', `${watchBody}${word}`);
  };

  const handleDelete = () => {
    deleteMessageToCustomer(id)
      .then(({ data }) => {
        setIsOpen(false);
        history.push('/content/message/customer');
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  };

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="メッセージ作成" />
      </Margin>
      <GridWrapper>
        <GridItem gridArea="title">
          <Label>件名</Label>
        </GridItem>
        <GridItem gridArea="titleField">
          <TextField
            name="title"
            ref={register({ required: '件名は必須項目です' })}
          />
        </GridItem>
        <GridItem gridArea="content">
          <Label>内容</Label>
        </GridItem>
        <GridItem gridArea="contentField">
          <ContentArea>
            <ContentCharCount>
              あと
              {charCount}
              文字
            </ContentCharCount>
            <ContentTextArea>
              <TextArea
                control={{
                  type: 'hook',
                  name: 'body',
                  register: register({ required: '内容は必須項目です' }),
                }}
                style={{
                  width: '785px',
                  height: '148px',
                }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/arrow_square_left.svg`}
                alt="arrow_square_left"
              />
              <TextInsertArea>
                <TextInsertItem onClick={handlePlaceholder('#first_name#')}>
                  性
                </TextInsertItem>
                <TextInsertItem onClick={handlePlaceholder('#last_name#')}>
                  名
                </TextInsertItem>
                <TextInsertItem onClick={handlePlaceholder('#birthday#')}>
                  セイ
                </TextInsertItem>
                <TextInsertItem
                  onClick={handlePlaceholder('#first_name_kana#')}
                >
                  メイ
                </TextInsertItem>
                <TextInsertItem onClick={handlePlaceholder('#last_name_kana#')}>
                  誕生日
                </TextInsertItem>
              </TextInsertArea>
            </ContentTextArea>
          </ContentArea>
        </GridItem>
        <GridItem gridArea="time">
          <Label>配信日時</Label>
        </GridItem>
        <GridItem gridArea="timeField">
          <DateTimeArea>
            <DatePicker
              date={date}
              handleChange={(changedDate) => setDate(changedDate)}
              style={{ fontSize: '12px', width: '104px', height: '30px' }}
            />
            <SelectBoxWrapper>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'hour',
                  register,
                }}
                style={{
                  width: '110px',
                  fontSize: '12px',
                  height: '22px',
                }}
              >
                {getHourList().map((i) => (
                  <option key={i} value={`00${i}`.slice(-2)}>
                    {`00${i}`.slice(-2)}
                  </option>
                ))}
              </SelectBox>
            </SelectBoxWrapper>
            ：
            <SelectBoxWrapper>
              <SelectBox
                control={{
                  type: 'hook',
                  name: 'minute',
                  register,
                }}
                style={{
                  width: '110px',
                  fontSize: '12px',
                  height: '22px',
                }}
              >
                {[...Array(60 / 15)].map((_, i) => {
                  const step = 15;
                  const num = `00${i * step}`.slice(-2);
                  return (
                    <option key={i} value={num}>
                      {num}
                    </option>
                  );
                })}
              </SelectBox>
            </SelectBoxWrapper>
          </DateTimeArea>
        </GridItem>
        <GridItem gridArea="image">
          <Label>画像</Label>
        </GridItem>
        <GridItem gridArea="imageField">
          <FileFieldWrapper>
            <input
              type="file"
              style={{ display: 'none' }}
              id="img"
              onChange={(e: any) => handleFileChange(e.target.files[0])}
            />
            <FileField htmlFor="img">ファイルを選択</FileField>
            <FileName>
              {img.name === '' ? 'ファイルが選択されていません' : img.name}
            </FileName>
          </FileFieldWrapper>
        </GridItem>
      </GridWrapper>
      <ValidateErrorMessageList errors={errors} />
      <ButtonWrapper>
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="プレビュー"
          color="BLUE_BORDER"
          borderRadius={4}
          handleClick={handleSubmit(() => setIsOpen(true))}
        />
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="戻る"
          color="WHITE"
          borderRadius={4}
          handleClick={() => history.push('/content/message/customer')}
        />
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="削除"
          color="RED"
          borderRadius={4}
          handleClick={handleDelete}
        />
      </ButtonWrapper>
      <SpModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        ContentComponent={(
          <SpPreviewModal
            {...getValues()}
            sendAt={`${date} ${getValues('hour')}:${getValues('minute')}`}
            image={img.file || message?.image_url || undefined}
          />
        )}
        actions={{
          onSubmit,
          onCancel: () => setIsOpen(false),
        }}
      />
    </Wrapper>
  );
};
