import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 88px);
  justify-content: center;
  align-items: center;

  p {
    font-weight: bold;
  }
`;

export const Indevelopment = () => {
  return (
    <Wrapper>
      <p>sorry... This page is under development.</p>
    </Wrapper>
  );
};
