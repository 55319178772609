import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { StylistDetailInputText } from '../parts/lv1/StylistDetailInputText';
import { StylistDetailSelect } from '../parts/lv1/StylistDetailSelect';
import { SquareButton } from '../parts/lv3/SquareButton';
import {
  addMotPoint,
  deleteMotPoint,
  getMotPointDetail,
  MotPoint,
  updateMotPoint,
} from '../../lib/api/stylist';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperContent = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  width: 300px;
  margin-right: 40px;
  margin-bottom: 40px;
`;

const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 1rem;
  }
`;

export const StylistEvaluationMotEdit = () => {
  const history = useHistory();
  const { stylistId, evaluationId } = useParams<{
    stylistId: string;
    evaluationId: string;
  }>();

  const [motPoint, setMotPoint] = useState<Partial<MotPoint>>({});

  const isAdd = useMemo(() => {
    const { pathname } = history.location;
    return pathname.includes('add');
  }, [history]);

  useEffect(() => {
    if (!isAdd) {
      getMotPointDetail(evaluationId)
        .then(({ data }) => {
          setMotPoint(data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [isAdd, evaluationId]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleChangeDate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setMotPoint((prev) => {
        return { ...prev, date: value };
      });
    },
    [],
  );

  const handleChangePoint = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setMotPoint((prev) => {
        return { ...prev, point: parseInt(value, 10) || undefined };
      });
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const params = { ...motPoint };
      if (isAdd) {
        await addMotPoint(stylistId, params);
      } else {
        await updateMotPoint(evaluationId, params);
      }
      history.goBack();
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [isAdd, history, motPoint, stylistId, evaluationId]);

  const handleClickDelete = useCallback(async () => {
    try {
      await deleteMotPoint(evaluationId);
      history.goBack();
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [history, evaluationId]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text={isAdd ? 'MOT数値登録' : 'MOT数値編集'} />
          </Margin>
        </WrapperGoBack>
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperContent>
          <FieldWrapper>
            <StylistDetailSelect
              value={motPoint.date}
              label="日付"
              options={[{ value: 'TODO', label: 'TODO' }]}
              onChange={handleChangeDate}
            />
          </FieldWrapper>

          <FieldWrapper>
            <StylistDetailInputText
              value={motPoint.point}
              label="MOT数値"
              placeholder="入力してください"
              onChange={handleChangePoint}
            />
          </FieldWrapper>

          <WrapperButton>
            <SquareButton
              fontSize={15}
              width={136}
              height={40}
              text="登録"
              borderRadius={4}
              color="BLUE"
              handleClick={handleSubmit}
            />

            {!isAdd && (
              <SquareButton
                fontSize={15}
                width={136}
                height={40}
                text="削除"
                borderRadius={4}
                color="RED"
                handleClick={handleClickDelete}
              />
            )}
          </WrapperButton>
        </WrapperContent>
      </WrapperShadow>
    </Wrapper>
  );
};
