import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { SquareButton } from '../lv3/SquareButton';
import { Color } from '../../../lib/styles/variables';
import { deleteAdmin } from '../../../lib/api/admin';

const Wrapper = styled.div`
  width: 631px;
  height: 235px;
  padding: 24px;
`;

const MainText = styled.p`
  font-size: 18px;
  line-height: 27px;
  color: #757575;
`;

const SubText = styled.p`
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  color: ${Color.BRACK};
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
`;

const DeleteButtonMargin = styled.div`
  margin-left: 16px;
`;

type Props = {
  closeModal: () => void;
  adminId: number;
};

export const EditAdminModal: React.FC<Props> = ({ closeModal, adminId }) => {
  const history = useHistory();
  const handleDeleteAdmin = () => {
    deleteAdmin(adminId).then(() => {
      history.push('/admin');
    });
  };

  return (
    <Wrapper>
      <MainText>アカウントを消去しますか？</MainText>
      <SubText>一度削除を行うとアカウントはもとに戻りません</SubText>
      <ButtonArea>
        <SquareButton
          fontSize={18}
          height={50}
          width={154}
          text="キャンセル"
          color="BLUE_BORDER"
          handleClick={() => closeModal()}
        />
        <DeleteButtonMargin>
          <SquareButton
            fontSize={18}
            height={50}
            width={154}
            text="削除"
            color="RED"
            handleClick={() => handleDeleteAdmin()}
          />
        </DeleteButtonMargin>
      </ButtonArea>
    </Wrapper>
  );
};
