import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { SquareButton } from '../parts/lv3/SquareButton';
import { FontSize } from '../../lib/styles/variables';
import { ModalContext } from '../../lib/contexts/ModalContext';
import { EditAdminModal } from '../parts/lv1/EditAdminModal';
import { updateAdmin, Admin } from '../../lib/api/admin';
import { Margin } from '../parts/lv3/Margin';
import useAllStore from '../../lib/hooks/useAllStore';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-right: 24px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  strong {
    margin-left: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FieldLabel = styled.p`
  font-size: ${FontSize.X_LARGE};
  line-height: 30px;
  color: #757575;
  margin-bottom: 8px;
`;

const SelectBoxArea = styled.div`
  border: 0.5px solid #979797;
  width: 130px;
  font-size: 11px;
  height: 47px;
  display: flex;
  align-items: center;
  padding: 2px 9px;
  margin-bottom: 24px;
  position: relative;

  span {
    width: 11px;
    position: absolute;
    right: 8px;
    top: 14px;
    pointer-events: none;
  }
`;

const SelectBox = styled.select`
  width: 117px;
  height: 40px;
  border: none;
  border-radius: 0;
  background: #fff;
  letter-spacing: 1.63304px;
  color: #757575;
  font-size: ${FontSize.TITLE};
`;

const ButtonMargin = styled.div`
  margin-bottom: 40px;
`;

const TextArea = styled.textarea`
  height: 136px;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};
`;

const SubmitButtonArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 200px;
`;

const DeleteButtonMargin = styled.div`
  margin-left: 40px;
`;

export const EditAdmin = () => {
  const { setModal, closeModal } = useContext(ModalContext);
  const history = useHistory();
  const { state } = useLocation<Admin>();
  const [salons, setSalons] = useState<(number | string)[]>(() => {
    return state.manage_all_salons
      ? ['all']
      : state.manage_salons
      ? state.manage_salons.map((salon) => salon.salon_id)
      : [];
  });
  const allStore = useAllStore();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      remarks: state.remarks,
    },
  });

  const handleSelectStore = (index: number, salonId: number | string) => {
    const copiedSalons = salons.concat();
    copiedSalons[index] = salonId;

    setSalons(copiedSalons);
  };

  const addSelectBox = () => {
    const copiedSalons = salons.concat();
    copiedSalons.push('');

    setSalons(copiedSalons);
  };

  const onSubmit = handleSubmit((value) => {
    let manage_all_salons = false;
    const manage_salon_ids: number[] = [];

    salons.forEach((salon) => {
      if (salon === 'all') {
        manage_all_salons = true;
      } else if (typeof salon === 'number') {
        manage_salon_ids.push(salon);
      }
    });

    updateAdmin(
      {
        remarks: value.remarks || '',
        manage_all_salons,
        manage_salon_ids,
      },
      state.id,
    ).then(() => {
      history.push('/admin');
    });
  });

  const ContentComponent = () => (
    <EditAdminModal adminId={state.id} closeModal={() => closeModal()} />
  );

  return (
    <Wrapper>
      <TopContentWrapper>
        <ArrowIcon
          style={{
            height: 14,
            width: 8,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          direction="LEFT"
          handleClick={() => history.goBack()}
        />
        <MainContentTitle text="管理者一覧" />
      </TopContentWrapper>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: 4,
          minHeight: 'calc(100vh - 200px)',
          padding: '64px 64px 40px 64px',
        }}
      >
        <Form>
          <FieldLabel>店舗</FieldLabel>
          {salons.map((salon, i) => (
            <SelectBoxArea key={i}>
              <SelectBox
                name="storeName"
                onChange={(e) => handleSelectStore(i, e.target.value)}
                value={salon}
              >
                <option value="">Select</option>
                <option value="all">全店</option>
                {allStore
                  ? allStore.map((store) => (
                    <option value={store.id} key={store.id}>
                      {store.name}
                    </option>
                    ))
                  : null}
              </SelectBox>
              <span>▼</span>
            </SelectBoxArea>
          ))}
          <ButtonMargin>
            <SquareButton
              fontSize={24}
              height={36}
              text="＋ 店舗を追加"
              color="NO_BORDER"
              handleClick={() => addSelectBox()}
            />
          </ButtonMargin>

          <Margin margin="40px 0 0 0">
            <FieldLabel>備考</FieldLabel>
            <TextArea name="remarks" ref={register} />
          </Margin>

          <SubmitButtonArea>
            <SquareButton
              fontSize={30}
              height={78}
              width={282}
              text="登録"
              color="BLUE"
              handleClick={() => onSubmit()}
            />
            <DeleteButtonMargin>
              <SquareButton
                fontSize={30}
                height={78}
                width={282}
                text="削除する"
                color="RED"
                handleClick={() =>
                  setModal({
                    ContentComponent,
                  })
                }
              />
            </DeleteButtonMargin>
          </SubmitButtonArea>
        </Form>
      </ShadedContainer>
    </Wrapper>
  );
};
