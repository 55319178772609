import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { FontSize, Color } from '../../lib/styles/variables';
import { SquareButton } from '../parts/lv3/SquareButton';
import { RadioButtonWithText } from '../parts/lv3/RadioButtonWithText';
import { CreateMessageLink } from '../parts/lv1/CreateMessageLink';
import { Table } from '../parts/lv1/Table';
import { searchStylists, Stylist } from '../../lib/api/questionaries';
import { getToggle } from '../../lib/utils/array';
import { CheckBox, CheckBoxWithText } from '../parts/lv3/CheckBoxWithText';
import { ValidateErrorMessageList } from '../parts/lv3/ValidationErrorMessage';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridWrapper = styled.div`
  display: grid;
  grid:
    'target targetField' 42px
    'store storeField' 42px
    'id idField' 42px
    / 147px 1fr;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const ButtonWrapper = styled.div`
  width: 296px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const TextField = styled.input`
  width: 170px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #979797;
  margin-left: 10px;
  font-size: 12px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const TableWrapper = styled.div`
  margin: 20px 0;

  > p {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 6px;
  }
`;

const SearchResult = styled.div`
  display: flex;
  align-items: center;
  & > div:last-child {
    margin-left: 40px;
  }
`;

export const FilterQuesionnaireStylist = () => {
  const [searchResult, setSearchResult] = useState<Stylist[] | null>(null);
  const history = useHistory();
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);
  const [selectStylistIds, setSelectStylistIds] = useState<number[]>([]);
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      send_target: 'salon_all',
      stylist_id: '',
      salon_name: '',
    },
  });

  const columns = [
    { header: '配信可否', accessor: 'deliverable', width: 78 },
    { header: '従業員名', accessor: 'user_kana_name', width: 150 },
  ];

  const onSubmit = handleSubmit((value) => {
    searchStylists(value)
      .then(({ data }) => {
        setSearchResult(data.target_stylists);
      })
      .catch((e) => {
        setErrorMessageList(['エラーが発生しました']);
      });
  });

  const tableData = !searchResult
    ? []
    : searchResult.map((it) => ({
        ...it,
        deliverable: (
          <CheckBox
            size={{ width: 24, height: 24 }}
            isChecked={selectStylistIds.some(
              (stylistId) => stylistId === it.id,
            )}
            handleClick={() =>
              setSelectStylistIds(getToggle(it.id, selectStylistIds))
            }
          />
        ),
      }));

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="アンケート管理" />
      </Margin>
      <GridWrapper>
        <GridItem gridArea="target">
          <Label>配信対象</Label>
        </GridItem>
        <GridItem gridArea="targetField" isRightmost>
          <RadioButtonWrapper>
            <RadioButtonWithText
              text="全店"
              id="salon_all"
              name="send_target"
              register={register}
              isSelected={watch().send_target === 'salon_all'}
            />
            <RadioButtonWithText
              text="店舗"
              id="salon_name"
              name="send_target"
              register={register}
              isSelected={watch().send_target === 'salon_name'}
            />
            <RadioButtonWithText
              text="従業員ID"
              id="stylist_id"
              name="send_target"
              register={register}
              isSelected={watch().send_target === 'stylist_id'}
            />
          </RadioButtonWrapper>
        </GridItem>

        <GridItem gridArea="store">
          <Label>店舗</Label>
        </GridItem>
        <GridItem gridArea="storeField" isRightmost>
          <TextField
            name="salon_name"
            ref={register}
            placeholder="店舗名を入力してください"
          />
        </GridItem>

        <GridItem gridArea="id" isBottom>
          <Label>従業員ID</Label>
        </GridItem>
        <GridItem
          gridArea="idField"
          isRightmost
          isBottom
          placeholder="IDを入力してください"
        >
          <TextField name="stylist_id" ref={register} />
        </GridItem>
      </GridWrapper>
      <ValidateErrorMessageList
        errors={{}}
        errorMessageList={errorMessageList}
      />
      <ButtonWrapper>
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="検索"
          color="BLUE_BORDER"
          borderRadius={4}
          handleClick={onSubmit}
        />
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="戻る"
          color="WHITE"
          borderRadius={4}
          handleClick={() => history.push('/content/questionnaire/stylist')}
        />
      </ButtonWrapper>
      {searchResult ? (
        <>
          <CreateMessageLink
            path={`/content/questionnaire/stylist/create?id=${selectStylistIds.join(
              ',',
            )}`}
            isStylist
          />
          <TableWrapper>
            <SearchResult>
              検索結果：
              {searchResult.length}
              件
              <CheckBoxWithText
                text="全て選択する"
                size={{ height: 30 }}
                isChecked={selectStylistIds.length === searchResult.length}
                handleClick={() =>
                  selectStylistIds.length === searchResult.length
                    ? setSelectStylistIds([])
                    : setSelectStylistIds(searchResult.map((it) => it.id))
                }
              />
            </SearchResult>
            <Table columns={columns} data={tableData} />
          </TableWrapper>
          <CreateMessageLink
            path={`/content/questionnaire/stylist/create?id=${selectStylistIds.join(
              ',',
            )}`}
            isStylist
          />
        </>
      ) : null}
    </Wrapper>
  );
};
