import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../lib/styles/variables';

const Wrapper = styled.div``;

const Label = styled.label<{ size: Props['size'] }>`
  display: flex;
  width: ${({ size }) => size.width}px;
  height: ${({ size }) => size.height}px;
  align-items: center;
`;

const CheckedCheckBox = styled.div<{ size: Props['size'] }>`
  width: ${({ size }) => size.height / 2}px;
  height: ${({ size }) => size.height / 2}px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.BLUE};
  color: ${Color.WHITE};
`;

const UnCheckedCheckBox = styled.div<{ size: Props['size']; color?: string }>`
  width: ${({ size }) => size.height / 2}px;
  height: ${({ size }) => size.height / 2}px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.WHITE};
  border: 1px solid ${({ color }) => color || '#979797'};
`;

const Text = styled.p<{ size: Props['size'] }>`
  font-size: ${({ size }) => size.height / 2}px;
`;

type Props = {
  text: string;
  id?: string;
  register?: any;
  size: {
    width?: number;
    height: number;
  };
  isChecked?: boolean;
  handleClick?: () => void;
  color?: string;
};

export const CheckBoxWithText: React.FC<Props> = (props) => {
  const { text, id, register, size, isChecked, handleClick, color } = props;

  return (
    <Wrapper>
      <input
        type="checkbox"
        name={id}
        ref={register}
        id={id}
        style={{ display: 'none' }}
      />
      <Label htmlFor={id} size={size} onClick={handleClick}>
        {isChecked ? (
          <CheckedCheckBox size={size}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/check.svg`}
              alt="check"
              height="10"
              width="10"
            />
          </CheckedCheckBox>
        ) : (
          <UnCheckedCheckBox size={size} color={color} />
        )}
        <Text size={size}>{text}</Text>
      </Label>
    </Wrapper>
  );
};

const CheckBoxLabel = styled.label<{ size: Props['size'] }>`
  display: flex;
  width: ${({ size }) => size.width}px;
  height: ${({ size }) => size.height}px;
  align-items: center;
  justify-content: center;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnChecked = styled.div<{ size: Props['size'] }>`
  width: ${({ size }) => size.height / 2}px;
  height: ${({ size }) => size.height / 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.WHITE};
  border: 1px solid #979797;
`;

const Checked = styled.div<{ size: Props['size'] }>`
  width: ${({ size }) => size.height / 2}px;
  height: ${({ size }) => size.height / 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.BLUE};
  color: ${Color.WHITE};
`;

type CheckBoxProps = {
  id?: string;
  register?: any;
  size: {
    width: number;
    height: number;
  };
  isChecked?: boolean;
  handleClick?: () => void;
};

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const { id, register, size, isChecked, handleClick } = props;
  return (
    <CheckBoxWrapper>
      <input
        type="checkbox"
        name={id}
        ref={register}
        id={id}
        style={{ display: 'none' }}
      />
      <CheckBoxLabel htmlFor={id} size={size} onClick={handleClick}>
        {isChecked ? (
          <Checked size={size}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/check.svg`}
              alt="check"
              height={7}
              width={7}
            />
          </Checked>
        ) : (
          <UnChecked size={size} />
        )}
      </CheckBoxLabel>
    </CheckBoxWrapper>
  );
};
