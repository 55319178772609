import React, { useState } from 'react';
import styled from 'styled-components';
import { SquareButton } from '../lv3/SquareButton';
import { Margin } from '../lv3/Margin';

const Wrapper = styled.form`
  width: 1098px;
  height: 1176px;
`;

const MainContainer = styled.div`
  margin: 40px 130px 40px 64px;
`;

const Title = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #757575;
  margin-bottom: 8px;
`;

const ProductName = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #212121;
`;

const TextField = styled.input`
  width: 100%;
  height: 39px;
  background: #ffffff;
  border: 1px solid #979797;
  padding: 8px 16px;
`;

const FileFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileField = styled.label`
  width: 144px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #979797;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileName = styled.p`
  margin-left: 16px;
  line-height: 24px;
  color: #212121;
`;

const TextArea = styled.div`
  padding: 8px 16px;
  width: 904px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #979797;
`;

const DescriptionList = styled.div``;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 104px;
`;

const Spacer = styled.span`
  width: 1px;
  margin-left: 40px;
`;

type Description = {
  bigTitle: string;
  smallTitle: string;
  imgName: string;
  img: string;
  text: string;
};

type Props = {
  closeModal: () => void;
};

export const ProductDescriptionModal: React.FC<Props> = ({ closeModal }) => {
  const [description, setDescription] = useState<Description[]>([
    { bigTitle: '', smallTitle: '', imgName: '', img: '', text: '' },
    { bigTitle: '', smallTitle: '', imgName: '', img: '', text: '' },
  ]);

  const deleteDescription = (index: number) => {
    const nextArray = description.concat();
    nextArray.splice(index, 1);
    setDescription(nextArray);
  };

  const addDescription = () => {
    const nextArray = description.concat();
    nextArray.push({
      bigTitle: '',
      smallTitle: '',
      imgName: '',
      img: '',
      text: '',
    });
    setDescription(nextArray);
  };

  return (
    <Wrapper>
      <MainContainer>
        <Margin margin="0 0 18px 0">
          <Title>商品説明</Title>
          <ProductName>
            アリミノ メン フリーズキープグリース 単品 100g
          </ProductName>
        </Margin>
        <Margin margin="0 0 24px 0">
          <Title>大見出し</Title>
          <TextField placeholder="大見出し" />
        </Margin>
        {description.map((_, index) => (
          <DescriptionList key={index}>
            <Margin margin="0 0 24px 0">
              <Title>小見出し</Title>
              <TextField placeholder="小見出し" />
            </Margin>
            <Margin margin="0 0 24px 0">
              <Title>画像</Title>
              <input type="file" style={{ display: 'none' }} />
              <FileFieldWrapper>
                <FileField>ファイルを選択</FileField>
                <FileName>.png</FileName>
              </FileFieldWrapper>
            </Margin>
            <Margin margin="0 0 16px 0">
              <Title>本文</Title>
              <TextArea placeholder="本文" />
            </Margin>
            <Margin margin="0 0 40px 0">
              {description.length === index + 1 ? (
                <SquareButton
                  text="追加"
                  color="BLUE_BORDER"
                  height={50}
                  width={154}
                  fontSize={16}
                  handleClick={() => addDescription()}
                />
              ) : (
                <SquareButton
                  text="削除"
                  color="RED_BORDER"
                  height={50}
                  width={154}
                  fontSize={16}
                  handleClick={() => deleteDescription(index)}
                />
              )}
            </Margin>
          </DescriptionList>
        ))}
      </MainContainer>

      <ButtonArea>
        <SquareButton
          text="登録"
          color="BLUE"
          height={56}
          width={160}
          fontSize={24}
          handleClick={() => closeModal()}
        />
        <Spacer />
        <SquareButton
          text="削除"
          color="RED"
          height={56}
          width={160}
          fontSize={24}
          handleClick={() => closeModal()}
        />
      </ButtonArea>
    </Wrapper>
  );
};
