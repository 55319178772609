import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../lib/contexts/AccountContext';
import { TextField } from '../parts/lv3/TextField';
import { ValidationErrorMessage } from '../parts/lv3/ValidationErrorMessage';
import storage from '../../lib/storage';

const Wrapper = styled.div`
  background: rgba(214, 214, 214, 0.54);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainCard = styled.div`
  width: 349px;
  height: 387px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  border-radius: 4px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
`;

const TextFieldContainer = styled.div``;

const TextFieldWrapper = styled.div`
  margin-top: 24px;
`;

const Label = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.86);
`;

const SubmitButton = styled.button`
  background: #4a90e2;
  border-radius: 4px;
  width: 300px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
`;

export const Login = () => {
  const [isFailed, setIsFailed] = useState<string | null>(null);
  const { login, account } = useContext(AccountContext);
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      login_id: '0000001',
      password: 'pass1234',
    },
  });

  useEffect(() => {
    storage.removeItem('selected_salon');
  }, []);

  const onSubmit = handleSubmit((value) => {
    login(value)
      .then(() => {
        history.push('/select-salon');
      })
      .catch(() => {
        setIsFailed('ログインに失敗しました');
      });
  });

  if (account !== null) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <MainCard>
        <Title>Mirrorball System</Title>
        <TextFieldContainer>
          <TextFieldWrapper>
            <Label>ID</Label>
            <TextField
              control={{ type: 'hook', name: 'login_id', register }}
              style={{
                width: '300px',
                height: '30px',
                border: '0.5px solid #C7C7CC',
              }}
            />
          </TextFieldWrapper>

          <TextFieldWrapper>
            <Label>パスワード</Label>
            <TextField
              control={{ type: 'hook', name: 'password', register }}
              style={{
                width: '300px',
                height: '30px',
                border: '0.5px solid #C7C7CC',
              }}
            />
          </TextFieldWrapper>
        </TextFieldContainer>

        <ValidationErrorMessage message={isFailed} />

        <SubmitButton onClick={onSubmit}>ログイン</SubmitButton>
      </MainCard>
    </Wrapper>
  );
};
