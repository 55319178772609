import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Table } from '../parts/lv1/Table';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { getCarousel, updateCaroucel, Caroucel } from '../../lib/api/carousel';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const FileFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileField = styled.label`
  width: 96px;
  height: 22px;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileName = styled.p`
  font-size: 12px;
  margin-left: 8px;
  line-height: 22px;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;

const TextField = styled.div`
  width: 100%;
  height: 100%;

  > input {
    width: 100%;
    height: 100%;

    border: 1px solid #979797;
  }
`;

const StyledButton = styled.button`
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e24a4a;
  background: #fff;
`;

const LiftWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  > div {
    width: 33px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Border = styled.span`
  width: 1px;
  height: 100%;
  background: #979797;
`;

const TriangleButton = styled.button`
  background: #fff;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const Spacer = styled.span`
  width: 24px;
`;

const ImgUploader = ({
  id,
  img,
  setImg,
}: {
  id: number;
  img?: string;
  setImg: (id: number, value: string) => void;
}) => {
  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        id={id.toString()}
        onChange={(e: any) =>
          setImg(id, e.target.files[0].name ?? 'データの読み取りに失敗しました')
        }
      />
      <FileFieldWrapper>
        <FileField htmlFor={id.toString()}>ファイルを選択</FileField>
        <FileName>{img ?? '画像が選択されていません'}</FileName>
      </FileFieldWrapper>
    </>
  );
};

const LinkField = ({
  id,
  value,
  setLink,
}: {
  id: number;
  value: string;
  setLink: (id: number, value: string) => void;
}) => {
  return (
    <TextField>
      <input value={value} onChange={(e: any) => setLink(id, e.target.value)} />
    </TextField>
  );
};

const EnhancedButton = ({
  id,
  removeCarousel,
}: {
  id: number;
  removeCarousel: (id: number) => void;
}) => {
  return <StyledButton onClick={() => removeCarousel(id)}>削除</StyledButton>;
};

const LiftButton = ({
  index,
  moveCaroucel,
  isFirst,
  isLast,
}: {
  index: number;
  moveCaroucel: (index: number, point: 'UP' | 'DOWN') => void;
  isFirst: boolean;
  isLast: boolean;
}) => {
  return (
    <LiftWrapper>
      <div>
        {isFirst ? null : (
          <TriangleButton onClick={() => moveCaroucel(index, 'UP')}>
            ▲
          </TriangleButton>
        )}
      </div>
      <Border />
      <div>
        {isLast ? null : (
          <TriangleButton onClick={() => moveCaroucel(index, 'DOWN')}>
            ▼
          </TriangleButton>
        )}
      </div>
    </LiftWrapper>
  );
};

export const EcCarousel = () => {
  const [carousel, setCarousel] = useState<Caroucel[]>([]);
  const [isSuccess, setIsSuccess] = useState<string | null>(null);

  const columns = [
    { header: '表示順', accessor: 'order', width: 67, noPadding: true },
    { header: '画像', accessor: 'img', width: 447 },
    { header: 'リンク先', accessor: 'link', width: 447 },
    { header: '', accessor: 'deleteButton', width: 60 },
  ];

  const setImg = (id: number, value: string) => {
    const nextArray = carousel.concat();
    nextArray[id].base64 = value;
    delete nextArray[id].image_url;

    setCarousel(nextArray);
  };

  const setLink = (id: number, value: string) => {
    const nextArray = carousel.concat();
    nextArray[id].link_url = value;

    setCarousel(nextArray);
  };

  const removeCarousel = (id: number) => {
    const nextArray = carousel.filter((_, i) => i !== id);
    setCarousel(nextArray);
  };

  const moveCaroucel = (index: number, point: 'UP' | 'DOWN') => {
    const nextArray = carousel.concat();
    nextArray.splice(index, 1);
    if (point === 'UP') {
      nextArray.splice(index, 0, carousel[index]);
    } else if (point === 'DOWN') {
      nextArray.splice(index + 1, 0, carousel[index]);
    }

    setCarousel(nextArray);
  };

  const addCaroucel = () => {
    const nextArray = carousel.concat();
    nextArray.push({ seq: carousel.length + 1, link_url: '', base64: '' });

    setCarousel(nextArray);
  };

  const handleUpdateCaroucel = () => {
    updateCaroucel(carousel).then(() => {
      setIsSuccess('保存に成功しました');
    });
  };

  const makeData = (): any[] => {
    const data: any[] = [];
    carousel.forEach(function(item: Caroucel, index: number) {
      const nextItem = {
        order: (
          <LiftButton
            index={index}
            moveCaroucel={moveCaroucel}
            isFirst={index === 0}
            isLast={carousel.length === index + 1}
          />
        ),
        img: (
          <ImgUploader
            id={index}
            img={item.image_url || item.base64}
            setImg={setImg}
          />
        ),
        link: <LinkField id={index} value={item.link_url} setLink={setLink} />,
        deleteButton: (
          <EnhancedButton id={index} removeCarousel={removeCarousel} />
        ),
      };

      data.push(nextItem);
    });

    return data;
  };

  useEffect(() => {
    getCarousel().then(({ data }) => {
      setCarousel(data);
    });
  }, []);

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="店舗Topカルーセル" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Margin margin="16px 0 0 0">
          <Table columns={columns} data={makeData()} />
        </Margin>
        <SubmitButtonWrapper>
          <SquareButton
            text="追加"
            color="BLUE"
            width={136}
            height={40}
            borderRadius={4}
            fontSize={15}
            handleClick={addCaroucel}
          />

          <Spacer />

          <SquareButton
            text="保存"
            color="BLUE_BORDER"
            width={136}
            height={40}
            borderRadius={4}
            fontSize={15}
            handleClick={() => handleUpdateCaroucel()}
          />

          {isSuccess && (
            <>
              <Spacer />
              {isSuccess}
            </>
          )}
        </SubmitButtonWrapper>
      </ShadedContainer>
    </Wrapper>
  );
};
