import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Margin } from '../parts/lv3/Margin';
import { Table } from '../parts/lv1/Table';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 500px;

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4a90e2;
  }
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const TableLabelContainer = styled.div`
  margin-bottom: 40px;
`;

export const CustomerOrder = () => {
  const history = useHistory();

  const columns = [
    { header: '商品名', accessor: 'itemName', width: 616 },
    { header: '個数', accessor: 'count', width: 104 },
    { header: '合計金額', accessor: 'sum', width: 104 },
  ];

  const data = [
    { itemName: 'リンス', count: '1,000,000個', sum: '1,000,000円' },
    { itemName: 'リンス', count: '10,000個', sum: '10,000円' },
    { itemName: 'リンス', count: '10,000個', sum: '10,000円' },
  ];

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Wrapper>
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={handleClickArrowLeft}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text="注文詳細" />
        </Margin>
      </TopContent>
      <WrapperShadow>
        <TableLabelContainer>
          <Table columns={columns} data={data} />
        </TableLabelContainer>
      </WrapperShadow>
    </Wrapper>
  );
};
