import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { CheckBoxWithText } from '../lv3/CheckBoxWithText';
import { RadioButtonWithText } from '../lv3/RadioButtonWithText';
import { SelectBox } from '../lv3/SelectBox';
import dayjs, { getMonthOfDateList, getWeeks } from '../../../lib/utils/dayjs';
import { getToggle } from '../../../lib/utils/array';

const Wrapper = styled.div`
  display: flex;
  border: 1px solid #979797;
  margin: 24px 0px;
  display: flex;
  flex-flow: column;
  padding: 20px;
`;

const Title = styled.div`
  font-weight: bold;
  color: #212121;
`;
const StylistArea = styled.div`
  margin-top: 20px;
`;
const StylistTitleArea = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const TitleStyle = css`
  font-weight: bold;
  font-size: 14px;
  color: #212121;
`;

const StylistTitle = styled.div`
  ${TitleStyle}
  margin-right: 20px;
`;

const StylistSelectArea = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 14px;
  font-weight: bold;
  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const DateSelectArea = styled.div`
  margin-top: 8px;
  border: 1px solid #979797;
  padding: 14px;
`;
const DateArea = styled.div``;
const DateTitle = styled.div`
  margin-top: 20px;
  ${TitleStyle}
`;
const DateBetweenLabel = styled.p`
  margin: 0px 4px;
`;
const DateBetweenArea = styled.div`
  display: flex;
`;
const DateWeekdayArea = styled.div`
  display: flex;
  font-weight: bold;
  & > div:not(:last-of-type) {
    margin-right: 10px;
  }
  & > div > label > div {
    margin-right: 4px;
  }
`;
const DateEverydayArea = styled.div``;
const ShiftArea = styled.div`
  margin-top: 20px;
`;
const ShiftTitle = styled.div`
  ${Title}
`;
const ActionsArea = styled.div`
  margin-top: 20px;
  display: flex;
`;
const SettingBtn = styled.div`
  width: 136px;
  background: #4a90e2;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 0px;
  text-align: center;
  margin-right: 20px;
`;
const CloseBtn = styled.div`
  width: 136px;
  color: #212121;
  border: 1px solid #212121;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 0px;
  text-align: center;
`;
const ShiftSelectArea = styled.div`
  margin-top: 8px;
  border: 1px solid #979797;
  padding: 14px;
`;

const ShiftActionArea = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;
const ShiftManagementBtn = styled.div`
  background: #4a90e2;
  color: #ffffff;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 4px;
`;

const WorkArea = styled.div`
  display: flex;
`;

const stylistMockData = [
  { id: 1, name: 'フリー', isChecked: false },
  { id: 2, name: '青空　一郎', isChecked: false },
  { id: 3, name: '青空　二郎', isChecked: false },
  { id: 4, name: '青空　三郎', isChecked: false },
  { id: 5, name: '青空　四郎', isChecked: false },
  { id: 6, name: '青空　四郎', isChecked: false },
  { id: 7, name: '青空　四郎', isChecked: false },
  { id: 8, name: '青空　四郎', isChecked: false },
  { id: 9, name: '青空　四郎', isChecked: false },
  { id: 10, name: '青空　四郎', isChecked: false },
  { id: 11, name: '青空　四郎', isChecked: false },
  { id: 12, name: '青空　四郎', isChecked: false },
  { id: 13, name: '青空　四郎', isChecked: false },
  { id: 14, name: '青空　四郎', isChecked: false },
  { id: 15, name: '青空　四郎', isChecked: false },
  { id: 16, name: '青空　四郎', isChecked: false },
  { id: 17, name: '青空　四郎', isChecked: false },
];

type Props = {
  day: dayjs.Dayjs;
};

export const ShiftActions: React.FC<Props> = ({ day }) => {
  const [isShow, setIsShow] = useState(false);
  const [selectStylistIds, setSelectStylistIds] = useState<number[]>([]);
  const [selectWeekIndexes, setSelectWeekIndexes] = useState<string[]>([]);
  const { register, getValues, watch, handleSubmit } = useForm({
    defaultValues: {
      isAllCheck: false,
      dateType: 'between',
      startDate: 1,
      endDate: 1,
      shiftType: 'work',
    },
  });

  useEffect(() => {
    if (getValues('isAllCheck')) {
      setSelectStylistIds(stylistMockData.map((it) => it.id));
    } else {
      setSelectStylistIds([]);
    }
  }, [getValues('isAllCheck')]);

  const onSearch = handleSubmit((data) => {
    const value = { ...data, selectStylistIds, selectWeekIndexes };
    console.log(value);
  });

  if (!isShow) {
    return (
      <ShiftActionArea>
        <ShiftManagementBtn onClick={() => setIsShow(true)}>
          シフトの一括設定
        </ShiftManagementBtn>
      </ShiftActionArea>
    );
  }

  return (
    <Wrapper>
      <Title>シフトの一括選択</Title>
      <StylistArea>
        <StylistTitleArea>
          <StylistTitle>スタッフ選択</StylistTitle>
          <CheckBoxWithText
            id="isAllCheck"
            text="全選択"
            size={{ height: 24 }}
            register={register}
            isChecked={watch('isAllCheck')}
          />
        </StylistTitleArea>

        <StylistSelectArea>
          {stylistMockData.map((it) => (
            <CheckBoxWithText
              id={`selectedStylistIds[${it.id}]`}
              key={it.id}
              text={it.name}
              size={{ height: 24 }}
              isChecked={selectStylistIds.includes(it.id)}
              handleClick={() =>
                setSelectStylistIds(getToggle(it.id, selectStylistIds))
              }
            />
          ))}
        </StylistSelectArea>
      </StylistArea>
      <DateArea>
        <DateTitle>日付を選択</DateTitle>
        <DateSelectArea>
          <DateBetweenArea>
            <RadioButtonWithText
              id="between"
              text="期間を指定"
              name="dateType"
              register={register}
              isSelected={watch('dateType') === 'between'}
            />
            <SelectBox
              control={{
                type: 'hook',
                name: 'startDate',
                register,
              }}
              style={{
                width: '84px',
                height: '22px',
                fontSize: '12px',
                border: 'all',
                padding: '0px 8px',
              }}
            >
              {getMonthOfDateList(day).map((it) => (
                <option key={it.date()} value={it.date()}>
                  {`${it.date()}日`}
                </option>
              ))}
            </SelectBox>
            <DateBetweenLabel>から</DateBetweenLabel>
            <SelectBox
              control={{
                type: 'hook',
                name: 'endDate',
                register,
              }}
              style={{
                width: '84px',
                height: '22px',
                fontSize: '12px',
                border: 'all',
                padding: '0px 8px',
              }}
            >
              {getMonthOfDateList(day).map((it) => (
                <option key={it.date()} value={it.date()}>
                  {`${it.date()}日`}
                </option>
              ))}
            </SelectBox>
            <DateBetweenLabel>まで</DateBetweenLabel>
          </DateBetweenArea>
          <DateWeekdayArea>
            <RadioButtonWithText
              id="weekday"
              text="曜日を指定"
              name="dateType"
              register={register}
              isSelected={watch('dateType') === 'weekday'}
            />

            {getWeeks().map((it) => (
              <CheckBoxWithText
                id={`selectWeekIndexes-${it.index}`}
                key={it.index}
                text={it.str}
                size={{ height: 24 }}
                isChecked={selectWeekIndexes.includes(it.index)}
                handleClick={() =>
                  setSelectWeekIndexes(getToggle(it.index, selectWeekIndexes))
                }
              />
            ))}
          </DateWeekdayArea>
          <DateEverydayArea>
            <RadioButtonWithText
              id="everyday"
              text="毎日"
              name="dateType"
              register={register}
              isSelected={watch('dateType') === 'everyday'}
            />
          </DateEverydayArea>
        </DateSelectArea>
      </DateArea>
      <ShiftArea>
        <ShiftTitle>スタッフ選択</ShiftTitle>
        <ShiftSelectArea>
          <WorkArea>
            <RadioButtonWithText
              id="work"
              text="出勤"
              name="shiftType"
              register={register}
              isSelected={watch('shiftType') === 'work'}
            />
            <SelectBox
              control={{
                type: 'hook',
                name: 'startDate',
                register,
              }}
              style={{
                width: '84px',
                height: '22px',
                fontSize: '12px',
                border: 'all',
                padding: '0px 8px',
              }}
            >
              <option value="1">全日</option>
            </SelectBox>
          </WorkArea>
          <RadioButtonWithText
            id="holiday"
            text="休日"
            name="shiftType"
            register={register}
            isSelected={watch('shiftType') === 'holiday'}
          />
        </ShiftSelectArea>
      </ShiftArea>
      <ActionsArea>
        <SettingBtn onClick={onSearch}>設定する</SettingBtn>
        <CloseBtn onClick={() => setIsShow(false)}>閉じる</CloseBtn>
      </ActionsArea>
    </Wrapper>
  );
};
