import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { CustomerSearchForm } from '../parts/lv1/CustomerSearchForm';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { FontSize, Color } from '../../lib/styles/variables';
import { useSpinner } from '../../lib/hooks/useSpinner';
import { getUsers, UsersItem, GetUsersParams } from '../../lib/api/user';
import { usePager } from '../../lib/hooks/usePager';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const CustomerCardsArea = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CustomerCard = styled.div`
  height: 145px;
  width: 49%;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 40px 40px 40px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TextContainer = styled.div`
  span {
    color: #757575;
    font-size: ${FontSize.SMALL};
    margin-bottom: 8px;
  }

  p {
    color: ${Color.BRACK};
    font-size: ${FontSize.TITLE};
  }
`;

export const Customer = () => {
  const [customers, setCustomers] = useState<UsersItem[] | null>(null);
  const [sentValue, setSentValue] = useState<GetUsersParams>({
    name: '',
    type: '',
    phone_number: '',
    city: '',
    email: '',
  });
  const history = useHistory();
  const { loading, startLoading, finishLoading } = useSpinner();

  const handlePaganation = (index: number) => {
    startLoading();
    getUsers({ page: index, ...sentValue })
      .then(({ data }) => {
        setCustomers(data.data);
      })
      .finally(() => {
        finishLoading();
      });
  };

  const { Pager, setLastPage } = usePager(handlePaganation);

  const handleSearch = (value: GetUsersParams) => {
    setSentValue(value);
    startLoading();
    getUsers(value)
      .then(({ data }) => {
        setCustomers(data.data);
      })
      .finally(() => {
        finishLoading();
      });
  };

  useEffect(() => {
    startLoading();
    getUsers({
      name: '',
      type: '',
      phone_number: '',
      city: '',
      email: '',
    })
      .then(({ data }) => {
        setCustomers(data.data);
        setLastPage(data.last_page);
      })
      .finally(() => {
        finishLoading();
      });
  }, []);

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="顧客リスト" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 67px 40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <CustomerSearchForm handleSearch={handleSearch} />
        <CustomerCardsArea>
          {!loading && customers
            ? customers.length !== 0
              ? customers.map((item) => (
                <CustomerCard
                  key={item.id}
                  onClick={() => history.push(`/customer/${item.id}`)}
                >
                  <TextContainer>
                    <span>{item.sex}</span>
                    <p>
                      {item.kanji_first_name === null ||
                        item.kanji_last_name === null
                          ? '未登録'
                          : `${item.kanji_first_name} ${item.kanji_last_name}`}
                    </p>
                  </TextContainer>
                  <ArrowIcon
                    style={{
                        height: 20,
                        width: 10,
                        color: '#757575',
                      }}
                    direction="RIGHT"
                  />
                </CustomerCard>
                ))
              : 'アイテムがありません'
            : loading}
        </CustomerCardsArea>
        <Pager />
      </ShadedContainer>
    </Wrapper>
  );
};
