import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, Route, useLocation } from 'react-router';
import { Navigation } from '../parts/lv1/Navigation';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { StylistEvaluationMultiple } from '../parts/lv1/StylistEvaluationMultiple';
import { StylistEvaluationMs } from '../parts/lv1/StylistEvaluationMs';
import { StylistEvaluationMot } from '../parts/lv1/StylistEvaluationMot';
import { SquareButton } from '../parts/lv3/SquareButton';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperContent = styled.div`
  width: 100%;
`;

const WrapperInnerNavi = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StylistEvaluation = () => {
  const { id, stylistId } = useParams<{ id: string; stylistId: string }>();
  const history = useHistory();
  const { pathname } = useLocation();

  const isSelectedInner = useMemo(() => {
    const arr = pathname.split('/');
    return arr[arr.length - 1];
  }, [pathname]);

  const links = useMemo(
    () => [
      {
        name: '基本情報',
        to: `/store/detail/${id}/stylist/detail/${stylistId}`,
        isselected: false,
      },
      {
        name: '顧客リスト',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/customer`,
        isselected: false,
      },
      {
        name: '売上実績',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/sales`,
        isselected: false,
      },
      {
        name: '評価',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation`,
        isselected: true,
      },
      {
        name: '手当',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/allowance`,
        isselected: false,
      },
      {
        name: '報酬保証',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/reward`,
        isselected: false,
      },
      {
        name: '賞与',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/bonus`,
        isselected: false,
      },
      {
        name: '歩合',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/commission`,
        isselected: false,
      },
    ],
    [id, stylistId],
  );

  const innerLinks = [
    {
      name: '多面評価',
      to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation`,
      isselected: isSelectedInner === 'evaluation',
    },
    {
      name: 'MS点数',
      to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation/ms`,
      isselected: isSelectedInner === 'ms',
    },
    {
      name: 'MOT点数',
      to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation/mot`,
      isselected: isSelectedInner === 'mot',
    },
  ];

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickAdd = useCallback(() => {
    if (isSelectedInner === 'evaluation') {
      history.push(
        `/store/detail/${id}/stylist/detail/${stylistId}/evaluation/add`,
      );
    } else if (isSelectedInner === 'ms') {
      history.push(
        `/store/detail/${id}/stylist/detail/${stylistId}/evaluation/ms/add`,
      );
    } else if (isSelectedInner === 'mot') {
      history.push(
        `/store/detail/${id}/stylist/detail/${stylistId}/evaluation/mot/add`,
      );
    }
  }, [history, isSelectedInner, id, stylistId]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="美容師詳細" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} />
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperInnerNavi>
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="新規作成"
            borderRadius={4}
            color="BLUE"
            handleClick={handleClickAdd}
          />
          <Navigation links={innerLinks} />
        </WrapperInnerNavi>

        <WrapperContent>
          <Route
            exact
            path="/store/detail/:id/stylist/detail/:stylistId/evaluation"
            component={StylistEvaluationMultiple}
          />
          <Route
            exact
            path="/store/detail/:id/stylist/detail/:stylistId/evaluation/ms"
            component={StylistEvaluationMs}
          />
          <Route
            exact
            path="/store/detail/:id/stylist/detail/:stylistId/evaluation/mot"
            component={StylistEvaluationMot}
          />
        </WrapperContent>
      </WrapperShadow>
    </Wrapper>
  );
};
