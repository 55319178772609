import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { SquareButton } from '../parts/lv3/SquareButton';
import { CheckBox, CheckBoxWithText } from '../parts/lv3/CheckBoxWithText';
import { Table } from '../parts/lv1/Table';
import {
  getStylists,
  GetStylistsType as StylelistParams,
  Stylist as StylistType,
} from '../../lib/api/stylist';
import { Color } from '../../lib/styles/variables';
import { getStore, GetStoreRes } from '../../lib/api/store';

const Wrapper = styled.div`
  position: relative;
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const MainContainer = styled.div`
  margin: 0 40px;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const RightButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

const BottomMenu = styled.div`
  width: calc(100% - 294px);
  height: 108px;
  position: fixed;
  bottom: 0;
  left: 2px;
  z-index: 10;
  display: grid;
  place-items: center;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  > div {
    width: 502px;
    height: 58px;
    display: flex;
    justify-content: space-between;
  }
`;

const DecideButton = styled.button`
  width: 160px;
  height: 56px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.63304px;
  color: #ffffff;
  background: #4a90e2;
`;

const CancelButton = styled.button`
  width: 160px;
  height: 56px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.63304px;
  color: #4a90e2;
  background: #ffffff;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
  z-index: 100;
  display: grid;
  place-items: center;
`;

const MonthPickModal = styled.div`
  width: 417px;
  height: 237px;
  background: #ffffff;
  border-radius: 8px;
`;

const TableLink = styled.a`
  color: ${Color.BLUE};
`;

type FormatedStylistType = {
  checkbox: any;
  id: number;
  name: string;
  shop: string;
};

export const Stylist = () => {
  const [isOutputMode, setIsOutputMode] = useState<boolean>(false);
  const [isOverlay, setIsOverlay] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [store, setStore] = useState<GetStoreRes>();
  const [stylists, setStylists] = useState<any>([]);

  const history = useHistory();
  const { id }: any = useParams();

  const links = [
    { name: '基本情報', to: `/store/detail/${id}`, isselected: false },
    {
      name: '美容師リスト',
      to: `/store/detail/${id}/stylist`,
      isselected: true,
    },
    {
      name: '顧客リスト',
      to: `/store/detail/${id}/customer`,
      isselected: false,
    },
    {
      name: 'POSユーザー',
      to: `/store/detail/${id}/posuser`,
      isselected: false,
    },
  ];

  const columns = [
    { header: '', accessor: 'checkbox', width: 40 },
    { header: 'ID', accessor: 'id', width: 500 },
    { header: '氏名', accessor: 'name', width: 500 },
    { header: '店舗', accessor: 'shop', width: 500 },
  ];

  console.log(stylists);

  const handleCheckBox = () => {};

  const fetchStore = () => {
    getStore(id).then((storeRes) => {
      const storeData: GetStoreRes = storeRes.data;
      setStore(storeData);
      const params: StylelistParams = {
        // page: page, ページングを実装する場合にはコメントアウト
        salon_id: id,
      };
      getStylists(params).then((stylistRes) => {
        const stylistData: StylistType[] = stylistRes.data.data;
        const formated = stylistData
          ? stylistData.map((r, index) => {
              return {
                checkbox: (
                  <CheckBox
                    size={{ width: 25, height: 25 }}
                    handleClick={handleCheckBox}
                  />
                ),
                id: r.id,
                name: (
                  <TableLink
                    href={`/store/detail/${id}/stylist/detail/${r.id}`}
                  >
                    {r.user_name}
                  </TableLink>
                ),
                shop: storeData.name,
                isChecked: false,
                index,
              };
            })
          : [];
        setStylists(formated);
        setPage(page + 1);
      });
    });
  };

  useEffect(() => {
    fetchStore();
  }, []);

  return (
    <Wrapper>
      {isOverlay && (
        <Overlay>
          <MonthPickModal />
        </Overlay>
      )}
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={() => history.push('/store')}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text={store ? store.name : ''} />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px 0 150px',
          margin: '0 32px 0 0',
          minHeight: 'calc(100vh - 100px)',
        }}
      >
        <MainContainer>
          <ButtonArea>
            <SquareButton
              fontSize={16}
              width={134}
              height={32}
              color="BLUE_BORDER"
              text="美容師の登録"
              type="button"
              handleClick={() =>
                history.push(`/store/detail/${id}/stylist/add`)
              }
            />
            <RightButtonArea>
              {isOutputMode && (
                <CheckBoxWithText
                  text="全選択"
                  size={{ width: 100, height: 40 }}
                  isChecked
                />
              )}
              {isOutputMode ? (
                <SquareButton
                  fontSize={16}
                  width={242}
                  height={32}
                  color="BLUE"
                  text="CSVで給与情報を書き出す"
                  type="button"
                  handleClick={() => setIsOutputMode(false)}
                />
              ) : (
                <SquareButton
                  fontSize={16}
                  width={242}
                  height={32}
                  color="BLUE_BORDER"
                  text="CSVで給与情報を書き出す"
                  type="button"
                  handleClick={() => setIsOutputMode(true)}
                />
              )}
            </RightButtonArea>
          </ButtonArea>
          <Table columns={columns} data={stylists} />
        </MainContainer>
      </ShadedContainer>
      {isOutputMode && (
        <BottomMenu>
          <div>
            <CancelButton onClick={() => setIsOutputMode(false)}>
              キャンセル
            </CancelButton>
            <DecideButton onClick={() => setIsOverlay(true)}>決定</DecideButton>
          </div>
        </BottomMenu>
      )}
    </Wrapper>
  );
};
