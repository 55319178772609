import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Component = styled.th<{ width: string }>`
  width: ${({ width }) => width};
  padding: 10px 12px;
  background-color: #f1f1f1;
  border-right: 1px solid #979797;
  font-size: 12px;
`;

const RequiredBadge = styled.div`
  padding: 2px 7px;
  color: red;
`;

export type Props = React.TdHTMLAttributes<HTMLTableDataCellElement> &
  React.ThHTMLAttributes<HTMLTableHeaderCellElement> & {
    width?: string;
    children?: React.ReactNode;
    required?: boolean;
  };

export const HeaderCell: React.FunctionComponent<Props> = ({
  width = 'auto',
  children,
  required = false,
  ...rest
}) => {
  return (
    <Component width={width} {...rest}>
      <Box>
        <p>{children}</p>
        {required && <RequiredBadge>※</RequiredBadge>}
      </Box>
    </Component>
  );
};
