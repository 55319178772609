/* eslint no-plusplus: 0 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import dayjs, { getMonthOfDateList, getTime } from '../../lib/utils/dayjs';
import {
  BatchSetting,
  StoreDateSettingBatchForm,
} from '../parts/lv1/StoreDateSettingBatchForm';
import { StoreDateSettingTable } from '../parts/lv1/StoreDateSettingTable';
import { useQuery } from '../../lib/hooks/useQuery';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const ContentsWrapper = styled.div`
  min-width: 1000px;
`;

const MonthSelectArea = styled.div`
  display: flex;
`;
const MonthSelectBtn = styled.div<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive
      ? `
  background: #212121;
  color: #FFFFFF;
`
      : ''}
  border-radius: 4px;
  border: 1px solid #212121;
  border-radius: 4px;
  padding: 8px 16px;
  width: 140px;
  margin-right: 16px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #979797;
  min-width: 1000px;
`;

const stepMinutes = 30;
const storeStartTime = '00:00';
const storeEndTime = '24:00';

export const StoreDateSettings = () => {
  const history = useHistory();
  const month = useQuery().get('month');

  const current = dayjs();
  const showMonth = 6;

  const [selectMonth] = useState(() => {
    if (!month) return current;

    const selectDay = dayjs(month, 'YYYY-MM');
    if (
      selectDay.isValid() &&
      selectDay.isAfter(current.add(-1, 'month')) &&
      selectDay.isBefore(current.add(showMonth - 1, 'month'))
    ) {
      return selectDay;
    }
    history.push('/store/date');
    history.go(0);
    return current;
  });

  const [dates, setDates] = useState(getMonthOfDateList(selectMonth));
  useEffect(() => {
    setDates(getMonthOfDateList(selectMonth));
  }, [selectMonth]);

  const [startHour, startMinute] = storeStartTime.split(':');
  const [endHour, endMinute] = storeEndTime.split(':');
  const hours = getTime(
    current,
    stepMinutes,
    +startHour,
    +startMinute,
    +endHour,
    +endMinute,
  );

  const [batch, setBatch] = useState<BatchSetting>({
    specify: '',
    period: {
      periodStartDate: '01',
      periodEndDate: '01',
      businessStartHours: '09:00:00',
      businessEndHours: '20:00:00',
    },
    days: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    },
    businessDay: '',
  });

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="営業時間設定" />
      </Margin>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px 48px 16px 40px',
          minHeight: 'calc(100vh - 200px)',
          minWidth: '1200px',
        }}
      >
        <MonthSelectArea>
          {Array(showMonth)
            .fill(0)
            .map((_, i) => (
              <MonthSelectBtn
                isActive={
                  selectMonth.format('YYYY-MM') ===
                  current.add(i, 'month').format('YYYY-MM')
                }
                key={i}
                onClick={() => {
                  history.push(
                    `/store/date?month=${current
                      .add(i, 'month')
                      .format('YYYY-MM')}`,
                  );
                  history.go(0);
                }}
              >
                {current.add(i, 'month').format('YYYY年MM月')}
              </MonthSelectBtn>
            ))}
        </MonthSelectArea>
        <ContentsWrapper>
          <Margin margin="16px 0 0 0">
            <Form>
              <StoreDateSettingBatchForm
                dates={dates}
                hours={hours}
                onSubmit={(value) => setBatch(value)}
              />
              <StoreDateSettingTable
                dates={dates}
                yearMonth={selectMonth.format('YYYY-MM')}
                hours={hours}
                batch={batch}
              />
            </Form>
          </Margin>
        </ContentsWrapper>
      </ShadedContainer>
    </Wrapper>
  );
};
