import React from 'react';
import styled from 'styled-components';
import { FontSize, Color } from '../../../lib/styles/variables';

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const Text = styled.p<{ fontSize: 'X_SMALL' | 'SMALL' }>`
  color: ${Color.BRACK};
  margin-right: 16px;
  margin-left: 5px;

  &[fontSize='X_SMALL'] {
    font-weight: bold;
    font-size: ${FontSize.X_SMALL};
  }

  &[fontSize='SMALL'] {
    font-size: ${FontSize.SMALL};
  }
`;

const SelectedRadioButton = styled.div`
  background: #4a90e2;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background: #fff;
  }
`;

const UnSelectedRadioButton = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  border: 1px solid #979797;
`;

type Props = {
  text: string;
  id: string;
  register: any;
  isSelected: boolean;
  name: string;
  fontSize?: 'X_SMALL' | 'SMALL';
};

export const RadioButtonWithText: React.FC<Props> = (props) => {
  const { text, id, register, isSelected, name, fontSize = 'X_SMALL' } = props;

  return (
    <>
      <input
        type="radio"
        name={name}
        ref={register}
        id={id}
        style={{ display: 'none' }}
        value={id === 'none' ? '' : id}
      />
      <Label htmlFor={id}>
        {isSelected ? (
          <SelectedRadioButton>
            <div />
          </SelectedRadioButton>
        ) : (
          <UnSelectedRadioButton />
        )}
        <Text fontSize={fontSize}>{text}</Text>
      </Label>
    </>
  );
};
