import React from 'react';
import styled from 'styled-components';
import { encodeBase64 } from '../../../lib/utils/base64';

const FileFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

const FileField = styled.label`
  width: 96px;
  height: 22px;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
`;

const FileName = styled.p`
  font-size: 12px;
  margin-left: 8px;
  line-height: 22px;
  color: #212121;
`;

export type ImageType = {
  name: string;
  base64: string;
};

type Props = {
  image: ImageType;
  onChange: (image: ImageType) => void;
  index?: number;
};

export const InputImage: React.FC<Props> = ({ image, onChange, index }) => {
  const handleFileChange = (uploadFile: any) => {
    encodeBase64(uploadFile)
      .then((encoded) => {
        onChange({ name: uploadFile.name, base64: encoded });
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  };
  const id = `img-${index}`;
  return (
    <FileFieldWrapper>
      <input
        type="file"
        style={{ display: 'none' }}
        id={id}
        onChange={(e: any) => handleFileChange(e.target.files[0])}
      />
      <FileField htmlFor={id}>ファイルを選択</FileField>
      <FileName>
        {image.name === '' ? 'ファイルが選択されていません' : image.name}
      </FileName>
    </FileFieldWrapper>
  );
};
