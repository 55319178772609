/* eslint no-plusplus: 0 */
import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { SelectBox } from '../lv3/SelectBox';
import { RadioButtonWithText } from '../lv3/RadioButtonWithText';
import { SquareButton } from '../lv3/SquareButton';
import { CheckBoxWithText } from '../lv3/CheckBoxWithText';
import dayjs from '../../../lib/utils/dayjs';

const Form = styled.form`
  margin: 8px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GridItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SettingDate = styled.div`
  display: flex;
  align-items: center;
`;

const SettingHours = styled.div`
  display: flex;
  align-items: center;
  margin-left: 70px;
  margin-right: 33px;
`;

const SettingDay = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;

const TextMargin = styled.a`
  margin-left: 4px;
  margin-right: 8px;
`;

const DateSelectBox = ({
  name,
  dates,
  register,
}: {
  name: string;
  dates: dayjs.Dayjs[];
  register: any;
}) => {
  return (
    <SelectBox
      control={{
        type: 'hook',
        name,
        register,
      }}
      style={{
        width: '84px',
        fontSize: '12px',
        height: '22px',
        border: 'all',
        padding: '0 8px',
        size: 10,
      }}
    >
      {dates.map((date) => {
        const value = `0${date.date()}`.slice(-2);
        return (
          <option key={`${name}-${value}`} value={value}>
            {value}
          </option>
        );
      })}
    </SelectBox>
  );
};

const HoursSelectBox = ({
  name,
  hours,
  register,
}: {
  name: string;
  hours: dayjs.Dayjs[];
  register: any;
}) => {
  return (
    <SelectBox
      control={{
        type: 'hook',
        name,
        register,
      }}
      style={{
        width: '84px',
        fontSize: '12px',
        height: '22px',
        border: 'all',
        padding: '0 8px',
        size: 10,
      }}
    >
      {hours.map((option) => {
        const time = option.format('HH:mm');
        return (
          <option key={`startHours-${time}`} value={`${time}:00`}>
            {time}
          </option>
        );
      })}
    </SelectBox>
  );
};

export type BatchSetting = {
  specify: 'period' | 'days' | '';
  period: {
    periodStartDate: string;
    periodEndDate: string;
    businessStartHours: string;
    businessEndHours: string;
  };
  days: {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
  };
  businessDay: 'businessDay' | 'holiday' | '';
};

type Props = {
  dates: dayjs.Dayjs[];
  hours: dayjs.Dayjs[];
  onSubmit: (value: BatchSetting) => void;
};

export const StoreDateSettingBatchForm: React.FC<Props> = ({
  dates,
  hours,
  onSubmit,
}) => {
  const { register, watch, handleSubmit } = useForm<BatchSetting>({
    defaultValues: {
      specify: 'period',
      period: {
        periodStartDate: '01',
        periodEndDate: '01',
        businessStartHours: '09:00:00',
        businessEndHours: '20:00:00',
      },
      days: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      },
      businessDay: 'businessDay',
    },
  });

  return (
    <Form onSubmit={handleSubmit((value) => onSubmit(value))}>
      営業日設定
      <FlexWrapper>
        <SettingDate>
          <RadioButtonWithText
            text="期間を指定"
            id="period"
            register={register}
            isSelected={watch().specify === 'period'}
            name="specify"
            fontSize="SMALL"
          />
          <DateSelectBox
            name="period.periodStartDate"
            dates={dates}
            register={register}
          />
          <TextMargin>日〜</TextMargin>
          <DateSelectBox
            name="period.periodEndDate"
            dates={dates}
            register={register}
          />
          <TextMargin>日</TextMargin>
        </SettingDate>
        <SettingHours>
          <TextMargin>時刻</TextMargin>
          <GridItemWrapper>
            <HoursSelectBox
              name="period.businessStartHours"
              register={register}
              hours={hours}
            />
            <TextMargin>〜</TextMargin>
            <HoursSelectBox
              name="period.businessEndHours"
              register={register}
              hours={hours}
            />
          </GridItemWrapper>
        </SettingHours>
        <SquareButton
          type="submit"
          text="設定する"
          width={92}
          height={40}
          fontSize={15}
          borderRadius={4}
          color="BRACK"
        />
      </FlexWrapper>
      <FlexWrapper>
        <SettingDay>
          <RadioButtonWithText
            text="曜日を指定"
            id="days"
            register={register}
            isSelected={watch().specify === 'days'}
            name="specify"
          />
          <CheckBoxWithText
            text="日"
            id="days.sunday"
            size={{ width: 50, height: 30 }}
            register={register}
            isChecked={watch('days.sunday')}
            color="#4A90E2"
          />
          <CheckBoxWithText
            text="月"
            id="days.monday"
            size={{ width: 50, height: 30 }}
            register={register}
            isChecked={watch('days.monday')}
            color="#4A90E2"
          />
          <CheckBoxWithText
            text="火"
            id="days.tuesday"
            size={{ width: 50, height: 30 }}
            register={register}
            isChecked={watch('days.tuesday')}
            color="#4A90E2"
          />
          <CheckBoxWithText
            text="水"
            id="days.wednesday"
            size={{ width: 50, height: 30 }}
            register={register}
            isChecked={watch('days.wednesday')}
            color="#4A90E2"
          />
          <CheckBoxWithText
            text="木"
            id="days.thursday"
            size={{ width: 50, height: 30 }}
            register={register}
            isChecked={watch('days.thursday')}
            color="#4A90E2"
          />
          <CheckBoxWithText
            text="金"
            id="days.friday"
            size={{ width: 50, height: 30 }}
            register={register}
            isChecked={watch('days.friday')}
            color="#4A90E2"
          />
          <CheckBoxWithText
            text="土"
            id="days.saturday"
            size={{ width: 50, height: 30 }}
            register={register}
            isChecked={watch('days.saturday')}
            color="#4A90E2"
          />
        </SettingDay>
        <RadioButtonWithText
          text="営業日"
          id="businessDay"
          register={register}
          isSelected={watch().businessDay === 'businessDay'}
          name="businessDay"
        />
        <RadioButtonWithText
          text="休業日"
          id="holiday"
          register={register}
          isSelected={watch().businessDay === 'holiday'}
          name="businessDay"
        />
      </FlexWrapper>
    </Form>
  );
};
