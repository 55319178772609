import React from 'react';
import { Table } from '../lv1/Table';

// TODO:: accessor名はAPIに合わせる
const columns = [
  { header: 'FX部門名（店舗コード）', accessor: 'accessor1', minWidth: 208 },
  { header: '借方ＦＸ科目コード', accessor: 'accessor2', minWidth: 208 },
  {
    header: '借方ＦＸ口座（補助）コード',
    accessor: 'accessor3',
    minWidth: 208,
  },
  { header: '借方ＦＸ取引先コード', accessor: 'accessor4', minWidth: 208 },
  { header: '貸方ＦＸ科目コード', accessor: 'accessor5', minWidth: 208 },
  { header: '貸方ＦＸ口座名（補助）', accessor: 'accessor6', minWidth: 208 },
  { header: '貸方ＦＸ取引先コード', accessor: 'accessor7', minWidth: 208 },
  { header: '課税区分', accessor: 'accessor8', minWidth: 208 },
  { header: '税率', accessor: 'accessor9', minWidth: 208 },
  { header: '事業区分', accessor: 'accessor10', minWidth: 208 },
];

const rowTitles = {
  titles: [
    '店舗報酬',
    'EC売上報酬',
    '月額費用＋利用料＋材料費＋ロッカー利用料＋クレジットカード利用手数料',
  ],
  width: 472,
  minWidth: 472,
};

const data = [
  {
    accessor1: `${
      columns.find((it) => it.accessor === 'accessor1')?.header
    }が入ります`,
    accessor2: `${
      columns.find((it) => it.accessor === 'accessor2')?.header
    }が入ります`,
    accessor3: `${
      columns.find((it) => it.accessor === 'accessor3')?.header
    }が入ります`,
    accessor4: `${
      columns.find((it) => it.accessor === 'accessor4')?.header
    }が入ります`,
    accessor5: `${
      columns.find((it) => it.accessor === 'accessor5')?.header
    }が入ります`,
    accessor6: `${
      columns.find((it) => it.accessor === 'accessor6')?.header
    }が入ります`,
    accessor7: `${
      columns.find((it) => it.accessor === 'accessor7')?.header
    }が入ります`,
    accessor8: `${
      columns.find((it) => it.accessor === 'accessor8')?.header
    }が入ります`,
    accessor9: `${
      columns.find((it) => it.accessor === 'accessor9')?.header
    }が入ります`,
    accessor10: `${
      columns.find((it) => it.accessor === 'accessor10')?.header
    }が入ります`,
  },
  {},
  {},
];

export const MonthlyTable = () => {
  return <Table columns={columns} data={data} rowTitles={rowTitles} />;
};
