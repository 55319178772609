import React, { FC, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from 'recharts';
import { StylistDailyTotalSale } from '../../../lib/api/stylist';

type Props = {
  daily_total_sales: StylistDailyTotalSale;
};

export const StylistDetailSalesChartDailyTotal: FC<Props> = ({
  daily_total_sales,
}) => {
  const data = useMemo(() => {
    return daily_total_sales.data.map((item) => {
      return {
        name: item.date.split('-')[2],
        total: Math.floor(item.total_sales_amount / 10000),
      };
    });
  }, [daily_total_sales]);

  const target = useMemo(() => {
    return Math.floor(daily_total_sales.total_sales_target_amount / 10000);
  }, [daily_total_sales]);

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart data={data} margin={{ top: 100, left: 20, bottom: 100 }}>
        <XAxis dataKey="name" axisLine={false} tickMargin={10}>
          <Label
            value="技術売上"
            offset={320}
            position="top"
            style={{ fontSize: '24px', fontWeight: 'bold' }}
          />
        </XAxis>
        <YAxis
          domain={[0, 1000]}
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tickFormatter={(value) => value.toLocaleString()}
        >
          <Label value="（万円）" offset={40} position="top" />
        </YAxis>
        <ReferenceLine y={target} stroke="#E24A4A" strokeWidth={5} />
        <CartesianGrid vertical={false} style={{ color: '#979797' }} />
        <Legend verticalAlign="top" align="right" height={60} />
        <Tooltip formatter={(value) => `${value.toLocaleString()}円`} />
        <Bar name="売上目標" dataKey="" fill="#E24A4A" />
        <Bar name="実績" dataKey="total" fill="#212121" />
      </BarChart>
    </ResponsiveContainer>
  );
};
