import { PageData } from './utils';
import apiBase from './apiBase';
import getQueryParams from '../utils/getQueryParams';

export type ContractType =
  | 0 // 正社員
  | 1 // 業務委託
  | 2; // シェアサロン利用者

export type RateOfMenu = {
  rate: number;
  category_name?: string;
  category?: string;
};

export type CustomerOfStylist = {
  id: number;
  full_name: string;
  sex: string;
};

export type WorkingCondition = {
  salon_id: number;
  salon_name: string;
  grade: string;
  distribution_rate: number;
};

export type Stylist = {
  id: number;
  employee_number: string;
  smart_hr_id: string;
  user_name: string;
  user_kana_name: string;
  email: string;
  email_verified_at: string | null;
  image_url: string;
  contract_type: ContractType;
  position: string | null;
  grade: string | null;
  main_salon_id: number;
  sub_salon_id: number;
  phone_number: string;
  emergency_phone_number: string;
  monthy_holiday_number: number;
  old_basic_salary: number;
  ec_feed_back_rate: number;
  commission_rate: number;
  treatment_sale_free_commission: number;
  treatment_sale_name_commission: number;
  product_sale_commission: number;
  bank_name: string | null;
  bank_number: string | null;
  branch_store_name: string | null;
  branch_store_number: string | null;
  bank_account_name: string | null;
  bank_account_type: string | null;
  bank_account_number: string | null;
  contract_status: string;
  hire_date: string | null;
  leave_date: string | null;
  years_of_experience: number;
  sex: string;
  address: string;
  naming_price: number;
  rank: string;
  catch_phrase: string;
  self_introduction: string;
  receptionable_number: number;
  deleted_at: string | null;
  menu_rate: RateOfMenu[];
  repeat_rate: number;
  unit_price_per_hour: number;
  working_conditions: WorkingCondition[];
  // TODO
  spot_usage_fee: number;
  monthly_fixed_usage_fee: number;
  sales_commission_usage_fee: number;
};

export type StylistTreatmentSale = {
  year_month: string;
  treatment_sales_target_amount: number;
  nomination_sales_amount: number;
  free_sales_amount: number;
};

export type StylistProductSale = {
  year_month: string;
  product_sales_target_amount: number;
  product_sales_amount: number;
};

export type StylistTotalSale = {
  year_month: string;
  total_sales_target_amount: number;
  total_sales_amount: number;
};

export type StylistDailyTotalSale = {
  year_month: string;
  total_sales_target_amount: number;
  data: { date: string; total_sales_amount: number }[];
};

export type StylistCoupon = {
  seq: number;
  name: string;
};

export type StylistUnusedCoupon = {
  name: string;
};

export type MonthlySalesDatum = {
  year_month: string;
  purchase_product_rate: number;
  new_customer_rate: number;
  repeat_customer_rate: number;
  spa_treatment_rate: number;
  new_customer_number: number;
  repeat_customer_number: number;
  unit_price_per_customer: number;
  // TODO: 時間単価を追加する
};

export type GetStylistSalesRes = {
  stylist_id: number;
  from_year_month: string;
  to_year_month: string;
  treatment_sales: StylistTreatmentSale[];
  product_sales: StylistProductSale[];
  total_sales: StylistTotalSale[];
  daily_total_sales: StylistDailyTotalSale;
  total_sales_expectation: number;
  rate_of_menus: RateOfMenu[];
  monthly_sales_data: MonthlySalesDatum[];
  top3_coupons: StylistCoupon[];
  unused_coupons: StylistUnusedCoupon[];
};

export type MultifacetedEvaluation = {
  id: number;
  stylist_id: number;
  year_month: string;
  nominal: string;
  point: number;
  remarks: string;
};

export type MsPoint = {
  id: number;
  stylist_id: number;
  date: string;
  point: number;
};

export type MotPoint = {
  id: number;
  stylist_id: number;
  date: string;
  point: number;
};

export type Allowance = {
  id: number;
  stylist_id: number;
  year_month: string;
  nominal: string;
  amount: number;
  remarks: string;
};

export type Reward = {
  id: number;
  stylist_id: number;
  salon_id: number;
  subject: string;
  from_date: string;
  to_date: string;
  amount: number;
  created_at: string;
  updated_at: string;
};

export type Bonus = {
  id: number;
  stylist_id: number;
  year_month: string;
  nominal: string;
  amount: number;
};

export type CommissionPoint = {
  id: number;
  stylist_id: number;
  nominal: string;
  date: string;
  point: number;
};

// 美容師
export const getStylistDetail = (stylistId: string) =>
  apiBase.get<Stylist>(`/stylist/${stylistId}`);

export const getCustomersOfStylist = (stylistId: string) =>
  apiBase.get<
    {
      data: CustomerOfStylist[];
    } & PageData
  >(`/stylist/${stylistId}/users`);

export const getStylistSales = (stylistId: string, from: string, to: string) =>
  apiBase.get<GetStylistSalesRes>(
    `/stylist/${stylistId}/sales?from_year_month=${from}&to_year_month=${to}`,
  );

export const addStylist = (
  params: Partial<
    Stylist & { password: string; password_confirmation: string }
  >,
) => apiBase.post<Stylist>(`/stylist`, params);

export const updateStylist = (params: Partial<Stylist>) =>
  apiBase.put<Stylist>(`/stylist/${params.id}`, params);

export const deleteStylist = () => apiBase.delete<Stylist>(`/stylist`);

// 多面評価
export const getMultifacetedEvaluations = (stylistId: string) =>
  apiBase.get<
    {
      data: MultifacetedEvaluation[];
    } & PageData
  >(`/stylist/${stylistId}/multifacetedEvaluations`);

export const getMultifacetedEvaluationDetail = (id: string) =>
  apiBase.get<MultifacetedEvaluation>(`/multifacetedEvaluation/${id}`);

export const addMultifacetedEvaluation = (
  stylistId: string,
  params: Partial<MultifacetedEvaluation>,
) =>
  apiBase.post<MultifacetedEvaluation>(
    `/stylist/${stylistId}/multifacetedEvaluation`,
    params,
  );

export const updateMultifacetedEvaluation = (
  id: string,
  params: Partial<MultifacetedEvaluation>,
) =>
  apiBase.put<MultifacetedEvaluation>(`/multifacetedEvaluation/${id}`, params);

export const deleteMultifacetedEvaluation = (id: string) =>
  apiBase.delete<MultifacetedEvaluation>(`/multifacetedEvaluation/${id}`);

// MSポイント
export const getMsPoints = (stylistId: string) =>
  apiBase.get<
    {
      data: MsPoint[];
    } & PageData
  >(`/stylist/${stylistId}/msPoints`);

export const getMsPointDetail = (id: string) =>
  apiBase.get<MsPoint>(`/msPoint/${id}`);

export const addMsPoint = (stylistId: string, params: Partial<MsPoint>) =>
  apiBase.post<MsPoint>(`/stylist/${stylistId}/msPoint`, params);

export const updateMsPoint = (id: string, params: Partial<MsPoint>) =>
  apiBase.put<MsPoint>(`/msPoint/${id}`, params);

export const deleteMsPoint = (id: string) =>
  apiBase.delete<MsPoint>(`/msPoint/${id}`);

// MOTポイント
export const getMotPoints = (stylistId: string) =>
  apiBase.get<
    {
      data: MotPoint[];
    } & PageData
  >(`/stylist/${stylistId}/motPoints`);

export const getMotPointDetail = (id: string) =>
  apiBase.get<MotPoint>(`/motPoint/${id}`);

export const addMotPoint = (stylistId: string, params: Partial<MotPoint>) =>
  apiBase.post<MotPoint>(`/stylist/${stylistId}/motPoint`, params);

export const updateMotPoint = (id: string, params: Partial<MotPoint>) =>
  apiBase.put<MotPoint>(`/motPoint/${id}`, params);

export const deleteMotPoint = (id: string) =>
  apiBase.delete<MotPoint>(`/motPoint/${id}`);

export const getAllowances = (stylistId: string) =>
  apiBase.get<
    {
      data: Allowance[];
    } & PageData
  >(`/stylist/${stylistId}/allowances`);

export const getAllowanceDetail = (id: string) =>
  apiBase.get<Allowance>(`/allowance/${id}`);

export const addAllowance = (stylistId: string, params: Partial<Allowance>) =>
  apiBase.post<Allowance>(`/stylist/${stylistId}/allowance`, params);

export const updateAllowance = (id: string, params: Partial<Allowance>) =>
  apiBase.put<Allowance>(`/allowance/${id}`, params);

export const deleteAllowance = (id: string) =>
  apiBase.delete<Allowance>(`/allowance/${id}`);

// 報酬保証
export const getRewards = (stylistId: string) =>
  apiBase.get<
    {
      data: Reward[];
    } & PageData
  >(`/stylist/${stylistId}/rewardGuarantees`);

export const getRewardDetail = (id: string) =>
  apiBase.get<Reward>(`/rewardGuarantee/${id}`);

export const addReward = (stylistId: string, params: Partial<Reward>) =>
  apiBase.post<Reward>(`/stylist/${stylistId}/rewardGuarantee`, params);

export const deleteReward = (id: string) =>
  apiBase.delete<Reward>(`/rewardGuarantee/${id}`);

// ボーナス
export const getBonuses = (stylistId: string) =>
  apiBase.get<
    {
      data: Bonus[];
    } & PageData
  >(`/stylist/${stylistId}/bonuses`);

export const getBonusDetail = (id: string) =>
  apiBase.get<Bonus>(`/bonus/${id}`);

export const addBonus = (stylistId: string, params: Partial<Bonus>) =>
  apiBase.post<Bonus>(`/stylist/${stylistId}/bonus`, params);

export const updateBonus = (id: string, params: Partial<Bonus>) =>
  apiBase.put<Bonus>(`/bonus/${id}`, params);

export const deleteBonus = (id: string) =>
  apiBase.delete<Bonus>(`/bonus/${id}`);

// 歩合
export const getCommissionPoints = (stylistId: string) =>
  apiBase.get<
    {
      data: CommissionPoint[];
    } & PageData
  >(`/stylist/${stylistId}/commissionPoints`);

export const getCommissionPointDetail = (id: string) =>
  apiBase.get<CommissionPoint>(`/commissionPoint/${id}`);

export const addCommissionPoint = (
  stylistId: string,
  params: Partial<CommissionPoint>,
) =>
  apiBase.post<CommissionPoint>(
    `/stylist/${stylistId}/commissionPoint`,
    params,
  );

export const updateCommissionPoint = (
  id: string,
  params: Partial<CommissionPoint>,
) => apiBase.put<CommissionPoint>(`/commissionPoint/${id}`, params);

export const deleteCommissionPoint = (id: string) =>
  apiBase.delete<CommissionPoint>(`/commissionPoint/${id}`);

export type GetStylistsType = {
  page?: number; // ページネーションが必要になる場合には実装
  salon_id?: number;
};

type GetStylists = {
  data: Stylist[];
};

export const getStylists = (params: Partial<GetStylistsType>) =>
  apiBase.get<GetStylists>(`/stylists?${getQueryParams(params)}`);
