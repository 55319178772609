import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as EyeOff } from '../../../image/eye-off.svg';
import { ReactComponent as Eye } from '../../../image/eye.svg';

const Wrapper = styled.div`
  width: 280px;
  position: relative;
  margin-right: 2rem;
`;

const InputCustom = styled.input`
  border: none;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  line-height: 36px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const Label = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: #757575;
`;

const Eyes = styled.div`
  position: absolute;
  top: 2rem;
  right: 1rem;
  color: #212121;
`;

export const StylistDetailInputPassword: FC<{
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, onChange }) => {
  const [isShown, setIsShown] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    },
    [onChange],
  );

  const handleClickEyes = useCallback((e: any) => {
    setIsShown((prev) => !prev);
  }, []);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputCustom
        type={isShown ? 'text' : 'password'}
        onChange={handleChange}
        required
      />
      <Eyes onClick={handleClickEyes}>
        <EyeOff style={{ display: isShown ? 'none' : 'block' }} />
        <Eye style={{ display: isShown ? 'block' : 'none' }} />
      </Eyes>
    </Wrapper>
  );
};
