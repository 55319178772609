import React, { FC } from 'react';
import styled from 'styled-components';
import { StylistUnusedCoupon } from '../../../lib/api/stylist';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      th {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        background: #f1f1f1;
      }
      td {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-size: 14px;
      }
    }
  }
`;

type Props = {
  coupons: StylistUnusedCoupon[];
};

export const StylistDetailTableUnusedCoupons: FC<Props> = ({ coupons }) => {
  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th colSpan={1}>未使用クーポン一覧</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((item, i) => {
            return (
              <tr key={`${item.name}-${i}`}>
                <td>{item.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};
