import React from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { SquareButton } from '../parts/lv3/SquareButton';
import { AccountingSearchForm } from '../parts/lv1/AccountingSearchForm';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
  max-height: calc(100vh - 88px);
`;

const TopContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Accounting = () => {
  return (
    <Wrapper>
      <TopContentWrapper>
        <MainContentTitle text="EC販売" />
        <SquareButton
          fontSize={16}
          width={242}
          height={31}
          text="CSVで情報を書き出す"
          color="BLUE_BORDER"
          handleClick={() => console.log('csv')}
        />
      </TopContentWrapper>

      <AccountingSearchForm />
    </Wrapper>
  );
};
