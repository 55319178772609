/* eslint consistent-return: 0 */
export const encodeBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      return resolve(undefined);
    }

    const fr = new FileReader();
    fr.onload = (ev) => {
      if (ev.target !== null) {
        resolve((ev.target as any).result as string);
      }
    };
    fr.onerror = (e) => reject(e);

    fr.readAsDataURL(file);
  });
};

export const decodeBase64 = async (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};
