import apiBase from './apiBase';
import getQueryParams from '../utils/getQueryParams';

export type Message = {
  id: number;
  title: string;
  sent_amount: number;
  send_at: string;
  is_sent: boolean;
};

export type MessageInfo = Message & {
  body: string;
  image_url: string;
};

export type GetMessagesRes = {
  data: Message[];
  last_page: number;
};

export const getMessagesToStylist = (page?: number) =>
  apiBase.get<GetMessagesRes>(`/messages/stylist?page=${page}`);

export const getMessagesToCustomer = (page?: number) =>
  apiBase.get<GetMessagesRes>(`/messages/user?page=${page}`);

export const getMessageInfoToCustomer = (id: string) =>
  apiBase.get<MessageInfo>(`/message/${id}/user`);

type GetTargetStylistParams = {
  send_target: string;
  stylist_id: string;
  salon_name: string;
};

export type Stylist = {
  id: number;
  user_kana_name: string;
};

type getTargetStylistRes = {
  target_stylists: {
    data: Stylist[];
  };
};

export const getTargetStylist = (params: GetTargetStylistParams) =>
  apiBase.get<getTargetStylistRes>(
    `/message/stylists?${getQueryParams(params)}`,
  );

type SearchMessagesToCustomerParams = {
  kanji_first_name: string;
  kanji_last_name: string;
  kana_first_name: string;
  kana_last_name: string;
  sex: string;
  age: number;
  last_treatment: string;
  purchased_item: string;
  from_birth_day: string;
  to_birth_day: string;
  days_after_last_visit: number;
  days_before_reservation: number;
  salon_visit_count: number;
};

export type Customer = {
  id: number;
  kana_name: string;
  kanji_name: string;
  sex: '男性' | '女性' | 'その他';
  birth_day: string;
  salon_visit_count: number;
  last_visit_date: string;
};

type SearchMessagesToCustomerRes = Customer[];

export const getTargetCustomer = (
  params: Partial<SearchMessagesToCustomerParams>,
) => {
  return apiBase.get<SearchMessagesToCustomerRes>(
    `/message/users?${getQueryParams(params)}`,
  );
};

export type PostMessageToStylistParams = {
  target: {
    salon_all: boolean;
    salon_id: number | null;
    stylist_ids?: number[] | null;
  };
  title: string;
  body: string;
  image?: {
    base64: string | ArrayBuffer | null; // @todo 型をどうにかしたい
  };
  send_at: string;
};

export const postMessageToStylist = (params: PostMessageToStylistParams) =>
  apiBase.post('/message/stylist', params);

export type PostMessageToCustomerParams = {
  target?: {
    user_ids: number[];
  };
  title: string;
  body: string;
  image?: {
    base64: string | ArrayBuffer | null; // @todo 型をどうにかしたい
  };
  send_at: string;
  image_url?: string;
};

export const postMessageToCustomer = (params: PostMessageToCustomerParams) =>
  apiBase.post('/message/user', params);

export const putMessageToCustomer = (
  id: string,
  params: PostMessageToCustomerParams,
) => apiBase.put(`/message/${id}/user`, params);

export const deleteMessageToCustomer = (id: string) =>
  apiBase.delete(`/message/${id}/user`);
