import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import dayjs, {
  StylistHeight,
  ShiftCellWidth,
  isSameDate,
} from '../../../lib/utils/dayjs';

const Wrapper = styled.div``;
const Row = styled.div`
  display: flex;
  height: ${StylistHeight}
  position: relative;
`;

const Cell = styled.div`
  border-bottom: 1px solid #979797;
  border-right: 1px solid #979797;
  min-width: ${ShiftCellWidth};
  padding: 4px;
`;

const Shift = styled.div<{ isHoliday: boolean }>`
  background: ${({ isHoliday }) => (isHoliday ? '#D8D8D8' : '#4A90E2')};
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

export interface ShiftType {
  shiftStartTime: string; // YYYY-MM-DD HH:mm:ss を想定
  shiftEndTime: string;
}

interface StylistType {
  id: number;
  shift: ShiftType[];
  name: string;
}

type Props = {
  days: dayjs.Dayjs[];
  stylists: StylistType[];
};

export const ShiftTableBody: React.FC<Props> = ({ days, stylists }) => {
  const history = useHistory();
  const holidayStr = '-';
  const getShowShiftTime = (day: dayjs.Dayjs, shift: ShiftType[]) => {
    const dayOfShift = shift.find((it) =>
      isSameDate(day, dayjs(it.shiftStartTime)),
    );
    if (dayOfShift) {
      const start = dayjs(dayOfShift.shiftStartTime);
      const end = dayjs(dayOfShift.shiftEndTime);
      return `${start.format('HH:mm')}-${end.format('HH:mm')}`;
    }
    return holidayStr;
  };
  return (
    <Wrapper>
      {stylists.map((stylist) => (
        <Row key={stylist.id}>
          {days.map((day) => (
            <Cell
              key={day.format('DD')}
              onClick={() =>
                history.push(
                  `/shift/setting/${stylist.id}/?date=${day.format(
                    'YYYY-MM-DD',
                  )}`,
                )
              }
            >
              <Shift
                isHoliday={holidayStr === getShowShiftTime(day, stylist.shift)}
              >
                {getShowShiftTime(day, stylist.shift)}
              </Shift>
            </Cell>
          ))}
        </Row>
      ))}
    </Wrapper>
  );
};
