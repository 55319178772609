import { useState, useEffect } from 'react';
import { getAllStore, GetAllStoreRes } from '../api/store';

const useAllStore = () => {
  const [allStore, setAllStore] = useState<GetAllStoreRes | null>(null);

  useEffect(() => {
    getAllStore().then(({ data }) => {
      setAllStore(data);
    });
  }, []);

  return allStore;
};

export default useAllStore;
