import { useState, useEffect } from 'react';
import { getSalons, Salon } from '../api/salon';

export const useSalons = () => {
  const [salons, setSalons] = useState<Salon[]>([]);

  useEffect(() => {
    getSalons()
      .then(({ data }) => {
        setSalons(data.data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, []);

  return salons;
};
