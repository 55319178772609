import apiBase from './apiBase';

export type Caroucel = {
  seq: number;
  image_url?: string;
  base64?: string;
  link_url: string;
};

export const getCarousel = () => apiBase.get<Caroucel[]>('/carousels');

export const updateCaroucel = (params: Caroucel[]) =>
  apiBase.put('/carousels', params);
