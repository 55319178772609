import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { FontSize, Color } from '../../lib/styles/variables';
import { SquareButton } from '../parts/lv3/SquareButton';
import { SelectBox } from '../parts/lv3/SelectBox';
import { RadioButtonWithText } from '../parts/lv3/RadioButtonWithText';
import { CreateMessageLink } from '../parts/lv1/CreateMessageLink';
import { Table } from '../parts/lv1/Table';
import { getTargetCustomer, Customer } from '../../lib/api/message';
import { getDayArray, getMonthArray, getYearArray } from '../../lib/utils/date';
import { CheckBox } from '../parts/lv3/CheckBoxWithText';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridWrapper = styled.div`
  display: grid;
  grid:
    'furigana furiganaField name nameField' 42px
    'gender genderField era eraField' 42px
    'lastTreatment lastTreatmentField purchased purchasedField' 42px
    'birthday birthdayField birthdayField birthdayField' 42px
    'visited visitedField visitedField visitedField' 42px
    'totalVisited totalVisitedField totalVisitedField totalVisitedField' 42px
    / 147px 282px 147px 1fr;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const ButtonWrapper = styled.div`
  width: 296px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const TextField = styled.input`
  width: 84px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #979797;
  margin-left: 10px;
`;

const SmallText = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  margin-left: 10px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px;
`;

const SelectBoxWrapper = styled.div`
  margin-left: 10px;
  padding: 2px;
  border: 1px solid #979797;
`;

const TableWrapper = styled.div`
  margin: 20px 0;

  > p {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 6px;
  }
`;

type FormValue = {
  kanji_first_name: string;
  kanji_last_name: string;
  kana_first_name: string;
  kana_last_name: string;
  sex: '選択なし' | '男性' | '女性' | 'その他';
  age: number;
  last_treatment: string;
  purchased_item: string;
  from_birth_year: string;
  from_birth_month: string;
  from_birth_day: string;
  to_birth_year: string;
  to_birth_month: string;
  to_birth_day: string;
  visited: 'days_after_last_visit' | 'days_before_reservation';
  days_after_last_visit: number;
  days_before_reservation: number;
  salon_visit_count: number;
};

type CustomerWithIsChecked = {
  isChecked: boolean;
  customer: Customer;
};

export const FilterMessageCustomer = () => {
  const [searchResult, setSearchResult] = useState<
    CustomerWithIsChecked[] | null
  >(null);
  const history = useHistory();
  const { register, watch, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      sex: '選択なし',
      visited: 'days_after_last_visit',
    },
  });

  const columns = [
    { header: '配信可否', accessor: 'deliverable', width: 78 },
    { header: 'お客様名（カナ）', accessor: 'furigana', width: 150 },
    { header: 'お客様名', accessor: 'name', width: 150 },
    { header: '性別', accessor: 'gender', width: 55 },
    { header: '生年月日', accessor: 'birthday', width: 120 },
    { header: '来店回数', accessor: 'visitedCount', width: 97 },
    { header: '前回来店日', accessor: 'lastVisited', width: 120 },
  ];

  const checkCustomer = (index: number, result: CustomerWithIsChecked[]) => {
    const copiedCustomer = result.concat();
    copiedCustomer[index].isChecked = !copiedCustomer[index].isChecked;
    setSearchResult(copiedCustomer);
  };

  const makeData = (result: CustomerWithIsChecked[]) => {
    return result.map((item, i) => ({
      deliverable: (
        <CheckBox
          size={{ width: 24, height: 24 }}
          isChecked={item.isChecked}
          handleClick={() => checkCustomer(i, result)}
        />
      ),
      furigana: item.customer.kana_name,
      name: item.customer.kanji_name,
      gender: item.customer.sex,
      birthday: item.customer.birth_day,
      visitedCount: item.customer.salon_visit_count,
      lastVisited: item.customer.last_visit_date,
    }));
  };

  const formatBirthday = (year: number, month: number, day: number) => {
    if (year && month && day) {
      return `${year}-${month}-${day}`;
    }
    return '';
  };

  const onSubmit = handleSubmit((value) => {
    const params = {
      kanji_first_name: value.kanji_first_name,
      kanji_last_name: value.kanji_last_name,
      kana_first_name: value.kana_first_name,
      kana_last_name: value.kana_last_name,
      sex: value.sex === '選択なし' ? '' : value.sex,
      age: value.age,
      last_treatment: value.last_treatment,
      purchased_item: value.purchased_item,
      from_birth_day: formatBirthday(
        Number(value.from_birth_year),
        Number(value.from_birth_month),
        Number(value.from_birth_day),
      ),
      to_birth_day: formatBirthday(
        Number(value.to_birth_year),
        Number(value.to_birth_month),
        Number(value.to_birth_day),
      ),
      days_after_last_visit:
        value.visited === 'days_after_last_visit'
          ? value.days_after_last_visit
          : undefined,
      days_before_reservation:
        value.visited === 'days_before_reservation'
          ? value.days_before_reservation
          : undefined,
      salon_visit_count: value.salon_visit_count,
    };

    getTargetCustomer(params).then(({ data }) => {
      const mapedData = data.map((customer) => ({
        isChecked: false,
        customer,
      }));

      setSearchResult(mapedData);
    });
  });

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="メッセージ作成" />
      </Margin>
      <GridWrapper>
        <GridItem gridArea="furigana">
          <Label>お客様名（カナ）</Label>
        </GridItem>
        <GridItem gridArea="furiganaField">
          <SmallText>セイ</SmallText>
          <TextField name="kana_first_name" ref={register} />
          <SmallText>メイ</SmallText>
          <TextField name="kana_last_name" ref={register} />
        </GridItem>

        <GridItem gridArea="name">
          <Label>お客様名</Label>
        </GridItem>
        <GridItem gridArea="nameField" isRightmost>
          <SmallText>性</SmallText>
          <TextField name="kanji_first_name" ref={register} />
          <SmallText>名</SmallText>
          <TextField name="kanji_last_name" ref={register} />
        </GridItem>

        <GridItem gridArea="gender">
          <Label>性別</Label>
        </GridItem>
        <GridItem gridArea="genderField">
          <RadioButtonWrapper>
            <RadioButtonWithText
              text="選択なし"
              id="選択なし"
              name="sex"
              register={register}
              isSelected={watch().sex === '選択なし'}
            />
            <RadioButtonWithText
              text="男性"
              id="男性"
              name="sex"
              register={register}
              isSelected={watch().sex === '男性'}
            />
            <RadioButtonWithText
              text="女性"
              id="女性"
              name="sex"
              register={register}
              isSelected={watch().sex === '女性'}
            />
            <RadioButtonWithText
              text="その他"
              id="その他"
              name="sex"
              register={register}
              isSelected={watch().sex === 'その他'}
            />
          </RadioButtonWrapper>
        </GridItem>

        <GridItem gridArea="era">
          <Label>年代</Label>
        </GridItem>
        <GridItem gridArea="eraField" isRightmost>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'age',
                register,
              }}
              style={{
                width: '110px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">----</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="60">60</option>
              <option value="70">70</option>
              <option value="80">80</option>
              <option value="90">90</option>
            </SelectBox>
          </SelectBoxWrapper>
        </GridItem>

        <GridItem gridArea="lastTreatment">
          <Label>前回施術内容</Label>
        </GridItem>
        <GridItem gridArea="lastTreatmentField">
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'last_treatment',
                register,
              }}
              style={{
                width: '170px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">----</option>
              <option value="カット">カット</option>
              <option value="カラー">カラー</option>
              <option value="パーマ">パーマ</option>
              <option value="トリートメント">トリートメント</option>
            </SelectBox>
          </SelectBoxWrapper>
        </GridItem>

        <GridItem gridArea="purchased">
          <Label>過去購入商品</Label>
        </GridItem>
        <GridItem gridArea="purchasedField" isRightmost>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'purchased_item',
                register,
              }}
              style={{
                width: '170px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">----</option>
              <option value="シャンプー/トリートメント">
                シャンプー/トリートメント
              </option>
              <option value="アウトバストリートメント">
                アウトバストリートメント
              </option>
              <option value="アイテム">アイテム</option>
              <option value="コスメ">コスメ</option>
              <option value="サプリ">サプリ</option>
              <option value="ワックス">ワックス</option>
            </SelectBox>
          </SelectBoxWrapper>
        </GridItem>

        <GridItem gridArea="birthday">
          <Label>誕生日</Label>
        </GridItem>
        <GridItem gridArea="birthdayField" isRightmost>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'from_birth_year',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">----</option>
              {getYearArray().map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>年</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'from_birth_month',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getMonthArray().map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>月</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'from_birth_day',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getDayArray().map((day) => (
                <option value={day} key={day}>
                  {day}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>日</SmallText>
          <SmallText>から</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'to_birth_year',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">----</option>
              {getYearArray().map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>年</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'to_birth_month',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getMonthArray().map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>月</SmallText>
          <SelectBoxWrapper>
            <SelectBox
              control={{
                type: 'hook',
                name: 'to_birth_day',
                register,
              }}
              style={{
                width: '84px',
                fontSize: '12px',
                height: '22px',
              }}
            >
              <option value="">--</option>
              {getDayArray().map((day) => (
                <option value={day} key={day}>
                  {day}
                </option>
              ))}
            </SelectBox>
          </SelectBoxWrapper>
          <SmallText>日</SmallText>
        </GridItem>

        <GridItem gridArea="visited">
          <Label>来店日時</Label>
        </GridItem>
        <GridItem gridArea="visitedField" isRightmost>
          <RadioButtonWrapper>
            <RadioButtonWithText
              text="前回来店から"
              id="days_after_last_visit"
              name="visited"
              register={register}
              isSelected={watch().visited === 'days_after_last_visit'}
            />
            <TextField
              name="days_after_last_visit"
              ref={register}
              type="number"
            />
            <SmallText>日後</SmallText>
          </RadioButtonWrapper>
          <RadioButtonWrapper>
            <RadioButtonWithText
              text="次回予約から"
              id="days_before_reservation"
              name="visited"
              register={register}
              isSelected={watch().visited === 'days_before_reservation'}
            />
            <TextField
              name="days_before_reservation"
              ref={register}
              type="number"
            />
            <SmallText>日前</SmallText>
          </RadioButtonWrapper>
        </GridItem>

        <GridItem gridArea="totalVisited" isBottom>
          <Label>総来店回数</Label>
        </GridItem>
        <GridItem gridArea="totalVisitedField" isRightmost isBottom>
          <TextField name="salon_visit_count" ref={register} />
        </GridItem>
      </GridWrapper>

      <ButtonWrapper>
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="検索"
          color="BLUE_BORDER"
          borderRadius={4}
          handleClick={onSubmit}
        />
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="戻る"
          color="WHITE"
          borderRadius={4}
          handleClick={() => history.push('/content/message/customer')}
        />
      </ButtonWrapper>
      {searchResult ? (
        <>
          <CreateMessageLink
            path={`/content/message/customer/create?id=${searchResult
              .filter((user) => user.isChecked)
              .map((user) => user.customer.id)
              .join(',')}`}
            checkUsers={searchResult.filter((user) => {
              return user.isChecked;
            })}
          />
          <TableWrapper>
            <p>
              検索結果：
              {searchResult.length}
              件
            </p>
            <Table columns={columns} data={makeData(searchResult)} />
          </TableWrapper>
          <CreateMessageLink />
        </>
      ) : null}
    </Wrapper>
  );
};
