import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Table } from '../parts/lv1/Table';
import { PageNation } from '../parts/lv1/PageNation';
import { PostRateSearchForm } from '../parts/lv1/PostRateSearchForm';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionsArea = styled.div`
  margin-top: 64px;
  margin-bottom: 24px;
`;
const SaveBtn = styled.div`
  background: #4a90e2;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 0px;
  text-align: center;
  width: 136px;
`;

const EditBtn = styled.div`
  color: #4a90e2;
`;

const columns = [
  { header: '従業員番号', accessor: 'accessor1', width: 160 },
  { header: '従業員名', accessor: 'accessor2', minWidth: 170, width: 0 },
  { header: '店舗', accessor: 'accessor3', minWidth: 170, width: 0 },
  { header: 'グレード', accessor: 'accessor4', width: 100 },
  { header: '分配率', accessor: 'accessor5', width: 100 },
  { header: '分配率', accessor: 'accessor6', width: 100 },
  { header: '分配率', accessor: 'accessor7', width: 100 },
  { header: '', accessor: 'accessor8', width: 50 },
];

export interface PostRateSearch {
  sei: string;
  mei: string;
  shop: string;
  grade: string;
}

export const PostRate = () => {
  const history = useHistory();
  const [search, setSearch] = useState<PostRateSearch>({
    sei: '',
    mei: '',
    shop: '',
    grade: '',
  });

  const data = Array(220)
    .fill({})
    .map((_, i) => ({
      accessor1: `${i + 1}`,
      accessor2: ['田中 圭', '広瀬 すず', '阿部 寛'][i % 3],
      accessor3: [
        'tocca千葉',
        'DUNO神戸三宮',
        'tocca津田沼',
        'Eleanor大宮',
        'Eleanor銀座',
      ][i % 5],
      accessor4: ['T6', 'D3'][i % 2],
      accessor5: ['1', '0.9', '0.8'][i % 3],
      accessor6: '20%',
      accessor7: '20%',
      accessor8: (
        <EditBtn
          onClick={() => history.push(`/accounting/post-rate/edit/${i + 1}`)}
        >
          編集
        </EditBtn>
      ),
    }));

  const filterData = () =>
    data.filter(
      (it) =>
        (!search.sei || it.accessor2.includes(search.sei)) &&
        (!search.mei || it.accessor2.includes(search.mei)) &&
        (!search.shop || it.accessor3.includes(search.shop)) &&
        (!search.grade || it.accessor4.includes(search.grade)),
    );

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="役職・分配率" />
        </Margin>
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 40px 40px 40px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <PostRateSearchForm setSearch={setSearch} />
        <ActionsArea>
          <SaveBtn onClick={() => history.push('/accounting/post-rate/add')}>
            追加
          </SaveBtn>
        </ActionsArea>
        <PageNation list={filterData()}>
          {(list) => <Table data={list} columns={columns} />}
        </PageNation>
      </ShadedContainer>
    </Wrapper>
  );
};
