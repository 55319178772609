import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { SquareButton } from '../parts/lv3/SquareButton';
import { TextField } from '../parts/lv3/TextField';
import { CheckBoxWithText } from '../parts/lv3/CheckBoxWithText';
import { ContentTable } from '../parts/lv1/ContentTable';
import { TextArea } from '../parts/lv3/TextArea';
import {
  postStylistCoupon,
  PostStylistCouponPayload,
} from '../../lib/api/coupon';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  width: 296px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const Flex = styled.div`
  display: flex;
`;

const ErrorText = styled.p`
  color: red;
`;

type FormValue = Omit<PostStylistCouponPayload, 'salonId' | 'image'>;

export const AddStylistCoupon = () => {
  const salonId = '1'; // Contextなどで取得
  const history = useHistory();
  const [image, setImage] = useState('');
  const { register, handleSubmit, watch, errors } = useForm<FormValue>({
    // debug用なので削除可
    defaultValues: {
      stylist_id: 1000001,
      name: '美容師のクーポン名',
      price: 3500,
      minute: 60,
      description: 'クーポンの説明',
      menu_ids: [1, 2],
      is_public: true,
    },
  });

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );

  const onSubmit = useCallback(
    async (data: FormValue) => {
      await postStylistCoupon({
        ...data,
        salonId,
        image: {
          base64: image,
        },
      });
    },
    [image],
  );

  return (
    <Wrapper>
      <Margin margin="0 0 12px 0">
        <MainContentTitle text="美容師クーポン登録" />
      </Margin>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentTable>
          <ContentTable.Body>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px" required>
                スタイリストID
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'stylist_id',
                    register: register({
                      required: '必須項目です',
                    }),
                  }}
                  style={{
                    width: '170px',
                  }}
                />
                <ErrorText>{errors.stylist_id?.message}</ErrorText>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                クーポン名
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'name',
                    register,
                  }}
                  style={{}}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                クーポン内容
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextArea
                  control={{
                    type: 'hook',
                    name: 'description',
                    register,
                  }}
                  style={{}}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                画像
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <input
                  type="file"
                  name="image.base64"
                  onChange={onFileChange}
                />
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                メニュー
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <Flex>
                  <CheckBoxWithText
                    id="cut"
                    text="カット"
                    isChecked
                    register={null}
                    size={{ height: 24 }}
                  />
                  <div style={{ marginLeft: '16px' }} />
                  <CheckBoxWithText
                    id="cut"
                    text="カラー"
                    isChecked
                    register={null}
                    size={{ height: 24 }}
                  />
                </Flex>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                価格
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <Flex>
                  <TextField
                    control={{
                      type: 'hook',
                      name: 'price',
                      register,
                    }}
                    style={{
                      width: '84px',
                    }}
                  />
                  円
                  <div style={{ marginLeft: '16px' }} />
                  <CheckBoxWithText
                    text="「 〜 」 を表示"
                    id="is_public"
                    size={{ height: 24 }}
                    register={register}
                    isChecked={watch('is_public')}
                  />
                </Flex>
              </ContentTable.Cell>
            </ContentTable.Row>
            <ContentTable.Row>
              <ContentTable.HeaderCell width="200px">
                所用時間
              </ContentTable.HeaderCell>
              <ContentTable.Cell>
                <TextField
                  control={{
                    type: 'hook',
                    name: 'minute',
                    register,
                  }}
                  style={{
                    width: '84px',
                  }}
                />
                分
              </ContentTable.Cell>
            </ContentTable.Row>
          </ContentTable.Body>
        </ContentTable>

        <ButtonWrapper>
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="登録"
            color="BLUE"
            type="submit"
            borderRadius={4}
          />
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="戻る"
            color="WHITE"
            borderRadius={4}
            type="button"
            handleClick={() => history.push('/content/coupon/stylist')}
          />
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};
