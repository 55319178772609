/* eslint react/display-name: 0 */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { ShadedContainer } from '../layout/ShadedContainer';
import { SquareButton } from '../parts/lv3/SquareButton';
import { FontSize, Color } from '../../lib/styles/variables';
import { DatePicker } from '../parts/lv3/DatePicker';
import { Row, Table } from '../parts/lv1/Table';
import { getTopics, Topic as TopicType } from '../../lib/api/topics';
import { SpModal } from '../parts/lv3/SpModal';
import { SpTopicPreviewModal } from '../parts/lv3/SpTopicPreviewModal';
import { useSalons } from '../../lib/hooks/useSalon';
import { useAllProducts } from '../../lib/hooks/useProduct';
import { useTopicInfo } from '../../lib/hooks/useTopic';
import { SelectBox } from '../parts/lv3/SelectBox';
import useBrands from '../../lib/hooks/useBrand';
import { ValidateErrorMessageList } from '../parts/lv3/ValidationErrorMessage';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

type GridItemProps = {
  gridArea: string;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const GridWrapper = styled.div`
  width: '952px';
  display: grid;
  grid:
    'brand brandField store storeField' 42px
    'term termField name nameField' 42px
    / 150px 280px 150px 1fr;
  border: 1px solid #979797;
  margin-bottom: 16px;
`;

const GridItem = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-bottom: ${({ isBottom }) => (isBottom ? 'none' : '1px solid #979797')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:nth-child(2n) {
    padding-left: 10px;
  }
`;

const Label = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f1f1;
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BRACK};
  font-weight: bold;
  padding-left: 10px;
`;

const DatePickerArea = styled.div`
  display: flex;
  align-items: center;
`;

const Hyphen = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  margin: 0 auto;
`;

const Triangle = styled.span`
  line-height: 100%;
  margin-left: 10px;
`;

const BlueText = styled.p`
  color: #4a90e2;
`;

export const Topics = () => {
  const history = useHistory();
  const brands = useBrands();
  const salons = useSalons();
  const [searchResult, setSearchResult] = useState<TopicType[] | null>(null);
  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs()
      .add(1, 'month')
      .format('YYYY-MM-DD'),
  );

  const { handleSubmit, register } = useForm({
    defaultValues: {
      brand_name: '',
      salon_name: '',
      author: '',
    },
  });
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);

  const onSubmit = handleSubmit((value) => {
    setErrorMessageList([]);
    const params = {
      from_date: startDate,
      to_date: endDate,
      ...value,
    };

    getTopics(params)
      .then(({ data }) => {
        setSearchResult(data.data);
      })
      .catch(() => {
        setErrorMessageList(['エラーが発生しました']);
      });
  });
  const [topicId, setTopicId] = useState<string | null>(null);
  const topic = useTopicInfo(topicId);

  const [isOpen, setIsOpen] = useState(false);
  const onPreview = (id: number) => {
    setIsOpen(true);
    setTopicId(`${id}`);
  };

  const products = useAllProducts();

  const links = [
    { name: 'Topics', to: '/content/topics', isselected: true },
    { name: 'STYLIST BLOG', to: '/content/blog', isselected: false },
  ];

  const columns = [
    { header: '投稿日', accessor: 'created_at', width: 94 },
    { header: 'ブランド', accessor: 'brand_name', width: 150 },
    { header: '店舗名', accessor: 'salon_name', width: 150 },
    {
      header: 'タイトル',
      accessor: (row: Row<TopicType>) => (
        <BlueText onClick={() => onPreview(row.original.id)}>
          {row.original.title}
        </BlueText>
      ),
      width: 514,
    },
    {
      header: '',
      accessor: (row: Row<TopicType>) => (
        <BlueText
          onClick={() =>
            history.push(`/content/topics/edit/${row.original.id}`)
          }
        >
          編集
        </BlueText>
      ),
      width: 66,
    },
  ];

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="ブログ" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '40px 67px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Margin margin="0 0 16px 0">
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="新規作成"
            borderRadius={4}
            color="BLUE"
            handleClick={() => {
              history.push('/content/topics/create');
            }}
          />
        </Margin>

        <GridWrapper>
          <GridItem gridArea="brand">
            <Label>ブランド</Label>
          </GridItem>

          <GridItem gridArea="brandField">
            <SelectBox
              control={{
                type: 'hook',
                name: 'brand_name',
                register,
              }}
              style={{
                width: '240px',
                height: '36px',
              }}
            >
              <option value="">選択してください</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.name}>
                  {brand.name}
                </option>
              ))}
            </SelectBox>
          </GridItem>

          <GridItem gridArea="store">
            <Label>店舗名</Label>
          </GridItem>

          <GridItem gridArea="storeField" isRightmost>
            <SelectBox
              control={{
                type: 'hook',
                name: 'salon_name',
                register,
              }}
              style={{
                width: '240px',
                height: '36px',
              }}
            >
              <option value="">選択してください</option>
              {salons.map((salon) => (
                <option key={salon.id} value={salon.name}>
                  {salon.name}
                </option>
              ))}
            </SelectBox>
          </GridItem>

          <GridItem gridArea="term" isBottom>
            <Label>期間</Label>
          </GridItem>

          <GridItem gridArea="termField" isBottom>
            <DatePickerArea>
              <DatePicker
                date={startDate}
                handleChange={(date) => setStartDate(date)}
                style={{ fontSize: '12px', width: '110px', height: '18px' }}
              />
              <Hyphen> - </Hyphen>
              <DatePicker
                date={endDate}
                handleChange={(date) => setEndDate(date)}
                style={{ fontSize: '12px', width: '110px', height: '18px' }}
              />
              <Triangle>▼</Triangle>
            </DatePickerArea>
          </GridItem>

          <GridItem gridArea="name" isBottom>
            <Label />
          </GridItem>

          <GridItem gridArea="nameField" isBottom isRightmost />
        </GridWrapper>
        <ValidateErrorMessageList
          errors={{}}
          errorMessageList={errorMessageList}
        />
        <Margin margin="0 0 40px 0">
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="検索"
            borderRadius={4}
            color="BLUE_BORDER"
            handleClick={onSubmit}
          />
        </Margin>
        {searchResult ? (
          <Table columns={columns} data={searchResult ?? []} />
        ) : null}
      </ShadedContainer>
      <SpModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        ContentComponent={(
          <SpTopicPreviewModal
            title={topic?.title ?? ''}
            sendAt={dayjs(topic?.created_at).format('YYYY/MM/DD')}
            salon={salons.find((salon) => salon.id === topic?.salon_id ?? 0)}
            product={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              products.find((product) => product.id === topic?.product_id ?? 0)!
            }
            onClose={() => setIsOpen(false)}
            descriptions={
              topic?.description.map((it) => ({
                body: it.body,
                image: { base64: it.image?.base64 ?? '', name: '' },
                image_url: it.image_url,
              })) ?? []
            }
          />
        )}
      />
    </Wrapper>
  );
};
