import axios, { AxiosError, AxiosPromise } from 'axios';
import configuration from '../config';
import storage from '../storage';

const genCli = () =>
  axios.create({
    baseURL: configuration.apiURL,
    timeout: configuration.apiTimeout,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${storage.getItem('access_token')}`,
    },
  });

const errorHandler = (err: AxiosError) => {
  if (err.response && err.response.status === 401) {
    storage.removeItem('access_token');
    window.location.href = '/';
  }

  if (
    err.response &&
    (err.response.status === undefined || err.response.status >= 500)
  ) {
    window.location.href = '/error';
  }

  if (err.response) {
    throw {
      ...err.response.data,
      status: err.response.status,
    };
  }

  throw err;
};

const _get = <Res>(path: string): AxiosPromise<Res> =>
  genCli()
    .get(path)
    .catch(errorHandler);

const _post = <Res>(path: string, body: any = {}): AxiosPromise<Res> =>
  genCli()
    .post(path, body)
    .catch(errorHandler);

const _put = <Res>(path: string, body: any = {}): AxiosPromise<Res> =>
  genCli()
    .put(path, body)
    .catch(errorHandler);

const _delete = <Res>(path: string, body: any = {}): AxiosPromise<Res> =>
  genCli()
    .delete(path, body)
    .catch(errorHandler);

export default {
  get: _get,
  post: _post,
  put: _put,
  delete: _delete,
};
