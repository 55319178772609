import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import { Navigation } from '../parts/lv1/Navigation';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { StylistDetailTableMenu } from '../parts/lv1/StylistDetailTableMenu';
import { StylistDetailTableMonthly } from '../parts/lv1/StylistDetailTableMonthly';
import { StylistDetailTableCouponRanking } from '../parts/lv1/StylistDetailTableCouponRanking';
import { StylistDetailTableUnusedCoupons } from '../parts/lv1/StylistDetailTableUnusedCoupons';
import { FontSize } from '../../lib/styles/variables';
import { getStylistSales, GetStylistSalesRes } from '../../lib/api/stylist';
import { StylistDetailSalesChartTreatment } from '../parts/lv1/StylistDetailSalesChartTreatment';
import { StylistDetailSalesChartProduct } from '../parts/lv1/StylistDetailSalesChartProduct';
import { StylistDetailSalesChartTotal } from '../parts/lv1/StylistDetailSalesChartTotal';
import { StylistDetailSalesChartDailyTotal } from '../parts/lv1/StylistDetailSalesChartDailyTotal';

registerLocale('ja', ja);

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperContent = styled.div`
  width: 100%;
`;

const DatePickerArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
  .react-datepicker__input-container {
    input {
      width: 150px;
      border: none;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }
  }
`;

const Hyphen = styled.span`
  font-size: ${FontSize.X_LARGE};
  font-weight: bold;
  line-height: 100%;
`;

const ChartWrapper = styled.div`
  width: 100%;
  margin-bottom: 5rem;
`;

const Label = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  color: #212121;
`;

const WrapperPrediction = styled.div`
  width: 100%;
  margin-bottom: 5rem;
`;

const makeToDate = () => {
  const now = new Date();
  now.setMonth(now.getMonth() + 1);
  return now;
};

export const StylistSales = () => {
  const { id, stylistId } = useParams<{ id: string; stylistId: string }>();
  const history = useHistory();

  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(makeToDate());
  const [sales, setSales] = useState<GetStylistSalesRes | null>(null);

  const links = useMemo(
    () => [
      {
        name: '基本情報',
        to: `/store/detail/${id}/stylist/detail/${stylistId}`,
        isselected: false,
      },
      {
        name: '顧客リスト',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/customer`,
        isselected: false,
      },
      {
        name: '売上実績',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/sales`,
        isselected: true,
      },
      {
        name: '評価',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/evaluation`,
        isselected: false,
      },
      {
        name: '手当',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/allowance`,
        isselected: false,
      },
      {
        name: '報酬保証',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/reward`,
        isselected: false,
      },
      {
        name: '賞与',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/bonus`,
        isselected: false,
      },
      {
        name: '歩合',
        to: `/store/detail/${id}/stylist/detail/${stylistId}/commission`,
        isselected: false,
      },
    ],
    [id, stylistId],
  );

  useEffect(() => {
    if (fromDate == null || toDate == null) {
      alert('期間が選択されていません');
      return;
    }
    if (fromDate >= toDate) {
      alert('期間が不適切です');
      return;
    }

    const from = fromDate.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
    });
    const to = fromDate.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
    });

    getStylistSales(stylistId, from, to)
      .then(({ data }) => {
        setSales(data);
      })
      .catch(() => {
        alert('エラーが発生しました');
      });
  }, [id, stylistId, fromDate, toDate]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleChangeFromDate = useCallback(
    (date: Date | null) => {
      setFromDate(date);
    },
    [setFromDate],
  );

  const handleChangeToDate = useCallback(
    (date: Date | null) => {
      setToDate(date);
    },
    [setToDate],
  );

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="美容師詳細" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} />
      </WrapperNavigation>
      <WrapperShadow>
        <WrapperContent>
          <DatePickerArea>
            <DatePicker
              selected={fromDate}
              onChange={handleChangeFromDate}
              locale="ja"
              showMonthYearPicker
              dateFormat="yyyy/MM"
            />
            <Hyphen> - </Hyphen>
            <DatePicker
              selected={toDate}
              onChange={handleChangeToDate}
              locale="ja"
              showMonthYearPicker
              dateFormat="yyyy/MM"
            />
          </DatePickerArea>

          {sales && (
            <>
              <ChartWrapper>
                <StylistDetailSalesChartTreatment
                  treatment_sales={sales.treatment_sales}
                />
              </ChartWrapper>
              <ChartWrapper>
                <StylistDetailSalesChartProduct
                  product_sales={sales.product_sales}
                />
              </ChartWrapper>
              <ChartWrapper>
                <StylistDetailSalesChartTotal total_sales={sales.total_sales} />
              </ChartWrapper>
              <ChartWrapper>
                <StylistDetailSalesChartDailyTotal
                  daily_total_sales={sales.daily_total_sales}
                />
              </ChartWrapper>

              <WrapperPrediction>
                <Label>当月着地予想</Label>
                <Label>{`${sales.total_sales_expectation.toLocaleString()}円`}</Label>
              </WrapperPrediction>

              <StylistDetailTableMenu rate_of_menus={sales.rate_of_menus} />

              <StylistDetailTableMonthly
                monthly_sales_data={sales.monthly_sales_data}
              />

              <StylistDetailTableCouponRanking coupons={sales.top3_coupons} />

              <StylistDetailTableUnusedCoupons coupons={sales.unused_coupons} />
            </>
          )}
        </WrapperContent>
      </WrapperShadow>
    </Wrapper>
  );
};
