import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 168px;
  height: 114px;
  padding: 8px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const MainText = styled.a``;

const Button = styled.div``;

const ColorWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
`;

const ColorMargin = styled.div<{ isLeftmost: boolean }>`
  margin-left: ${({ isLeftmost }) => (isLeftmost ? '0' : '8px')};
`;

const ColorItem = styled.a<{ color: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
`;

type Props = {
  closeModal: () => void;
  onChange: (color: string) => void;
  onAddColorClick: () => void;
};

export const ColorModal: React.FC<Props> = ({
  closeModal,
  onChange,
  onAddColorClick,
}) => {
  const colors: string[] = [
    '#E24A4A',
    '#4A90E2',
    '#212121',
    '#F1F1F1',
    '#F2994A',
    '#9B51E0',
    '#219653',
    '#F2C94C',
  ];

  const handleAddButtonClick = () => {
    closeModal();
    onAddColorClick();
  };

  const handleColorClick = (color: string) => {
    onChange(color);
    closeModal();
  };

  const AddColorButton = () => {
    return <Button onClick={handleAddButtonClick}>＋</Button>;
  };

  return (
    <Wrapper>
      <Header>
        <MainText>color styles</MainText>
        <AddColorButton />
      </Header>
      <ColorWrapper>
        {colors.map((value, index) => {
          return (
            <ColorMargin
              key={`color-margin-${index}`}
              isLeftmost={index % 4 === 0}
            >
              <ColorItem
                color={value}
                key={value}
                onClick={() => handleColorClick(value)}
              />
            </ColorMargin>
          );
        })}
      </ColorWrapper>
    </Wrapper>
  );
};
