import apiBase from './apiBase';

export type GetUsersParams = {
  name: string;
  type: string;
  phone_number: string;
  city: string;
  email: string;
  page?: number;
};

export type UsersItem = {
  id: number;
  kanji_first_name: string | null;
  kanji_last_name: string | null;
  sex: string | null;
};

type GetUsersRes = {
  data: UsersItem[];
  last_page: number;
};

export const getUsers = (params: GetUsersParams) =>
  apiBase.get<GetUsersRes>(
    `/users?page=${params.page}&name=${params.name}&type=${params.type}&phone_number=${params.phone_number}&city=${params.city}&email=${params.email}`,
  );

export type User = {
  id: number;
  user_name: string;
  email: string;
  email_verified_at: string;
  line_id: number | null;
  point: number | null;
  invite_code: number | null;
  be_invited_user_id: number | null;
  kanji_first_name: string | null;
  kanji_last_name: string | null;
  kana_first_name: string;
  kana_last_name: string;
  phone_number: string | null;
  city: string;
  post_number: string;
  address: string;
  birth_day: string | null;
  sex: string;
  salon_visit_count: number;
  cart_update_count: number;
  stripe_customer_id: null;
  is_deleted: number;
  created_at: string;
  updated_at: string;
  stylist_in_charge: {
    id: number;
    user_name: string;
  };
  invited_users: {
    id: number;
    first_visited_date: string;
    kanji_first_name: string;
    kanji_last_name: string;
    stylist_in_charge: { id: number; user_name: string };
  }[];
  invitor_user: {
    id: number;
    kanji_first_name: string;
    kanji_last_name: string;
  };
};

export type UserOrder = {
  created_at: string;
  id: number;
  product_total_price: number;
};

export type UserPurchasedItem = {
  date: string;
  name: string;
  price: number;
};

export type Order = {
  id: number;
  user_id: number;
  order_id: number;
  product_id: number;
  amount: number;
  category: string;
  image_url: string;
  name: string;
  capacity: number;
  capacity_unit: string;
  price: number;
  sticker_price: number;
  point_return_amount: number;
};

export type Material = {
  id: number;
  name: string;
  description: string;
  price: number;
};

export type UserKarte = {
  id: number;
  images: string[];
  date: string;
  materials: Material[];
  recommend: {
    id: number;
    name: string;
    description: string;
    price: number;
  };
  recommend_product_description: string;
  ref_images: string[];
  memo: string;
};

export const getUser = (userId: number) => apiBase.get<User>(`/user/${userId}`);

export const getUserOrders = (userId: number) =>
  apiBase.get<UserOrder[]>(`/user/${userId}/orders`);

export const getUserOrder = (orderId: number) =>
  apiBase.get<Order[]>(`/order/${orderId}`);

export const getUserPurchasedItems = (userId: number) =>
  apiBase.get<UserPurchasedItem[]>(`/user/${userId}/purchasedItems`);

export const getUserKarte = (userId: string) =>
  apiBase.get<UserKarte[]>(`/user/${userId}/karte`);
