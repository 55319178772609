/* eslint react/display-name: 0 */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Table } from '../parts/lv1/Table';
import { Navigation } from '../parts/lv1/Navigation';
import { ModalContext } from '../../lib/contexts/ModalContext';
import { ColorModal } from '../parts/lv1/ColorModal';
import { AddColorModal } from '../parts/lv1/InputColorModal';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TitleMargin = styled.div`
  width: 20%;
  margin: 0 0 12px 0;
`;

const TableMargin = styled.div`
  margin 24px 0 60px 0;
`;

const ColorItem = styled.a<{ color: string }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border: 1px solid #979797;
`;

type DealerData = {
  dealer: string;
  phone: string;
  fax: string;
  order: string;
  color: string;
  note: string;
};

export const Dealer = () => {
  const { setModal, closeModal } = useContext(ModalContext);

  const [dealers, setDealers] = useState<DealerData[]>([
    {
      dealer: 'ディーラー名が入ります',
      phone: '080-1234-1234',
      fax: '1234567',
      order: 'fax',
      color: '#E24A4A',
      note: '',
    },
  ]);

  const Color = ({
    index,
    color,
    onChangeColor,
  }: {
    index: number;
    color: string;
    onChangeColor: (color: string) => void;
  }) => {
    return (
      <ColorItem
        color={color}
        onClick={(e: React.MouseEvent) => {
          const customStyle = {
            overlay: {
              background: 'rgba(0, 0, 0, 0.01)',
              top: '88px',
              right: '294px',
            },
            content: {
              maxHeight: '70vh',
              top: `${e.clientY - 20}px`,
              left: `${e.clientX + 60}px`,
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              backgroud: '#fff',
              overflow: 'scroll',
              padding: 0,
            },
          };

          setModal({
            ContentComponent: () => (
              <ColorModal
                key={`color-modal-${index}`}
                closeModal={closeModal}
                onChange={onChangeColor}
                onAddColorClick={() => addColor(index, customStyle)}
              />
            ),
            isExistCloseButton: false,
            shouldCloseOnOverlayClick: true,
            customStyle,
          });
        }}
      />
    );
  };

  const links = [
    { name: '材料一覧', to: `/content/material/`, isselected: false },
    {
      name: 'ディーラー一覧',
      to: `/content/material/dealer`,
      isselected: true,
    },
    { name: '店舗基準量', to: `/content/material/basis`, isselected: false },
    { name: '店舗販売', to: `/content/material/storefront`, isselected: false },
    {
      name: 'シェアサロン利用者',
      to: `/content/material/share`,
      isselected: false,
    },
  ];

  const columns = [
    { header: 'ディーラー名', accessor: 'dealer', width: 250 },
    { header: '電話番号', accessor: 'phone', width: 150 },
    { header: 'fax番号', accessor: 'fax', width: 150 },
    { header: '発注方法', accessor: 'order', width: 150 },
    { header: 'カラー', accessor: 'color', width: 60 },
    { header: '備考', accessor: 'note', width: 256 },
  ];

  const data = dealers.map((dealer, index) => {
    return {
      dealer: dealer.dealer,
      phone: dealer.phone,
      fax: dealer.fax,
      order: dealer.order,
      color: (
        <Color
          index={index}
          color={dealer.color}
          onChangeColor={(color: string) => onChangeColor(index, color)}
        />
      ),
      note: dealer.note,
    };
  });

  const onChangeColor = (index: number, color: string) => {
    const nextArray: DealerData[] = dealers.concat();
    nextArray[index].color = color;
    setDealers(nextArray);

    // TODO APIへの登録
  };

  const addColor = (index: number, style: {}) => {
    setModal({
      ContentComponent: () => (
        <AddColorModal
          closeModal={closeModal}
          inputColorCode={(color: string) => onChangeColor(index, color)}
        />
      ),
      isExistCloseButton: false,
      shouldCloseOnOverlayClick: true,
      customStyle: style,
    });
  };

  return (
    <Wrapper>
      <TopContent>
        <TitleMargin>
          <MainContentTitle text="材料" />
        </TitleMargin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          borderRadius: 4,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '24px 24px 16px 24px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <TableMargin>
          <Table columns={columns} data={data} />
        </TableMargin>
      </ShadedContainer>
    </Wrapper>
  );
};
