import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { FontSize } from '../../../lib/styles/variables';

type Props = {
  ContentComponent: React.FC;
  isOpen: boolean;
  closeModal: () => void;
  style: {};
  isExistCloseButton: boolean;
  shouldCloseOnOverlayClick: boolean;
};

const CloseButton = styled.button`
  position: fixed;
  top: 16px;
  right: 16px;
  float: right;
  font-size: ${FontSize.TITLE};
  border: none;
  margin: 12px 12px 0 0;
  background: #fff;
`;

export const Modal: React.FC<Props> = ({
  isOpen,
  closeModal,
  style,
  ContentComponent,
  isExistCloseButton,
  shouldCloseOnOverlayClick,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={style}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={closeModal}
    >
      {isExistCloseButton ? (
        <CloseButton onClick={closeModal}>×</CloseButton>
      ) : null}
      {ContentComponent}
    </ReactModal>
  );
};
