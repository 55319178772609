import React from 'react';
import styled from 'styled-components';
import { FontSize } from '../../../lib/styles/variables';
import { SquareButton } from '../lv3/SquareButton';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FieldLabel = styled.p`
  font-size: ${FontSize.X_LARGE};
  line-height: 30px;
  color: #757575;
  margin-bottom: 8px;
`;

const InputField = styled.input`
  height: 40px;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};

  ::placeholder {
    color: #bdbdbd;
  }
`;

const InputTextAreaField = styled.textarea`
  height: 40px;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #979797;
  font-size: ${FontSize.TITLE};

  ::placeholder {
    color: #bdbdbd;
  }
`;

const FormItemArea = styled.div`
  width: 337px;
  position: relative;
`;
const FormItemRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  & > div:first-child {
    margin-right: 80px;
  }
`;

const Margin = styled.div`
  margin-top: 40px;
`;

const ActionsArea = styled.div`
  margin-top: 40px;
  display: flex;
  & > button:first-child {
    margin-right: 24px;
  }
`;

const Unit = styled.div<{ label: string }>`
  display: flex;
  border-bottom: 1px solid #979797;
  &>input {
    border-bottom: none;
  }
  &::after {
    content: "${({ label }) => label}";
    font-size: 24px;
    color: #212121;
  }
`;

type Props = {
  register: any;
  onSubmit: () => void;
  onDelete?: () => void;
};

export const RecordAccountingForm: React.FC<Props> = ({
  register,
  onSubmit,
  onDelete,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <FormItemRow>
        <FormItemArea>
          <FieldLabel>科目</FieldLabel>
          <InputField
            type="text"
            name="subject"
            ref={register}
            placeholder="入力してください"
          />
        </FormItemArea>
        <FormItemArea>
          <FieldLabel>金額</FieldLabel>
          <Unit label="円">
            <InputField
              type="text"
              name="money"
              ref={register}
              placeholder="150,000"
            />
          </Unit>
        </FormItemArea>
      </FormItemRow>
      <Margin>
        <FieldLabel>備考</FieldLabel>
        <InputTextAreaField
          name="note"
          ref={register}
          placeholder="入力してください"
          style={{
            width: '90%',
            height: '150px',
          }}
        />
      </Margin>
      <ActionsArea>
        <SquareButton
          fontSize={15}
          height={40}
          width={136}
          text="登録"
          color="BLUE"
          type="submit"
          borderRadius={4}
        />
        {onDelete && (
          <SquareButton
            fontSize={15}
            height={40}
            width={136}
            text="削除"
            color="RED"
            handleClick={onDelete}
            borderRadius={4}
          />
        )}
      </ActionsArea>
    </Form>
  );
};
