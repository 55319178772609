import React, { FC } from 'react';
import styled from 'styled-components';
import { ContractType } from '../../../lib/api/stylist';

const Wrapper = styled.div`
  margin: 0 40px;
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.span`
  width: 204px;
  height: 204px;
  background: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 102px;
  margin-bottom: 24px;
`;

const Img = styled.img`
  width: 204px;
  height: 204px;
  border-radius: 102px;
  border: 1px solid #979797;
`;

const Number = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  margin-bottom: 16px;
`;

const Label = styled.p`
  font-size: 24px;
  line-height: 36px;
  color: #757575;
  margin-bottom: 8px;
`;

const Contract = styled.div`
  border: 1px solid #4a90e2;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  color: #4a90e2;
`;

type Props = {
  employeeNumber: number;
  contractType: ContractType;
  img_url: string | undefined;
};

export const StylistDetailLeftInfo: FC<Props> = ({
  img_url,
  employeeNumber,
  contractType,
}) => {
  return (
    <Wrapper>
      {img_url ? <Img src={img_url} alt="profile_img" /> : <Circle />}
      <Label>従業員番号</Label>
      <Number>{employeeNumber}</Number>
      <Contract>{contractType}</Contract>
    </Wrapper>
  );
};
