import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div<{ isSmall?: boolean; flexEnd?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: ${({ isSmall }) => (isSmall ? '40%' : '80%')};
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'flex-start')};
`;

const Slash = styled.span`
  font-size: 24px;
  color: #757575;
  margin: 0 15px;
`;

const StyledLink = styled(Link)<{ isselected?: boolean }>`
  font-size: 24px;
  line-height: 36px;
  white-space: nowrap;
`;

const Text = styled.span<{ isselected?: boolean }>`
  color: ${({ isselected }) => (isselected ? '#4A90E2' : '#757575')};
`;

type Props = {
  links: {
    name: string;
    to: string;
    isselected: boolean;
    onClick?: () => void;
  }[];
  isSmall?: boolean;
  flexEnd?: boolean;
};

export const Navigation: React.FC<Props> = ({
  links,
  isSmall = false,
  flexEnd = false,
}) => {
  return (
    <Wrapper isSmall={isSmall} flexEnd={flexEnd}>
      {links.map((link, index) => (
        <div key={index}>
          <StyledLink to={link.to} onClick={link.onClick}>
            <Text isselected={link.isselected}>{link.name}</Text>
          </StyledLink>
          {links.length !== index + 1 ? <Slash>/</Slash> : null}
        </div>
      ))}
    </Wrapper>
  );
};
