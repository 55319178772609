import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      th {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        background: #f1f1f1;
      }
      td {
        padding: 0.5rem;
        border: 1px solid #979797;
        font-size: 14px;
      }
    }
  }
`;

type Props = {
  repeat_rate: number;
  unit_price_per_hour: number;
};

export const StylistDetailTableRepeat: FC<Props> = ({
  repeat_rate,
  unit_price_per_hour,
}) => {
  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th>リピート率</th>
            <th>時間単価</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{`${repeat_rate}％`}</td>
            <td>{`${unit_price_per_hour}円`}</td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
};
