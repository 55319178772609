import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputCustom = styled.input`
  width: 100%;
  height: 28px;
  padding: 0 8px;
  border: none;
  background: #f6f6f6;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #212121;
`;

const Unit = styled.div`
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #212121;
`;

export const AccountingInputText: FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  unit?: string;
}> = ({ onChange, placeholder, unit }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <InputCustom
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
      />
      {unit && <Unit>{unit}</Unit>}
    </Wrapper>
  );
};
