import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Margin } from '../parts/lv3/Margin';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { SquareButton } from '../parts/lv3/SquareButton';
import { CheckBox } from '../parts/lv3/CheckBoxWithText';
import { AccountingInputText } from '../parts/lv1/AccountingInputText ';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperShadow = styled.div`
  margin: 12px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const Label = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
`;

const WrapperTable = styled.div<{ width: number; margin?: string }>`
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
  overflow: scroll;
`;

const WrapperMonths = styled.div`
  display: flex;
  align-items: center;
  width: 820px;
  height: 50px;
  margin-bottom: 36px;
  padding: 0 8px;
  border: 1px solid #979797;
`;

const CheckboxWithMonth = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-weight: 500;
  font-size: 12px;
`;

const StyledTable = styled.table`
  margin-bottom: 2rem;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  tr {
    th {
      padding: 12px;
      border: 1px solid #979797;
      text-align: left;
      font-size: 12px;
      font-weight: normal;
    }
    td {
      padding: 4px;
      border: 1px solid #979797;
      font-size: 14px;
    }
  }
`;

const StyledTablePoint = styled.table`
  width: 100%;
  margin-bottom: 2rem;
  border-collapse: collapse;
  table-layout: fixed;
  tr {
    th {
      width: 80px;
      padding: 4px;
      border: 1px solid #979797;
      text-align: left;
      font-size: 12px;
      font-weight: normal;
      :first-child {
        width: 200px;
        text-align: center;
        background: #f1f1f1;
      }
    }
    td {
      border: 1px solid #979797;
      padding: 4px;
      font-size: 12px;
      :first-child {
        width: 200px;
        text-align: center;
        background: #f1f1f1;
      }
    }
  }
`;

const headBackRate = [
  '29P以下',
  '30P~',
  '40P~',
  '50P~',
  '60P~',
  '70P~',
  '80P~',
  '90P~',
];

export const AccountingCommissionManager = () => {
  const history = useHistory();

  const [checkedMonths, setCheckedMonths] = useState<number[]>([]);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickSave = useCallback(() => {
    alert('TODO');
  }, []);

  const handleClickMonth = useCallback(
    (month: number) => () => {
      setCheckedMonths((prev) => {
        const newArr = [...prev];
        const index = newArr.indexOf(month);
        if (index > -1) {
          newArr.splice(index, 1);
          return newArr;
        }
        newArr.push(month);
        return newArr;
      });
    },
    [],
  );

  const links = useMemo(
    () => [
      {
        name: '正社員',
        to: `/accounting/commission/regular`,
        isselected: false,
      },
      {
        name: '店長',
        to: `/accounting/commission/manager`,
        isselected: true,
      },
      {
        name: 'DM',
        to: `/accounting/commission/dm`,
        isselected: false,
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="歩合給・賞与" />
          </Margin>
        </WrapperGoBack>
        <Navigation links={links} flexEnd />
      </WrapperNavigation>
      <WrapperShadow>
        <Margin margin="0 0 24px 0 ">
          <SquareButton
            fontSize={15}
            width={136}
            height={40}
            text="保存"
            color="BLUE"
            borderRadius={4}
            handleClick={handleClickSave}
          />
        </Margin>

        <Label>支給時期</Label>
        <WrapperMonths>
          {Array.from(Array(12).keys()).map((item) => {
            return (
              <CheckboxWithMonth key={item}>
                <CheckBox
                  size={{ width: 24, height: 24 }}
                  isChecked={checkedMonths.indexOf(item + 1) > -1}
                  handleClick={handleClickMonth(item + 1)}
                />
                {`${item + 1}月`}
              </CheckboxWithMonth>
            );
          })}
        </WrapperMonths>

        <WrapperTable width={1000} margin="0 0 36px 0">
          <Label>達成給・賞与テーブル</Label>
          <StyledTable>
            <thead>
              <tr>
                <th />
                <th>基準値</th>
                <th>2,000,000</th>
                <th>2,000,000</th>
                <th>3,000,000</th>
                <th>4,000,000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>店舗予算</td>
                <td>設定予算額</td>
                {Array.from(Array(4).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>仕入れ＋タオル</td>
                <td>8.50%</td>
                {Array.from(Array(4).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>店販購入者比率</td>
                <td>8%</td>
                {Array.from(Array(4).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>MOT点数</td>
                <td>90点</td>
                {Array.from(Array(4).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>合計</td>
                <td />
                {Array.from(Array(4).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} unit="円" />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </StyledTable>
        </WrapperTable>

        <WrapperTable width={1000} margin="0 0 36px 0">
          <Label>ポイント表</Label>
          <StyledTablePoint>
            <thead>
              <tr>
                <th />
                {Array.from(Array(10).keys()).map((item) => {
                  return <th key={item}>{`${item + 1}P`}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>新規再来率 3か月</td>
                {Array.from(Array(10).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>顧客再来率 3か月</td>
                {Array.from(Array(10).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>トリートメント比率</td>
                {Array.from(Array(10).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>MOTチェック点数</td>
                {Array.from(Array(10).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>個人の時間単価（技術売）</td>
                {Array.from(Array(10).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>店舗予算達成</td>
                {Array.from(Array(10).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </StyledTablePoint>
        </WrapperTable>

        <WrapperTable width={1000} margin="0 0 36px 0">
          <Label>その他業務ポイント</Label>
          <StyledTablePoint>
            <thead>
              <tr>
                <th />
                {Array.from(Array(10).keys()).map((item) => {
                  return <th key={item}>{`${(item + 1) * 2}P`}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>その他業務ポイント</td>
                {Array.from(Array(10).keys()).map((item) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </StyledTablePoint>
        </WrapperTable>

        <WrapperTable width={1000} margin="0 0 36px 0">
          <Label>バック率</Label>
          <StyledTablePoint>
            <thead>
              <tr>
                <th />
                {headBackRate.map((item) => {
                  return <th key={item}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>指名</td>
                {headBackRate.map((item, i) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>フリー</td>
                {headBackRate.map((item, i) => {
                  return (
                    <td key={item}>
                      <AccountingInputText onChange={() => {}} />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </StyledTablePoint>
        </WrapperTable>
      </WrapperShadow>
    </Wrapper>
  );
};
