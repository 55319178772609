/* eslint no-plusplus: 0 */
/* eslint no-unused-expressions: "off" */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { FontSize } from '../../../lib/styles/variables';
import { getDayTextColor } from '../../../lib/styles/dayTextColor';
import dayjs from '../../../lib/utils/dayjs';
import { SelectBox } from '../lv3/SelectBox';
import { CheckBoxWithText } from '../lv3/CheckBoxWithText';
import { SquareButton } from '../lv3/SquareButton';
import { Margin } from '../lv3/Margin';
import { AccountContext } from '../../../lib/contexts/AccountContext';
import {
  getBusinessHoursList,
  updateBusinessHours,
} from '../../../lib/api/businessHours';
import { useSpinner } from '../../../lib/hooks/useSpinner';
import { BatchSetting } from './StoreDateSettingBatchForm';

const Form = styled.form`
  width: 100%;
`;

const Table = styled.table`
  border: 1px solid #979797;
  border-collapse: collapse;
  width: 100%;
  border-right: none;
  border-left: none;
`;

const Thead = styled.thead`
  border-right: none;
  border-left: none;
`;

const WeekHoliday = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ThreadItemProps = {
  width?: number;
  isLeftmost?: boolean;
  isRightmost?: boolean;
  isBottom?: boolean;
};

const Tbody = styled.tbody``;

const Tr = styled.tr`
  border: 1px solid #979797;

  border-right: none;
  border-left: none;
`;

const Th = styled.th<ThreadItemProps>`
  font-weight: bold;
  border: 1px solid #979797;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: 38px;
  text-align: center;
  font-size: 12px;

  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-left: ${({ isLeftmost }) =>
    isLeftmost ? 'none' : '1px solid #979797'};
`;

const Td = styled.td<ThreadItemProps>`
  border: 1px solid #979797;
  height: 80px;
  font-size: 12px;
  padding: 4px;
  vertical-align: middle;
  border-right: ${({ isRightmost }) =>
    isRightmost ? 'none' : '1px solid #979797'};
  border-left: ${({ isLeftmost }) =>
    isLeftmost ? 'none' : '1px solid #979797'};
`;

const GridItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DayText = styled.div<{ color?: string }>`
  width: 100%;
  text-align: center;
  vertical-align: middle;
  margin-left: 4px;
  color: ${({ color }) => color || '#212121'};
`;

const DateText = styled.a<{ color?: string }>`
  font-weight: bold;
  font-size: ${FontSize.LARGE};
  margin-right: 8px;
  color: ${({ color }) => color || '#212121'};
`;

const TextMargin = styled.a`
  margin-left: 4px;
  margin-right: 8px;
`;

const WEEK_DAYS = [
  {
    num: 0,
    en: 'sunday',
    jp: '日',
  },
  {
    num: 1,
    en: 'monday',
    jp: '月',
  },
  {
    num: 2,
    en: 'tuesday',
    jp: '火',
  },
  {
    num: 3,
    en: 'wednesday',
    jp: '水',
  },
  {
    num: 4,
    en: 'thursday',
    jp: '木',
  },
  {
    num: 5,
    en: 'friday',
    jp: '金',
  },
  {
    num: 6,
    en: 'saturday',
    jp: '土',
  },
];

const getDateArray = (dates: dayjs.Dayjs[]) => {
  const dateArray = Array(dates[0].day())
    .fill('')
    .concat(dates.map((date) => date.date().toString()))
    .concat(Array(6 - dates[dates.length - 1].day()).fill(''));
  return Array(dateArray.length / 7)
    .fill('')
    .map((_, index) => {
      return dateArray.slice(index * 7, 7 * (index + 1));
    });
};

const HoursSelectBox = ({
  name,
  isRegularHoliday = false,
  hours,
  register,
}: {
  name: string;
  isRegularHoliday: boolean;
  hours: dayjs.Dayjs[];
  register: any;
}) => {
  return (
    <SelectBox
      control={{
        type: 'hook',
        name,
        register,
      }}
      style={{
        width: '58px',
        fontSize: '12px',
        height: '22px',
        border: 'all',
        padding: '0 8px',
        size: 8,
        background: isRegularHoliday ? '#E24A4A' : '#fff',
      }}
    >
      {hours.map((option, index) => {
        const time = option.format('HH:mm');
        return (
          <option
            key={`${name}-${index}-${time}`}
            value={`${time}:00`}
            disabled={isRegularHoliday}
          >
            {time}
          </option>
        );
      })}
    </SelectBox>
  );
};

const BusinessHoursSelectBox = ({
  name,
  isRegularHoliday = false,
  hours,
  register,
}: {
  name: string;
  isRegularHoliday?: boolean;
  hours: dayjs.Dayjs[];
  register: any;
}) => {
  return (
    <GridItemWrapper>
      <HoursSelectBox
        name={`${name}.startHours`}
        register={register}
        hours={hours}
        isRegularHoliday={isRegularHoliday || false}
      />
      <TextMargin>〜</TextMargin>
      <HoursSelectBox
        name={`${name}.endHours`}
        register={register}
        hours={hours}
        isRegularHoliday={isRegularHoliday || false}
      />
    </GridItemWrapper>
  );
};

const GridDateItem = ({
  name = '',
  date = '',
  day,
  isChecked = false,
  hours,
  register,
}: {
  name?: string;
  date?: string;
  day: number;
  isChecked: boolean;
  hours: dayjs.Dayjs[];
  register: any;
}) => {
  if (date === '') return null;
  return (
    <>
      <GridItemWrapper>
        <DateText color={getDayTextColor(day)}>{date}</DateText>
        <CheckBoxWithText
          text="休業日"
          id={`${name}.isHoliday`}
          size={{ width: 100, height: 30 }}
          register={register}
          isChecked={isChecked}
          color="#4A90E2"
        />
      </GridItemWrapper>
      <BusinessHoursSelectBox
        name={name}
        hours={hours}
        register={register}
        isRegularHoliday={isChecked}
      />
    </>
  );
};

type Props = {
  yearMonth: string;
  dates: dayjs.Dayjs[];
  hours: dayjs.Dayjs[];
  batch: BatchSetting;
};

export const StoreDateSettingTable: React.FC<Props> = ({
  yearMonth,
  dates,
  hours,
  batch,
}) => {
  const { selectedSalon } = useContext(AccountContext);
  const [weeks, setWeeks] = useState(getDateArray(dates));
  const { loading, startLoading, finishLoading } = useSpinner();
  const methods = useForm<{}>();

  // 一覧取得
  useEffect(() => {
    if (!selectedSalon) return;
    startLoading();
    setWeeks(getDateArray(dates));

    getBusinessHoursList(selectedSalon, yearMonth)
      .then(({ data }) => {
        const resetObject: any = {};
        data.forEach((info, index) => {
          resetObject[`date${index + 1}`] = {
            isHoliday: info.is_holiday,
            startHours: info.from_time,
            endHours: info.to_time,
          };
        });
        methods.reset(resetObject);
      })
      .catch(() => {})
      .finally(() => {
        finishLoading();
      });
  }, [yearMonth, dates]);

  // 編集
  const onSubmit = methods.handleSubmit((value: any) => {
    if (!selectedSalon) return;
    startLoading();
    const params = dates.map((date, index) => {
      const input = value[`date${index + 1}`];
      return {
        salon_id: selectedSalon,
        date: date.format('YYYY-MM-DD'),
        from_time: input.startHours || '',
        to_time: input.endHours || '',
        is_holiday: input.isHoliday || false,
      };
    });

    updateBusinessHours(selectedSalon, params)
      .then(() => {})
      .finally(() => {
        finishLoading();
      });
  });

  // 一括設定
  useEffect(() => {
    const isHoliday = batch.businessDay === 'holiday';

    if (batch.specify === 'period') {
      const startDate = Number(batch.period.periodStartDate);
      const endDate = Number(batch.period.periodEndDate);
      if (
        startDate <= endDate &&
        batch.period.businessStartHours <= batch.period.businessEndHours
      ) {
        Array(endDate - startDate + 1)
          .fill('')
          .forEach((_, index) => {
            methods.setValue(`date${startDate + index}.isHoliday`, isHoliday);
            if (isHoliday) return;
            methods.setValue(
              `date${startDate + index}.startHours`,
              batch.period.businessStartHours,
            );
            methods.setValue(
              `date${startDate + index}.endHours`,
              batch.period.businessEndHours,
            );
          });
      }
    } else {
      Object.entries(batch.days).forEach(([key, value], index) => {
        methods.setValue(`regularHoliday.${key}`, value && isHoliday);
        weeks.forEach((week) => {
          const date = week[index];
          if (date === '') return;
          methods.setValue(`date${date}.isHoliday`, isHoliday);
          if (isHoliday) return;
          methods.setValue(
            `date${date}.startHours`,
            batch.period.businessStartHours,
          );
          methods.setValue(
            `date${date}.endHours`,
            batch.period.businessEndHours,
          );
        });
      });
    }
  }, [batch]);

  // 曜日の休日設定と各日付の休日設定をfetch
  const changeRegularHoliday = ({ en, num }: { en: string; num: number }) => {
    const isHoliday = !methods.watch(`regularHoliday.${en}`);
    weeks.forEach((week) => {
      const date = week[num];
      if (date === '') return;
      methods.setValue(`date${date}.isHoliday`, isHoliday);
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {!loading ? (
        <>
          <Table>
            <Thead>
              <Tr>
                {WEEK_DAYS.map((item, index) => (
                  <Th
                    key={item.en}
                    width={144}
                    isLeftmost={index === 0}
                    isRightmost={index === 6}
                  >
                    <WeekHoliday>
                      <CheckBoxWithText
                        text="休業日"
                        id={`regularHoliday.${item.en}`}
                        size={{ width: 80, height: 30 }}
                        register={methods.register}
                        isChecked={methods.watch(`regularHoliday.${item.en}`)}
                        handleClick={() => changeRegularHoliday(item)}
                      />
                    </WeekHoliday>
                  </Th>
                ))}
              </Tr>
              <tr>
                {WEEK_DAYS.map((item, index) => (
                  <Th
                    key={item.en}
                    width={144}
                    isLeftmost={index === 0}
                    isRightmost={index === 6}
                  >
                    <DayText
                      key={`title-${item.en}`}
                      color={getDayTextColor(index)}
                    >
                      {item.jp}
                    </DayText>
                  </Th>
                ))}
              </tr>
            </Thead>
            <Tbody>
              {weeks.map((week, weekIndex) => {
                return (
                  <Tr key={`week${weekIndex + 1}`}>
                    {week.map((day, dayIndex) => {
                      return (
                        <Td
                          key={`week${weekIndex + 1}-date${dayIndex}`}
                          className="table_row_title"
                          isLeftmost={dayIndex === 0}
                        >
                          <GridDateItem
                            key={`week${weekIndex + 1}-date${dayIndex}`}
                            name={`date${day}`}
                            date={day}
                            day={dayIndex}
                            hours={hours}
                            register={methods.register}
                            isChecked={methods.watch(`date${day}.isHoliday`)}
                          />
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Margin margin="8px">
            <SquareButton
              type="submit"
              text="登録"
              width={114}
              height={40}
              fontSize={15}
              borderRadius={4}
              color="BRACK"
            />
          </Margin>
        </>
      ) : (
        loading
      )}
    </Form>
  );
};
