/* eslint react/display-name: 0 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Row, Table } from '../parts/lv1/Table';
import { getMessagesToCustomer, Message } from '../../lib/api/message';
import { usePager } from '../../lib/hooks/usePager';
import { Color } from '../../lib/styles/variables';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditLink = styled.div`
  color: ${Color.BLUE};
`;

type MessageTable = Message & {
  sent_result: string;
};

export const MessageCustomer = () => {
  const history = useHistory();
  const [messages, setMessages] = useState<Message[] | null>(null);

  const handlePagenation = () => {
    getMessagesToCustomer().then(({ data }) => {
      setMessages(data.data);
      setLastPage(data.last_page);
    });
  };

  const { Pager, setLastPage } = usePager(handlePagenation);

  const links = [
    { name: 'お客様', to: '/content/message/customer', isselected: true },
    { name: '従業員', to: '/content/message/stylist', isselected: false },
  ];

  const columns = [
    {
      header: '件名',
      accessor: (row: Row<MessageTable>) => {
        if (row.original.is_sent) {
          return row.original.title;
        }
        return (
          <EditLink
            onClick={() =>
              history.push(`/content/message/customer/edit/${row.original.id}`)
            }
          >
            {row.original.title}
          </EditLink>
        );
      },
      width: 665,
    },
    { header: '配信人数', accessor: 'send_amount', width: 68 },
    { header: '送信日時', accessor: 'send_at', width: 136 },
    { header: '送信済', accessor: 'sent_result', width: 60 },
  ];

  const tableData =
    messages?.map((message) => ({
      ...message,
      sent_result: message.is_sent ? '送信済み' : '',
    })) || [];

  useEffect(() => {
    getMessagesToCustomer().then(({ data }) => {
      setMessages(data.data);
      setLastPage(data.last_page);
    });
  }, []);

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="メッセージ" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <Margin margin="24px 0 32px 0">
        <SquareButton
          fontSize={15}
          width={136}
          height={40}
          text="新規作成"
          borderRadius={4}
          color="BLUE"
          handleClick={() => history.push('/content/message/customer/filter')}
        />
      </Margin>
      {messages ? <Table columns={columns} data={tableData} /> : null}
      <Pager />
    </Wrapper>
  );
};
