import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { Table } from '../parts/lv1/Table';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  padding: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas:
    'id birthday'
    'sex prefecture'
    'tel visit_count'
    'introduction person';
  width: 700px;
`;

const GridItem = styled.div<{ gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  flex-direction: column;
  height: 70px;
  margin-bottom: 40px;
`;

const LabelStyle = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.63304px;
  color: #757575;
`;

const TableLabelContainer = styled.div`
  margin-bottom: 40px;
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4a90e2;
  }
`;

const TextStyle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1.36087px;
  color: #212121;
`;

export const CustomerDetail = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const links = [
    { name: '基本情報', to: `/customer/${id}`, isselected: true },
    {
      name: '予約状況',
      to: `/customer/${id}/reservation`,
      isselected: false,
    },
  ];

  const introduceColumns = [
    { header: '紹介来店日時', accessor: 'introductionVisitDate', width: 150 },
    { header: '名前', accessor: 'name', width: 150 },
    { header: '担当者', accessor: 'person', width: 600 },
  ];

  const introduceData = [
    { introductionVisitDate: '2020-01-24', name: '大野智', person: 'フリー' },
    { introductionVisitDate: '2020-01-24', name: '大野智', person: 'フリー' },
    { introductionVisitDate: '2020-01-24', name: '大野智', person: 'フリー' },
  ];

  const EcColumns = [
    { header: '購入日', accessor: 'buyDate', width: 150 },
    { header: '注文ID', accessor: 'id', width: 600 },
    { header: '注文の合計金額', accessor: 'sum', width: 150 },
  ];

  const EcData = [
    {
      buyDate: '2020-01-24',
      id: <Link to={`/customer/${id}/order/a1b2gbiyfse/`}>a1b2gbiyfse</Link>,
      sum: '1,000,000円',
    },
    {
      buyDate: '2020-01-24',
      id: <Link to={`/customer/${id}/order/a1b2gbiyfse/`}>a1b2gbiyfse</Link>,
      sum: '1,000,000円',
    },
    {
      buyDate: '2020-01-24',
      id: <Link to={`/customer/${id}/order/a1b2gbiyfse/`}>a1b2gbiyfse</Link>,
      sum: '1,000,000円',
    },
  ];

  const storeColumns = [
    { header: '購入日', accessor: 'buyDate', width: 150 },
    { header: '商品名', accessor: 'name', width: 600 },
    { header: '価格', accessor: 'price', width: 150 },
  ];

  const storeData = [
    { buyDate: '2020-01-24', name: 'リンス', price: '1,000,000円' },
    { buyDate: '2020-01-24', name: 'リンス', price: '1,000,000円' },
    { buyDate: '2020-01-24', name: 'リンス', price: '1,000,000円' },
  ];

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Wrapper>
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={handleClickArrowLeft}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text="山田太郎" />
        </Margin>
        <Navigation links={links} isSmall />
      </TopContent>
      <WrapperShadow>
        <GridContainer>
          <GridItem gridArea="id">
            <LabelStyle>ID</LabelStyle>
            <TextStyle>123456789</TextStyle>
          </GridItem>
          <GridItem gridArea="birthday">
            <LabelStyle>生年月日</LabelStyle>
            <TextStyle>1998/09/07</TextStyle>
          </GridItem>
          <GridItem gridArea="sex">
            <LabelStyle>性別</LabelStyle>
            <TextStyle>男性</TextStyle>
          </GridItem>
          <GridItem gridArea="prefecture">
            <LabelStyle>都道府県</LabelStyle>
            <TextStyle>千葉</TextStyle>
          </GridItem>
          <GridItem gridArea="tel">
            <LabelStyle>電話番号</LabelStyle>
            <TextStyle>123456789</TextStyle>
          </GridItem>
          <GridItem gridArea="visit_count">
            <LabelStyle>総来店回数</LabelStyle>
            <TextStyle>123456789</TextStyle>
          </GridItem>
          <GridItem gridArea="introduction">
            <LabelStyle>紹介された</LabelStyle>
            <TextStyle>櫻井翔</TextStyle>
          </GridItem>
          <GridItem gridArea="person">
            <LabelStyle>担当者</LabelStyle>
            <TextStyle>杉野</TextStyle>
          </GridItem>
        </GridContainer>
        <TableLabelContainer>
          <LabelStyle>紹介した</LabelStyle>
          <Table columns={introduceColumns} data={introduceData} />
        </TableLabelContainer>
        <TableLabelContainer>
          <LabelStyle>EC購入履歴</LabelStyle>
          <Table columns={EcColumns} data={EcData} />
        </TableLabelContainer>
        <TableLabelContainer>
          <LabelStyle>店舗購入履歴</LabelStyle>
          <Table columns={storeColumns} data={storeData} />
        </TableLabelContainer>
      </WrapperShadow>
    </Wrapper>
  );
};
