import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { ShadedContainer } from '../layout/ShadedContainer';
import { InventoryTable } from '../parts/fx/InventoryTable';
import { SubcontractingTable } from '../parts/fx/SubcontractingTable';
import { MonthlyTable } from '../parts/fx/MonthlyTable';
import { FxLinkingTable } from '../parts/fx/FxLinkingTable';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ScrollXArea = styled.div`
  overflow-x: scroll;
  height: 100%;
  display: flex;
  margin-top: 24px;
`;

const EmptyContent = styled.div`
  min-width: 67px;
  min-height: 20px;
`;

const ActionsArea = styled.div``;
const TableArea = styled.div`
  & .table_row_title {
    font-weight: bold;
    text-align: center;
  }
`;

const SaveBtn = styled.div`
  background: #4a90e2;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 0px;
  text-align: center;
  width: 136px;
`;

enum ETabs {
  Top = 'top',
  Inventory = 'inventory',
  Subcontracting = 'subcontracting',
  Monthly = 'monthly',
}

export const FxLinking = () => {
  const location = useLocation();
  const tabParam = new URLSearchParams(location.search).get('t');
  const defaultTab = tabParam ? (tabParam as ETabs) : ETabs.Top;
  const [tab, setTab] = useState(defaultTab);

  const content = (() => {
    switch (tab) {
      case ETabs.Inventory:
        return <InventoryTable />;
      case ETabs.Subcontracting:
        return <SubcontractingTable />;
      case ETabs.Monthly:
        return <MonthlyTable />;
      default:
        return <FxLinkingTable />;
    }
  })();

  const links = [
    {
      name: 'レジ締め',
      to: '/accounting/fx-linking',
      isselected: tab === ETabs.Top,
      onClick: () => {
        setTab(ETabs.Top);
      },
    },
    {
      name: '棚卸し',
      to: `/accounting/fx-linking?t=${ETabs.Inventory}`,
      isselected: tab === ETabs.Inventory,
      onClick: () => {
        setTab(ETabs.Inventory);
      },
    },
    {
      name: '業務委託費',
      to: `/accounting/fx-linking?t=${ETabs.Subcontracting}`,
      isselected: tab === ETabs.Subcontracting,
      onClick: () => {
        setTab(ETabs.Subcontracting);
      },
    },
    {
      name: 'シェアサロン月額利用者',
      to: `/accounting/fx-linking?t=${ETabs.Monthly}`,
      isselected: tab === ETabs.Monthly,
      onClick: () => {
        setTab(ETabs.Monthly);
      },
    },
  ];

  return (
    <Wrapper>
      <TopContent>
        <Margin margin="0 0 12px 0">
          <MainContentTitle text="会計ソフトFX連携" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '64px 0px 40px 67px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <ActionsArea>
          <SaveBtn>保存</SaveBtn>
        </ActionsArea>
        <ScrollXArea>
          <TableArea>
            {content}
            <EmptyContent />
          </TableArea>
          <EmptyContent />
        </ScrollXArea>
      </ShadedContainer>
    </Wrapper>
  );
};
