import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ShadedContainer } from '../layout/ShadedContainer';
import { Margin } from '../parts/lv3/Margin';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Navigation } from '../parts/lv1/Navigation';
import { SquareButton } from '../parts/lv3/SquareButton';
import { Table } from '../parts/lv1/Table';
import { getAllPosUsers, deletePosUser } from '../../lib/api/posUser';
import { useSpinner } from '../../lib/hooks/useSpinner';

const Wrapper = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 23px;
  margin-bottom: 32px;
`;

const TopContent = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const MainContainer = styled.div`
  margin: 0 67px;
`;

const Button = styled.button`
  font-size: 12px;
  color: #e24a4a;
  background: #fff;
  width: 100%;
  align-items: center;
`;

type PosUserDataType = {
  id: string;
  remarks: string;
  deleteButton: JSX.Element;
};

export const PosUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const { loading, startLoading, finishLoading } = useSpinner();
  const [posUsers, setPosUsers] = useState<PosUserDataType[]>([]);

  const links = [
    { name: '基本情報', to: `/store/detail/${id}`, isselected: false },
    {
      name: '美容師リスト',
      to: `/store/detail/${id}/stylist`,
      isselected: false,
    },
    {
      name: '顧客リスト',
      to: `/store/detail/${id}/customer`,
      isselected: false,
    },
    {
      name: 'POSユーザー',
      to: `/store/detail/${id}/posuser`,
      isselected: true,
    },
  ];

  const DeleteButton = ({ userId }: { userId: number }) => {
    return (
      <Button
        onClick={() => {
          startLoading();
          deletePosUser(id, userId)
            .then(() => {
              getAllPosUsers(id)
                .then(({ data }) => {
                  const tableData = data?.data.map((pos) => {
                    return {
                      id: pos.id.toString(),
                      remarks: pos.remarks,
                      deleteButton: <DeleteButton userId={pos.id} />,
                    };
                  });
                  setPosUsers(tableData);
                })
                .finally(() => {
                  finishLoading();
                });
            })
            .catch(() => {
              alert('削除に失敗しました。時間を置いて再度お試しください。');
            });
        }}
      >
        削除
      </Button>
    );
  };

  const columns = [
    { header: 'ID', accessor: 'id', width: 300 },
    { header: '備考', accessor: 'remarks', width: 3000 },
    { header: '', accessor: 'deleteButton', width: 120 },
  ];

  useEffect(() => {
    startLoading();
    getAllPosUsers(id)
      .then(({ data }) => {
        const tableData = data?.data.map((pos) => {
          return {
            id: pos.id.toString(),
            remarks: pos.remarks,
            deleteButton: <DeleteButton userId={pos.id} />,
          };
        });
        setPosUsers(tableData);
      })
      .finally(() => {
        finishLoading();
      });
  }, []);

  return (
    <Wrapper>
      <TopContent>
        <ArrowIcon
          style={{ height: 14, width: 8, color: 'rgba(0, 0, 0, 0.54)' }}
          direction="LEFT"
          handleClick={() => history.push('/store')}
        />
        <Margin margin="0 0 0 24px">
          <MainContentTitle text="ミラーボール渋谷店" />
        </Margin>
        <Navigation links={links} />
      </TopContent>
      <ShadedContainer
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          padding: '80px 0 150px',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <MainContainer>
          <Margin margin="0 0 16px 0">
            <SquareButton
              fontSize={15}
              width={136}
              height={40}
              borderRadius={4}
              color="BLUE_BORDER"
              text="追加"
              type="submit"
              handleClick={() =>
                history.push(`/store/detail/${id}/posuser/add`)
              }
            />
          </Margin>
          {!loading ? (
            posUsers.length > 0 ? (
              <Table columns={columns} data={posUsers} />
            ) : (
              'POSユーザーの情報はありません'
            )
          ) : (
            loading
          )}
        </MainContainer>
      </ShadedContainer>
    </Wrapper>
  );
};
