import { createContext } from 'react';
import { AuthParams, UpdateProfileParams, AccountType } from '../api/auth';

export const AccountContext = createContext<{
  account: AccountType | null;
  isMountAccount: boolean;
  selectedSalon: number | null;
  login: (params: AuthParams) => Promise<void>;
  logout: () => void;
  mountAccount: () => Promise<void> | void;
  updateAccount: (params: UpdateProfileParams) => Promise<void>;
  selectSalon: (salonId: number) => void;
}>({
  account: null,
  isMountAccount: null as any,
  selectedSalon: null,
  login: null as any,
  logout: null as any,
  mountAccount: null as any,
  updateAccount: null as any,
  selectSalon: null as any,
});
