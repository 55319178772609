import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { MainContentTitle } from '../parts/lv3/MainContentTitle';
import { ArrowIcon } from '../parts/lv3/ArrowIcon';
import { Margin } from '../parts/lv3/Margin';
import { Navigation } from '../parts/lv1/Navigation';
import { getUserKarte, Material, UserKarte } from '../../lib/api/user';

const Wrapper = styled.div`
  padding: 32px;
`;

const WrapperGoBack = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:last-child {
    width: auto;
  }
`;

const WrapperShadow = styled.div`
  margin: 32px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const WrapperKarteItem = styled.div`
  padding: 32px;
  border-bottom: 8px solid #f1f1f1;
`;

const Date = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #757575;
`;

const Label = styled.div`
  margin-bottom: 0.5rem;
  font-size: 20px;
  line-height: 30px;
  color: #757575;
`;

const WrapperText = styled.div`
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
  border-bottom: 1px solid #979797;
`;

export const CustomerKarte: FC = () => {
  const history = useHistory();

  const { customerId, reservationId } = useParams<{
    customerId: string;
    reservationId: string;
  }>();

  const [karte, setKarte] = useState<UserKarte[]>([]);

  const links = [
    {
      name: '予約情報',
      to: `/reservation/customer/${customerId}/status/${reservationId}`,
      isselected: false,
    },
    {
      name: 'カルテ',
      to: `/reservation/customer/${customerId}/status/${reservationId}/karte`,
      isselected: true,
    },
  ];

  useEffect(() => {
    getUserKarte(customerId)
      .then(({ data }) => {
        setKarte(data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, [customerId]);

  const isReservation = useMemo(() => {
    const { pathname } = history.location;
    return (
      pathname ===
      `/reservation/customer/${customerId}/status/${reservationId}/karte`
    );
  }, [history, customerId, reservationId]);

  const materialNames = useCallback((materials: Material[]) => {
    const arr = materials.map((item) => item.name);
    return arr.join(', ');
  }, []);

  const handleClickArrowLeft = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Wrapper>
      <WrapperNavigation>
        <WrapperGoBack>
          <ArrowIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              height: 20,
              width: 20,
            }}
            direction="LEFT"
            handleClick={handleClickArrowLeft}
          />
          <Margin margin="0 0 0 12px">
            <MainContentTitle text="顧客詳細" />
          </Margin>
        </WrapperGoBack>
        {isReservation && <Navigation links={links} />}
      </WrapperNavigation>
      <WrapperShadow>
        {karte.map((item) => {
          return (
            <WrapperKarteItem key={item.id}>
              <Date>{item.date}</Date>
              <ImageGallery images={item.images} />
              <Label>使用材料</Label>
              <WrapperText>{materialNames(item.materials)}</WrapperText>
              <Label>オススメ商品</Label>
              <WrapperText>{item.recommend.name}</WrapperText>
              <Label>商品説明</Label>
              <WrapperText>{item.recommend.description}</WrapperText>
              <Label>参考画像</Label>
              <ImageGallery images={item.ref_images} />
              <Label>全体メモ</Label>
              <WrapperText>{item.memo}</WrapperText>
            </WrapperKarteItem>
          );
        })}
      </WrapperShadow>
    </Wrapper>
  );
};

const WrapperImages = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
`;

const FirstImage = styled.div`
  margin-right: 24px;
  display: flex;
  img {
    flex: none;
    width: 240px;
    height: 240px;
  }
`;

const OtherImages = styled.div`
  display: flex;
  img {
    flex: none;
    width: 64px;
    height: 64px;
    margin-right: 24px;
  }
`;

const ImageGallery: FC<{ images: string[] }> = ({ images }) => {
  return (
    <WrapperImages>
      <FirstImage>
        <img src={images[0]} alt="img" />
      </FirstImage>
      <OtherImages>
        {images.slice(1).map((img) => {
          return <img key={img} src={img} alt="img" />;
        })}
      </OtherImages>
    </WrapperImages>
  );
};
